import { replace } from "lodash";
 
export function formatCurrency(
  value: number,
  currency: string = "usd",
  locale: string,
  compact: boolean = false,
  digits: number = 1
): string {
  const isNegative = value < 0;
  const absValue = Math.abs(value);
 
  // Expression régulière pour supprimer les zéros inutiles après la virgule
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
 
  if (compact) {
    const lookup = [
      { value: 1e6, symbol: "M" },
      { value: 1e3, symbol: "K" },
    ]; // abréviations pour les milliers et les millions
    const item = lookup.find((item) => absValue >= item.value);
 
    let formattedValue;
    // Si la valeur est supérieure à 1000, diviser par la valeur de l'élément et concaténer le symbole
    if (item) {
      formattedValue = (absValue / item.value)
        .toFixed(digits)
        .replace(regexp, "")
        .concat(item.symbol);
    } else {
      // Pour les valeurs inférieures à 1000, afficher normalement
      formattedValue = absValue.toFixed(digits).replace(regexp, "");
    }
 
    // Récupérer les parties de la devise
    const parts = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).formatToParts(0);
 
    // Récupérer le symbole de la devise
    const currencySymbol =
      parts.find((part) => part.type === "currency")?.value || currency;
    const position =
      parts.findIndex((part) => part.type === "currency") <
      parts.findIndex((part) => part.type === "integer")
        ? "before"
        : "after";
    let result =
      position === "before"
        ? `${currencySymbol}${formattedValue}`
        : `${formattedValue}${currencySymbol}`;
 
    if (locale === "fr" && currency === "USD") {
      result = result.replace("$US", "$");
    }
    if (isNegative) {
      result = `-${result}`;
    }
 
    return locale === "fr" ? replace(result, ".", ",") : result;
  }
 
  // Si la valeur n'est pas compacte, formater normalement
  // Utiliser la fonction NumberFormat de l'API Intl
  let result = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(value).replace(regexp, "");
 
  // Retire le US de dollars si en français
  if (locale === "fr" && currency === "USD") {
    result = result.replace("$US", "$");
  }
 
  return result;
}