import React, { forwardRef, useState, useEffect, useCallback } from 'react';
import {
  Tooltip,
  Box,
  Link,
  ListItemText,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from '../../iconify';
import { NavItemProps, NavConfigProps } from '../types';
import AddBiddingStrategyModal from 'src/layouts/dashboard/AddBiddingStrategyModal';
import { RouterLink } from 'src/routes/components';
import { useRouter } from 'src/routes/hooks';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useDispatch } from 'react-redux';
import { useGetActiveModeQuery, useGetKoBiddingQuery, useGetKoBiddingTargetMaximizeQuery } from 'src/context/api/liveBidding/api';

// Interface des props pour StyledItem
interface StyledItemProps {
  active?: boolean;
  open?: boolean;
  depth?: any;
  disabled?: boolean;
  isLastChild?: boolean;
  isChild: any;
  urlToogle: any;
}

// StyledItem basé sur ButtonBase
const StyledItem = styled('div', {
  shouldForwardProp: (prop: string) =>
    !['active', 'open', 'depth', 'config', 'color', 'isLastChild', 'urlToogle'].includes(prop),
})<StyledItemProps>(({ active, open, depth, theme, isLastChild, isChild, urlToogle }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  textAlign: 'left',
  cursor: 'pointer',
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(isChild ? 1 : 1.5, isChild ? 0 : 2),
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  backgroundColor: !isChild && active ? theme.palette.action.selected : 'transparent',
  '&:hover': {
    backgroundColor: !isChild && theme.palette.action.hover,
  },
  paddingLeft: theme.spacing(3 * depth), // Espacement des niveaux

  // Trait vertical (liens parent-enfant)
  '&::before': depth > 1 && {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    // Si isLastChild est vrai, la hauteur est de 30%, sinon, vérifie si urlToogle est défini
    height: isLastChild
      ? '30%'
      : urlToogle === "/admin/features/live-bidding/search" // Si urlToogle est la bonne URL
        ? '500%' // On met la hauteur à 300% pour l'URL spécifique
        : '100%', // Par défaut, on met la hauteur à 100%
    left: theme.spacing(4 * (depth - 1)),
    width: '3px',
    backgroundColor: theme.palette.divider,
  },

  // Trait horizontal reliant le parent à l'enfant
  '&::after': depth > 1 && {
    content: '""',
    position: 'absolute',
    top: theme.spacing(1.3), // Ajuster la position verticale
    left: theme.spacing(4 * (depth - 1)), // Position horizontale du trait
    width: theme.spacing(2), // Longueur de la courbe horizontale
    height: theme.spacing(2), // Hauteur de la courbe
    border: isLastChild ? `3px solid #333e47` : `2px solid #333e47`, // Couleur et épaisseur du trait
    borderTop: 'none', // Supprimer la bordure supérieure
    borderRight: 'none', // Supprimer la bordure droite
    borderBottomLeftRadius: theme.spacing(2), // Créer la courbure en bas à gauche
    transform: 'rotate(0deg)', // Assurez-vous que la rotation est correcte
  },
}));

// StyledIcon pour gérer l'icône
const StyledIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(2),
}));

// Composant NavItem
type Props = NavItemProps & {
  config: NavConfigProps;
  isLastChild?: boolean;
  isChild?: any;
  urlToogle: any;
};

const NavItem = forwardRef<HTMLDivElement, Props>(function NavItem(
  { item, open, depth = 1, active, config, externalLink, isLastChild, isChild, urlToogle, ...other },
  ref
) {
  const { title, path, icon, info, children, disabled, caption, roles, modal } = item;
  const router = useRouter();
  const workspace = useCurrentWorkspace();



  const { data: dataKoBiddingTargetMaximize, error: dataKoBiddingTargetMaximizeError, isFetching: isFetchingDataKoBiddingTargetMaximize } = useGetKoBiddingTargetMaximizeQuery({
    workspaceId: workspace?.id,
  });

  const { data: dataActiveMode, error: dataActiveModeError, isFetching: isFetchingDataActiveMode, refetch: refetchActiveMode } = useGetActiveModeQuery({
    workspaceId: workspace?.id,
  });

  const { data: dataKoBidding, error: dataKoBiddingError, isFetching: isFetchingDataKoBidding, refetch: refetchKoBidding } = useGetKoBiddingQuery({
    workspaceId: workspace?.id,
  });

  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const workspaceId = workspace?.id || 1;
  const modifiedPath = `${path}?workspace=${workspaceId}`;

  const renderContent = (
    <StyledItem
      ref={ref}
      disabled={disabled}
      active={active}
      title={title}
      depth={depth}
      isLastChild={isLastChild}
      isChild={isChild}
      urlToogle={urlToogle} // Utilisation de urlToogle pour ajuster dynamiquement la hauteur
      {...other}
    >
      {icon && <StyledIcon><img className='icon-navbar' style={{color: 'red', fill: 'red', stroke: 'red'}} width={25} height={25} src={icon?.props?.src} /></StyledIcon>}
      <ListItemText
        title={title}
        sx={{
          backgroundColor: (isChild && active) && '#2f3843',
          '&:hover': {
            backgroundColor: isChild && '#2f3843',
          },
          borderRadius: .4,
          marginLeft: (isChild) ? theme.spacing(.8 * (depth - 1)) : theme.spacing(1 * (depth - 1)),
          p: isChild && .5,
          width: '100%'
        }}
        primary={title}
        secondary={
          caption && (
            <Tooltip title={caption} placement="top-start">
              <span>{caption}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          marginLeft: isChild && theme.spacing(.2 * (depth - 1)),
          typography: 'body2',
          fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          component: 'span',
          typography: 'caption',
          color: 'text.disabled',
        }}
      />

      {title === 'TROAS / Maximize Conversion Value' &&
        <Box component="span" sx={{ ml: 1, backgroundColor: '#7635dc', color: 'white', fontWeight: 700, borderRadius: .5, p: .5, px: 1, fontSize: 13 }}>{dataKoBiddingTargetMaximize ? dataKoBiddingTargetMaximize?.length : 0}</Box>
      }

      {title === 'Max CPC' &&
        <Box component="span" sx={{ ml: 1, backgroundColor: '#7635dc', color: 'white', fontWeight: 700, borderRadius: .5, p: .5, px: 1, fontSize: 13 }}>{dataKoBidding ? dataKoBidding?.length : 0}</Box>
      }

      {title === 'Active Mode' &&
        <Box component="span" sx={{ ml: 1, backgroundColor: '#7635dc', color: 'white', fontWeight: 700, borderRadius: .5, p: .5, px: 1, fontSize: 13 }}>{dataActiveMode ? dataActiveMode?.length : 0}</Box>
      }

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  if (roles && !roles.includes(`${config.currentRole}`)) return null;

  if (externalLink) {
    return (
      <Link href={modifiedPath} target="_blank" rel="noopener" underline="hover" color="inherit">
        {renderContent}
      </Link>
    );
  }

  if (modal) {
    return (
      <>
        <Link component="button" underline="none" color="inherit" onClick={handleOpenModal}>
          {renderContent}
        </Link>
        <AddBiddingStrategyModal open={openModal} onClose={handleCloseModal} />
      </>
    );
  }

  if (children) {
    return renderContent;
  }

  return (
    <Link
      component={RouterLink}
      href={modifiedPath}
      underline="none"
      color="inherit"
      onClick={(e) => {
        if (!disabled) {
          e.preventDefault();
          router.push(modifiedPath);
        }
      }}
    >
      {renderContent}
    </Link>
  );
});

export default NavItem;
