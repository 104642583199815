import { Alert, Box, Button, Checkbox, FormControlLabel, Radio, Stack, Switch, TextField, Typography } from "@mui/material";
import CustomPopover, { usePopover } from "../custom-popover";
import useDatePreset, { presetValues } from "src/hooks/use-date-preset";
import { useEffect, useState } from "react";
import DateRagePicker from "../custom-date-range-picker/custom-date-range-picker";
import { TimeStep } from "src/context/reducers/attribution-settings";
import { useDispatch, useSelector } from "react-redux";
import { setFilterCompare } from 'src/context/reducers/filter-settings/filterSlice';
import { useLocales } from "src/locales";
import { useResponsive } from "src/hooks/use-responsive";
import { differenceInDays } from 'date-fns';
import { enqueueSnackbar } from "notistack";

interface DatePreset {
    displayName: string;
    start: Date;
    end: Date;
    step: string;
}

interface Props {
    features?: { time_step: boolean };
    startDate: Date;
    compareStartDate: any;
    compareEndDate: any;

    endDate: Date;
    timeStep?: TimeStep;
    selected: string;
    preset: string;
    onChangeStartDate: (date: Date | null) => void;
    onChangeEndDate: (date: Date | null) => void;

    onChangeCompareStartDate: (date: Date | null) => void;
    onChangeCompareEndDate: (date: Date | null) => void;

    onChangePreset: (value: string) => void;
    onChangeTimeStep?: (value: TimeStep) => void;
}

interface Options {
    step: string;
    preset: string;
    selected?: "custom" | "preset";
    start?: Date;
    end?: Date;
}

export const useDateRangeSelect = (options: Options) => {
    const datePreset = useDatePreset(options.preset);
    const [startDate, setStartDate] = useState<Date | null>(options.start || null);
    const [endDate, setEndDate] = useState<Date | null>(options.end || null);
    const [selected, setSelected] = useState<"custom" | "preset">(options.selected || "preset");
    const [timeStep, setTimeStep] = useState<TimeStep>(options.step as TimeStep || presetValues[options.preset].step);

    const onChangePreset = (value: string) => {
        datePreset.setPreset(value);
        setTimeStep(presetValues[value].step);

        setSelected("preset");
    };

    const onChangeStartDate = (date: Date | null) => {
        setStartDate(date);
        setSelected("custom");
    }

    const onChangeEndDate = (date: Date | null) => {
        setEndDate(date);
        setSelected("custom");
    }

    const onChangeTimeStep = (value: TimeStep) => {
        setTimeStep(value);
    }

    return {
        preset: selected === "preset" ? datePreset : null,
        start: startDate,
        end: endDate,
        step: timeStep,
        selected,
        onChangeEndDate,
        onChangeStartDate,
        onChangePreset,
        onChangeTimeStep,
    }
}

export default function DateRangeSelect(props: Props) {
    const { t } = useLocales();
    const calendar = usePopover();
    const dispatch = useDispatch();
    const [preset, setPreset] = useState<string>(props.preset);
    const [startDate, setStartDate] = useState<Date | null>(props.startDate);
    const filterStore = useSelector((state: any) => state.filters);

    const [compareStartDate, setCompareStartDate] = useState<Date | null>(props.compareStartDate);
    const [compareEndDate, setCompareEndDate] = useState<Date | null>(props.compareEndDate);

    const [endDate, setEndDate] = useState<Date | null>(props.endDate);
    const [timeStep, setTimeStep] = useState<TimeStep | null>(filterStore.timestep);
    const [selected, setSelected] = useState<"custom" | "preset">("preset");

    const [enableComparison, setEnableComparison] = useState(filterStore?.compare || false);
    const lgUp = useResponsive('up', 'lg');

    useEffect(() => {
        setTimeStep(filterStore.timestep)
    }, [filterStore.timestep])

    useEffect(() => {
        if (!calendar.open) {
            setEnableComparison(filterStore?.compare || false);
        }
    }, [calendar.open, filterStore?.compare]);

    const onChangeStartDate = (date: Date | null) => {
        setStartDate(date);
        setSelected("custom");
    }

    const onChangeCompareStartDate = (date: Date | null) => {
        setCompareStartDate(date);
        setSelected("custom");
    }

    const onChangeCompareEndDate = (date: Date | null) => {
        setCompareEndDate(date);
        setSelected("custom");
    }

    const onChangeEndDate = (date: Date | null) => {
        setEndDate(date);
        setSelected("custom");
    }

    const handleEnableComparisonChange = () => {
        setEnableComparison(!enableComparison);
    };


    const apply = () => {
        // Calculer le nombre de jours dans la période de comparaison et de sélection
        const selectedDays = startDate && endDate ? differenceInDays(endDate, startDate) : 0;
        const compareDays = compareStartDate && compareEndDate ? differenceInDays(compareEndDate, compareStartDate) : 0;

        // Check if the periods match
        if (enableComparison && selectedDays !== compareDays) {
            enqueueSnackbar('The selected and comparison \n date ranges do not match \n in the number of days.', { variant: 'warning' });
        }

        if (selected === 'preset') {
            const { start, end } = presetValues[preset];
            props.onChangeStartDate(start);
            props.onChangeEndDate(end);
        } else {
            props.onChangeEndDate(endDate);
            props.onChangeStartDate(startDate);

            props.onChangeCompareStartDate(compareStartDate);
            props.onChangeCompareEndDate(compareEndDate);
        }

        if (props.features?.time_step === true && props.onChangeTimeStep && timeStep) {
            props.onChangeTimeStep(timeStep);
        }

        dispatch(setFilterCompare(enableComparison));
        calendar.onClose();
    };

    const selectedDays = startDate && endDate ? differenceInDays(endDate, startDate) : 0;
    const compareDays = compareStartDate && compareEndDate ? differenceInDays(compareEndDate, compareStartDate) : 0;

    return (
        <>
            <TextField size="small" onClick={calendar.onOpen}
                sx={{
                    borderRadius: 1,
                    width: { sm: '100%', xs: '100%', lg: 205 }
                }} label="Date" value={props.selected}>
                {props.selected}
            </TextField>

            <CustomPopover
                sx={{ pt: 2, px: 1, pb: 0, display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
                open={calendar.open}
                onClose={calendar.onClose}
            >

                <Stack sx={{ width: '100%', position: 'relative' }} display={'flex'} flexDirection={'column'}>

                    {enableComparison && selectedDays !== null && compareDays !== null && selectedDays !== compareDays && compareDays !== 29 && (
                        <Alert severity="warning" variant="outlined">
                            The selected and comparison date ranges do not match in the number of days.
                        </Alert>
                    )}

                    <DateRagePicker
                        onClose={calendar.onClose}
                        variant="calendar"
                        startDate={selected === "custom" ? startDate : presetValues[preset].start}
                        endDate={selected === "custom" ? endDate : presetValues[preset].end}
                        onChangeEndDate={onChangeEndDate}
                        onChangeStartDate={onChangeStartDate}
                        onChangeCompareStartDate={onChangeCompareStartDate}
                        enableComparison={enableComparison}
                        onChangeCompareEndDate={onChangeCompareEndDate}
                    />

                    <Box
                        sx={{
                            ml: 1,
                            mt: 2,
                            position: lgUp ? 'absolute' : 'relative',
                            top: lgUp ? (enableComparison && selectedDays !== null && compareDays !== null && selectedDays !== compareDays && compareDays !== 29 ? 330 : 280) : 0,
                            left: lgUp ? 17 : 0
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    size={lgUp ? "small" : "medium"}
                                    checked={enableComparison}
                                    onChange={handleEnableComparisonChange}
                                />
                            }
                            label={<Box sx={{ fontSize: lgUp ? 12 : 14 }}>{t('dashboard.filters.enable_comparison')}</Box>}
                        />

                    </Box>

                    <Stack flexDirection={'row'} height={'100%'} spacing={2} mb={2} mt={2} ml={1}>
                        {props.features?.time_step === true ? (
                            <Box>
                                <Typography fontSize={15}>{t('dashboard.filters.group_data')}: </Typography>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            onChange={() => setTimeStep('date')}
                                            checked={timeStep === 'date'}
                                        />
                                    }
                                    label={t('dashboard.filters.daily')}
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            onChange={() => setTimeStep('year_week')}
                                            checked={timeStep === 'year_week'}
                                        />
                                    }
                                    label={t('dashboard.filters.weekly')}
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            onChange={() => setTimeStep('year_month')}
                                            checked={timeStep === 'year_month'}
                                        />
                                    }
                                    label={t('dashboard.filters.monthly')}
                                />
                            </Box>
                        ) : (
                            ''
                        )}
                        <Box flexGrow={1} />
                        <Stack alignSelf={'end'} direction={'row'} spacing={2}>
                            <Button
                                sx={{ maxHeight: '30px', my: 'auto' }}
                                variant="outlined"
                                color="inherit"
                                onClick={() => {
                                    calendar.onClose();
                                    setEnableComparison(filterStore?.compare || false);
                                }}
                            >
                                {t('dashboard.global.cancel')}
                            </Button>
                            <Button
                                sx={{ maxHeight: '30px', my: 'auto' }}
                                color="primary"
                                variant="contained"
                                onClick={apply}
                            >
                                {t('dashboard.filters.ok')}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </CustomPopover>
        </>
    )
}
