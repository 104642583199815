import { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    Stepper,
    Step,
    StepLabel,
    TextField,
    Autocomplete,
    Chip,
    Alert,
} from '@mui/material';
import { useTheme } from "@mui/system";
import { ComponentBlock } from 'src/sections/mui/component-block';
import Iconify from 'src/components/iconify';
import { ComponentBlockCustom } from 'src/sections/mui/component-block-custom';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import {
    useAddActiveModeMutation,
    useGetKeywordsKoBiddingQuery,
    useGetKeywordInfoKoBiddingQuery,
    useGetActiveModeQuery,
    useGetCampaignsQuery,
    useGetMatchTypeKoBiddingQuery,
    useGetCountriesQuery,
    useGetKeywordsQuery,
    useGetProfileQuery,
    useGetAdGroupKoBiddingQuery,
    useGetCountriesKoBiddingQuery,
    useAddKoBiddingMutation,
    useGetProfileKoBiddingQuery,
    useGetKoBiddingQuery,
    useGetKeywordInfoActiveModeQuery,
    useGetCampaignsShoppingQuery,
    useGetAdGroupShoppingQuery,
    useGetProfileShoppingQuery,
    useCampaignInfoShoppingQuery,
    useGetCountriesShoppingQuery,
    useAddShoppingMutation,
    useGetShoppingQuery,
    useCheckActiveModeExistsMutation,
    useCheckKoBiddingExistsMutation,
    useCheckShoppingExistsMutation,
    useCheckShoppingCampaignExistsMutation,
    useGetBiddingStrategyTypeQuery,
    useProfileInfoShoppingQuery,
    useGetCampaignsKoBiddingQuery,
    useGetBiddingStrategyTypeKoBiddingQuery,
    useCampaignInfoKoBiddingQuery,
    useProfileInfoKoBiddingQuery,
    useGetAdGroupKoBiddingTargetMaximizeQuery,
    useCampaignInfoKoBiddingTargetMaximizeQuery,
    useProfileInfoKoBiddingTargetMaximizeQuery,
    useGetCountriesKoBiddingTargetMaximizeQuery,
    useCheckKoBiddingCampaignExistsMutation
} from 'src/context/api/liveBidding/api';
import { useSnackbar } from 'notistack';
import { useLazyTriggerBOQuery } from 'src/context/api/admin/api';

interface AddBiddingStrategyModalProps {
    open: boolean;
    onClose: () => void;
}

const steps = ['Step 1: Basic Info', 'Step 2: Targeting', 'Step 3: Review'];

export default function AddBiddingStrategyModal({ open, onClose }: AddBiddingStrategyModalProps) {
    const [activeStep, setActiveStep] = useState(0);
    const [selection, setSelection] = useState('Search');
    const [subSelection, setSubSelection] = useState('Ko Bidding');
    const workspace: any = useCurrentWorkspace();

    const [activeModeSearchExist] = useCheckActiveModeExistsMutation();
    const [koBiddingSearchExist] = useCheckKoBiddingExistsMutation();
    const [shoppingExist] = useCheckShoppingExistsMutation();
    const [triggerBO] = useLazyTriggerBOQuery();

    // État pour gérer les erreurs
    const [keywordError, setKeywordError] = useState(false);
    const [accountIdSa360Error, setAccountIdSa360Error] = useState(false);
    const [keywordKoBiddingError, setKeywordKoBiddingError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [platformError, setPlatformError] = useState(false);
    const [platformShoppingError, setPlatformShoppingError] = useState(false);
    const [modeKoBiddingError, setModeKoBiddingError] = useState(false);
    const [campaignError, setCampaignError] = useState(false);
    const [adGroupKoBiddingError, setAdGroupKoBiddingError] = useState(false);
    const [campaignKoBiddingError, setCampaignKoBiddingError] = useState(false);
    const [matchTypeKoBiddingErrorState, setMatchTypeKoBiddingErrorState] = useState(false);
    const [deviceError, setDeviceError] = useState(false);
    const [profileError, setProfileError] = useState(false);
    const [profileActiveModeError, setProfileActiveModeError] = useState(false);
    const [platformActiveModeError, setPlatformActiveModeError] = useState(false);
    const [campaignShoppingError, setCampaignShoppingError] = useState(false);
    const [profileShoppingError, setProfileShoppingError] = useState(false);
    const [adGroupShoppingError, setAdGroupShoppingError] = useState(false);
    const [targetRoasError, setTargetRoasError] = useState(false);
    const [searchActiveModeDuplicate, setSearchActiveModeDuplicate] = useState()
    const [searchKoBiddingDuplicate, setSearchKoBiddingDuplicate] = useState()
    const [shoppingDuplicate, setShoppingDuplicate] = useState()
    const [shoppingMaxCPCDuplicate, setShoppingMaxCPCDuplicate]: any = useState()
    const [shoppingTargetROASDuplicate, setShoppingTargetROASDuplicate]: any = useState()


    const { enqueueSnackbar } = useSnackbar();
    const [checkShoppingCampaignExists] = useCheckShoppingCampaignExistsMutation();
    const [checkKoBiddingCampaignExists] = useCheckKoBiddingCampaignExistsMutation();
    const [addActiveMode] = useAddActiveModeMutation();
    const [addKoBidding] = useAddKoBiddingMutation();

    const { refetch: refetchActiveMode } = useGetActiveModeQuery({
        workspaceId: workspace?.id,
    });

    const { refetch: refetchShopping } = useGetShoppingQuery({
        workspaceId: workspace?.id,
    });

    const { refetch: refetchKoBidding } = useGetKoBiddingQuery({
        workspaceId: workspace?.id,
    });

    const [addShopping, { isLoading, error }] = useAddShoppingMutation();

    const [formDataShopping, setFormDataShopping]: any = useState({
        account: '',
        account_id: "",
        campaign_name: [],
        campaign_id: [],
        country: [],
        country_code: [],
        ad_group_name: '',
        ad_group_id: '',
        target_roas: 1,
        bidding_strategy_type: '',
        domain: '',
        mode: "KO Bidding Manual",
        platform: '',
        campaign_type: selection,
        client: '',
        workspaceId: null,
        max_cpc_portfolio_strategy: null,
        name_portfolio_strategy: null,
        target_roas_portfolio_strategy: null,
        status: true,
    });

    console.log(formDataShopping, "formDataShopping")

    const [formData, setFormData]: any = useState({
        keywords: [],
        keyword_id: '',
        country: [],
        account: '',
        frequency: '10 min',
        device_gads: ['desktop'],
        device: ['desktop'],
        campaign_name: [],
        campaign_id: [],
        home_language: "",
        domain: '',
        mode: "Active Mode",
        account_id: "",
        campaign_type: selection,
        platform: '',
        platform_scraping: null,
        client: '',
        workspaceId: null
    });

    const [formDataKoBidding, setFormDataKoBidding]: any = useState({
        client: '',
        status: true,
        mode: '',
        platform: '',
        account_id: '',
        campaign_type: selection,
        campaign_name: [],
        campaign_id: [],
        bidding_strategy_type: '',
        ad_group_name: '',
        ad_group_id: '',
        keyword_bid: '',
        domain: '',
        match_type: '',
        enforce_max_cpc: false,
        target_roas: null,
        max_cpc_portfolio_strategy: null,
        name_portfolio_strategy: null,
        target_roas_portfolio_strategy: null,
        account_id_sa360: null,
        max_cpc: null,
        country: [],
        country_code: [],
        keyword_status: '',
        keyword_id: '',
        account: ''
    });

    const { data: keywordOptions, isLoading: isLoadingKeywords } = useGetKeywordsQuery({
        client: workspace?.bqTable,
    })

    const { data: keywordKoBiddingOptions, isLoading: isLoadingKoBiddingKeywords } = useGetKeywordsKoBiddingQuery({
        client: workspace?.bqTable,
        platform: formDataKoBidding?.platform,
        campaign: formDataKoBidding?.campaign_name,
        account: formDataKoBidding?.account
    })

    const { data: matchTypeKoBiddingOptions, error: matchTypeKoBiddingError, isLoading: isLoadingMatchTypeKoBidding } = useGetMatchTypeKoBiddingQuery({
        client: workspace?.bqTable,
        keyword: formDataKoBidding?.keyword_bid,
        platform: formDataKoBidding?.platform,
        account: formDataKoBidding?.account,
        campaign: formDataKoBidding?.campaign_name,
        bidding_strategy_type: formDataKoBidding?.bidding_strategy_type
    }, {
        skip: formDataKoBidding?.keyword_bid === 0
    });

    const { data: profileOptions, isLoading: isLoadingProfile } = useGetProfileQuery({
        client: workspace?.bqTable,
        keyword: formData.keywords
    }, {
        skip: formData.keywords.length === 0
    });

    const { data: profileKoBiddingOptions, isLoading: isLoadingProfileKoBidding } = useGetProfileKoBiddingQuery({
        client: workspace?.bqTable,
        platform: formDataKoBidding?.platform
    }, {
    });

    const { data: campaignOptions, isLoading: isLoadingCampaigns } = useGetCampaignsQuery({
        client: workspace?.bqTable,
        keyword: formData.keywords,
        profile: formData?.account,
        country: formData?.country,
        platform: formData?.platform_scraping,
        match_type: "Exact"
    }, {
        skip: (formData.keywords.length === 0 || !formData.account) // Vérifie si keywords est vide ou si profile est undefined
    });

    const { data: campaignOptionsKoBidding, isLoading: isLoadingCampaignsKoBidding } = useGetCampaignsKoBiddingQuery({
        client: workspace?.bqTable,
        profile: formDataKoBidding?.account,
        platform: formDataKoBidding?.platform
    }, {
        skip: !formDataKoBidding.account
    });

    const { data: biddingStrategyTypeKoBidding, isLoading: isLoadingBiddingStrategyTypeKoBidding } = useGetBiddingStrategyTypeKoBiddingQuery({
        client: workspace?.bqTable,
        profile: formDataKoBidding?.account,
        campaign: formDataKoBidding?.campaign_name,
        platform: formDataKoBidding?.platform,
    }, {
        skip: (!formDataKoBidding.account || !formDataKoBidding?.campaign_name[0]) // Vérifie si keywords est vide ou si profile est undefined
    });

    useEffect(() => {
        if (
            biddingStrategyTypeKoBidding &&
            formDataKoBidding.account &&
            formDataKoBidding.campaign_name
        ) {
            setFormDataKoBidding((prevFormData: any) => ({
                ...prevFormData,
                bidding_strategy_type: biddingStrategyTypeKoBidding?.bidding_strategy_type,
            }));
        }
    }, [biddingStrategyTypeKoBidding, formDataKoBidding.account, formDataKoBidding.campaign_name]);


    const { data: biddingStrategyType, isLoading: isLoadingBiddingStrategyType } = useGetBiddingStrategyTypeQuery({
        client: workspace?.bqTable,
        profile: formDataShopping?.account,
        campaign: formDataShopping?.campaign_name[0],
        platform: formDataShopping?.platform
    }, {
        skip: (!formDataShopping.account || !formDataShopping?.campaign_name[0]) // Vérifie si keywords est vide ou si profile est undefined
    });

    useEffect(() => {
        if (biddingStrategyType) {
            setFormDataShopping((prevFormData: any) => ({
                ...prevFormData,
                bidding_strategy_type: biddingStrategyType?.bidding_strategy_type,
            }));
        }
    }, [biddingStrategyType])

    const { data: adGroupOptionsKoBidding, isLoading: isLoadingAdGroupKoBidding } = useGetAdGroupKoBiddingQuery({
        client: workspace?.bqTable,
        keyword: formDataKoBidding.keyword_bid,
        profile: formDataKoBidding?.account,
        campaign: formDataKoBidding?.campaign_name,
        match_type: formDataKoBidding?.match_type,
        platform: formDataKoBidding?.platform
    }, {
        skip: (!formDataKoBidding.account || !formDataKoBidding?.campaign_name) // Vérifie si keywords est vide ou si profile est undefined
    });

    const { data: adGroupOptionsKoBiddingTargetMaximize, isLoading: isLoadingAdGroupKoBiddingTargetMaximize } = useGetAdGroupKoBiddingTargetMaximizeQuery({
        client: workspace?.bqTable,
        profile: formDataKoBidding?.account,
        campaign: formDataKoBidding?.campaign_name,
    }, {
        skip: (!formDataKoBidding.account || !formDataKoBidding?.campaign_name) // Vérifie si keywords est vide ou si profile est undefined
    });


    const { data: countryOptions, isLoading: isLoadingCountries } = useGetCountriesQuery({
        client: formData.client,
        keyword: formData.keywords,
        profile: formData?.account,
    });

    const { data: countryKoBiddingOptions, isLoading: isLoadingCountriesKoBidding } = useGetCountriesKoBiddingQuery({
        client: workspace?.bqTable,
        keyword: formDataKoBidding.keyword_bid,
        campaign: formDataKoBidding?.campaign_name,
        profile: formDataKoBidding?.account,
        match_type: formDataKoBidding?.match_type
    });

    const { data: countryKoBiddingTargetMaximizeOptions, isLoading: isLoadingCountriesTargetMaximizeKoBidding } = useGetCountriesKoBiddingTargetMaximizeQuery({
        client: workspace?.bqTable,
        campaign: formDataKoBidding?.campaign_name,
        profile: formDataKoBidding?.account,
        ad_group_name: formDataKoBidding?.ad_group_name,
    });

    useEffect(() => {
        if (countryKoBiddingTargetMaximizeOptions) {
            setFormDataKoBidding((prevFormData: any) => ({
                ...prevFormData,
                country: countryKoBiddingTargetMaximizeOptions[0]?.country,
                country_code: countryKoBiddingTargetMaximizeOptions[0]?.country_code,
            }));
        }
    }, [countryKoBiddingTargetMaximizeOptions])

    const { data: countryShoppingOptions, isLoading: isLoadingCountriesShopping } = useGetCountriesShoppingQuery({
        client: workspace?.bqTable,
        campaign: formDataShopping?.campaign_name[0],
        profile: formDataShopping?.account,
        ad_group_name: formDataShopping.ad_group_name,
    });

    useEffect(() => {
        if (countryShoppingOptions) {
            setFormDataShopping((prevFormData: any) => ({
                ...prevFormData,
                country: [countryShoppingOptions[0]?.country],
                country_code: [countryShoppingOptions[0]?.country_code],
            }));
        }
    }, [countryShoppingOptions])

    const { data: keywordInfo } = useGetKeywordInfoKoBiddingQuery({
        client: formDataKoBidding?.client,
        keyword: formDataKoBidding?.keyword_bid,
        campaign: formDataKoBidding?.campaign_name, // Supposant que tu utilises la première campagne sélectionnée
        profile: formDataKoBidding?.account,
        match_type: formDataKoBidding?.match_type,
        ad_group_name: formDataKoBidding?.ad_group_name,
    }, {
        skip: !formDataKoBidding.client ||
            !formDataKoBidding?.keyword_bid ||
            !formDataKoBidding?.campaign_name ||
            !formDataKoBidding?.account ||
            !formDataKoBidding?.match_type ||
            !formDataKoBidding?.ad_group_name
    });

    const { data: keywordInfoActiveMode } = useGetKeywordInfoActiveModeQuery({
        client: formData.client,
        keyword: formData.keywords,
        campaign: formData.campaign_name[0], // Supposant que tu utilises la première campagne sélectionnée
        profile: formData.account,
        country: formData.country
    }, {
        skip: !formData.client || !formData.keywords || !formData?.account || !formData?.campaign_name || !formData.country // Ignore la requête si client ou keyword_bid n'est pas défini
    });

    const { data: campaignOptionsShopping, isLoading: isLoadingCampaignsShopping } = useGetCampaignsShoppingQuery({
        client: workspace?.bqTable,
        profile: formDataShopping?.account,
        platform: formDataShopping?.platform
    }, {
        skip: !workspace?.bqTable || !formDataShopping.account.length
    });

    const { data: profileOptionsShopping, isLoading: isLoadingProfileShopping } = useGetProfileShoppingQuery({
        client: workspace?.bqTable,
        platform: formDataShopping?.platform
    });

    const { data: adGroupOptionsShopping, isLoading: isLoadingAdGroupsShopping } = useGetAdGroupShoppingQuery({
        client: workspace?.bqTable,
        campaign: formDataShopping?.campaign_name[0],
        profile: formDataShopping?.account,
        platform: formDataShopping?.platform
    }, {
        skip: !formDataShopping.campaign_name.length || !formDataShopping.account.length
    });

    const { data: campaignIdShopping, isLoading: isLoadingCampaignIdShopping } = useCampaignInfoShoppingQuery({
        client: workspace?.bqTable,
        campaign: formDataShopping.campaign_name[0],
        profile: formDataShopping.account,
        ad_group_name: formDataShopping.ad_group_name
    }, {
        skip: !formDataShopping.campaign_name.length || !formDataShopping.account.length || !formDataShopping.ad_group_name.length
    });

    const { data: campaignIdKoBidding, isLoading: isLoadingCampaignIdKoBidding } = useCampaignInfoKoBiddingQuery({
        client: workspace?.bqTable,
        keyword: formDataKoBidding.keyword_bid,
        campaign: formDataKoBidding.campaign_name, // Supposant que tu utilises la première campagne sélectionnée
        profile: formDataKoBidding.account,
        match_type: formDataKoBidding.match_type,
        ad_group_name: formDataKoBidding?.ad_group_name,
    }, {
        skip: !formDataKoBidding?.campaign_name.length || !formDataKoBidding?.account.length || !formDataKoBidding?.ad_group_name.length
    });

    useEffect(() => {
        if (campaignIdKoBidding && campaignIdKoBidding?.length !== 0) {
            setFormDataKoBidding((prevFormData: any) => ({
                ...prevFormData,
                campaign_id: [campaignIdKoBidding[0]?.campaign_id],
            }));
        }
    }, [campaignIdKoBidding])

    const { data: campaignIdKoBiddingTargetMaximize, isLoading: isLoadingCampaignIdKoBiddingTargetMaximize } = useCampaignInfoKoBiddingTargetMaximizeQuery({
        client: workspace?.bqTable,
        campaign: formDataKoBidding.campaign_name[0], // Supposant que tu utilises la première campagne sélectionnée
        profile: formDataKoBidding.account,
    }, {
        skip: !formDataKoBidding.campaign_name.length || !formDataKoBidding.account.length
    });

    useEffect(() => {
        if (campaignIdKoBiddingTargetMaximize?.length !== 0) {
            setFormDataKoBidding((prevFormData: any) => ({
                ...prevFormData,
                campaign_id: [campaignIdKoBiddingTargetMaximize?.campaign_id],
            }));
        }
    }, [campaignIdKoBiddingTargetMaximize])

    const { data: profileIdShopping, isLoading: isLoadingProfileIdShopping } = useProfileInfoShoppingQuery({
        client: workspace?.bqTable,
        campaign: formDataShopping.campaign_name[0],
        profile: formDataShopping.account,
        ad_group_name: formDataShopping.ad_group_name
    }, {
        skip: !formDataShopping.campaign_name.length || !formDataShopping.account.length || !formDataShopping.ad_group_name.length
    });

    useEffect(() => {
        if (profileIdShopping) {
            setFormDataShopping((prevFormData: any) => ({
                ...prevFormData,
                account_id: profileIdShopping[0]?.profile_id,
            }));
        }
    }, [profileIdShopping])


    const { data: profileIdKoBidding, isLoading: isLoadingProfileIdKoBidding } = useProfileInfoKoBiddingQuery({
        client: workspace?.bqTable,
        keyword: formDataKoBidding.keyword_bid,
        campaign: formDataKoBidding.campaign_name,
        profile: formDataKoBidding.account,
        match_type: formDataKoBidding.match_type,
        ad_group_name: formDataKoBidding?.ad_group_name,
        platform: formDataKoBidding?.platform
    }, {
        skip: !formDataKoBidding?.campaign_name.length || !formDataKoBidding?.account.length || !formDataKoBidding?.ad_group_name.length
    });

    useEffect(() => {
        if (profileIdKoBidding && profileIdKoBidding?.length !== 0) {
            setFormDataKoBidding((prevFormData: any) => ({
                ...prevFormData,
                account_id: profileIdKoBidding[0]?.profile_id,
            }));
        }
    }, [profileIdKoBidding])

    const { data: profileIdKoBiddingTargetMaximize, isLoading: isLoadingProfileIdKoBiddingTargetMaximize } = useProfileInfoKoBiddingTargetMaximizeQuery({
        client: workspace?.bqTable,
        ad_group_name: formDataKoBidding?.ad_group_name,
        campaign: formDataKoBidding.campaign_name[0], // Supposant que tu utilises la première campagne sélectionnée
        profile: formDataKoBidding.account,
    }, {
        skip: !formDataKoBidding?.campaign_name.length || !formDataKoBidding?.account.length || !formDataKoBidding?.ad_group_name.length
    });

    useEffect(() => {
        if (profileIdKoBiddingTargetMaximize) {
            setFormDataKoBidding((prevFormData: any) => ({
                ...prevFormData,
                account_id: profileIdKoBiddingTargetMaximize?.profile_id,
            }));
        }
    }, [profileIdKoBiddingTargetMaximize])

    useEffect(() => {
        if (campaignIdShopping) {
            setFormDataShopping((prevFormData: any) => ({
                ...prevFormData,
                campaign_id: [campaignIdShopping[0]?.campaign_id],
            }));
        }
    }, [campaignIdShopping])

    useEffect(() => {
        if (campaignIdShopping) {
            setFormDataShopping((prevFormData: any) => ({
                ...prevFormData,
                campaign_id: [campaignIdShopping[0]?.campaign_id],
            }));
        }
    }, [campaignIdShopping])

    const handleCampaignShoppingChange = (newValue: any) => {
        setFormDataShopping((prevFormData: any) => ({
            ...prevFormData,
            campaign_name: [newValue.campaign_name],
        }));

        if (newValue)
            setCampaignShoppingError(false);
    };

    const handleAdGroupShoppingChange = (newValue: any) => {
        setFormDataShopping((prevFormData: any) => ({
            ...prevFormData,
            ad_group_name: newValue?.ad_group_name || '',
            ad_group_id: newValue?.ad_group_id || '',
        }));

        if (newValue) {
            setAdGroupShoppingError(false);
        }
    };

    useEffect(() => {
        if (keywordInfoActiveMode) {
            setFormData((prevFormDataActiveMode: any) => ({
                ...prevFormDataActiveMode,
                keyword_id: keywordInfoActiveMode[0]?.keyword_id || '',
            }));
        }
    }, [keywordInfoActiveMode])

    useEffect(() => {
        if (keywordInfo) {
            setFormDataKoBidding((prevFormDataKoBidding: any) => ({
                ...prevFormDataKoBidding,
                keyword_id: keywordInfo[0]?.keyword_id || '',
                keyword_status: keywordInfo[0]?.keyword_status || ''
            }));
        }
    }, [keywordInfo])

    useEffect(() => {
        if (countryKoBiddingOptions && countryKoBiddingOptions.length > 0) {
            const countryData = countryKoBiddingOptions[0];
            setFormDataKoBidding((prevFormDataKoBidding: any) => ({
                ...prevFormDataKoBidding,
                country: countryData.country || [],
                country_code: countryData.country_code || []
            }));
        }
    }, [countryKoBiddingOptions]);

    useEffect(() => {
        if (workspace) {
            setFormData(
                {
                    ...formData,
                    domain: workspace?.url,
                    client: workspace?.bqTable,
                    workspaceId: workspace?.id
                }
            );

            setFormDataKoBidding(
                {
                    ...formDataKoBidding,
                    domain: workspace?.url,
                    client: workspace?.bqTable,
                    workspaceId: workspace?.id
                }
            );

            setFormDataShopping(
                {
                    ...formDataShopping,
                    domain: workspace?.url,
                    client: workspace?.bqTable,
                    workspaceId: workspace?.id
                }
            );
        }
    }, [workspace, refetchActiveMode, refetchKoBidding, refetchShopping])

    const validateKoBiddingForm = () => {
        let hasError = false;

        if (!["Target ROAS", "Maximize Conversion Value"].includes(formDataKoBidding?.bidding_strategy_type)) {
            if (!formDataKoBidding.keyword_bid) {
                setKeywordKoBiddingError(true);
                hasError = true;
            }
        }

        if (!["Target ROAS", "Maximize Conversion Value"].includes(formDataKoBidding?.bidding_strategy_type)) {
            if (!formDataKoBidding.keyword_bid) {
                setKeywordKoBiddingError(true);
                hasError = true;
            }
        }

        if (!formDataKoBidding.campaign_name.length) {
            setCampaignKoBiddingError(true);
            hasError = true;
        }

        if (!formDataKoBidding?.ad_group_name) {
            setAdGroupKoBiddingError(true);
            hasError = true;
        }

        if (!["Target ROAS", "Maximize Conversion Value"].includes(formDataKoBidding?.bidding_strategy_type)) {
            if (!formDataKoBidding.keyword_bid) {
                setKeywordKoBiddingError(true);
                hasError = true;
            }
        }


        if (!formDataKoBidding.account) {
            setProfileError(true);
            hasError = true;
        }

        if (!["Target ROAS", "Maximize Conversion Value"].includes(formDataKoBidding?.bidding_strategy_type)) {
            if (!formDataKoBidding.keyword_bid) {
                setKeywordKoBiddingError(true);
                hasError = true;
            }
        }

        if (!["Target ROAS", "Maximize Conversion Value"].includes(formDataKoBidding?.bidding_strategy_type)) {
            if (!formDataKoBidding.keyword_bid) {
                setKeywordKoBiddingError(true);
                hasError = true;
            }
        }


        return !hasError; // Si aucune erreur, retourne true
    };

    const handleNext = () => {
        let hasError = false;

        if (activeStep === 1) {
            if (selection === 'Shopping') {
                // Validation pour Shopping
                if (formDataShopping.campaign_name.length === 0) {
                    setCampaignShoppingError(true);
                    hasError = true;
                }

                if (formDataShopping.account.length === 0) {
                    setProfileShoppingError(true);
                    hasError = true;
                }

                if (!formDataShopping.platform) {
                    setPlatformShoppingError(true);
                    hasError = true;
                }


                if (!formDataShopping.ad_group_name) {
                    setAdGroupShoppingError(true);
                    hasError = true;
                }

                if (hasError) {
                    return;
                }
            }
            if (subSelection === "Active Mode" && selection !== 'Shopping') {
                // Validation pour Active Mode
                if (formData.keywords?.length === 0) {
                    setKeywordError(true);
                    hasError = true;
                }

                if (!formData.country || formData.country.length === 0) {
                    setCountryError(true);
                    hasError = true;
                }

                if (!formData.platform) {
                    setPlatformActiveModeError(true);
                    hasError = true;
                }

                if (!formData.account) {
                    setProfileActiveModeError(true);
                    hasError = true;
                }

                if (formData.campaign_name.length === 0) {
                    setCampaignError(true);
                    hasError = true;
                }

                if (formData.device.length === 0) {
                    setDeviceError(true);
                    hasError = true;
                }

                if (hasError) {
                    return;
                }
            } else if (subSelection === "Ko Bidding" && selection !== 'Shopping') {
                // Validation pour Ko Bidding
                if (!validateKoBiddingForm()) {
                    return; // Si la validation échoue, on arrête ici
                }
            }
        }

        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleNextSkipReview = () => {
        let hasError = false;

        if (activeStep === 1) {
            if (selection === 'Shopping') {
                // Validation pour Shopping
                if (formDataShopping.campaign_name.length === 0) {
                    setCampaignShoppingError(true);
                    hasError = true;
                }

                if (formDataShopping.account.length === 0) {
                    setProfileShoppingError(true);
                    hasError = true;
                }

                if (!formDataShopping.ad_group_name) {
                    setAdGroupShoppingError(true);
                    hasError = true;
                }

                if (hasError) {
                    return;
                }
            }
            if (subSelection === "Active Mode" && selection !== 'Shopping') {
                // Validation pour Active Mode
                if (formData.keywords?.length === 0) {
                    setKeywordError(true);
                    hasError = true;
                }

                if (!formData.country || formData.country.length === 0) {
                    setCountryError(true);
                    hasError = true;
                }


                if (!formData.account) {
                    setProfileActiveModeError(true);
                    hasError = true;
                }

                if (formData.campaign_name.length === 0) {
                    setCampaignError(true);
                    hasError = true;
                }

                if (formData.device.length === 0) {
                    setDeviceError(true);
                    hasError = true;
                }

                if (hasError) {
                    return;
                }
            } else if (subSelection === "Ko Bidding" && selection !== 'Shopping') {
                // Validation pour Ko Bidding
                if (!validateKoBiddingForm()) {
                    return; // Si la validation échoue, on arrête ici
                }
            }
        }

        if (selection === 'Shopping') {
            handleFinishShopping();
        } else if (subSelection === 'Active Mode') {
            handleFinishActiveMode();
        } else {
            handleFinishKoBidding();
        }
    };

    const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
    const isLastStep = activeStep === steps.length - 1;


    const handleFinishActiveMode = async () => {
        try {
            const response: any = await activeModeSearchExist({
                keywords: formData?.keywords,
                country: formData.country,
                profile: formData?.account,
                platform: formData?.platform,
                workspaceId: workspace?.id,
            }).unwrap();

            const result = await addActiveMode(formData).unwrap();
            enqueueSnackbar('Bidding strategy added successfully!', { variant: 'success' });
            refetchActiveMode();

            triggerBO()
            onClose();

            setActiveStep(0)

            setFormData({
                keywords: [],
                country: [],
                account: '',
                frequency: '10 min',
                device_gads: ['desktop'],
                device: ['desktop'],
                campaign_name: [],
                campaign_id: [],
                home_language: "",
                platform: [],
                mode: "Active Mode",
                country_code: [],
                client: workspace?.bqTable,
                domain: workspace?.url,
                workspaceId: workspace?.id,
                account_id: "",
                campaign_type: selection,
            });
        } catch (error: any) {
            setSearchActiveModeDuplicate(error?.data)
            enqueueSnackbar('Failed to add bidding strategy!', { variant: 'error' });
        }
    };

    const handleKeywordChange = (value: any) => {
        setFormData({
            ...formData,
            keywords: value,
            campaign_name: [],
            campaign_id: [],

        });

        if (value) {
            setKeywordError(false);
        }
    };

    const handleProfileChange = (value: any) => {

        setFormData({
            ...formData,
            account: value?.profile,
            account_id: value?.profile_id,
            campaign_name: [],
            campaign_id: [],
        });

        if (value) {
            setProfileActiveModeError(false);
        }
    };

    const handlePlatformActiveModeChange = (value: any) => {
        let platformScrapingValue = "";

        if (value === "Google Ads") {
            platformScrapingValue = "Google";
        } else if (value === "Bing Ads") {
            platformScrapingValue = "Bing";
        }

        setFormData({
            ...formData,
            platform: value,
            platform_scraping: [platformScrapingValue]
        });

        if (value) {
            setPlatformActiveModeError(false);
        }
    };

    const handleDeviceGAdsChange = (deviceGads: string) => {
        let newDeviceGads = [...formData.device_gads];

        // Si le device_gads est déjà présent, on le retire, sinon on l'ajoute
        if (newDeviceGads.includes(deviceGads)) {
            newDeviceGads = newDeviceGads.filter(d => d !== deviceGads);
        } else {
            newDeviceGads.push(deviceGads);
        }

        // Mises à jour du champ `device` selon les règles de device_gads
        let newDevice: string[] = [];

        if (newDeviceGads.includes('desktop') && newDeviceGads.includes('mobile')) {
            newDevice = ['desktop', 'mobile'];  // Obligatoire les deux
        } else if (newDeviceGads.includes('desktop')) {
            newDevice = ['desktop'];  // Desktop obligatoire, mobile optionnel
        } else if (newDeviceGads.includes('mobile')) {
            newDevice = ['mobile'];  // Mobile obligatoire, desktop optionnel
        }

        // Mise à jour de formData avec les nouvelles valeurs
        setFormData({
            ...formData,
            device_gads: deviceGads,
            device: deviceGads,  // Mettre à jour automatiquement le champ `device`
        });

        if (newDevice.length > 0) {
            setDeviceError(false);  // Réinitialise l'erreur si des devices sont sélectionnés
        }
    };

    const handleDeviceChange = (device: any) => {
        setFormData({ ...formData, device: device });
        if (device.length > 0) {
            setDeviceError(false);
        }
    };

    const handleCountryChange = (event: any, newValue: any) => {
        setFormData({
            ...formData,
            country: [newValue?.country_name] || [],
            country_code: [newValue?.country_code] || [],
            home_language: newValue?.home_language || ''
        });

        if (newValue) {
            setCountryError(false);
        }
    };

    const handleCampaignChange = (event: any, newValue: any) => {
        if (newValue && newValue.length > 0) {
            const campaign_names = newValue.map((campaign: any) => campaign.campaign_name);
            const campaign_ids = newValue.map((campaign: any) => campaign.campaign_id);

            const account_id = newValue[0].profile_id;

            setFormData({
                ...formData,
                campaign_name: campaign_names,
                campaign_id: campaign_ids,
                account_id: account_id
            });

            if (campaign_names.length > 0) {
                setCampaignError(false);
            }
        } else {
            setFormData({ ...formData, campaign_name: [], campaign_id: [], account_id: '' });
        }
    };

    const handlePlatformChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            platform: newValue || '',
        });

        if (newValue)
            setPlatformError(false);
    };


    const handlePlatformShoppingChange = (event: any, newValue: any) => {
        setFormDataShopping({
            ...formDataShopping,
            platform: newValue || '',
        });

        if (newValue)
            setPlatformShoppingError(false);
    };

    const handleKeywordKoBiddingChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            keyword_bid: newValue?.keyword_bid || '',
            keyword_id: newValue?.keyword_id || '',
            keyword_status: newValue?.keyword_status || '',
            match_type: ''
        });

        if (newValue)
            setKeywordKoBiddingError(false);
    };

    const handleMatchTypeChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            match_type: newValue || '',
        });

        if (newValue)
            setMatchTypeKoBiddingErrorState(false);
    };

    const handleAccountKoBiddingChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            account: newValue?.profile || '', // Met à jour l'account
            account_id: newValue?.profile_id || '' // Met à jour l'account
        });

        if (newValue)
            setProfileError(false);
    };

    const handleModeKoBiddingChange = (event: any, newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            mode: newValue || '', // Met à jour le mode
        });

        if (newValue)
            setModeKoBiddingError(false);
    };

    const handleCampaignKoBiddingChange = (newValue: any) => {
        setFormDataKoBidding((prevState: any) => ({
            ...prevState,
            campaign_name: newValue?.campaign_name || "",
        }));

        if (newValue)
            setCampaignKoBiddingError(false);
    };

    const handleProfileShoppingChange = (newValue: any) => {
        setFormDataShopping((prevFormData: any) => ({
            ...prevFormData,
            account: newValue?.profile || '',  // Met à jour le nom du profil
        }));

        // Réinitialise l'erreur si une valeur est sélectionnée
        if (newValue) {
            setProfileShoppingError(false);
        }
    };

    const handleAdGroupKoBiddingChange = (newValue: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            ad_group_name: newValue.ad_group || '',
            ad_group_id: newValue.ad_group_id || ''
        });

        if (newValue)
            setAdGroupKoBiddingError(false);
    }

    const handleDecimalChange = (value: string) => {
        // Accepte les nombres avec zéro, ex: '0', '0.', '0.5', etc.
        const regex = /^(0|[1-9]\d*)(\.\d{0,2})?$/;

        // Validation directe via regex, autorise la saisie intermédiaire comme '0.'
        if (regex.test(value) || value === '' || value === '0') {
            setFormDataKoBidding({
                ...formDataKoBidding,
                max_cpc: value, // Stocke la valeur même si c'est un zéro
            });
        } else {
            setFormDataKoBidding({
                ...formDataKoBidding,
                max_cpc: '', // Réinitialise si le format est incorrect
            });
        }
    };

    const handleFinishKoBidding = async () => {
        try {

            if (formDataKoBidding.target_roas) {
                const response: any = await shoppingExist({
                    profile: formDataKoBidding?.account,
                    campaign: formDataKoBidding?.campaign_name,
                    ad_group: formDataKoBidding?.ad_group_name,
                    workspaceId: workspace?.id,
                }).unwrap();

            } else {
                const response: any = await koBiddingSearchExist({
                    keywords: formDataKoBidding?.keyword_bid,
                    profile: formDataKoBidding?.account,
                    campaign: formDataKoBidding?.campaign_name,
                    match_type: formDataKoBidding?.match_type,
                    ad_group: formDataKoBidding?.ad_group_name,
                    workspaceId: workspace?.id,
                }).unwrap();
            }


            // Convertir la valeur max_cpc en float avant d'envoyer le formulaire
            const formDataWithFloatMaxCPC = {
                ...formDataKoBidding,
                max_cpc: parseFloat(formDataKoBidding.max_cpc), // Conversion en float
                target_roas: parseFloat(formDataKoBidding.target_roas), // Sinon, prendre la valeur actuelle de target_roas,
                target_roas_portfolio_strategy: parseFloat(formDataKoBidding.target_roas), // Sinon, prendre la valeur actuelle de target_roas
                max_cpc_portfolio_strategy: parseFloat(formDataKoBidding.max_cpc_portfolio_strategy),
                campaign_name: Array.isArray(formDataKoBidding.campaign_name)
                    ? formDataKoBidding.campaign_name
                    : [formDataKoBidding.campaign_name],
            };

            const result = await addKoBidding(formDataWithFloatMaxCPC).unwrap();
            enqueueSnackbar('KO Bidding strategy added successfully!', { variant: 'success' });
            refetchKoBidding();

            triggerBO()
            onClose();

            setActiveStep(0)

            setFormDataKoBidding({
                status: true,
                mode: '',
                platform: 'Google Ads',
                account_id: '',
                client: workspace?.bqTable,
                domain: workspace?.url,
                workspaceId: workspace?.id,
                campaign_type: 'Search',
                campaign_name: [],
                campaign_id: [],
                ad_group_name: '',
                ad_group_id: '',
                keyword_bid: '',
                match_type: '',
                enforce_max_cpc: false,
                max_cpc: 0.01,
                country: [],
                country_code: [],
                keyword_status: '',
                keyword_id: '',
                account: ''
            });

        } catch (error: any) {
            setSearchKoBiddingDuplicate(error?.data)
            enqueueSnackbar('Failed to add KO Bidding strategy!', { variant: 'error' });
        }
    };

    useEffect(() => {
        const verifyShoppingCampaign = async () => {
            if (activeStep === 2 && selection === 'Shopping') {
                try {
                    // Effectuer la vérification pour l'avertissement Max CPC uniquement lors de l'arrivée à l'étape de révision
                    const { data } = await checkShoppingCampaignExists({
                        workspaceId: workspace?.id,
                        campaign: formDataShopping?.campaign_name[0],
                    }).unwrap();


                } catch (error: any) {
                    setShoppingMaxCPCDuplicate(error?.data);
                    setShoppingTargetROASDuplicate(error?.data);
                }
            }
        };

        verifyShoppingCampaign();
    }, [activeStep, selection, formDataShopping, workspace]);

    useEffect(() => {
        const verifyShoppingCampaign = async () => {
            if (activeStep === 2 && selection === 'Search' && formDataKoBidding?.target_roas) {
                try {
                    // Effectuer la vérification pour l'avertissement Max CPC uniquement lors de l'arrivée à l'étape de révision
                    const { data } = await checkKoBiddingCampaignExists({
                        workspaceId: workspace?.id,
                        campaign: formDataKoBidding?.campaign_name[0],
                    }).unwrap();

                } catch (error: any) {
                    setShoppingMaxCPCDuplicate(error?.data);
                    setShoppingTargetROASDuplicate(error?.data);
                }
            }
        };

        verifyShoppingCampaign();
    }, [activeStep, selection, formDataKoBidding, workspace]);

    const handleFinishShopping = async () => {
        try {
            // Vérifiez d'abord l'existence de la stratégie de shopping
            const response: any = await shoppingExist({
                profile: formDataShopping?.account,
                campaign: formDataShopping?.campaign_name,
                ad_group: formDataShopping?.ad_group_name,
                workspaceId: workspace?.id,
            }).unwrap();

            // Si une stratégie existante est trouvée, utilisez la valeur existante de max_cpc_portfolio_strategy si nécessaire
            const maxCPCPortfolioStrategy = shoppingMaxCPCDuplicate?.max_cpc_portfolio_strategie;
            const targetROASPortfolioStrategy = shoppingTargetROASDuplicate?.target_roas;

            const formDataWithFloatTargetRoas = {
                ...formDataShopping,
                target_roas: targetROASPortfolioStrategy
                    ? targetROASPortfolioStrategy * 100 // Si une stratégie existante est trouvée, l'utiliser
                    : parseFloat(formDataShopping.target_roas), // Sinon, prendre la valeur actuelle de target_roas,
                target_roas_portfolio_strategy: targetROASPortfolioStrategy
                    ? targetROASPortfolioStrategy // Si une stratégie existante est trouvée, l'utiliser
                    : parseFloat(formDataShopping.target_roas), // Sinon, prendre la valeur actuelle de target_roas
                max_cpc_portfolio_strategy: maxCPCPortfolioStrategy
                    ? maxCPCPortfolioStrategy
                    : parseFloat(formDataShopping.max_cpc_portfolio_strategy),
                domain: workspace?.url,
                client: workspace?.bqTable,
                workspaceId: workspace?.id,
            };

            // Appel de la mutation addShopping
            await addShopping(formDataWithFloatTargetRoas).unwrap();

            enqueueSnackbar('Shopping strategy added successfully!', { variant: 'success' });
            triggerBO();
            onClose();

            setActiveStep(0);
            refetchShopping();

            // Réinitialiser les données du formulaire
            setFormDataShopping({
                account: '',
                account_id: '',
                campaign_name: [],
                campaign_id: [],
                country: [],
                country_code: [],
                client: workspace?.bqTable,
                domain: workspace?.url,
                workspaceId: workspace?.id,
                ad_group_name: '',
                ad_group_id: '',
                mode: 'KO Bidding Manual',
                platform: 'Google Ads',
                campaign_type: selection,
                target_roas: 1,
                status: true,
            });
        } catch (error: any) {
            setShoppingDuplicate(error?.data);
            enqueueSnackbar('Failed to add Shopping strategy!', { variant: 'error' });
        }
    };



    useEffect(() => {
        setFormDataShopping({
            ...formDataShopping,
            campaign_type: selection,
        });
    }, [selection]);

    const handleTargetRoasChange = (value: string) => {
        // Permet les nombres jusqu'à deux décimales
        const regex = /^\d+(\.\d{0,2})?$/;

        // Vérifie que la chaîne est un nombre valide et se situe dans la plage acceptable
        if (regex.test(value) || value === '') {
            const parsedValue = parseFloat(value);
            if (parsedValue >= 1 && parsedValue <= 5001) {
                setFormDataShopping({
                    ...formDataShopping,
                    target_roas: value === '' ? '' : value, // Stocke la valeur directement pour la validation progressive
                });
                setTargetRoasError(false); // Enlève l'erreur si la valeur est correcte
            } else {
                setTargetRoasError(true); // Active l'erreur si la valeur dépasse la plage autorisée
            }
        } else {
            setTargetRoasError(true); // Active l'erreur si le format est incorrect
        }
    };


    const handleTargetRoasMaximizeChange = (value: string) => {
        // Permet les nombres jusqu'à deux décimales
        const regex = /^\d+(\.\d{0,2})?$/;

        // Vérifie que la chaîne est un nombre valide et se situe dans la plage acceptable
        if (regex.test(value) || value === '') {
            const parsedValue = parseFloat(value);
            if (parsedValue >= 1 && parsedValue <= 5001) {
                setFormDataKoBidding({
                    ...formDataKoBidding,
                    target_roas: value === '' ? '' : value, // Stocke la valeur directement pour la validation progressive
                });
                setTargetRoasError(false); // Enlève l'erreur si la valeur est correcte
            } else {
                setTargetRoasError(true); // Active l'erreur si la valeur dépasse la plage autorisée
            }
        } else {
            setTargetRoasError(true); // Active l'erreur si le format est incorrect
        }
    };

    const handleMaxCPCMaximizePortfolioStartegy = (value: string) => {
        // Permet les nombres jusqu'à deux décimales
        const regex = /^(0|[1-9]\d*)(\.\d{0,2})?$/;

        // Vérifie que la chaîne est un nombre valide et se situe dans la plage acceptable
        if (regex.test(value) || value === '' || value === '0') {
            setFormDataKoBidding({
                ...formDataKoBidding,
                max_cpc_portfolio_strategy: value, // Stocke la valeur directement pour la validation progressive
                target_roas_portfolio_strategy: formDataKoBidding?.target_roas
            });
        }
    }

    const handleMaxCPCPortfolioStartegy = (value: string) => {
        // Permet les nombres jusqu'à deux décimales
        const regex = /^(0|[1-9]\d*)(\.\d{0,2})?$/;

        // Vérifie que la chaîne est un nombre valide et se situe dans la plage acceptable
        if (regex.test(value) || value === '' || value === '0') {
            setFormDataShopping({
                ...formDataShopping,
                max_cpc_portfolio_strategy: value, // Stocke la valeur directement pour la validation progressive
                target_roas_portfolio_strategy: formDataShopping?.target_roas
            });
        }
    }

    const handleAccountIdSa360Change = (value: any) => {
        setFormDataKoBidding({
            ...formDataKoBidding,
            account_id_sa360: value,
        });

        if (value) {
            setAccountIdSa360Error(false)
        }
    }

    const handleClose = () => {
        onClose();

        // Réinitialiser tous les états
        setActiveStep(0);
        setSelection('Search');
        setSubSelection('Ko Bidding');

        setFormData({
            keywords: [],
            keyword_id: '',
            country: [],
            account: '',
            frequency: '10 min',
            device_gads: ['desktop'],
            device: ['desktop'],
            campaign_name: [],
            campaign_id: [],
            home_language: "",
            domain: '',
            mode: "Active Mode",
            account_id: "",
            campaign_type: 'Search',
            client: '',
            workspaceId: null,
        });

        setFormDataKoBidding({
            client: '',
            status: true,
            mode: '',
            platform: 'Google Ads',
            account_id: '',
            campaign_type: 'Search',
            campaign_name: [],
            campaign_id: [],
            bidding_strategy_type: '',
            ad_group_name: '',
            ad_group_id: '',
            keyword_bid: '',
            domain: '',
            match_type: '',
            enforce_max_cpc: false,
            target_roas: null,
            max_cpc_portfolio_strategy: null,
            name_portfolio_strategy: null,
            target_roas_portfolio_strategy: null,
            account_id_sa360: null,
            max_cpc: null,
            country: [],
            country_code: [],
            keyword_status: '',
            keyword_id: '',
            account: ''
        });

        setFormDataShopping({
            account: '',
            account_id: "",
            campaign_name: [],
            campaign_id: [],
            country: [],
            country_code: [],
            ad_group_name: '',
            ad_group_id: '',
            target_roas: 1,
            bidding_strategy_type: '',
            domain: '',
            mode: "KO Bidding Manual",
            platform: 'Google Ads',
            campaign_type: 'Search',
            client: '',
            workspaceId: null,
            max_cpc_portfolio_strategy: null,
            name_portfolio_strategy: null,
            target_roas_portfolio_strategy: null,
            status: true,
        });

        // Réinitialiser les erreurs
        setKeywordError(false);
        setAccountIdSa360Error(false);
        setKeywordKoBiddingError(false);
        setCountryError(false);
        setPlatformError(false);
        setPlatformShoppingError(false);
        setModeKoBiddingError(false);
        setCampaignError(false);
        setAdGroupKoBiddingError(false);
        setCampaignKoBiddingError(false);
        setMatchTypeKoBiddingErrorState(false);
        setDeviceError(false);
        setProfileError(false);
        setProfileActiveModeError(false);
        setCampaignShoppingError(false);
        setProfileShoppingError(false);
        setAdGroupShoppingError(false);
        setTargetRoasError(false);

        // Réinitialiser les duplications
        setSearchActiveModeDuplicate(undefined);
        setSearchKoBiddingDuplicate(undefined);
        setShoppingDuplicate(undefined);
        setShoppingMaxCPCDuplicate(undefined);
        setShoppingTargetROASDuplicate(undefined);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Add Bidding Strategy</DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Box sx={{ mt: 4 }}>
                    {activeStep === 0 && (
                        <StepOneContent
                            selection={selection}
                            setSelection={setSelection}
                            subSelection={subSelection}
                            setSubSelection={setSubSelection}
                            setFormData={setFormData}
                        />
                    )}
                    {activeStep === 1 && subSelection === 'Active Mode' && selection !== "Shopping" && (
                        <StepTwoContent
                            setFormData={setFormData}
                            isLoadingCampaigns={isLoadingCampaigns}
                            campaignOptions={campaignOptions}
                            profileOptions={profileOptions}
                            isLoadingProfile={isLoadingProfile}
                            keywordOptions={keywordOptions}
                            isLoadingKeywords={isLoadingKeywords}
                            handlePlatformActiveModeChange={handlePlatformActiveModeChange}
                            platformActiveModeError={platformActiveModeError}
                            isLoadingCountries={isLoadingCountries}
                            countriesOptions={countryOptions}
                            formData={formData}
                            handleProfileChange={handleProfileChange}
                            handleKeywordChange={handleKeywordChange}
                            handleCountryChange={handleCountryChange}
                            handleDeviceGAdsChange={handleDeviceGAdsChange}
                            handleDeviceChange={handleDeviceChange}
                            handleCampaignChange={handleCampaignChange}
                            keywordError={keywordError}
                            profileError={profileError}
                            countryError={countryError}
                            campaignError={campaignError}
                            deviceError={deviceError}
                            profileActiveModeError={profileActiveModeError}
                            subSelection={subSelection}
                            selection={selection}
                        />
                    )}

                    {activeStep === 1 && subSelection === 'Ko Bidding' && selection !== "Shopping" && (
                        <StepTwoContentKoBidding
                            handleMaxCPCMaximizePortfolioStartegy={handleMaxCPCMaximizePortfolioStartegy}
                            targetRoasError={targetRoasError}
                            handleTargetRoasMaximizeChange={handleTargetRoasMaximizeChange}
                            adGroupOptionsKoBiddingTargetMaximize={adGroupOptionsKoBiddingTargetMaximize}
                            formDataKoBidding={formDataKoBidding}
                            setFormDataKoBidding={setFormDataKoBidding}
                            handlePlatformChange={handlePlatformChange}
                            handleKeywordKoBiddingChange={handleKeywordKoBiddingChange}
                            keywordKoBiddingOptions={keywordKoBiddingOptions}
                            isLoadingKoBiddingKeywords={isLoadingKoBiddingKeywords}
                            handleMatchTypeChange={handleMatchTypeChange}
                            matchTypeKoBiddingOptions={matchTypeKoBiddingOptions}
                            isLoadingMatchTypeKoBidding={isLoadingMatchTypeKoBidding}
                            handleModeKoBiddingChange={handleModeKoBiddingChange}
                            handleAccountKoBiddingChange={handleAccountKoBiddingChange}
                            profileKoBiddingOptions={profileKoBiddingOptions}
                            isLoadingProfileKoBidding={isLoadingProfileKoBidding}
                            campaignKoBiddingError={campaignKoBiddingError}
                            handleAccountIdSa360Change={handleAccountIdSa360Change}
                            profileError={profileError}
                            modeKoBiddingError={modeKoBiddingError}
                            adGroupKoBiddingError={adGroupKoBiddingError}
                            handleCampaignKoBiddingChange={handleCampaignKoBiddingChange}
                            accountIdSa360Error={accountIdSa360Error}
                            matchTypeKoBiddingErrorState={matchTypeKoBiddingErrorState}
                            campaignOptionsKoBidding={campaignOptionsKoBidding}
                            keywordKoBiddingError={keywordKoBiddingError}
                            isLoadingCampaignsKoBidding={isLoadingCampaignsKoBidding}
                            isLoadingAdGroupKoBidding={isLoadingAdGroupKoBidding}
                            platformError={platformError}
                            handleAdGroupKoBiddingChange={handleAdGroupKoBiddingChange}
                            adGroupOptionsKoBidding={adGroupOptionsKoBidding}
                            handleDecimalChange={handleDecimalChange}
                        />
                    )}

                    {activeStep === 1 && selection === 'Shopping' && (
                        <StepTwoContentShopping
                            formDataShopping={formDataShopping}
                            handleCampaignShoppingChange={handleCampaignShoppingChange}
                            handlePlatformShoppingChange={handlePlatformShoppingChange}
                            platformShoppingError={platformShoppingError}
                            setFormDataShopping={setFormDataShopping}
                            campaignOptionsShopping={campaignOptionsShopping}
                            isLoadingCampaignsShopping={isLoadingCampaignsShopping}
                            campaignShoppingError={campaignShoppingError}
                            handleProfileShoppingChange={handleProfileShoppingChange}
                            isLoadingBiddingStrategyType={isLoadingBiddingStrategyType}
                            biddingStrategyType={biddingStrategyType}
                            isLoadingProfileShopping={isLoadingProfileShopping}
                            profileShoppingError={profileShoppingError}
                            profileOptionsShopping={profileOptionsShopping}
                            adGroupOptionsShopping={adGroupOptionsShopping}
                            isLoadingAdGroupsShopping={isLoadingAdGroupsShopping}
                            handleAdGroupShoppingChange={handleAdGroupShoppingChange}
                            adGroupShoppingError={adGroupShoppingError}
                            targetRoasError={targetRoasError}
                            handleTargetRoasChange={handleTargetRoasChange}
                            handleMaxCPCPortfolioStartegy={handleMaxCPCPortfolioStartegy}
                        />
                    )}

                    {/* Mise à jour de l'étape 3 */}
                    {activeStep === 2 && (
                        <>
                            {selection === 'Shopping' ? (
                                <StepThreeContentShopping formDataShopping={formDataShopping} shoppingDuplicate={shoppingDuplicate} shoppingMaxCPCDuplicate={shoppingMaxCPCDuplicate} shoppingTargetROASDuplicate={shoppingTargetROASDuplicate} />
                            ) : subSelection === 'Active Mode' ? (
                                <StepThreeContent workspace={workspace} formData={formData} searchActiveModeDuplicate={searchActiveModeDuplicate} searchKoBiddingDuplicate={searchKoBiddingDuplicate} />
                            ) : (
                                <StepThreeContentKoBidding searchKoBiddingDuplicate={searchKoBiddingDuplicate} shoppingDuplicate={shoppingDuplicate} formDataKoBidding={formDataKoBidding} shoppingMaxCPCDuplicate={shoppingMaxCPCDuplicate} shoppingTargetROASDuplicate={shoppingTargetROASDuplicate} />
                            )}
                        </>
                    )}

                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={handleClose} sx={{ color: 'white' }}>
                    Cancel
                </Button>
                {activeStep !== 0 && (
                    <Button variant="contained" onClick={handleBack}>
                        Back
                    </Button>
                )}
                <Button
                    variant="contained"
                    onClick={
                        isLastStep
                            ? selection === 'Shopping'
                                ? handleFinishShopping
                                : subSelection === 'Active Mode'
                                    ? handleFinishActiveMode
                                    : handleFinishKoBidding
                            : handleNext
                    }
                >
                    {isLastStep ? 'Finish' : 'Next'}
                </Button>

                {activeStep !== 0 && (
                    <Button variant="outlined" onClick={handleNextSkipReview} sx={{ color: 'white', border: '1px solid #323c48' }}>
                        Skip Review
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

// Step 1: Basic Info
const StepOneContent = ({ selection, setSelection, subSelection, setSubSelection, setFormData }: any) => {
    const theme: any = useTheme();

    const buttonStyle = (isSelected: boolean) => ({
        backgroundColor: isSelected ? '#7635dc' : '#1c252e',
        color: theme.palette.mode === 'dark'
            ? isSelected
                ? 'white'
                : 'white'
            : isSelected
                ? 'white'
                : 'black',
        border: `1px solid ${isSelected ? 'white' : '#2e3842'}`,
        width: '150px',
        height: '50px',
        margin: '10px',
    });


    const handleSelection = (value: string) => {
        setSelection(value);
    };

    const handleSubSelection = (value: string) => {
        setSubSelection(value);
    };

    useEffect(() => {
        if (selection === 'Shopping')
            setSubSelection("Ko Bidding")
    }, [selection])

    return (
        <Box>
            <ComponentBlock sx={{ marginTop: 7, width: '100%' }} title="Choose your campaign type">
                <Box display="flex" width="100%">
                    <Button
                        fullWidth
                        sx={{ flex: 1, marginRight: 1, py: 6 }}
                        style={buttonStyle(selection === 'Search')}
                        onClick={() => handleSelection('Search')}
                    >
                        <Box display={'flex'} flexDirection={'column'} py={4}>
                            <Box>
                                <Iconify
                                    icon={"solar:card-search-bold-duotone"}
                                    width={30}
                                />
                                <Typography>Search</Typography>
                            </Box>
                        </Box>
                    </Button>
                    <Button
                        fullWidth
                        sx={{ flex: 1, marginRight: 1, py: 6 }}
                        style={buttonStyle(selection === 'Shopping')}
                        onClick={() => handleSelection('Shopping')}
                    >
                        <Box display={'flex'} flexDirection={'column'} py={4}>
                            <Box>
                                <Iconify
                                    icon={"solar:tag-bold-duotone"}
                                    width={30}
                                />
                                <Typography>Shopping</Typography>
                            </Box>
                        </Box>
                    </Button>
                    <Button
                        fullWidth
                        disabled
                        className='cursor-not-allowed'
                        sx={{
                            flex: 1, marginRight: 1, py: 6, cursor: 'not-allowed',
                            "&:disabled": {
                                opacity: .4,
                                cursor: "not-allowed",
                                pointerEvents: "all !important",
                            }
                        }}
                        style={buttonStyle(selection === 'Pmax')}
                        onClick={() => handleSelection('Pmax')}
                    >
                        <Box display={'flex'} flexDirection={'column'} py={4}>
                            <Box>
                                <Iconify
                                    icon={"solar:graph-up-bold-duotone"}
                                    width={30}
                                />
                                <Typography>Pmax</Typography>
                            </Box>
                        </Box>
                    </Button>
                </Box>
            </ComponentBlock>

            <ComponentBlock sx={{ marginTop: 5, mb: 1, width: '100%' }} title="Choose your bidding strategy type">
                <Box display="flex" width="100%">
                    <Button
                        fullWidth
                        sx={{ flex: 1, marginRight: 1, py: 6 }}
                        style={buttonStyle(subSelection === 'Ko Bidding')}
                        onClick={() => handleSubSelection('Ko Bidding')}
                    >
                        <Box display={'flex'} flexDirection={'column'} py={4}>
                            <Box>
                                <Iconify
                                    icon={"solar:round-transfer-vertical-bold-duotone"}
                                    width={30}
                                />
                                <Typography>KO Bidding</Typography>
                            </Box>
                        </Box>
                    </Button>
                    <Button
                        fullWidth
                        disabled={selection === "Shopping"}
                        sx={{
                            flex: 1, marginRight: 1, py: 6,
                            "&:disabled": {
                                cursor: "not-allowed",
                                pointerEvents: "all !important",
                                opacity: .4,
                            }
                        }}
                        style={buttonStyle(subSelection === 'Active Mode')}
                        onClick={() => handleSubSelection('Active Mode')}
                    >
                        <Box display={'flex'} flexDirection={'column'} py={4}>
                            <Box>
                                <Iconify
                                    icon={"solar:pause-circle-bold-duotone"}
                                    width={30}
                                />
                                <Typography>Active Mode</Typography>
                            </Box>
                        </Box>
                    </Button>
                </Box>
            </ComponentBlock>
        </Box>
    );
};

// Step 2: Formulaire Active Mode
const StepTwoContent = ({ setFormData, formData, profileOptions, platformActiveModeError, handlePlatformActiveModeChange, isLoadingProfile, campaignOptions, profileActiveModeError, isLoadingCampaigns, isLoadingCountries, countriesOptions, handleKeywordChange, keywordOptions, isLoadingKeywords, keywordError, deviceError, campaignError, countryError, profileError, handleProfileChange, handleCountryChange, handleDeviceChange, handleDeviceGAdsChange, handleCampaignChange }: any) => {
    const isKeywordSelected = formData.keywords.length > 0; // Détermine si un mot-clé est sélectionné
    const isProfileSelected = formData.account.length > 0; // Détermine si un mot-clé est sélectionné

    useEffect(() => {
        if (profileOptions && profileOptions?.length === 1) {
            setFormData({
                ...formData,
                account: profileOptions[0]?.profile,
                account_id: profileOptions[0]?.profile_id,
            });
        }
    }, [profileOptions])

    return (
        <>
            <Box>

                <ComponentBlock sx={{ mt: 3 }} title="Keyword Targeting">

                    {/* Keywords Field */}
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <Autocomplete
                            disableClearable
                            multiple={false}
                            loading={isLoadingKeywords}
                            noOptionsText={keywordOptions ? "Loading..." : "No options"}
                            options={isLoadingKeywords ? [] : keywordOptions || []}
                            value={keywordOptions?.find((option: any) => option === formData.keywords) || null}  // Gestion de la valeur actuelle
                            onChange={(event, newValue) => handleKeywordChange(newValue)}

                            // Personnalisation du filtrage et du tri des options
                            filterOptions={(options, { inputValue }) => {
                                const lowerInputValue = inputValue.toLowerCase();

                                // Correspondance exacte
                                const exactMatches = options.filter((option) =>
                                    option.toLowerCase() === lowerInputValue
                                );

                                // Correspondances partielles
                                const partialMatches = options.filter((option) =>
                                    option.toLowerCase().includes(lowerInputValue) &&
                                    option.toLowerCase() !== lowerInputValue
                                );

                                // Combine exact matches en premier, puis partial matches
                                return [...exactMatches, ...partialMatches];
                            }}

                            renderTags={(value: string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Keyword (Exact Match)"
                                    placeholder="Enter keywords"
                                    error={keywordError}
                                    helperText={keywordError ? "At least one keyword is required" : ""}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: keywordError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />

                    </Box>

                    {!isLoadingProfile && profileOptions !== undefined && (profileOptions?.length !== 1) && (
                        <Box sx={{ width: '100%', mt: 2 }}>
                            <Autocomplete
                                disableClearable
                                disabled={!isKeywordSelected}
                                loading={isLoadingProfile}
                                noOptionsText={profileOptions ? "Loading..." : "No options"}
                                multiple={false}
                                options={isLoadingProfile ? [] : profileOptions || []}
                                value={profileOptions && profileOptions.find((option: any) => option.profile === formData?.account)}  // Correspond à une option
                                getOptionLabel={(option: any) => option.profile}  // Affiche le profil dans la liste déroulante
                                onChange={(event, newValue) => handleProfileChange(newValue)}
                                renderTags={(value: string[], getTagProps) =>
                                    value.map((option: string, index: number) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Account"
                                        error={profileActiveModeError}
                                        helperText={profileActiveModeError ? "At least one account is required" : ""}
                                        placeholder="Enter account"
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                // Applique une bordure rouge lorsque le champ est désactivé
                                                '&.Mui-disabled': {
                                                    border: profileActiveModeError ? '1px solid red' : '',
                                                },
                                                // Applique la couleur de la bordure lorsque le champ est en erreur
                                                '& fieldset': {
                                                    borderColor: profileActiveModeError ? 'red' : '',
                                                },
                                            },
                                        }}
                                    />
                                )}

                            />
                        </Box>
                    )}

                    {/* Country Field */}
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <Autocomplete
                            disableClearable
                            loading={isLoadingCountries}
                            disabled={!isKeywordSelected}
                            noOptionsText={countriesOptions ? "Loading..." : "No options"}
                            options={isLoadingCountries ? [] : countriesOptions || []}
                            getOptionLabel={(option: any) => option.country_name || ''}
                            onChange={handleCountryChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Country"
                                    placeholder="Select a country"
                                    error={countryError}
                                    helperText={countryError ? "Country is required" : ""}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: countryError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>

                    <Box sx={{ width: '100%', mt: 2 }}>
                        <Autocomplete
                            disableClearable
                            multiple={false}
                            options={["Google Ads", "Bing Ads"]}
                            onChange={(event, newValue) => handlePlatformActiveModeChange(newValue)}
                            renderTags={(value: string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Platform"
                                    placeholder="Enter platform"
                                    error={platformActiveModeError}
                                    helperText={platformActiveModeError ? "At least one platform is required" : ""}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: platformActiveModeError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>

                    {/* campaign_name Field */}
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <Autocomplete
                            disableClearable
                            multiple
                            loading={isLoadingCampaigns}
                            noOptionsText={campaignOptions ? "Loading..." : "No options"}
                            disabled={(!isKeywordSelected || !isProfileSelected || !formData?.country || !formData?.platform)}
                            options={isLoadingCampaigns ? [] : campaignOptions || []}  // options = tableau d'objets campagne
                            getOptionLabel={(option: any) => option?.campaign_name || ''}  // Gérer l'affichage du nom des campagnes
                            value={campaignOptions?.filter((option: any) => formData.campaign_name.includes(option.campaign_name)) || []}  // Assurez-vous que la sélection actuelle correspond à ce qui est dans formData
                            onChange={handleCampaignChange}  // Met à jour formData avec les campagnes sélectionnées
                            renderTags={(value: any[], getTagProps) =>
                                value.map((option: any, index: number) => (
                                    <Chip
                                        label={option.campaign_name}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Campaign(s)"
                                    placeholder="Select a campaign"
                                    error={campaignError}
                                    helperText={campaignError ? "At least one campaign is required" : ""}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            // Applique une bordure rouge lorsque le champ est désactivé
                                            '&.Mui-disabled': {
                                                border: campaignError ? '1px solid red' : '',
                                            },
                                            // Applique la couleur de la bordure lorsque le champ est en erreur
                                            '& fieldset': {
                                                borderColor: campaignError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />

                    </Box>
                </ComponentBlock>



                <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Device(s)">
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            disableClearable
                            multiple
                            options={["desktop", "mobile"]}
                            value={formData.device_gads}
                            onChange={(event, newValue) => handleDeviceGAdsChange(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Scraping Device(s)"
                                    placeholder="Select devices"
                                    error={deviceError}
                                    helperText={deviceError ? "At least one device is required" : ""}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: deviceError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>

                    {/* Scraping Device(s) Field */}
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <Autocomplete
                            disableClearable
                            multiple
                            options={["desktop", "mobile"]}
                            value={formData.device}
                            onChange={(event, newValue) => handleDeviceChange(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Scraping Active Mode"
                                    placeholder="Select devices"
                                    error={deviceError}
                                    helperText={deviceError ? "At least one device is required" : ""}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: deviceError ? 'red' : '',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>
                </ComponentBlock>
            </Box>
        </>
    );
};

const StepTwoContentShopping = ({
    formDataShopping,
    setFormDataShopping,
    handleCampaignShoppingChange,
    campaignOptionsShopping,
    isLoadingCampaignsShopping,
    campaignShoppingError,
    handlePlatformShoppingChange,
    profileOptionsShopping,
    isLoadingProfileShopping,
    handleProfileShoppingChange,
    profileShoppingError,
    adGroupOptionsShopping,
    isLoadingAdGroupsShopping,
    platformShoppingError,
    handleAdGroupShoppingChange,
    adGroupShoppingError,
    handleTargetRoasChange,
    targetRoasError,
    handleMaxCPCPortfolioStartegy
}: any) => {

    //loremipsum

    useEffect(() => {
        if (profileOptionsShopping && profileOptionsShopping?.length === 1) {
            setFormDataShopping({
                ...formDataShopping,
                account: profileOptionsShopping[0]?.profile,
            });
        }

    }, [profileOptionsShopping])

    useEffect(() => {
        if (campaignOptionsShopping && campaignOptionsShopping?.length === 1) {
            setFormDataShopping({
                ...formDataShopping,
                campaign_name: campaignOptionsShopping[0]?.campaign_name,
            });
        }

    }, [campaignOptionsShopping])


    useEffect(() => {
        if (adGroupOptionsShopping && adGroupOptionsShopping?.length === 1) {

            setFormDataShopping({
                ...formDataShopping,
                ad_group_name: adGroupOptionsShopping[0]?.ad_group_name,
                ad_group_id: adGroupOptionsShopping[0]?.ad_group_id,
            });
        }

    }, [adGroupOptionsShopping])



    return (
        <Box sx={{ mb: .5 }}>

            {/* Platform Field */}
            <ComponentBlock sx={{ marginTop: 3 }} title="Platform">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        disableClearable
                        options={["Google Ads", "Bing Ads"]}
                        value={formDataShopping?.platform}
                        onChange={handlePlatformShoppingChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Platform"
                                placeholder="Select a platform"
                                error={platformShoppingError}
                                helperText={platformShoppingError ? "At least one platform is required" : ""}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        // Applique une bordure rouge lorsque le champ est désactivé
                                        '&.Mui-disabled': {
                                            border: platformShoppingError ? '1px solid red' : '',
                                        },
                                        // Applique la couleur de la bordure lorsque le champ est en erreur
                                        '& fieldset': {
                                            borderColor: platformShoppingError ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>

            <ComponentBlock sx={{ marginTop: 3 }} title="Account">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        disableClearable
                        disabled={!formDataShopping?.platform || profileOptionsShopping?.length === 1}
                        options={isLoadingProfileShopping ? [] : profileOptionsShopping || []}
                        value={profileOptionsShopping?.find((option: any) => option?.profile === formDataShopping?.account) || null}
                        onChange={handleProfileShoppingChange}
                        getOptionLabel={(option: any) => option.profile} // Affiche le profil dans la liste déroulante
                        isOptionEqualToValue={(option, value) => option?.profile === value?.profile}
                        noOptionsText={isLoadingProfileShopping ? "Loading..." : "No options"}
                        loading={isLoadingProfileShopping}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Account"
                                placeholder="Select an account"
                                error={profileShoppingError}
                                helperText={profileShoppingError ? "At least one account is required" : ""}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        // Applique une bordure rouge lorsque le champ est désactivé
                                        '&.Mui-disabled': {
                                            border: profileShoppingError ? '1px solid red' : '',
                                        },
                                        // Applique la couleur de la bordure lorsque le champ est en erreur
                                        '& fieldset': {
                                            borderColor: profileShoppingError ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>

            <ComponentBlock sx={{ marginTop: 3 }} title="Campaign">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        disabled={!formDataShopping?.account || !formDataShopping?.platform || campaignOptionsShopping?.length === 1}
                        disableClearable
                        loading={isLoadingCampaignsShopping}
                        noOptionsText={campaignOptionsShopping ? "Loading..." : "No options"}
                        options={isLoadingCampaignsShopping ? [] : campaignOptionsShopping || []}
                        getOptionLabel={(option: any) => option?.campaign_name || ''}
                        value={campaignOptionsShopping?.find((option: any) => option.campaign_name === formDataShopping.campaign_name[0]) || null}
                        onChange={(event, newValue) => handleCampaignShoppingChange(newValue)}
                        isOptionEqualToValue={(option, value) => option.campaign_name === value.campaign_name}
                        renderTags={(value: any[], getTagProps) =>
                            value.map((option: any, index: number) => (
                                <Chip label={option.campaign_name} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Campaign(s)"
                                placeholder="Select a campaign"
                                error={campaignShoppingError}
                                helperText={campaignShoppingError ? 'Campaign is required' : ''}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        // Applique une bordure rouge lorsque le champ est désactivé
                                        '&.Mui-disabled': {
                                            border: campaignShoppingError ? '1px solid red' : '',
                                        },

                                        '& fieldset': {
                                            borderColor: campaignShoppingError ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>

            <ComponentBlock sx={{ marginTop: 3 }} title="Bidding Strategy Type">
                <Box sx={{ width: '100%' }}>
                    <TextField
                        sx={{ width: '100%' }}
                        value={formDataShopping?.bidding_strategy_type || ''}
                        variant="outlined"
                        label="Bidding Strategy Type"
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled
                    />
                </Box>
            </ComponentBlock>


            {/* Ad Group Shopping */}
            <ComponentBlock sx={{ marginTop: 3 }} title="Ad Group">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        disableClearable
                        disabled={
                            formDataShopping?.campaign_name?.length === 0 ||
                            formDataShopping?.account?.length === 0 ||
                            adGroupOptionsShopping?.length === 1
                        }
                        loading={isLoadingAdGroupsShopping}
                        noOptionsText={adGroupOptionsShopping ? "Loading..." : "No options"}
                        options={isLoadingAdGroupsShopping ? [] : adGroupOptionsShopping || []}
                        getOptionLabel={(option: any) => option?.ad_group_name || ''}
                        value={
                            adGroupOptionsShopping?.find(
                                (option: any) => option?.ad_group_id === formDataShopping?.ad_group_id
                            ) || null
                        }
                        onChange={(event, newValue) => handleAdGroupShoppingChange(newValue)}
                        isOptionEqualToValue={(option, value) => option?.ad_group_id === value?.ad_group_id}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Ad Group"
                                placeholder="Select an ad group"
                                error={adGroupShoppingError}
                                helperText={adGroupShoppingError ? 'Ad Group is required' : ''}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        // Applique une bordure rouge lorsque le champ est désactivé
                                        '&.Mui-disabled': {
                                            border: adGroupShoppingError ? '1px solid red' : '',
                                        },

                                        '& fieldset': {
                                            borderColor: adGroupShoppingError ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>


            <ComponentBlock sx={{ marginTop: 3 }} title="Target ROAS (1 - 5000)">
                <Box sx={{ width: '100%' }}>
                    <TextField
                        variant="outlined"
                        placeholder="Target ROAS"
                        type="text"
                        value={formDataShopping.target_roas}
                        onChange={(event) => handleTargetRoasChange(event.target.value)}
                        error={targetRoasError}
                        helperText={
                            targetRoasError ? "La valeur doit être entre 1 et 5000" : ""
                        }
                        fullWidth
                    />
                </Box>
            </ComponentBlock>


            <ComponentBlock sx={{ marginTop: 3 }} title="Advanced Settings (optionnal)">
                <Box sx={{ width: '100%' }}>
                    <TextField
                        variant="outlined"
                        placeholder="Max CPC"
                        type="text"
                        value={formDataShopping.max_cpc_portfolio_strategy}
                        onChange={(event) => handleMaxCPCPortfolioStartegy(event.target.value)}
                        fullWidth
                    />
                </Box>
            </ComponentBlock>

        </Box>
    );
};


const StepTwoContentKoBidding = ({ setFormDataKoBidding, adGroupOptionsKoBiddingTargetMaximize, handleMaxCPCMaximizePortfolioStartegy, targetRoasError, handleTargetRoasMaximizeChange, formDataKoBidding, accountIdSa360Error, handleAccountIdSa360Change, modeKoBiddingError, adGroupKoBiddingError, keywordKoBiddingError, platformError, campaignKoBiddingError, profileError, matchTypeKoBiddingErrorState, handleAdGroupKoBiddingChange, handleDecimalChange, adGroupOptionsKoBidding, isLoadingAdGroupKoBidding, isLoadingCampaignsKoBidding, campaignOptionsKoBidding, handleCampaignKoBiddingChange, profileKoBiddingOptions, isLoadingProfileKoBidding, handleAccountKoBiddingChange, handleModeKoBiddingChange, handleMatchTypeChange, isLoadingMatchTypeKoBidding, matchTypeKoBiddingOptions, handlePlatformChange, handleKeywordKoBiddingChange, keywordKoBiddingOptions, isLoadingKoBiddingKeywords }: any) => {

    useEffect(() => {
        if (profileKoBiddingOptions && profileKoBiddingOptions?.length === 1) {
            setFormDataKoBidding({
                ...formDataKoBidding,
                account: profileKoBiddingOptions[0]?.profile,
            });
        }

    }, [profileKoBiddingOptions])

    useEffect(() => {
        if (campaignOptionsKoBidding && campaignOptionsKoBidding?.length === 1) {
            setFormDataKoBidding({
                ...formDataKoBidding,
                campaign_name: campaignOptionsKoBidding[0]?.campaign_name,
            });
        }

    }, [campaignOptionsKoBidding])

    useEffect(() => {
        if (matchTypeKoBiddingOptions && matchTypeKoBiddingOptions?.length === 1) {
            setFormDataKoBidding({
                ...formDataKoBidding,
                match_type: matchTypeKoBiddingOptions[0],
            });
        }

    }, [matchTypeKoBiddingOptions])

    useEffect(() => {
        if (adGroupOptionsKoBidding && adGroupOptionsKoBidding?.length === 1) {
            setFormDataKoBidding({
                ...formDataKoBidding,
                ad_group_name: adGroupOptionsKoBidding[0]?.ad_group,
                ad_group_id: adGroupOptionsKoBidding[0]?.ad_group_id,
            });
        }

    }, [adGroupOptionsKoBidding])

    return (
        <>
            {/* Platform Field */}
            <ComponentBlock sx={{ marginTop: 3 }} title="Platform">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        disableClearable
                        options={["Google Ads", "Bing Ads", "SA360"]}
                        value={formDataKoBidding.platform}
                        onChange={handlePlatformChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Platform"
                                placeholder="Select a platform"
                                error={platformError}
                                helperText={platformError ? "At least one platform is required" : ""}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        // Applique une bordure rouge lorsque le champ est désactivé
                                        '&.Mui-disabled': {
                                            border: platformError ? '1px solid red' : '',
                                        },
                                        // Applique la couleur de la bordure lorsque le champ est en erreur
                                        '& fieldset': {
                                            borderColor: platformError ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>

            {/* Conditionally render the account_id_sa360 field if platform is "SA360" */}
            {formDataKoBidding.platform === "SA360" && (
                <ComponentBlock sx={{ marginTop: 3 }} title="SA360 Account ID">
                    <Box sx={{ width: '100%' }}>
                        <TextField
                            variant="outlined"
                            label="SA360 Account ID"
                            placeholder="SA360 Account ID"
                            value={formDataKoBidding.account_id_sa360 || ""}
                            onChange={(event) => handleAccountIdSa360Change(event.target.value)}
                            error={accountIdSa360Error}
                            helperText={accountIdSa360Error ? "SA360 Account IDis required" : ""}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    // Applique une bordure rouge lorsque le champ est désactivé
                                    '&.Mui-disabled': {
                                        border: accountIdSa360Error ? '1px solid red' : '',
                                    },
                                    // Applique la couleur de la bordure lorsque le champ est en erreur
                                    '& fieldset': {
                                        borderColor: accountIdSa360Error ? 'red' : '',
                                    },
                                },
                            }}
                            fullWidth
                        />
                    </Box>
                </ComponentBlock>
            )}

            <ComponentBlock sx={{ marginTop: 3 }} title="Account">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        disableClearable
                        disabled={!formDataKoBidding?.platform || profileKoBiddingOptions?.length === 1}
                        options={isLoadingProfileKoBidding ? [] : profileKoBiddingOptions || []}
                        value={profileKoBiddingOptions?.find((option: any) => option?.profile === formDataKoBidding?.account) || null}
                        onChange={handleAccountKoBiddingChange}
                        getOptionLabel={(option: any) => option.profile} // Affiche le profil dans la liste déroulante
                        isOptionEqualToValue={(option, value) => option?.profile === value?.profile}
                        noOptionsText={isLoadingProfileKoBidding ? "Loading..." : "No options"}
                        loading={isLoadingProfileKoBidding}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Account"
                                placeholder="Select an account"
                                error={profileError}
                                helperText={profileError ? "At least one account is required" : ""}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        // Applique une bordure rouge lorsque le champ est désactivé
                                        '&.Mui-disabled': {
                                            border: profileError ? '1px solid red' : '',
                                        },
                                        // Applique la couleur de la bordure lorsque le champ est en erreur
                                        '& fieldset': {
                                            borderColor: profileError ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>


            <ComponentBlock sx={{ marginTop: 3 }} title="Campaign">
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        multiple={false} // Désactive la sélection multiple
                        disableClearable
                        disabled={!formDataKoBidding?.account || !formDataKoBidding?.platform || campaignOptionsKoBidding?.length === 1}
                        loading={isLoadingCampaignsKoBidding}
                        noOptionsText={campaignOptionsKoBidding ? "Loading..." : "No options"}
                        options={isLoadingCampaignsKoBidding ? [] : campaignOptionsKoBidding || []}
                        getOptionLabel={(option: any) => option?.campaign_name}  // Affiche les noms des campagnes
                        value={
                            campaignOptionsKoBidding?.find(
                                (option: any) => option.campaign_name === formDataKoBidding?.campaign_name
                            ) || null
                        }
                        onChange={(event, newValue) => handleCampaignKoBiddingChange(newValue)}  // Met à jour l'état avec l'option sélectionnée
                        isOptionEqualToValue={(option, value) => option?.campaign_name === value?.campaign_name} // Comparaison des options et de la valeur
                        renderOption={(props, option, index) => (
                            <li {...props} key={option.campaign_id || index}>
                                {option.campaign_name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Campaign"
                                placeholder="Select a campaign"
                                error={campaignKoBiddingError}
                                helperText={campaignKoBiddingError ? "Campaign is required" : ""}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        // Applique une bordure rouge lorsque le champ est désactivé
                                        '&.Mui-disabled': {
                                            border: campaignKoBiddingError ? '1px solid red' : '',
                                        },
                                        // Applique la couleur de la bordure lorsque le champ est en erreur
                                        '& fieldset': {
                                            borderColor: campaignKoBiddingError ? 'red' : '',
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </ComponentBlock>

            <ComponentBlock sx={{ marginTop: 3, mb: 1 }} title="Bidding Strategy Type">
                <Box sx={{ width: '100%' }}>
                    <TextField
                        sx={{ width: '100%' }}
                        value={formDataKoBidding?.bidding_strategy_type || ''}
                        variant="outlined"
                        label="Bidding Strategy Type"
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled
                    />
                </Box>
            </ComponentBlock>

            {formDataKoBidding?.bidding_strategy_type === "Max CPC" &&
                <>
                    <ComponentBlock sx={{ marginTop: 2 }} title="Keyword">
                        <Box sx={{ width: '100%' }}>
                            <Autocomplete
                                disableClearable
                                options={isLoadingKoBiddingKeywords ? [] : keywordKoBiddingOptions}
                                getOptionLabel={(option: any) => option?.keyword_bid || ''}
                                value={keywordKoBiddingOptions?.find((option: any) => option?.keyword_bid === formDataKoBidding?.keyword_bid) || null}
                                onChange={handleKeywordKoBiddingChange}
                                isOptionEqualToValue={(option, value) => option?.keyword_bid === value?.keyword_bid}

                                // Personnalisation du filtrage et du tri des options
                                filterOptions={(options, { inputValue }) => {
                                    const lowerInputValue = inputValue.toLowerCase();

                                    // Correspondance exacte
                                    const exactMatches = options.filter((option) =>
                                        option.keyword_bid.toLowerCase() === lowerInputValue
                                    );

                                    // Correspondances partielles
                                    const partialMatches = options.filter((option) =>
                                        option.keyword_bid.toLowerCase().includes(lowerInputValue) &&
                                        option.keyword_bid.toLowerCase() !== lowerInputValue
                                    );

                                    // Combine exact matches en premier, puis partial matches
                                    return [...exactMatches, ...partialMatches];
                                }}

                                renderOption={(props, option, index) => (
                                    <li {...props} key={option.keyword_bid}>
                                        {option.keyword_bid}
                                    </li>
                                )}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Keyword"
                                        placeholder="Select a keyword"
                                        error={keywordKoBiddingError}
                                        helperText={keywordKoBiddingError ? "At least one keyword is required" : ""}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: keywordKoBiddingError ? 'red' : '',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </ComponentBlock>

                    <ComponentBlock sx={{ marginTop: 3 }} title="Match Type">
                        <Box sx={{ width: '100%' }}>
                            <Autocomplete
                                disableClearable
                                disabled={!formDataKoBidding.keyword_bid || matchTypeKoBiddingOptions?.length === 1}
                                options={isLoadingMatchTypeKoBidding ? [] : matchTypeKoBiddingOptions || []}
                                value={formDataKoBidding.match_type}
                                onChange={handleMatchTypeChange}
                                loading={isLoadingMatchTypeKoBidding}
                                noOptionsText={matchTypeKoBiddingOptions ? "Loading..." : "No options"}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Match Type"
                                        placeholder="Select a match type"
                                        error={matchTypeKoBiddingErrorState}
                                        helperText={matchTypeKoBiddingErrorState ? "At least one match type is required" : ""}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                // Applique une bordure rouge lorsque le champ est désactivé
                                                '&.Mui-disabled': {
                                                    border: matchTypeKoBiddingErrorState ? '1px solid red' : '',
                                                },
                                                // Applique la couleur de la bordure lorsque le champ est en erreur
                                                '& fieldset': {
                                                    borderColor: matchTypeKoBiddingErrorState ? 'red' : '',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </ComponentBlock>

                    <ComponentBlock sx={{ marginTop: 3 }} title="Ad Group">
                        <Box sx={{ width: '100%' }}>
                            <Autocomplete
                                disableClearable
                                disabled={
                                    formDataKoBidding.keyword_bid === '' ||
                                    formDataKoBidding.account === '' ||
                                    formDataKoBidding.campaign_name.length === 0 ||
                                    adGroupOptionsKoBidding?.length === 1
                                }
                                multiple={false} // Sélection simple pour ad group
                                noOptionsText={adGroupOptionsKoBidding ? "Loading..." : "No options"}
                                options={isLoadingAdGroupKoBidding ? [] : adGroupOptionsKoBidding || []}
                                getOptionLabel={(option: any) => option.ad_group || ''} // Affiche les noms des ad groups
                                value={adGroupOptionsKoBidding?.find((option: any) => option.ad_group_id === formDataKoBidding.ad_group_id) || null} // Correspond à l'ad group sélectionné
                                onChange={(event, newValue) => handleAdGroupKoBiddingChange(newValue)} // Met à jour ad_group_id et ad_group_name
                                renderOption={(props, option) => (
                                    <li {...props} key={option.ad_group_id}> {/* Utilise une clé unique */}
                                        {option.ad_group}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Ad Group"
                                        placeholder="Select an ad group"
                                        error={adGroupKoBiddingError}
                                        helperText={adGroupKoBiddingError ? "At least one ad group is required" : ""}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                // Applique une bordure rouge lorsque le champ est désactivé
                                                '&.Mui-disabled': {
                                                    border: adGroupKoBiddingError ? '1px solid red' : '',
                                                },
                                                // Applique la couleur de la bordure lorsque le champ est en erreur
                                                '& fieldset': {
                                                    borderColor: adGroupKoBiddingError ? 'red' : '',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </ComponentBlock>

                    {/* Mode Field */}
                    <ComponentBlock sx={{ marginTop: 3 }} title="Mode">
                        <Box sx={{ width: '100%' }}>
                            <Autocomplete
                                disableClearable
                                options={["KO Bidding Manual", "KO Bidding Blended CTR", "KO Bidding Ad CTR"]}
                                value={formDataKoBidding.mode}
                                onChange={handleModeKoBiddingChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Mode"
                                        placeholder="Select a mode"
                                        error={modeKoBiddingError}
                                        helperText={modeKoBiddingError ? "At least one mode is required" : ""}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                // Applique une bordure rouge lorsque le champ est désactivé
                                                '&.Mui-disabled': {
                                                    border: modeKoBiddingError ? '1px solid red' : '',
                                                },
                                                // Applique la couleur de la bordure lorsque le champ est en erreur
                                                '& fieldset': {
                                                    borderColor: modeKoBiddingError ? 'red' : '',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </ComponentBlock>

                    <ComponentBlock sx={{ marginTop: 3 }} title="Max CPC (0.01 - 50)">
                        <Box sx={{ width: '100%' }}>
                            <TextField
                                variant="outlined"
                                placeholder="Max CPC"
                                type="text" // Changez le type en 'text' pour permettre la saisie de '0'
                                value={formDataKoBidding.max_cpc}
                                onChange={(event) => handleDecimalChange(event.target.value)} // Appelle la fonction de validation
                                error={parseFloat(formDataKoBidding.max_cpc) < 0.01 || parseFloat(formDataKoBidding.max_cpc) > 50} // Vérifie que la valeur est bien dans la plage 0.01-30
                                helperText={
                                    (parseFloat(formDataKoBidding.max_cpc) < 0.01 || parseFloat(formDataKoBidding.max_cpc) > 50)
                                        ? "Value must be between 0.01 and 50"
                                        : ""
                                }
                                fullWidth
                            />
                        </Box>
                    </ComponentBlock>
                </>
            }

            {['Target ROAS', 'Maximize Conversion Value'].includes(formDataKoBidding?.bidding_strategy_type) && (
                <>
                    <ComponentBlock sx={{ marginTop: 2 }} title="Ad Group Selection">
                        <Box sx={{ width: '100%' }}>
                            <Autocomplete
                                disableClearable
                                disabled={
                                    formDataKoBidding.account === '' ||
                                    formDataKoBidding.campaign_name.length === 0
                                }
                                multiple={false} // Sélection simple pour ad group
                                noOptionsText={adGroupOptionsKoBiddingTargetMaximize ? "Loading..." : "No options"}
                                options={isLoadingAdGroupKoBidding ? [] : adGroupOptionsKoBiddingTargetMaximize || []}
                                getOptionLabel={(option: any) => option.ad_group || ''} // Affiche les noms des ad groups
                                value={adGroupOptionsKoBiddingTargetMaximize?.find((option: any) => option.ad_group_id === formDataKoBidding.ad_group_id) || null} // Correspond à l'ad group sélectionné
                                onChange={(event, newValue) => handleAdGroupKoBiddingChange(newValue)} // Met à jour ad_group_id et ad_group_name
                                renderOption={(props, option) => (
                                    <li {...props} key={option.ad_group_id}> {/* Utilise une clé unique */}
                                        {option.ad_group}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Ad Group"
                                        placeholder="Select an ad group"
                                        error={adGroupKoBiddingError}
                                        helperText={adGroupKoBiddingError ? "At least one ad group is required" : ""}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                // Applique une bordure rouge lorsque le champ est désactivé
                                                '&.Mui-disabled': {
                                                    border: adGroupKoBiddingError ? '1px solid red' : '',
                                                },
                                                // Applique la couleur de la bordure lorsque le champ est en erreur
                                                '& fieldset': {
                                                    borderColor: adGroupKoBiddingError ? 'red' : '',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </ComponentBlock>

                    {/* Mode Field */}
                    <ComponentBlock sx={{ marginTop: 3 }} title="Mode">
                        <Box sx={{ width: '100%' }}>
                            <Autocomplete
                                disableClearable
                                options={["KO Bidding Manual", "KO Bidding Blended CTR", "KO Bidding Ad CTR"]}
                                value={formDataKoBidding.mode}
                                onChange={handleModeKoBiddingChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Mode"
                                        placeholder="Select a mode"
                                        error={modeKoBiddingError}
                                        helperText={modeKoBiddingError ? "At least one mode is required" : ""}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                // Applique une bordure rouge lorsque le champ est désactivé
                                                '&.Mui-disabled': {
                                                    border: modeKoBiddingError ? '1px solid red' : '',
                                                },
                                                // Applique la couleur de la bordure lorsque le champ est en erreur
                                                '& fieldset': {
                                                    borderColor: modeKoBiddingError ? 'red' : '',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </ComponentBlock>


                    <ComponentBlock sx={{ marginTop: 3 }} title="Target ROAS (1 - 5000)">
                        <Box sx={{ width: '100%' }}>
                            <TextField
                                variant="outlined"
                                placeholder="Target ROAS"
                                type="text"
                                value={formDataKoBidding.target_roas}
                                onChange={(event) => handleTargetRoasMaximizeChange(event.target.value)}
                                error={targetRoasError}
                                helperText={
                                    targetRoasError ? "La valeur doit être entre 1 et 5000" : ""
                                }
                                fullWidth
                            />
                        </Box>
                    </ComponentBlock>


                    <ComponentBlock sx={{ marginTop: 3 }} title="Advanced Settings (optionnal)">
                        <Box sx={{ width: '100%' }}>
                            <TextField
                                variant="outlined"
                                placeholder="Max CPC"
                                type="text"
                                value={formDataKoBidding.max_cpc_portfolio_strategy}
                                onChange={(event) => handleMaxCPCMaximizePortfolioStartegy(event.target.value)}
                                fullWidth
                            />
                        </Box>
                    </ComponentBlock>

                </>
            )}

        </>
    );
};


// Step 3: Review
const StepThreeContent = ({ formData, searchActiveModeDuplicate, workspace }: any) => {
    return (
        <Box sx={{ mt: 4 }}>

            {searchActiveModeDuplicate &&
                <Alert sx={{ my: 3, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                    <Typography sx={{ fontWeight: 900 }}>{searchActiveModeDuplicate?.title}</Typography>
                    <Typography fontSize={14} sx={{ mt: .4 }}>{searchActiveModeDuplicate?.description}</Typography>
                </Alert>
            }

            <ComponentBlock sx={{ marginTop: 2 }} title="Review Your Selections(s)">
                <ComponentBlockCustom sx={{ mb: 0 }} title="Monitoring">
                    <Typography>
                        The presence of the {workspace?.url} domain on Google Ads in{' '}
                        <span
                            style={{
                                backgroundColor: 'black',
                                color: '#b884f3',
                                border: '1px solid #454f5b',
                                padding: 4,
                                borderRadius: '6px',
                            }}
                        >
                            {formData.country}
                        </span>{' '}
                        will be monitored in real time for the keyword{' '}
                        <span
                            style={{
                                backgroundColor: 'black',
                                color: '#b884f3',
                                border: '1px solid #454f5b',
                                padding: 4,
                                borderRadius: '6px',
                            }}
                        >
                            {formData.keywords}
                        </span>{' '}
                        on{' '}
                        {formData.device.length === 2 ? (
                            <>
                                <span
                                    style={{
                                        backgroundColor: 'black',
                                        color: '#b884f3',
                                        border: '1px solid #454f5b',
                                        padding: 4,
                                        borderRadius: '6px',
                                    }}
                                >
                                    {formData.device[0]}
                                </span>{' '}
                                and{' '}
                                <span
                                    style={{
                                        backgroundColor: 'black',
                                        color: '#b884f3',
                                        border: '1px solid #454f5b',
                                        padding: 4,
                                        borderRadius: '6px',
                                    }}
                                >
                                    {formData.device[1]}
                                </span>
                            </>
                        ) : (
                            <span
                                style={{
                                    backgroundColor: 'black',
                                    color: '#b884f3',
                                    border: '1px solid #454f5b',
                                    padding: 4,
                                    borderRadius: '6px',
                                }}
                            >
                                {formData.device[0]}
                            </span>
                        )}
                        .
                    </Typography>
                </ComponentBlockCustom>

                <ComponentBlock sx={{ marginTop: 2 }} title="Activation">
                    <Typography>Each time no competitor is detected, this keyword will be added to the exclusion list for the <span
                        style={{
                            backgroundColor: 'black',
                            color: '#b884f3',
                            border: '1px solid #454f5b',
                            padding: 4,
                            borderRadius: '6px',
                        }}
                    >{formData.campaign_name}</span> campaign of the <span
                        style={{
                            backgroundColor: 'black',
                            color: '#b884f3',
                            border: '1px solid #454f5b',
                            padding: 4,
                            borderRadius: '6px',
                        }}
                    >{formData.account}</span> account in, {formData.device.length === 2 ? (
                        <>
                            <span
                                style={{
                                    backgroundColor: 'black',
                                    color: '#b884f3',
                                    border: '1px solid #454f5b',
                                    padding: 4,
                                    borderRadius: '6px',
                                }}
                            >
                                {formData.device[0]}
                            </span>{' '}
                            and{' '}
                            <span
                                style={{
                                    backgroundColor: 'black',
                                    color: '#b884f3',
                                    border: '1px solid #454f5b',
                                    padding: 4,
                                    borderRadius: '6px',
                                }}
                            >
                                {formData.device[1]}
                            </span>
                        </>
                    ) : (
                        <span
                            style={{
                                backgroundColor: 'black',
                                color: '#b884f3',
                                border: '1px solid #454f5b',
                                padding: 4,
                                borderRadius: '6px',
                            }}
                        >
                            {formData.device[0]}
                        </span>
                    )}, thus pausing the bidding on this keyword/device(s). As soon as one or more competitors are detected again, the keyword
                        will be removed from the exclusion list in order to resume bidding.</Typography>
                </ComponentBlock>

            </ComponentBlock>
        </Box>
    );
};

// Step 3: Review pour KO Bidding
const StepThreeContentKoBidding = ({ formDataKoBidding, searchKoBiddingDuplicate, shoppingTargetROASDuplicate, shoppingMaxCPCDuplicate }: any) => {

    return (
        <Box sx={{ mt: 4 }}>
            {searchKoBiddingDuplicate &&
                <Alert sx={{ my: 3, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                    <Typography sx={{ fontWeight: 900 }}>{searchKoBiddingDuplicate?.title}</Typography>
                    <Typography fontSize={14} sx={{ mt: .4 }}>{searchKoBiddingDuplicate?.description}</Typography>
                </Alert>
            }


            <ComponentBlock sx={{ marginTop: 2 }} title="Review Your KO Bidding Strategy">
                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Client">
                    <Typography variant="body1">{formDataKoBidding.client}</Typography>
                </ComponentBlockCustom>

                {!formDataKoBidding?.target_roas &&
                    <ComponentBlockCustom sx={{ marginTop: 2 }} title="Platform">
                        <Typography variant="body1">{formDataKoBidding.platform}</Typography>
                    </ComponentBlockCustom>
                }

                {!formDataKoBidding?.target_roas &&
                    <ComponentBlockCustom sx={{ marginTop: 2 }} title="Keyword">
                        <Typography variant="body1">{formDataKoBidding.keyword_bid}</Typography>
                    </ComponentBlockCustom>
                }

                {!formDataKoBidding?.target_roas &&
                    <ComponentBlockCustom sx={{ marginTop: 2 }} title="Match Type">
                        <Typography variant="body1">{formDataKoBidding.match_type}</Typography>
                    </ComponentBlockCustom>
                }

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Account">
                    <Typography variant="body1">{formDataKoBidding.account}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Campaign Name">
                    <Typography variant="body1">{formDataKoBidding.campaign_name}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Ad Group">
                    <Typography variant="body1">{formDataKoBidding?.ad_group_name}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Target ROAS">
                    {shoppingTargetROASDuplicate ? (
                        <Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    textDecoration: 'line-through',
                                    backgroundColor: 'rgba(255, 0, 0, 0.2)', // Fond rouge pâle
                                    padding: '4px',
                                    borderRadius: '4px',
                                    display: 'inline-block',
                                    mr: 1,
                                }}
                            >
                                {formDataKoBidding.target_roas}
                            </Typography>
                            <Typography variant="body1" sx={{ display: 'inline' }}>
                                {shoppingTargetROASDuplicate.target_roas * 100}
                            </Typography>

                            <Alert sx={{ mt: 1, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                                <Typography sx={{ fontWeight: 900 }}>{shoppingTargetROASDuplicate?.title}</Typography>
                                <Typography fontSize={14} sx={{ mt: 0.4 }}>{shoppingTargetROASDuplicate?.description}</Typography>
                            </Alert>
                        </Box>
                    ) : (
                        <Typography variant="body1">{formDataKoBidding.target_roas}</Typography>
                    )}
                </ComponentBlockCustom>

                {!formDataKoBidding?.target_roas &&
                    <ComponentBlockCustom sx={{ marginTop: 2 }} title="Max CPC">
                        <Typography variant="body1">{formDataKoBidding.max_cpc}</Typography>
                    </ComponentBlockCustom>
                }

                {formDataKoBidding?.max_cpc_portfolio_strategy && (
                    <ComponentBlockCustom sx={{ marginTop: 2 }} title="Advanced Settings (optional)">
                        {shoppingMaxCPCDuplicate ? (
                            <Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        textDecoration: 'line-through',
                                        backgroundColor: 'rgba(255, 0, 0, 0.2)', // Fond rouge pâle
                                        padding: '4px',
                                        borderRadius: '4px',
                                        display: 'inline-block',
                                        mr: 1,
                                    }}
                                >
                                    {formDataKoBidding.max_cpc_portfolio_strategy}
                                </Typography>
                                <Typography variant="body1" sx={{ display: 'inline' }}>
                                    {shoppingMaxCPCDuplicate.max_cpc_portfolio_strategie}
                                </Typography>

                                {shoppingMaxCPCDuplicate &&
                                    <Alert sx={{ mt: 1, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                                        <Typography sx={{ fontWeight: 900 }}>{shoppingMaxCPCDuplicate?.title}</Typography>
                                        <Typography fontSize={14} sx={{ mt: 0.4 }}>{shoppingMaxCPCDuplicate?.description}</Typography>
                                    </Alert>
                                }
                            </Box>
                        ) : (
                            <Typography variant="body1">{formDataKoBidding.max_cpc_portfolio_strategy}</Typography>
                        )}
                    </ComponentBlockCustom>
                )}

                {!formDataKoBidding?.max_cpc_portfolio_strategy && shoppingMaxCPCDuplicate && (
                    <ComponentBlockCustom sx={{ marginTop: 2 }} title="Advanced Settings (optional)">
                        <Typography variant="body1" sx={{ display: 'inline' }}>
                            {shoppingMaxCPCDuplicate.max_cpc_portfolio_strategie}
                        </Typography>

                        <Alert sx={{ mt: 1, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                            <Typography sx={{ fontWeight: 900 }}>{shoppingMaxCPCDuplicate?.title}</Typography>
                            <Typography fontSize={14} sx={{ mt: 0.4 }}>{shoppingMaxCPCDuplicate?.description}</Typography>
                        </Alert>
                    </ComponentBlockCustom>
                )}

            </ComponentBlock>
        </Box>
    );
};

const StepThreeContentShopping = ({ formDataShopping, shoppingDuplicate, shoppingMaxCPCDuplicate, shoppingTargetROASDuplicate }: any) => {
    return (
        <Box sx={{ mt: 4 }}>
            {shoppingDuplicate &&
                <Alert sx={{ my: 3, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                    <Typography sx={{ fontWeight: 900 }}>{shoppingDuplicate?.title}</Typography>
                    <Typography fontSize={14} sx={{ mt: 0.4 }}>{shoppingDuplicate?.description}</Typography>
                </Alert>
            }

            <ComponentBlock sx={{ marginTop: 2, mb: 1 }} title="Review Your Shopping Strategy">

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Campaign(s)">
                    <Typography variant="body1">{formDataShopping.campaign_name[0]}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Account">
                    <Typography variant="body1">{formDataShopping.account}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Ad Group">
                    <Typography variant="body1">{formDataShopping.ad_group_name}</Typography>
                </ComponentBlockCustom>

                <ComponentBlockCustom sx={{ marginTop: 2 }} title="Target ROAS">
                    {shoppingTargetROASDuplicate ? (
                        <Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    textDecoration: 'line-through',
                                    backgroundColor: 'rgba(255, 0, 0, 0.2)', // Fond rouge pâle
                                    padding: '4px',
                                    borderRadius: '4px',
                                    display: 'inline-block',
                                    mr: 1,
                                }}
                            >
                                {formDataShopping.target_roas}
                            </Typography>
                            <Typography variant="body1" sx={{ display: 'inline' }}>
                                {shoppingTargetROASDuplicate.target_roas * 100}
                            </Typography>

                            <Alert sx={{ mt: 1, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                                <Typography sx={{ fontWeight: 900 }}>{shoppingTargetROASDuplicate?.title}</Typography>
                                <Typography fontSize={14} sx={{ mt: 0.4 }}>{shoppingTargetROASDuplicate?.description}</Typography>
                            </Alert>
                        </Box>
                    ) : (
                        <Typography variant="body1">{formDataShopping.target_roas}</Typography>
                    )}
                </ComponentBlockCustom>

                {formDataShopping?.max_cpc_portfolio_strategy && (
                    <ComponentBlockCustom sx={{ marginTop: 2 }} title="Advanced Settings (optional)">
                        {shoppingMaxCPCDuplicate ? (
                            <Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        textDecoration: 'line-through',
                                        backgroundColor: 'rgba(255, 0, 0, 0.2)', // Fond rouge pâle
                                        padding: '4px',
                                        borderRadius: '4px',
                                        display: 'inline-block',
                                        mr: 1,
                                    }}
                                >
                                    {formDataShopping.max_cpc_portfolio_strategy}
                                </Typography>
                                <Typography variant="body1" sx={{ display: 'inline' }}>
                                    {shoppingMaxCPCDuplicate.max_cpc_portfolio_strategie}
                                </Typography>

                                {shoppingMaxCPCDuplicate &&
                                    <Alert sx={{ mt: 1, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                                        <Typography sx={{ fontWeight: 900 }}>{shoppingMaxCPCDuplicate?.title}</Typography>
                                        <Typography fontSize={14} sx={{ mt: 0.4 }}>{shoppingMaxCPCDuplicate?.description}</Typography>
                                    </Alert>
                                }
                            </Box>
                        ) : (
                            <Typography variant="body1">{formDataShopping.max_cpc_portfolio_strategy}</Typography>
                        )}
                    </ComponentBlockCustom>
                )}

                {!formDataShopping?.max_cpc_portfolio_strategy && shoppingMaxCPCDuplicate && (
                    <ComponentBlockCustom sx={{ marginTop: 2 }} title="Advanced Settings (optional)">
                        <Typography variant="body1" sx={{ display: 'inline' }}>
                            {shoppingMaxCPCDuplicate.max_cpc_portfolio_strategie}
                        </Typography>

                        <Alert sx={{ mt: 1, backgroundColor: '#322f33', color: '#e0ac8a' }} severity="error">
                            <Typography sx={{ fontWeight: 900 }}>{shoppingMaxCPCDuplicate?.title}</Typography>
                            <Typography fontSize={14} sx={{ mt: 0.4 }}>{shoppingMaxCPCDuplicate?.description}</Typography>
                        </Alert>
                    </ComponentBlockCustom>
                )}

            </ComponentBlock>
        </Box>
    );
};
