import { Container } from "@mui/system";

export default function CampaignBased() {


    return (
        <>
            <Container maxWidth="xl" sx={{ marginTop: 1 }}>
                Campaign Based
            </Container>
        </>
    );
}
