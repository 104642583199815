import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { useSettingsContext } from '../settings';


// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  loader?: boolean
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, loader = false, sx, ...other }, ref) => {
    const theme = useTheme();
    const settings = useSettingsContext();
    const isNavMini = settings.themeLayout === 'mini';

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    const logo =
      isNavMini

        ? <Box
          ref={ref}
          component="div"
          sx={{
            position: 'relative',
            width: 30,
            height: 30,
            display: 'inline-flex',
            ...sx,
          }}
          {...other}
        >
          <img src='/assets/logo.png' />
        </Box>
        : (
          <Box
            ref={ref}
            component="div"
            sx={{
              width: 200,
              height: 30,
              display: 'inline-flex',
              ...sx,
            }}
            {...other}
          >
            {loader
              ? <img src='/assets/logo.png' />
              : <>
                <img src='/assets/logo_white_nav.png' />
              </>
            }
          </Box>
        );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
