import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { SplashScreen } from 'src/components/loading-screen';
import { useCurrentUserQuery } from 'src/context/api/auth';
import Auth from 'src/pages/auth';
import { useRouter } from 'src/routes/hooks';

const signinPath = '/auth/signin';

export default function AuthGuard(props: Props) {
  const router = useRouter()
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, user: auth0User, isLoading: isLoadingAuth0 } = useAuth0();
  const { data: user, isLoading, isFetching, isError, error } = useCurrentUserQuery();



  const checkAuth = useCallback(() => {
    if (isAuthenticated && auth0User) {
      setAuth(true)
      setLoading(isLoadingAuth0)
    } else {
      setAuth(!error)
      setLoading(isLoading)

    }

  }, [user, error, auth0User])

  useEffect(() => {
    checkAuth()
  }, [user, error]);

  if (loading) {
    return <SplashScreen />
  }


  if (!auth) {
    return <Auth />

  }


  return <>{props.children}</>
}

interface Props {
  children: React.ReactNode;
}
