import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { fPercent } from 'src/utils/format-number';
import Box from '@mui/material/Box';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { generateAnnotations } from 'src/utils/graph-demo-annotations';

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  labels: any;
};

export default function ChartColumnStacked({ series, labels }: Props) {
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const workspace = useCurrentWorkspace()
  const annotations = generateAnnotations(workspace,filterStore,labels)

  enum ColorSchema {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
  }

  const chartOptions = useChart({
    chart: {
      stacked: false,
      zoom: {
        enabled: true,
      },
    },
    labels: labels || [],
    series: series,
    legend: {
      itemMargin: {
        vertical: 8,
      },
      markers: {
        customHTML: function () {
          return '<div style="width: 16px; height: 16px; border-radius: 50%; background-color: currentcolor;"></div>';
        },
      },
      position: 'top',
      offsetY: 0,
    },
    fill: {
      colors: [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, theme.palette[ColorSchema.WARNING].light, theme.palette[ColorSchema.INFO].light, theme.palette[ColorSchema.ERROR].light]
    },
    colors: [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, theme.palette[ColorSchema.WARNING].light, theme.palette[ColorSchema.INFO].light, theme.palette[ColorSchema.ERROR].light],
    plotOptions: {
      bar: { barHeight: '80%', columnWidth: '40%', borderRadius: 0 },

    },


    stroke: {
      show: false,
    },
    tooltip: {

      shared: true,
      intersect: false,
      y: {
        formatter: (val: number) => {
          return `${fPercent(val)}` || '0%';
        },
      },
    },
    yaxis: [
      {
        labels: { formatter(value) { return fPercent(value) } }
      },
    ],
    annotations: {
      xaxis: annotations
    },
  });

  return (
    <Box sx={{
      '.apexcharts-canvas .apexcharts-legend-text': {
        lineHeight: '18px !important',
        textTransform: 'lowercase !important',
      },
    }}>
      <Chart dir="ltr" type="bar" series={series} options={chartOptions} height={420} />
    </Box>
  );
}
