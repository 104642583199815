import { Box, Button, Container } from '@mui/material';
import { useTheme } from '@mui/system';
import { useState } from 'react';
import { useParams } from 'react-router';
import MaintenanceIllustration from 'src/assets/illustrations/maintenance-illustration';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';

const Maintenance = () => {
    const { t } = useLocales();
    const theme = useTheme();
    const router = useRouter();
    const [workspaceId, setWorkspaceId] = useState<number>(0);
    const workspace = useCurrentWorkspace();

    return (
        <div style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: 'url(/assets/background/background.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>

            <div style={{
                width: '100%',
                maxWidth: '400px',
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '40px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
                    <div style={{
                        width: '100px',
                        height: '100px',

                        position: 'relative',
                        borderRadius: '50%',
                        overflow: 'hidden',
                    }}>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            backgroundImage: 'linear-gradient(180deg, rgba(0, 167, 111, 1) -100%, rgba(0, 167, 111, 0.22) 100%)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 0,
                            opacity: 0.3,
                            borderRadius: '50%',
                        }}>

                        </div>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            zIndex: 1,
                        }}>

                            <img
                                src='/assets/logo.png'
                                alt="Logo"
                                style={{
                                    width: '60px',
                                    height: 'auto',
                                }}
                            />
                        </div>
                    </div>
                </div>
                <Container style={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '50px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 5, alignItems: 'center' }}>
                        <Box>
                            <h3>Application currently under maintenance</h3>
                            <p style={{ textWrap: 'wrap', marginTop: 20 }}>Our tech teams are carrying out a maintenance operation on the Cross Brand application. Only the application is temporarily inaccessible, real-time optimization of your campaigns is currently running.</p>
                        </Box>

                        <MaintenanceIllustration />
                    </Box>

                </Container>
            </div>
        </div>

    );
};

export default Maintenance;
