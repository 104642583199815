import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const trafficDetails = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
  reducerPath: 'api.trafficDetails',
  tagTypes: ['trafficDetails'],
  endpoints: (builder) => ({
    getKeywords: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], workspaceId: any, keywords: string[] | null, platform: any }>({
      query: ({ startDate, endDate, device, countries, compareStartDate, compareEndDate, workspaceId, keywords, platform }) => {
        const url = `/trafficDetails/getKeywordsStats`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          device,
          keywords,
          countries,
          workspaceId,
          platform
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getDevices: builder.query<any, { device: string[] | null, compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, keywords: string[] | null, countries: string[], workspaceId: any, platform: any }>({
      query: ({ startDate, device, endDate, keywords, countries, compareStartDate, compareEndDate, workspaceId, platform }) => {
        const url = `/trafficDetails/getDevicesStats`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          keywords,
          device,
          countries,
          platform,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getPlatformsStats: builder.query<any, { device: string[] | null, compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, keywords: string[] | null, countries: string[], workspaceId: any, platform: any }>({
      query: ({ startDate, device, endDate, keywords, countries, compareStartDate, compareEndDate, workspaceId, platform }) => {
        const url = `/trafficDetails/getPlatformStats`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          keywords,
          device,
          countries,
          platform,
          workspaceId
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getCountries: builder.query<any, { countries: string[], compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, keywords: string[] | null, devices: string[], workspaceId: any, platform: any }>({
      query: ({ startDate, endDate, keywords, devices, compareStartDate, compareEndDate, workspaceId, countries, platform }) => {
        const url = `/trafficDetails/getCountriesStats`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          keywords,
          countries,
          devices,
          workspaceId,
          platform,
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
    getStats: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], workspaceId: any, timestep: any, keywords: any, platform: any }>({
      query: ({ startDate, endDate, device, countries, compareStartDate, compareEndDate, workspaceId, timestep, keywords, platform  }) => {
        const url = `/trafficDetails/getStatsDay`;
        const body = {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          device,
          countries,
          keywords,
          workspaceId,
          timestep,
          platform
        };

        return {
          url,
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Accept: 'application/json',
          },
        };
      },
    }),
  }),
});

export const {
  useGetKeywordsQuery,
  useGetCountriesQuery,
  useGetDevicesQuery,
  useGetPlatformsStatsQuery,
  useGetStatsQuery
} = trafficDetails;

export default trafficDetails;
