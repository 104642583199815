import React, { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5flow from "@amcharts/amcharts5/flow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useTheme } from "@mui/system";

const SankeyChart = ({ nodeData, linkData }: any) => {
  const theme = useTheme();

  const chartRef: any = useRef(null);
  const seriesRef: any = useRef(null);
  const [direction,setDirection] = useState<any>('horizontal');
  const screenWidth = window.innerWidth;
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(screenWidth <= 768);
    }, [screenWidth]);


  useEffect(() => {
    if (!chartRef.current) {
      const root = am5.Root.new("chartdiv");
      // Apply themes
      root.setThemes([am5themes_Animated.new(root)]);

      // Save the root instance in the ref
      chartRef.current = root;

      const screenWidth = window.innerWidth;
      // const isMobile = screenWidth <= 768; // Adjust the breakpoint as needed
      const newPaddingRight = isMobile ? 60 : 200; // Adjust the padding as needed
      if (isMobile) {
        setDirection('vertical');
      } else {
        setDirection('horizontal');
      }

      // Create series
      const series = root.container.children.push(
        am5flow.Sankey.new(root, {
          sourceIdField: "from",
          targetIdField: "to",
          valueField: "value",
          nodeWidth: isMobile ? 60 : 30,
          paddingRight: newPaddingRight,
          orientation: direction,
          nodePadding: isMobile ? 35 : 10
        })
      );

      series.nodes.setAll({
        nameField: "name",
      });

      // Define light and dark colors for text and borders
      const darkMode = theme.palette.mode === "dark";
      const labelColor = darkMode ? 0xffffff : 0x000000;
      const borderColor = darkMode ? 0x212b36 : 0xcccccc; // Dark border in dark mode, light border in light mode

      // Tooltip configuration
      const tooltip: any = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        pointerOrientation: "horizontal",
      });

      tooltip.get("background").setAll({
        fill: am5.color(0xffffff),  // Keep the background white
        fillOpacity: 0.7,
        strokeOpacity: 0,
        cornerRadius: 10,
      });

      series.set("tooltip", tooltip);

      series.links.template.setAll({
        tooltipHTML: "<div class='custom-tooltip'>From <b>{fromName}</b>\n to <b>{toName}</b>: <b>{value}</b></div>",
      });

      // Format Nodes
      series.nodes.rectangles.template.setAll({
        fillOpacity: 0.5,
        stroke: am5.color(borderColor), // Dynamic stroke color based on mode
        strokeWidth: 1,
        cornerRadiusTL: 4,
        cornerRadiusTR: 4,
        cornerRadiusBL: 4,
        cornerRadiusBR: 4,
      });

      // Labels
      series.nodes.labels.template.setAll({
        fontSize: 14,
        maxWidth: isMobile ? 130 : 400,
        fontFamily: "Public Sans, sans-serif",
        fill: am5.color(labelColor), // Dynamic label color based on mode
        oversizedBehavior: "wrap",
        paddingLeft: isMobile ? 35 : 40,
      });

      if (isMobile) {
        series.nodes.labels.template.adapters.add("centerX", function (center: any, target: any) {
          if (target.dataItem.get("incomingLinks", []) == 0) {
            return am5.p100;
          } else if (target.dataItem.get("outgoingLinks", []) == 0) {
            return am5.p50;
          }
          return am5.p50;
        });
      }

      series.nodes.rectangles.template.set("templateField", "nodeSettings");
      series.links.template.set("templateField", "linkSettings");

      // Save the series reference to update data later
      seriesRef.current = series;
      // Animate on load
      series.appear(1000, 100);

      // Cleanup on component unmount
      return () => {
        root.dispose();
        chartRef.current = null;
        seriesRef.current = null;
      };
    }
  }, [direction, theme.palette.mode]); // Re-run if the theme changes

  useEffect(() => {
    // Update the data if nodeData or linkData changes
    if (seriesRef.current) {
      // Update nodes data
      seriesRef.current.nodes.data.setAll(nodeData);

      // Normalize link data and update the series
      const enhancedLinkData = linkData.map((link: any) => {
        const fromNode = nodeData.find((node: any) => node.id === link.from);
        const toNode = nodeData.find((node: any) => node.id === link.to);

        return {
          ...link,
          tooltipValue: link.tooltipValue, // Use tooltipValue for tooltip
          value: Number(link.value),
          fromName: fromNode?.id || link.from,
          toName: toNode?.id || link.to,
        };
      });

      seriesRef.current.data.setAll(enhancedLinkData);
    }
  }, [nodeData, linkData]);

  return <div id="chartdiv" style={{ width: "100%", height: "400px" }}></div>;
};

export default SankeyChart;
