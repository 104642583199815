import * as Yup from 'yup';
import { Card, CardHeader, IconButton, InputAdornment, Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import FormProvider from "src/components/hook-form/form-provider";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField } from 'src/components/hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify';
import { useDeleteAccountMutation, useUpdatePasswordMutation } from 'src/context/api/auth';
import { useEffect } from 'react';
import { useRouter } from 'src/routes/hooks';
import { useSnackbar } from 'src/components/snackbar'
import { useLocales } from 'src/locales';

export default function AccountSecurity() {
    const { t } = useLocales();
    const theme = useTheme();

    const [updatePassword, response] = useUpdatePasswordMutation();
    const [deleteAccount, deleteResponse] = useDeleteAccountMutation();
    const passwordSchema = Yup.object().shape({
        currentPassword: Yup.string().required(t('dashboard.account.current_password_is _required')),
        password: Yup.string().min(8).required(t('dashboard.account.password_is_required')),
        confirmPassword: Yup.string().oneOf([Yup.ref('password')], t('dashboard.account.passwords_must_match')).required(t('dashboard.account.password_confirmation_is_required')),
    });
    const methods = useForm({
        resolver: yupResolver(passwordSchema),
        defaultValues: {
            currentPassword: '',
            password: '',
            confirmPassword: '',
        }
    });
    const currenntPasswordToggle = useBoolean();
    const newPasswordToggle = useBoolean();
    const confirmPasswordToggle = useBoolean();
    const router = useRouter();
    const snackbar = useSnackbar();

    useEffect(() => {
        if (response.isSuccess) {
            methods.reset();
        }
        else if (response.isError) {
            snackbar.enqueueSnackbar(t('dashboard.snackbar.error_updating_password'), {
                variant: 'error',
            });
        }
    }, [response.isSuccess, methods]);

    useEffect(() => {
        if (deleteResponse.isSuccess) {
            router.reload();
        }
        else if (deleteResponse.isError) {
            snackbar.enqueueSnackbar(t('dashboard.snackbar.error_deleting_account'), {
                variant: 'error',
            });
        }
    }, [deleteResponse.isSuccess, methods]);

    return (
        <Stack direction={"column"} spacing={3}>
            <Card sx={{ padding: 3 }}>
                <Typography variant="subtitle1" fontWeight={'bold'} sx={{ color: theme.palette.mode == "dark" ? "white" : 'black', textAlign: 'start', pb: 3 }}>
                    Change your password
                </Typography>
                <FormProvider methods={methods} onSubmit={methods.handleSubmit(updatePassword)}>
                    <Stack direction={"column"} spacing={3}>
                        <RHFTextField type={currenntPasswordToggle.value ? "text" : "password"} fullWidth name='currentPassword' label={t('dashboard.account.current_password')}
                            autoComplete="new-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={currenntPasswordToggle.onToggle} edge="end">
                                            <Iconify icon={currenntPasswordToggle.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                        <RHFTextField type={newPasswordToggle.value ? "text" : "password"} fullWidth name='password'
                            autoComplete="new-password" label={t('dashboard.account.current_password')} helperText={t('dashboard.account.must_be_minimum_8')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={newPasswordToggle.onToggle} edge="end">
                                            <Iconify icon={newPasswordToggle.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                        <RHFTextField type={confirmPasswordToggle.value ? "text" : "password"} fullWidth name='confirmPassword'
                            autoComplete="new-password" label={t('dashboard.account.password_confirmation')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={confirmPasswordToggle.onToggle} edge="end">
                                            <Iconify icon={confirmPasswordToggle.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                    </Stack>
                    <Stack direction={{ xs: "column", md: "row" }} paddingTop={3} justifyContent={"flex-end"} alignContent={"flex-end"}>
                        <LoadingButton loading={response.isLoading} type='submit' variant='contained' color='inherit'>
                            {t('dashboard.global.save_changes')}
                        </LoadingButton>
                    </Stack>
                </FormProvider>
            </Card>
        </Stack>
    )
}