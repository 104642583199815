import { m } from 'framer-motion';
import { useState, useCallback, useEffect } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
//
import NotificationItem from './notification-item';
import { Invitation, useInvitationsQuery } from 'src/context/api/workspaces';
import { ListItemAvatar, ListItemButton, ListItemText, Skeleton, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGetBrandsQuery } from 'src/context/api/competitorsMonitoring/api';
import { setFilterAdvertizerPLA, setFilterBrandPLA, setFilterCountry, setFilterDomainPLA, setFilterKeyword, setNotificationBrandInfringement, setNotificationBrandInfringementTXT, setFilterDomainTXT, setFilterBrandTXT, setNotificationBrandInNonBrandCampaignsFilterEnabled } from 'src/context/reducers/filter-settings/filterSlice';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useRouter } from 'src/routes/hooks';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();
  const { t } = useTranslation();
  const smUp = useResponsive('up', 'sm');
  const filterStore = useSelector((state: any) => state.filters);
  const [currentTab, setCurrentTab] = useState(t('dashboard.global.all'));
  const dispatch = useDispatch();
  const workspace: any = useCurrentWorkspace();
  const theme: any = useTheme();

  const TABS = [
    {
      value: 'all',
      label: t('dashboard.global.all'),
      count: 22,
    },
  ];


  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const invitations = useInvitationsQuery(null);

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'unread' && 'info') ||
                (tab.value === 'archived' && 'success') ||
                'default'
              }
            >
              {(invitations.data?.length ?? 0) +
                ((filterStore?.notificationBrandInNonBrandCampaigns) ? 1 : 0) +
                (workspace?.showPlaAlert && (filterStore?.notificationBrandInfringementNotification?.ads?.length ?? 0) > 0 ? 1 : 0) +
                (workspace?.showTxtAlert && (filterStore?.notificationBrandInfringementNotificationTXT?.ads?.length ?? 0) > 0 ? 1 : 0)}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const { data: brands, error: brandsError, isLoading: isLoadingBrands, isFetching: isFetchingBrands } = useGetBrandsQuery({
    bqTable: workspace?.bqTable,
  });

  const renderAvatar = (
    <ListItemAvatar>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 40,
          height: 40,
          borderRadius: '50%',
          bgcolor: 'background.neutral',
        }}
      >
        <Box sx={{
          p: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#28323D' : ' #F4F6F8',
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
          borderRadius: '10px',
          padding: '10px',
          width: 44
        }}>
          <Box component="img" src={`https://www.google.com/s2/favicons?domain=${workspace?.url}&sz=64`} sx={{ width: 24, height: 24, borderRadius: '5px' }} />
        </Box>
      </Stack>
    </ListItemAvatar>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {invitations?.data?.map((invitation: Invitation) => (
          <NotificationItem key={invitation.id} notification={invitation} />
        )) || [1, 3, 4, 5].map((index) => <Skeleton sx={{ margin: 2 }} variant="rounded" key={index} width={"95%"} height={75} />)}
      </List>
    </Scrollbar>
  );

  const router = useRouter();

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge
          badgeContent={
            (invitations.data?.length ?? 0) +
            ((filterStore?.notificationBrandInNonBrandCampaigns) ? 1 : 0) +
            (workspace?.showPlaAlert && (filterStore?.notificationBrandInfringementNotification?.ads?.length ?? 0) > 0 ? 1 : 0) +
            (workspace?.showTxtAlert && (filterStore?.notificationBrandInfringementNotificationTXT?.ads?.length ?? 0) > 0 ? 1 : 0)}
          color="error"
        >
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
        </Stack>

        <Divider />

        {workspace?.showPlaAlert &&
          <>
            {filterStore?.notificationBrandInfringementNotification?.ads &&
              filterStore.notificationBrandInfringementNotification.ads.length !== 0 && (
                <>
                  <ListItemButton
                    disableRipple
                    sx={{
                      p: 2.5,
                      alignItems: 'flex-start',
                      backgroundColor: 'rgba(255, 86, 48, 0.08)',
                      borderBottom: (theme: any) => `dashed 1px ${theme.palette.divider}`,
                    }}
                  >
                    {renderAvatar}

                    <Box
                      sx={{
                        top: 26,
                        width: 8,
                        height: 8,
                        right: 20,
                        borderRadius: '50%',
                        bgcolor: '#ec6240',
                        position: 'absolute',
                      }}
                    />

                    <Stack sx={{ flexGrow: 1, color: theme.palette.mode === "dark" ? '#e1a381' : "#B71D18", fontWeight: 800 }}>
                      Brand Infringement (PLA)
                      <ListItemText
                        disableTypography
                        secondary={
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ typography: 'caption', color: theme.palette.mode === "dark" ? '#e1a381' : "#B71D18" }}
                            divider={
                              <Box
                                sx={{
                                  width: 2,
                                  height: 2,
                                  bgcolor: 'currentColor',
                                  mx: 0.5,
                                  borderRadius: '50%',
                                }}
                              />
                            }
                          >
                            Some competitors are using your Brand in their Product Listing Ads Content.
                          </Stack>
                        }
                      />
                      <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
                        <LoadingButton onClick={() => {
                          dispatch(setFilterBrandPLA(brands));
                          dispatch(setFilterKeyword([]));
                          dispatch(setFilterCountry([]));
                          dispatch(setFilterDomainPLA([]));
                          dispatch(setFilterAdvertizerPLA([]));
                          dispatch(setNotificationBrandInfringement(false));
                          router.push(`/dash/product-listing-ads?workspace=${workspace?.id}`);
                          drawer.onFalse()
                        }} size="small" variant="contained" sx={{ backgroundColor: '#ec6240', color: 'white' }}>
                          Show
                        </LoadingButton>
                      </Stack>

                    </Stack>
                  </ListItemButton>
                </>
              )}
          </>
        }

        {workspace?.showTxtAlert &&
          <>
            {filterStore?.notificationBrandInfringementNotificationTXT?.ads &&
              filterStore.notificationBrandInfringementNotificationTXT.ads.length !== 0 && (
                <>
                  <ListItemButton
                    disableRipple
                    sx={{
                      p: 2.5,
                      alignItems: 'flex-start',
                      backgroundColor: 'rgba(255, 86, 48, 0.08)',
                      borderBottom: (theme: any) => `dashed 1px ${theme.palette.divider}`,
                    }}
                  >
                    {renderAvatar}

                    <Box
                      sx={{
                        top: 26,
                        width: 8,
                        height: 8,
                        right: 20,
                        borderRadius: '50%',
                        bgcolor: '#ec6240',
                        position: 'absolute',
                      }}
                    />

                    <Stack sx={{ flexGrow: 1, color: '#e1a381', fontWeight: 800 }}>
                      Brand Infringement (Text Ads)
                      <ListItemText
                        disableTypography
                        secondary={
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ typography: 'caption', color: '#e1a381' }}
                            divider={
                              <Box
                                sx={{
                                  width: 2,
                                  height: 2,
                                  bgcolor: 'currentColor',
                                  mx: 0.5,
                                  borderRadius: '50%',
                                }}
                              />
                            }
                          >
                            Some competitors are using your Brand in their Text Ads Content.
                          </Stack>
                        }
                      />
                      <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
                        <LoadingButton onClick={() => {
                          dispatch(setFilterBrandTXT(brands));
                          dispatch(setFilterKeyword([]));
                          dispatch(setFilterCountry([]));
                          dispatch(setFilterDomainTXT([]));
                          dispatch(setNotificationBrandInfringementTXT(false));
                          router.push(`/dash/text-ads?workspace=${workspace?.id}`);
                          drawer.onFalse()
                        }} size="small" variant="contained" sx={{ backgroundColor: '#ec6240', color: 'white' }}>
                          Show
                        </LoadingButton>
                      </Stack>

                    </Stack>
                  </ListItemButton>
                </>
              )}
          </>
        }


        {filterStore?.notificationBrandInNonBrandCampaigns &&
          <>
            <ListItemButton
              disableRipple
              sx={{
                p: 2.5,
                alignItems: 'flex-start',
                backgroundColor: 'rgba(255, 86, 48, 0.08)',
                borderBottom: (theme: any) => `dashed 1px ${theme.palette.divider}`,
              }}
            >
              {renderAvatar}

              <Box
                sx={{
                  top: 26,
                  width: 8,
                  height: 8,
                  right: 20,
                  borderRadius: '50%',
                  bgcolor: '#ec6240',
                  position: 'absolute',
                }}
              />

              <Stack sx={{ flexGrow: 1, color: '#e1a381', fontWeight: 800 }}>
                Brand Campaigns Sealing
                <ListItemText
                  disableTypography
                  secondary={
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ typography: 'caption', color: '#e1a381' }}
                      divider={
                        <Box
                          sx={{
                            width: 2,
                            height: 2,
                            bgcolor: 'currentColor',
                            mx: 0.5,
                            borderRadius: '50%',
                          }}
                        />
                      }
                    >
                      Some of your non-brand campaigns are running ads on branded search terms.
                    </Stack>
                  }
                />
                <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
                  <LoadingButton onClick={() => { dispatch(setNotificationBrandInNonBrandCampaignsFilterEnabled(true)); router.push(`/dash/campaign-explorer?workspace=${workspace?.id}`); }} size="small" variant="contained" sx={{ backgroundColor: '#ec6240', color: 'white' }}>
                    Show
                  </LoadingButton>
                </Stack>

              </Stack>
            </ListItemButton>
          </>
        }

        {renderList}
      </Drawer>
    </>
  );
}
