import type { Transition } from 'framer-motion';
import type { BoxProps } from '@mui/material/Box';
import type { AvatarProps } from '@mui/material/Avatar';

import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import { fontSize, useTheme } from '@mui/system';
import { Typography } from '@mui/material';
import { useCurrentUserQuery } from 'src/context/api/auth';

// ----------------------------------------------------------------------

export type AnimateAvatarProps = BoxProps & {
    slotProps?: {
        avatar?: AvatarProps;
        animate?: { transition?: Transition };
        overlay?: {
            color?: string;
            border?: number;
            spacing?: number;
        };
    };
};

export function AnimateAvatar({
    sx,
    slotProps,
    children,
    width = 40,
    ...other
}: AnimateAvatarProps) {
    const theme = useTheme();
    const { data: user } = useCurrentUserQuery()

    return (
        <Box
            sx={{
                width,
                height: width,
                flexShrink: 0,
                borderRadius: '50%',
                position: 'relative',
                alignItems: 'center',
                display: 'inline-flex',
                justifyContent: 'center',
                backgroundColor: theme.palette.primary.main,
                ...sx,
            }}
            {...other}
        >
            <Typography sx={{fontSize: '3rem'}}>
                {user?.profile?.firstname?.charAt(0).toUpperCase()}
                {user?.profile?.lastname?.charAt(0).toUpperCase()}
            </Typography>

            <Box
                component={m.span}
                animate={{ rotate: 360 }}
                transition={{
                    duration: 8,
                    ease: 'linear',
                    repeat: Infinity,
                    ...slotProps?.animate?.transition,
                }}
                sx={{
                    top: 0,
                    left: 0,
                    width: 1,
                    height: 1,
                    position: 'absolute',
                    borderRadius: 'inherit',
                    background: slotProps?.overlay?.color ?? 'conic-gradient(cyan, magenta, yellow, cyan)',
                    mask: 'linear-gradient(#FFF 0 0) content-box, linear-gradient(#FFF 0 0)',
                    WebkitMask: 'linear-gradient(#FFF 0 0) content-box, linear-gradient(#FFF 0 0)',
                    maskComposite: 'exclude',
                    WebkitMaskComposite: 'xor',
                    p: `4px`,
                }}
            />
        </Box>
    );
}
