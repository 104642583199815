import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const campaignExplorer = createApi({
    baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
    reducerPath: 'api.campaignExplorer',
    tagTypes: ['campaignExplorer'],
    endpoints: (builder) => ({
        getData: builder.query<any, {
            client: string,
            searchAdGroup: any,
            searchCampaign: any,
            searchKeyword: any,
            searchSearchTerm: any,
            sortPeriod: any,
            campaignFilterType?: any,
            adGroupFilterType?: any,
            keywordFilterType?: any,
            searchTermFilterType?: any,
            rangePrice: any,
            rangePriceItemType: string,
            rangeClicks: any,
            rangeClicksItemType: any,
            rangeImpressions: any,
            rangeImpressionsItemType: any,
            brands: any,
            selectedBiddingStrategy: any,
            campaignTypeFilter: any
        }>({
            query: ({ client,
                searchAdGroup,
                searchCampaign,
                searchKeyword,
                searchSearchTerm,
                sortPeriod,
                campaignFilterType,
                adGroupFilterType,
                keywordFilterType,
                searchTermFilterType,
                rangePrice,
                rangePriceItemType,
                rangeClicks,
                rangeClicksItemType,
                rangeImpressions,
                rangeImpressionsItemType,
                brands,
                selectedBiddingStrategy,
                campaignTypeFilter }) => ({
                    url: `/campaignExplorer/getData`,
                    method: 'POST',
                    body: {
                        client,
                        searchAdGroup,
                        searchCampaign,
                        searchKeyword,
                        searchSearchTerm,
                        sortPeriod,
                        campaignFilterType,
                        adGroupFilterType,
                        keywordFilterType,
                        searchTermFilterType,
                        rangePrice,
                        rangePriceItemType,
                        rangeClicks,
                        rangeClicksItemType,
                        rangeImpressions,
                        rangeImpressionsItemType,
                        campaignTypeFilter,
                        brands,
                        selectedBiddingStrategy
                    },
                    headers: {
                        Accept: 'application/json',
                        'Cache-Control': 'max-age=0',
                    },
                }),
        }),
        getBiddingStrategyType: builder.query<any, { client: any }>({
            query: ({ client }) => {
                const url = '/campaignExplorer/bidding-strategy-type';
                const body = {
                    client
                };
                return {
                    url,
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        Accept: 'application/json',
                    },
                };
            },
        }),
        getCampaignType: builder.query<any, { client: any }>({
            query: ({ client }) => {
                const url = '/campaignExplorer/campaign-type';
                const body = {
                    client
                };
                return {
                    url,
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        Accept: 'application/json',
                    },
                };
            },
        }),
        getBrandInNonBrandCampaigns: builder.query<any, { client: any, brands: any }>({
            query: ({ client, brands }) => {
                const url = '/campaignExplorer/brand-in-non-brand-campaigns';
                const body = {
                    client,
                    brands
                };
                return {
                    url,
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        Accept: 'application/json',
                    },
                };
            },
        }),
    }),
});

export const {
    useGetDataQuery,
    useGetBiddingStrategyTypeQuery,
    useGetCampaignTypeQuery,
    useGetBrandInNonBrandCampaignsQuery
} = campaignExplorer;

export default campaignExplorer;