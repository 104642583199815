import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';
import AdminUsers from 'src/sections/overview/dashboards/views/admin/users';
// ----------------------------------------------------------------------

export default function Home() {
    const { t } = useLocales()
    return (
        <>
            <Helmet>
                <title>{t('adminUsers')}</title>
            </Helmet>

            <AdminUsers />
        </>
    );
}
