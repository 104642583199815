import { m } from 'framer-motion';
// @mui
import { Theme, SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Badge, { badgeClasses } from '@mui/material/Badge';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { useFiltersContext } from 'src/components/filters';
import { Button } from "@mui/material";
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

type Props = {
    sx?: SxProps<Theme>;
};

export default function FiltersButton({ sx }: Props) {
    const filters = useFiltersContext();
    const { t } = useLocales();

    return (
        <Badge
            color="error"
            variant="dot"
            invisible={!filters.canReset}
            sx={{
                [`& .${badgeClasses.badge}`]: {
                    top: 8,
                    right: 8,
                },
                ...sx,
            }}
        >
            <Box component={m.div}>

                <Button size='small' onClick={filters.onToggle} sx={{ marginLeft: 1 }} variant="contained" color="inherit">
                    <Iconify icon="icon-park-outline:filter" width={22} sx={{ marginRight: .5 }} /> {t('dashboard.global.filters')}
                </Button>

            </Box>
        </Badge>
    );
}
