// components
import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';
import { useResponsive } from 'src/hooks/use-responsive';

// ----------------------------------------------------------------------

type Props = {
    series: number[];
};

export default function ChartDonut({ series }: Props) {
    const theme = useTheme();
    const lgUp = useResponsive('up', 'lg');

    enum ColorSchema {
        PRIMARY = 'primary',
        SECONDARY = 'secondary',
        TERTIARY = 'tertiary',
        ERROR = 'error',
        WARNING = 'warning',
        INFO = 'info',
        SUCCESS = 'success'
    }

    const chartOptions = useChart({
        labels: ['Organic Brand Traffic', 'Paid Brand Traffic', 'Uncaptured Brand Traffic'],
        stroke: {
            show: false,
        },
        legend: {
            horizontalAlign: 'left',
            position: !lgUp ? 'top' : 'right',
        },
        fill: {
            colors: [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, '#b7b7b7']
        },
        colors: [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, '#b7b7b7'],
        tooltip: {
            fillSeriesColor: false,
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '70px',

                },
            },
        },
    });

    return <Chart dir="ltr" type="donut" series={series} options={chartOptions} sx={{ width: { lg: 600, xs: 900 } }} />;
}
