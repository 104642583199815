import React, { useMemo } from 'react';
import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';

type Props = {
    series: number[];
    labels: any;
};

const ChartPolarArea = React.memo(({ series, labels }: Props) => {
    const workspace = useCurrentWorkspace();
    // TODO: gérer les formats dynamiquement
    const formatCurrency = (number: any, options: any) => {
        const lang = localStorage.getItem('i18nextLng');

        const currencySymbol = workspace?.currency === 'eur' ? '€' : workspace?.currency === 'usd' ? '$' : '';

        let formattedNumber;
        if (number >= 1000000) {
            formattedNumber = (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 10000) {
            formattedNumber = (number / 1000).toFixed(1) + 'K';
        } else {
            formattedNumber = number.toLocaleString(lang, options);
        }

        if (lang === 'fr') {
            formattedNumber = formattedNumber.replace('.', ',');
        }

        return formattedNumber;
    };

    const transformedSeries = useMemo(() => {
        return series.map(value => Math.log10(value + 1));
    }, [series]);

    const formattedSeries = useMemo(() => {
        return series.map(value => formatCurrency(value, { minimumFractionDigits: 1, maximumFractionDigits: 1 }));
    }, [series, workspace?.currency]);

    const chartOptions = useChart({
        chart: {
            type: 'polarArea',
        },
        series: transformedSeries,
        labels: labels.map((label: string, index: number) => {
            return `${label} (${formattedSeries[index]})`;
        }),
        fill: {
            opacity: 0.8,
            colors: ["#016aa4", "#fab000", "#0a9156", "#e74c3c", "#8e44ad", "#3498db", "#2ecc71", "#f39c12", "#d35400"]
        },
        colors: ["#016aa4", "#fab000", "#0a9156", "#e74c3c", "#8e44ad", "#3498db", "#2ecc71", "#f39c12", "#d35400"],
        stroke: {
            colors: ['transparent'],
        },
        legend: {
            show: true,
            itemMargin: {
                vertical: 8,
            },
            markers: {
                customHTML: function () {
                    return '<div style="width: 16px; height: 16px; border-radius: 50%; background-color: currentcolor;"></div>';
                },
            },
            position: 'right',
            offsetY: 20,
        },
        tooltip: {
            y: {
                formatter: (val: number, { seriesIndex }: { seriesIndex: number }) => {
                    const originalValue = series[seriesIndex];
                    return formatCurrency(originalValue, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
                }
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: '100%',
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    });

    return (
        <Box sx={{
            '& .apexcharts-legend.apx-legend-position-right': {
                marginRight: '30px!important',
            },
            '& .apexcharts-legend':{
                display:{xs:'block', sm:'flex'},
                marginBottom:{xs:2, sm:0},
            },
        }}>
            <Chart dir="ltr" type="polarArea" series={transformedSeries} options={chartOptions} height={420} />
        </Box>
    );
});

export default ChartPolarArea;
