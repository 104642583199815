import { createContext, useContext, ReactNode, FC } from "react";

// Définition d'une énumération pour les rôles d'utilisateur
enum UserRole {
  ADMIN = "admin",
  BRAND_SPECIALIST = "brand specialist",
  CONSULTANT = "consultant",
  CUSTOMER = "customer",
  GUEST = "guest",
}

// Fonction utilitaire pour obtenir le niveau de rôle
const getRoleLevel = (role: string): number => {
  switch (role) {
    case UserRole.ADMIN:
      return 4;
    case UserRole.BRAND_SPECIALIST:
      return 3;
    case UserRole.CONSULTANT:
      return 2;
    case UserRole.CUSTOMER:
      return 1;
    case UserRole.GUEST:
      return 0;
    default:
      return -1; // Rôle invalide
  }
}

// Fonction utilitaire pour valider un rôle
const isValidUserRole = (role: string): role is UserRole => {
  return Object.values(UserRole).includes(role as UserRole);
}

type PermissionContextType = {
  canPerformAction: (userRole: string, action: string, targetUserRole?: string) => boolean;
};

const PermissionsContext = createContext<PermissionContextType | undefined>(undefined);

export const PermissionsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const canPerformAction = (userRole: string, action: string, targetUserRole?: string): boolean => {
    const userLevel = getRoleLevel(userRole);
    const targetLevel = targetUserRole ? getRoleLevel(targetUserRole) : -1;

    switch (action) {
      // Users //
      case "delete_user":
          //return true if target user is below current user level
          return targetLevel < userLevel;
      default:
        return false; // All other actions are denied by default
    }
  };

  const value = { canPerformAction };

  return (
    <PermissionsContext.Provider value={value}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error("usePermissions must be used within a PermissionsProvider");
  }
  return context;
};
