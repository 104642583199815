import { Box, Container, Grid, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import { MotivationIllustration } from "src/assets/illustrations";
import { useResponsive } from "src/hooks/use-responsive";
import { setFilterLoadedPlatform, setFilterMinDatePicker } from "src/context/reducers/filter-settings/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useGetPlatformsCustomQuery, useGetStatsCustomEcommerceQuery } from "src/context/api/businessOverview/api";
import { fDate } from "src/utils/format-time";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { SplashScreen } from "src/components/loading-screen";
import Iconify from "src/components/iconify";
import ChartMixedCustom from "src/sections/charts/chart-mixed-custom-ecommerce";
import ChartColumnStacked from "src/sections/charts/chart-column-standart-lead";
import ChartMixedCustomLeadStacked from "src/sections/charts/chart-mixed-custom-lead-stacked";
import ChartMixedCustomLeadNoStacked from "src/sections/charts/chart-mixed-custom-lead-nostacked";
import { differenceInDays } from "date-fns";
import { useLocales } from "src/locales";
import { useBoolean } from "src/hooks/use-boolean";
import BankingWidgetSummary from "../banking-widget-summary";
import Welcome from "../welcome";

export default function CustomEcommerce() {
  const { t, currentLang } = useLocales();
  const lgUp = useResponsive('up', 'lg');
  const dispatch = useDispatch();
  const workspace: any = useCurrentWorkspace();
  const filterStore = useSelector((state: any) => state.filters);
  const theme = useTheme()
  const disableWelcome = useBoolean();

  const skipQuery = !filterStore.start || !filterStore.end || !workspace?.id;

  const { data: platforms, error: platformsError, isFetching: isFetchingPlatforms } = useGetPlatformsCustomQuery({
    startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
    endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
    devices: filterStore.devices,
    countries: filterStore.countries,
    keywords: filterStore.keywords,
    workspaceId: workspace?.id,
  },
    { skip: skipQuery }
  );

  useEffect(() => {
    if (platforms)
      dispatch(setFilterLoadedPlatform(platforms))
  }, [platforms])

  const { data: statsData, error: statsError, isFetching } = useGetStatsCustomEcommerceQuery({
    startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
    endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
    compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
    compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
    timestep: filterStore.timestep,
    device: filterStore.devices,
    countries: filterStore.countries,
    keywords: filterStore.keywords,
    workspaceId: workspace?.id,
    platforms: filterStore?.platforms
  },
    {
      skip: skipQuery,

    }
  );
  const [customLabels, setCustomLabels] = useState<any>(statsData?.stats.map((item: any) => item?.timestep) || []);

  const getDaysDifference = (startDate: any, endDate: any) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return differenceInDays(end, start);
  }

  const getTimestep = (startDate: any, endDate: any) => {
    const daysDifference = getDaysDifference(startDate, endDate);

    if (daysDifference > 365) {
      return 'year_month';
    } if (daysDifference > 90) {
      return 'year_week';
    }
    return 'date';

  }

  const { data: sparkLinesData, error: sparkLinesDataError, isFetching: isFetchingSparkLinesData } = useGetStatsCustomEcommerceQuery({
    startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
    endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
    compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
    compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
    timestep: getTimestep(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null),
    device: filterStore.devices,
    countries: filterStore.countries,
    keywords: filterStore.keywords,
    workspaceId: workspace?.id,
    platforms: filterStore.platform
  },
    {
      skip: skipQuery,
      refetchOnMountOrArgChange: false,
    }
  );

  enum ColorSchema {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
  }

  useEffect(() => {
    dispatch(setFilterMinDatePicker(statsData?.minDatePicker[0]?.first_date))
  }, [statsData])

  const sparkLinesDataTotalAdCost = sparkLinesData?.stats?.map((item: any) => {
    const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

    if (daysDifference > 365) {
      const sum = (item?.total_ad_cost || 0);
      return { y: sum, x: item?.timestep };
    } if (daysDifference > 90) {
      const sum = (item?.total_ad_cost || 0);
      return { y: sum, x: item?.timestep };
    }
    const sum = (item?.total_ad_cost || 0);

    const date = new Date(item?.timestep);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return { y: sum, x: `${year}-${month}-${day}` };

  });

  const sparkLinesDataTotalBlendedRevenue = sparkLinesData?.stats?.map((item: any) => {
    const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

    if (daysDifference > 365) {
      const sum = (item?.total_blended_conversions * workspace?.averageCart || 0);
      return { y: sum, x: item?.timestep };
    } else if (daysDifference > 90) {
      const sum = (item?.total_blended_conversions * workspace?.averageCart || 0);
      return { y: sum, x: item?.timestep };
    } else {
      const sum = (item?.total_blended_conversions * workspace?.averageCart || 0);

      const date = new Date(item?.timestep);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return { y: sum, x: `${year}-${month}-${day}` };

    }
  });

  const sparkLinesDataBlendedROI = sparkLinesData?.stats?.map((item: any) => {
    const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

    if (daysDifference > 365) {
      const roi = item?.total_ad_cost !== 0
        ? ((item?.total_blended_conversions * workspace?.averageCart) / item?.total_ad_cost) / 1000
        : 0;
      return { y: roi, x: item?.timestep };
    } else if (daysDifference > 90) {
      const roi = item?.total_ad_cost !== 0
        ? ((item?.total_blended_conversions * workspace?.averageCart) / item?.total_ad_cost) / 1000
        : 0;
      return { y: roi, x: item?.timestep };
    } else {
      const roi = item?.total_ad_cost !== 0
        ? ((item?.total_blended_conversions * workspace?.averageCart) / item?.total_ad_cost) / 1000
        : 0;

      const date = new Date(item?.timestep);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return { y: roi, x: `${year}-${month}-${day}` };

    }
  });

  const sparkLinesDataTotalActualSavings = sparkLinesData?.stats_savings?.map((item: any) => {
    const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

    if (daysDifference > 365) {
      const sum = (item?.total_actual_savings || 0);
      return { y: sum, x: item?.year_month };
    } if (daysDifference > 90) {
      const sum = (item?.total_actual_savings || 0);
      return { y: sum, x: item?.year_week };
    }
    const sum = (item?.total_actual_savings || 0);

    const date = new Date(item?.date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return { y: sum, x: `${year}-${month}-${day}` };
  });

  const sparkLinesDataTotalBlendedCPC = sparkLinesData?.stats?.map((item: any) => {
    const daysDifference = getDaysDifference(
      filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
      filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null
    );

    if (daysDifference > 365) {
      // Aggregate data yearly
      const blendedCpc = item?.total_ad_cost / item?.total_blended_clicks || 0;
      return { y: blendedCpc, x: item?.year_month };
    } else if (daysDifference > 90) {
      // Aggregate data weekly
      const blendedCpc = item?.total_ad_cost / item?.total_blended_clicks || 0;
      return { y: blendedCpc, x: item?.year_week };
    } else {
      // Aggregate data daily
      const blendedCpc = item?.total_ad_cost / item?.total_blended_clicks || 0;
      const date = new Date(item?.timestep);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      return { y: blendedCpc, x: `${year}-${month}-${day}` };
    }
  });

  const stats = [
    {
      title: t('dashboard.global.savings'),
      value: statsData?.total_actual_savings,
      compareData: (((statsData?.total_actual_savings - statsData?.total_actual_savings_compare) / statsData?.total_actual_savings_compare) * 100),
      color: ColorSchema.SUCCESS,
      textColor: '#118d57',
      spanColor: theme.palette.mode === "dark" ? '#6dad8d' : 'transparent',
      icon: "solar:hand-money-bold-duotone",
      series: sparkLinesDataTotalActualSavings
    },
    {
      title: t('dashboard.global.ad_cost'),
      value: statsData?.total_ad_cost,
      color: ColorSchema.WARNING,
      textColor: '#b76e00',
      icon: "solar:money-bag-bold-duotone",
      compareData: parseFloat((((statsData?.total_ad_cost - statsData?.total_ad_cost_compare) / statsData?.total_ad_cost_compare) * 100).toFixed(2)),
      spanColor: theme.palette.mode === "dark" ? '#ffd066' : 'transparent',
      series: sparkLinesDataTotalAdCost
    },
    {
      title: t('dashboard.global.blended_revenue'),
      value: statsData?.total_blended_conversions !== undefined
        ? parseFloat((statsData?.total_blended_conversions * workspace?.averageCart).toFixed(3)) // Convertir le résultat en number après l'arrondi
        : 0,
      compareData: statsData?.total_blended_conversions_compare !== undefined
        ? parseFloat(((((statsData?.total_blended_conversions * workspace?.averageCart) - (statsData?.total_blended_conversions_compare * workspace?.averageCart)) / (statsData?.total_blended_conversions_compare * workspace?.averageCart)) * 100).toFixed(2)) // Conversion en number
        : 0,
      color: ColorSchema.TERTIARY,
      textColor: '#006c9c',
      spanColor: theme.palette.mode === "dark" ? '#65b5c9' : 'transparent',
      icon: "solar:dollar-bold-duotone",
      series: sparkLinesDataTotalBlendedRevenue
    },
    {
      title: t('dashboard.global.blended_roi'),
      value: statsData?.total_blended_conversions !== 0
        ? parseFloat((((statsData?.total_blended_conversions * workspace?.averageCart) / statsData?.total_ad_cost) / 1000).toFixed(3))
        : 0,
      compareData: statsData?.total_blended_conversions_compare !== 0 && statsData?.total_ad_cost_compare !== 0
        ? parseFloat((((((statsData?.total_blended_conversions * workspace?.averageCart) / statsData?.total_ad_cost) - ((statsData?.total_blended_conversions_compare * workspace?.averageCart) / statsData?.total_ad_cost_compare)) / ((statsData?.total_blended_conversions_compare * workspace?.averageCart) / statsData?.total_ad_cost_compare)) * 100).toFixed(2))
        : 0,
      color: ColorSchema.SECONDARY,
      textColor: '#880e4f',
      icon: "lets-icons:chart-alt-duotone",
      spanColor: theme.palette.mode === "dark" ? '#a64b7b' : 'transparent',
      series: sparkLinesDataBlendedROI
    },
  ]

  const blendedCpcStat = {
    title: t('dashboard.global.blended_cpc'),
    value: statsData?.total_ad_cost / statsData?.total_blended_clicks || 0,
    compareData: statsData?.total_blended_clicks_compare > 0
      ? parseFloat((((statsData?.total_ad_cost / statsData?.total_blended_clicks) - (statsData?.total_blended_clicks_compare / statsData?.total_blended_clicks_compare)) / (statsData?.total_ad_cost_compare / statsData?.total_blended_clicks_compare) * 100).toFixed(2))
      : 0,
    color: ColorSchema.ERROR,
    textColor: '#004085',
    icon: "solar:graph-bold-duotone",
    spanColor: theme.palette.mode === "dark" ? '#8fc2e6' : 'transparent',
    series: sparkLinesDataTotalBlendedCPC
  };

  const filteredStats = workspace?.showSavings
    ? stats
    : [
      ...stats.filter(item => item.title !== t('dashboard.global.savings')),
      blendedCpcStat,
    ];

  const getFilteredColumns = (data: any) => {
    if (!data || data.length === 0) return [];
    const firstRow = data[0];
    return Object.keys(firstRow).filter(key =>
      key.toLowerCase().includes('_line') ||
      key.toLowerCase().includes('_bar') ||
      key.toLowerCase().includes('_stacked')
    );
  };



  const getTitleFromColumns = (columns: any) => {
    if (!columns || columns.length === 0) return '';

    const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

    const capitalizeWords = (str: string) => str.split(' ').map(capitalizeFirstLetter).join(' ');

    const formattedNames = columns.map((col: any) => capitalizeWords(formatColumnName(col)));

    if (formattedNames.length > 5) return 'Custom Metrics';

    if (formattedNames.length === 1) return formattedNames[0];

    const lastColumn = formattedNames.pop();
    return `${formattedNames.join(', ')} & ${lastColumn}`;
  };


  const formatColumnName = (col: any) => col.replace(/_/g, ' ').replace(/ (line|bar|usd)$/i, '').replace(/Line|Bar|Stacked|USD/i, '');

  const filteredColumns = getFilteredColumns(statsData?.stats || []);

  const dynamicTitle = getTitleFromColumns(filteredColumns);

  const dynamicSeries = filteredColumns.map((col) => ({
    name: formatColumnName(col),
    data: statsData?.stats?.map((item: any) => item[col]),
    type: col.toLowerCase().includes('_line') ? 'line' : 'bar',
    stacked: !!col.toLowerCase().includes('_stacked'),
    format: col.toLowerCase().includes('_usd') ? 'usd' : 'number'
  }));

  const savingsOverTime = [
    { name: t('dashboard.business_overview.actual_savings'), data: statsData?.stats_savings?.map((item: any) => item?.total_actual_savings) },
    { name: t('dashboard.business_overview.remaining_pot_savings'), data: statsData?.stats_savings?.map((item: any) => item?.total_savings) },
  ]

  const blendedRevenueAdCost = [
    { name: t('dashboard.global.ad_cost'), data: statsData?.stats?.map((item: any) => item?.total_ad_cost ?? 0), type: "bar" },
    { name: t('dashboard.global.blended_revenue'), data: statsData?.stats?.map((item: any) => item?.total_blended_conversions * workspace?.averageCart ?? 0), type: "line" },
    { name: t('dashboard.global.blended_roi'), data: statsData?.stats?.map((item: any) => (item?.total_blended_conversions * workspace?.averageCart) / item?.total_ad_cost ?? 0), type: "line" },
    { name: t('dashboard.global.blended_cpc'), data: statsData?.stats?.map((item: any) => item?.total_blended_cpc ?? 0), type: "line" }
  ]

  const isStacked = dynamicSeries.some((seriesItem: any) => seriesItem.stacked === true);

  useEffect(() => {
    if (statsData && !isFetching) {
      if (filterStore.timestep === 'year_week') {
        const formattedLabels = statsData.stats.map((item: any) => {
          return item?.timestep
        });
        setCustomLabels(formattedLabels);
      } else if (filterStore.timestep === 'year_month') {
        const formattedLabels = statsData.stats.map((item: any) => {
          const [year, week] = item.timestep.split('-');
          return `${week}/${year}`;
        });
        setCustomLabels(formattedLabels);
      } else {
        const formattedDailyDates = statsData.stats.map((item: any) => {
          const newDate = new Date(item.timestep);
          const month = String(newDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based, so we add 1
          const day = String(newDate.getUTCDate()).padStart(2, '0');
          const year = newDate.getUTCFullYear();
          if (currentLang.value === 'fr') {
            return `${day}/${month}/${year}`;
          } else {
            return `${month}/${day}/${year}`;
          }
        })
        setCustomLabels(formattedDailyDates);
      }
    }

  }, [currentLang.value, statsData, filterStore.timestep]);

  return (
    <>
      {(!filterStore?.countries || !filterStore?.keywords || !filterStore?.devices)
        ? <SplashScreen />
        : <Box sx={{ marginTop: 1 , px: 3}}>

          {!disableWelcome?.value &&
            <Grid container spacing={2} sx={{ marginBottom: 3, borderRadius: 2 }}>
              <Grid item xs={12}>
                <Welcome
                  onClose={() => disableWelcome.onTrue()}
                  title={t('dashboard.global.welcome')}
                  description={t('dashboard.business_overview.descriptionWelcome')}
                  img={lgUp ? <MotivationIllustration /> : <></>}
                />
              </Grid>
            </Grid>
          }

          <Grid container spacing={2}>
            {filteredStats.map((stat, index) => (
              <Grid item key={index} xs={6} md={4} lg={3}>
                {isFetching || (!statsData && !statsError) ? (
                  <Skeleton
                    width="100%"
                    height="110px"
                    style={{ borderRadius: 10 }}
                    variant="rounded"
                    animation="wave"
                  >
                    <>
                      <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                      <Typography color="success.main">Active</Typography>
                    </>
                  </Skeleton>
                ) : (
                  <BankingWidgetSummary
                    spanColor={stat.spanColor}
                    title={stat?.title}
                    compareData={stat?.compareData}
                    textColor={stat?.textColor}
                    icon={stat?.icon}
                    color={stat.color}
                    percent={0}
                    total={stat?.value}
                    chart={{
                      series: stat.series,
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>


          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                height: '500px',
                borderRadius: 2,
                marginTop: 3,
                marginBottom: 1
              }}
            >

              {isFetching || (!statsData && !statsError)
                ? <Skeleton width="100%" height="100%" style={{ borderRadius: 10 }} variant="rectangular" animation="wave">
                  <>
                    <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                    <Typography color="success.main">Active</Typography>
                  </>
                </Skeleton>
                : <div>
                  <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                    <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.business_overview.business')}</Typography>

                    <Typography variant="h6" sx={{ mb: 1 }}>
                      {t('dashboard.business_overview.blended_revenue_ad_cost')}
                    </Typography>

                    <Typography fontSize={16} sx={{ mb: 5, color: "#999999", opacity: .7 }}>
                      {t('dashboard.business_overview.evolution_ad_cost_and_blended_revenue_over_time')}
                    </Typography>

                  </div>

                  <Box sx={{ paddingLeft: 2 }}>
                    <ChartMixedCustom
                      series={blendedRevenueAdCost}
                      labels={customLabels}
                    />
                  </Box>

                </div>
              }
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                height: '500px',
                borderRadius: 2,
                marginTop: 3,
                marginBottom: 1
              }}
            >

              {isFetching || (!statsData && !statsError)
                ? <Skeleton width="100%" height="100%" style={{ borderRadius: 10 }} variant="rectangular" animation="wave">
                  <>
                    <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                    <Typography color="success.main">Active</Typography>
                  </>
                </Skeleton>
                : <div>
                  <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                    <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.business_overview.business')}</Typography>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      {dynamicTitle}
                    </Typography>
                    <Typography fontSize={16} sx={{ mb: 5, color: "#999999", opacity: .7 }}>
                      {t('dashboard.business_overview.evolution_custom_metrics_over_time')}
                    </Typography>

                  </div>

                  <Box sx={{ paddingLeft: 2, paddingRight: 4 }}>
                    {isStacked
                      ? <ChartMixedCustomLeadStacked
                        series={dynamicSeries}
                        labels={customLabels}
                      />
                      : dynamicSeries && <ChartMixedCustomLeadNoStacked
                        series={dynamicSeries}
                        labels={customLabels}
                      />
                    }
                  </Box>

                </div>
              }
            </Paper>
          </Grid>

          {workspace?.showSavings &&
            <Grid item xs={12} md={6} lg={6}>
              <Paper
                sx={{
                  backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                  boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                  height: '600px',
                  borderRadius: 2,
                  marginTop: 2,
                  marginBottom: 1
                }}
              >

                {isFetching || (!statsData && !statsError)
                  ? <Skeleton width="100%" height="100%" style={{ borderRadius: 10 }} variant="rectangular" animation="wave">
                    <>
                      <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                      <Typography color="success.main">Active</Typography>
                    </>
                  </Skeleton>
                  : <div>
                    <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                      <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.business_overview.business')}</Typography>

                      <Typography variant="h6" sx={{ mb: 1 }}>
                        {t('dashboard.business_overview.savings_over_time')}
                      </Typography>

                      <Typography fontSize={16} sx={{ mb: 5, color: "#999999", opacity: .7 }}>
                        {t('dashboard.business_overview.evolution_brend_spends_savings_over_time')}
                      </Typography>

                    </div>

                    <Box sx={{ paddingLeft: 2, paddingRight: 4 }}>
                      <ChartColumnStacked
                        series={savingsOverTime}
                        labels={customLabels}
                      />
                    </Box>

                  </div>
                }
              </Paper>
            </Grid>
          }
        </Box>
      }
    </>
  )
}