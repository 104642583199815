import { ApexOptions } from 'apexcharts';
// @mui
import { useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { CardProps } from '@mui/material/Card';
import Typography from '@mui/material/Typography';
// utils
import { fNumber, fPercent } from 'src/utils/format-number';
// theme
import { ColorSchema } from 'src/theme/palette';
import { bgGradient } from 'src/theme/css';
// components
import Iconify from 'src/components/iconify';
import Chart, { useChart } from 'src/components/chart';
import { useResponsive } from 'src/hooks/use-responsive';
import { getValue } from '@mui/system';
import { useSelector } from 'react-redux';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title: string;
  total: any;
  percent: number;
  color?: ColorSchema;
  textColor?: string;
  compareData?: any;
  icon: string;
  spanColor: any;
  chart: {
    series: {
      x: any;
      y: any;
    }[];
    options?: ApexOptions;
  };
}

export default function BankingWidgetSummary({
  title,
  total,
  compareData,
  icon,
  textColor,
  spanColor,
  percent,
  color = 'primary',
  chart,
  sx,
  ...other
}: Props) {
  const theme = useTheme();
  const lgUp = useResponsive('up', 'lg');
  const filterStore = useSelector((state: any) => state.filters);
  const { t } = useLocales();

  const { series, options } = chart;

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    series: [{ data: series }],
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    tooltip: {
      marker: {
        show: false,
      },
      y: {
        formatter: (value: number) => title !== t('dashboard.competitorsMonitoring.uniqueCompetitors') ? fPercent((value * 100)?.toFixed(2)) : fNumber(value),
        title: {
          formatter: () => '',
        },
      },
    },
    fill: {
      colors: [theme.palette[color].main],
    },
    colors: [theme.palette[color].main],
    ...options,
  });

  let trendIcon = '';
  let trendColor = '';
  if (compareData !== undefined) {
    if (compareData < 0) {
      trendIcon = 'eva:trending-down-fill';
      trendColor = '#a81b21';
    } else if (compareData > 0) {
      trendIcon = 'eva:trending-up-fill';
      trendColor = '#065e49';
    }
  }

  return (
    <Stack
      sx={{
        ...bgGradient({
          direction: '135deg',
          startColor: alpha(theme.palette[color].light, 0.2),
          endColor: alpha(theme.palette[color].main, 0.2),
        }),
        width: 1,
        height: '100%',
        borderRadius: 2,
        overflow: 'hidden',
        position: 'relative',
        color: theme.palette.mode !== "dark" ? `${textColor}` : 'white',
        boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
        backgroundColor: theme.palette.mode === "dark" ? `${color}.darker` : `${color}.lighter`,
        ...sx,
      }}
      {...other}
    >
      {lgUp &&
        <Iconify
          icon={icon}
          sx={{
            transform: icon === 'icon-park-twotone:search' ? 'rotate(80deg)' : undefined || icon === 'icon-park-twotone:click' ? 'rotate(80deg)' : undefined,
            width: 80,
            right: -20,
            top: 20,
            height: 80,
            opacity: 0.5,
            position: 'absolute',
          }}
        />
      }

      <Stack sx={{ p: 2 }}>
        <Typography sx={{ fontSize: 12 }} variant="subtitle2">{title.toUpperCase()}</Typography>

        <Typography variant="h3">
          {total}
        </Typography>

        <Stack
          spacing={0.5}
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{ typography: 'body2', fontWeight: 'bold', whiteSpace: 'nowrap' }}
        >
          {filterStore?.compare !== false &&
            <>
              {total !== 0 && compareData !== undefined && !isNaN(compareData) && isFinite(compareData)
                ? <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, height: '100%', }}>
                  <div style={{ backgroundColor: spanColor, padding: '1px 4px', display: 'flex', flexDirection: 'row', borderRadius: 10 }}>
                    <Iconify fontSize={{ xs: 10, lg: 15 }} icon={trendIcon} sx={{ color: trendColor }} />

                    <Box fontSize={{ xs: 12, lg: 15 }} sx={{ opacity: 0.8, color: trendColor, whiteSpace: 'nowrap' }}>
                      {fPercent(compareData || 0)}
                    </Box>

                  </div>
                  <Box fontSize={{ xs: 12, lg: 15 }} sx={{ opacity: 0.8, whiteSpace: 'nowrap' }}>vs comp. period</Box>
                </Box>
                : <div style={{ height: 22 }}></div>
              }
            </>
          }
        </Stack>

      </Stack>
      {total !== 0 && series?.length > 0
        ? <Chart type="area" series={[{ data: series }]} options={chartOptions} height={40} />
        : <div style={{ height: 40 }}></div>
      }
    </Stack>
  );
}
