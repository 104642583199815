import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';
import TrafficDetails from 'src/sections/overview/dashboards/views/trafficDetails';

// ----------------------------------------------------------------------

export default function Home() {
    const { t } = useLocales()
    return (
        <>
            <Helmet>
                <title>{t('trafficDetails')}</title>
            </Helmet>

            <TrafficDetails />
        </>
    );
}
