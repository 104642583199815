import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';
import GlobalPlaAds from 'src/sections/overview/dashboards/views/globalPlaAds';

// ----------------------------------------------------------------------

export default function Home() {
  const { t } = useLocales()
  return (
    <>
      <Helmet>
        <title>Product Listing Ads</title>
      </Helmet>

      <GlobalPlaAds />
    </>
  );
}