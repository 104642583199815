import Box from '@mui/system/Box';
import Chart, { useChart } from 'src/components/chart';
import { useResponsive } from 'src/hooks/use-responsive';

type Props = {
  series: number[];
  labels: string[];
};

export default function ChartDonut({ series, labels }: Props) {
  const numericSeries = (series || []).map(item => Number(item));
  const total = numericSeries.reduce((acc, val) => acc + val, 0);
  const lgUp = useResponsive('up', 'lg');

  const lightenColor = (color: string, lightness: number, maxLightness: number) => {
    const hslColor = rgbToHsl(color);
    const newLightness = hslColor.l + lightness;
    const finalLightness = Math.min(newLightness, maxLightness);
    const newHslColor = `hsl(${hslColor.h}, ${hslColor.s}%, ${finalLightness}%)`;
    return newHslColor;
  };

  const generateRedShades = (numShades: number, maxLightness: number) => {
    const baseColor = "#b71d18";
    const shades = [];
    for (let i = 0; i < numShades; i++) {
      const ratio = i / (numShades - 1);
      const shade = lightenColor(baseColor, ratio * 100, maxLightness);
      shades.push(shade);
    }
    return shades;
  };

  const rgbToHsl = (color: string) => {
    const hex = (c: string) => parseInt(c, 16);
    const r = hex(color.substring(1, 3)) / 255;
    const g = hex(color.substring(3, 5)) / 255;
    const b = hex(color.substring(5, 7)) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    const l = (max + min) / 2;

    if (max !== min) {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }

    return { h: Math.round(h * 360), s: Math.round(s * 100), l: Math.round(l * 100) };
  };

  const redShades = generateRedShades(numericSeries.length, 90);

  const chartOptions = useChart({
    labels: labels || [],
    colors: redShades,
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: '#373d3f',
              offsetY: -10,
              formatter: function (val) {
                return val; // Label for the chart segments
              }
            },
            value: {
              show: true,
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              color: '#373d3f',
              offsetY: 16,
              formatter: function (val) {
                return val; // Value displayed at the center of the chart
              }
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              fontSize: '22px',
              fontWeight: 600,
              color: '#373d3f',
              formatter: function (w) {
                // Calculate the total and display it in the center
                const total = w.globals.seriesTotals.reduce((a: any, b: any) => a + b, 0);
                return total.toLocaleString(); // Formatting the total
              }
            }
          }
        }
      }
    },
    legend: {
      horizontalAlign: 'center',
      position: 'bottom',
      fontSize: '12px',
    },
    tooltip: {
      shared: true,
      y: {
        formatter: (value, { seriesIndex }) => {
          const total = numericSeries.reduce((acc, val) => acc + val, 0);
          const percentage = ((value / total) * 100).toFixed(2);
          return `${value.toLocaleString()} (${percentage}%)`;
        }
      }
    },
    responsive: [
      {
        breakpoint: 0,
        options: {
          chart: {
            width: '600px',
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '450px',
          },
        },
      },
    ],
  });

  return (
    <Box>
      <Chart dir="ltr" type="donut" series={numericSeries} options={chartOptions} width={580} />
    </Box>
  );
}
