import { useState, useCallback } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { Stack, useTheme } from '@mui/system';
import { Box, LinearProgress, Typography } from '@mui/material';
import { fPercent } from 'src/utils/format-number';
import { setFilterDate } from 'src/context/reducers/filter-settings/filterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { useLocales } from 'src/locales';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { formatWeekLabel } from 'src/utils/dates-labels';
import { formatCurrency } from 'src/utils/format-currency';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

type Props = {
  data: {
    id: string;
    keyword: string;
  }[];
};

export default function DataGridCustomDay({ data }: Props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const { t, currentLang } = useLocales();
  const workspace = useCurrentWorkspace();

  const createColumns = (theme: any, t: any, lgUp: any, formatCurrency: any, currency: any, locale: any): GridColDef[] => [
    {
      field: 'date',
      headerName: t('dashboard.global.date'),
      flex: 1,
      valueGetter: (params: any) => {
        if (filterStore.timestep === 'date') {
          // const dateObj = new Date(Date.UTC(params));
          const localDate = new Date(params); // En local (selon le fuseau horaire de l'utilisateur)
          // Convertir en UTC
          const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
          // if (dateObj.getDate()) {
          if (params) {
            const date = currentLang.value === 'fr' ? format(utcDate, "dd/MM/yyyy", { locale: fr }) : format(utcDate, "MM/dd/yyyy", { locale: enUS })

            return date
          }

        } else if (filterStore.timestep === 'year_week') {
          return params
        }
        else {
          const [year, week] = params.split('-');
          return `${week}/${year}`
          // return params
        }
      },
    },
    {
      field: 'brandSearches',
      headerName: t('dashboard.brandTraffic.brandSearches'),
      flex: 1,
      type: 'number',
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#db8377' : '#B71D18' }}>
          {params.row.brandSearches}
        </span>
      ),
    },
    {
      field: 'totalBrandTraffic',
      headerName: t('dashboard.brandTraffic.totalBrandTraffic'),
      flex: 1,
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#a64b7b' : '#880E4F' }}>
          {params.row.totalBrandTraffic}
        </span>
      ),
      type: 'number',
    },
    {
      field: 'organicBrandTraffic',
      headerName: t('dashboard.brandTraffic.organicBrandTraffic'),
      flex: 1,
      type: 'number',
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#6dad8d' : '#065E49' }}>
          {params.row.organicBrandTraffic}
        </span>
      ),
    },
    {
      field: 'paidBrandTraffic',
      headerName: t('dashboard.brandTraffic.paidBrandTraffic'),
      flex: 1,
      type: 'number',
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#65b5c9' : '#006C9C' }}>
          {params.row.paidBrandTraffic}
        </span>
      ),
    },
    {
      field: 'uncapturedBrandTraffic',
      headerName: t('dashboard.brandTraffic.uncapturedBrandTraffic'),
      flex: 1,
      type: 'number',
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7' }}>
          {params.row.paidBrandTraffic}
        </span>
      ),
    },
    {
      field: 'blendedCtr',
      headerName: t('dashboard.global.blended_ctr'),
      flex: 1,
      width: 160,
      renderCell: (params) => {
        const cappedBlendedCtr = Math.min(params.row.blendedCtr, 100); // Limiter à 100%
        return (
          <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
            <LinearProgress
              value={cappedBlendedCtr}
              variant="determinate"
              sx={{
                width: 1,
                height: 6,
                backgroundColor: theme.palette.mode === "dark" ? '#3d1873' : 'rgb(61, 24, 115, .24)',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#7635dc',
                }
              }}
            />
            <Typography variant="caption" sx={{ width: 80 }}>
              {cappedBlendedCtr === 0 ? '0%' : fPercent(cappedBlendedCtr)}
            </Typography>
          </Stack>
        );
      },
      type: 'number',
    },
    {
      field: 'paidCtr',
      headerName: t('dashboard.brandTraffic.paidCtr'),
      flex: 1,
      width: 160,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.paidCtr}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#183c50' : 'rgb(24, 60, 80, .24)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#006C9C',
              }
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.paidCtr === 0 ? '0%' : fPercent(params.row.paidCtr)}
          </Typography>
        </Stack>
      ),
      type: 'number',
    },
    {
      field: 'organicCtr',
      headerName: t('dashboard.brandTraffic.organicCtr'),
      flex: 1,
      width: 160,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.organicCtr}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#19383a' : 'rgb(25, 56, 58, .24)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#065E49',
              }
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.organicCtr === 0 ? '0%' : fPercent(params.row.organicCtr)}
          </Typography>
        </Stack>
      ),
      type: 'number',
    },
    {
      field: 'uncapturedPercent',
      headerName: t('dashboard.brandTraffic.percentUncaptured'),
      flex: 1,
      width: 160,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.uncapturedPercent}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#454d54' : 'rgb(69, 77, 84, .24)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#ADADAD',
              }
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.uncapturedPercent === 0 ? '0%' : fPercent(params.row.uncapturedPercent)}
          </Typography>
        </Stack>
      ),
      type: 'number',
    },
    {
      field: 'adCost',
      headerName: t('dashboard.global.ad_cost'),
      flex: 1,
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#FFD066' : '#FFAB00' }}>
          {formatCurrency(params.row.adCost, currency, locale, true, 1)}
        </span>
      ),
      type: 'number',
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const handleRowClick = (params: any) => {
    const clickedDate = params?.row?.date;

    // Vérifiez si clickedDate est une chaîne valide
    if (typeof clickedDate === 'string') {
      const parsedDate = new Date(clickedDate); // Pas besoin de parse, le format ISO est valide

      if (!isNaN(parsedDate.getTime())) { // Vérifiez que la date est valide
        const parsedDateISOString = parsedDate.toISOString();

        if (filterStore.start === parsedDateISOString && filterStore.end === parsedDateISOString) {
          const newStartDate = new Date();
          newStartDate.setMonth(newStartDate.getUTCMonth() - 1);
          newStartDate.setDate(newStartDate.getUTCDate() + 1);

          const newEndDate = new Date();
          newEndDate.setDate(newEndDate.getUTCDate() - 1);

          dispatch(
            setFilterDate({
              start: newStartDate.toISOString(),
              end: newEndDate.toISOString(),
            })
          );
        } else {
          dispatch(
            setFilterDate({
              start: parsedDateISOString,
              end: parsedDateISOString,
            })
          );
        }
      } else {
        console.error("Date invalide :", clickedDate);
      }
    } else {
      console.error("Clicked Date n'est pas une chaîne :", clickedDate);
    }
  };


  const handlePaginationModelChange = (newModel: any) => {
    setPaginationModel(newModel);
  };

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-cell': {
          cursor: 'pointer',
        },
      }}
    >
      <DataGridPro
        disableDensitySelector
        autoHeight
        checkboxSelection={false}
        disableRowSelectionOnClick
        rows={data}
        pagination
        onRowClick={handleRowClick}
        paginationModel={paginationModel}
        columns={createColumns(theme, t, true, formatCurrency, workspace?.currency, currentLang.value)}
        onPaginationModelChange={handlePaginationModelChange}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </Box>
  );
}