import { createContext, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// Créer le contexte
const PreviousLocationContext = createContext(null);

// Fournisseur du contexte
export const PreviousLocationProvider = ({ children }: any) => {
  const location = useLocation();
  const prevLocationRef: any = useRef(location.pathname); // Utilise useRef pour stocker la page précédente

  // useEffect pour mettre à jour l'URL précédente à chaque changement de route
  useEffect(() => {
    prevLocationRef.current = location.pathname;
  }, [location]);

  return (
    <PreviousLocationContext.Provider value={prevLocationRef.current}>
      {children}
    </PreviousLocationContext.Provider>
  );
};

// Hook pour accéder à l'URL précédente
export const usePreviousLocation = () => {
  return useContext(PreviousLocationContext);
};