import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  categories: string[];
};

export default function ChartBar({ series, categories }: Props) {
  const theme = useTheme();

  enum ColorSchema {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
  }

  const chartOptions = useChart({
    chart: { stacked: true },
    stroke: { show: false },
    legend: {
      markers: {
        customHTML: function () {
          return '<div style="width: 16px; height: 16px; border-radius: 50%; background-color: currentcolor;"></div>';
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val: number) => `${val !== undefined ? val.toFixed(0) : 0}`,
      },
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '80%', columnWidth: '50%', borderRadius: 0 },
    },
    fill: {
      colors: series?.length === 3
        ? [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, '#b7b7b7']
        : series?.length === 5 ? [theme.palette[ColorSchema.SUCCESS].light, "#1e88e5", "#64b5f6", '#b7dcfb', '#b7b7b7'] : ["#118d57", "#1e88e5", "#64b5f6", "#E1E1E1"],
    },
    colors: series?.length === 3
      ? [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, '#b7b7b7']
      : series?.length === 5 ? [theme.palette[ColorSchema.SUCCESS].light, "#1e88e5", "#64b5f6", '#b7dcfb', '#b7b7b7'] : ["#118d57", "#1e88e5", "#64b5f6", "#E1E1E1"],
    xaxis: {
      categories: categories || [],
    },
  });

  return (
    <Chart
      dir="ltr"
      type="bar"
      series={series}
      options={chartOptions}
      height={500}
    />
  );
}
