import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";
import { RegisterResponse } from "../auth";
import { UserList } from "./models";
import { FilterUsers } from "./query";
import { update } from "lodash";

const admin = createApi({
    baseQuery: fetchBaseQueryCSRF({
        credentials: 'include',
        baseUrl: CROSS_API_URL,
    }),
    reducerPath: 'api.admin',
    tagTypes: ['admin'],
    endpoints: (builder) => ({
        getUsers: builder.query<UserList, FilterUsers | void>({
            query: (arg) => {
                const filter = new URLSearchParams(Object.assign({}, arg) as any).toString();

                return {
                    url: '/admin/users?' + filter,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        Accept: 'application/json',
                    },
                }
            }
        }),
        getWorkspaceUsers: builder.query<UserList, FilterUsers | void>({
            query: (arg) => {
                const filter = new URLSearchParams(Object.assign({}, arg) as any).toString();

                return {
                    url: '/admin/workspace-users?' + filter,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        Accept: 'application/json',
                    },
                }
            }
        }),
        addUser: builder.mutation<RegisterResponse, { email: string | null, role: any | null }>({
            query: (arg) => ({
                url: '/admin/addUser',
                method: 'POST',
                body: arg,
                headers: {
                    'Cache-Control': 'max-age=0',
                    Accept: 'application/json',
                },
            }),
        }),
        deleteUser: builder.mutation<any, { user_id: number | null }>({
            query: ({ user_id }) => ({
                url: `/admin/user/${user_id}`,
                method: 'DELETE',
                headers: {
                    'Cache-Control': 'max-age=0',
                    Accept: 'application/json',
                },
            }),
        }),
        updateUser: builder.mutation<any, { user_id: number | null, oldUser: any | null, newUser: any | null }>({
            query: ({ user_id, oldUser, newUser }) => ({
                url: `/admin/user/${user_id}`,
                method: 'PUT',
                body: { oldUser, newUser },
                headers: {
                    'Cache-Control': 'max-age=0',
                    Accept: 'application/json',
                },
            }),
        }),
        getWorkspaces: builder.query<string, void>({
            query: (arg) => ({
                url: '/admin/getWorkspaces',
                method: 'GET',
                body: arg,
                headers: {
                    'Cache-Control': 'max-age=0',
                    Accept: 'application/json',
                },
            }),
        }),
        addKeywordScraping: builder.mutation<any, { keywords: string[], country: string, frequency: string, device: string, mode: string, home_language: string, platform_scraping: string[] }>({
            query: (arg) => ({
                url: '/admin/scraping/add/keyword',
                method: 'POST',
                body: arg,
                headers: {
                    'Cache-Control': 'max-age=0',
                    Accept: 'application/json',
                },
            }),
        }),
        getScraping: builder.query<any, { workspaceId: any | null }>({
            query: (arg) => ({
                url: '/admin/scraping',
                method: 'GET',
                params: {
                    workspaceId: arg.workspaceId,
                    mode: 'Scraping',
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    Accept: 'application/json',
                }
            }),
        }),
        deleteScraping: builder.mutation<any, { scraping_id: number | null }>({
            query: ({ scraping_id }) => ({
                url: `/admin/scraping/${scraping_id}`,
                method: 'DELETE',
                headers: {
                    'Cache-Control': 'max-age=0',
                    Accept: 'application/json',
                },
            }),
        }),
        updateScraping: builder.mutation<any, { updates: { id: string | number, changes: Partial<any>, keywords: any, country: any, workspace_id: any }[] }>({
            query: (arg) => ({
                url: '/admin/scraping/update',
                method: 'PUT',
                body: arg.updates,
                headers: {
                    'Cache-Control': 'max-age=0',
                    Accept: 'application/json',
                },
            }),
        }),
        scrapingExist: builder.mutation<{ exists: boolean }, { keywords: string[], country: any, workspaceId: string, platform_scraping: any }>({
            query: ({ keywords, country, workspaceId, platform_scraping }) => {
                const params = new URLSearchParams();
                params.append('keywords', keywords.join(','));  // Joindre les mots-clés en une seule chaîne
                params.append('country', country);
                params.append('workspaceId', workspaceId);
                params.append('platform_scraping', platform_scraping.join(','));

                return {
                    url: `/admin/scraping/exists?${params.toString()}`,
                    method: 'GET',
                    headers: {
                        'Cache-Control': 'max-age=0',
                        Accept: 'application/json',
                    },
                };
            },
        }),
        getUserByEmail: builder.query<any, { email: string | null }>({
            query: (email) => ({
                url: `/admin/user/email`,
                method: 'GET',
                params: email,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Cache-Control': 'max-age=0',

                },
            }),
        }),
        triggerBO: builder.query<void, void>({  // Specify void for both request and response
            query: () => ({
                url: '/admin/bo-activations/trigger',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    Accept: 'application/json',
                }
            }),
        }),
    }),
});

export const {
    useGetUsersQuery,
    useGetWorkspaceUsersQuery,
    useLazyGetUsersQuery,
    useAddUserMutation,
    useDeleteUserMutation,
    useUpdateUserMutation,
    useGetWorkspacesQuery,
    useAddKeywordScrapingMutation,
    useGetScrapingQuery,
    useDeleteScrapingMutation,
    useUpdateScrapingMutation,
    useScrapingExistMutation,
    useLazyGetUserByEmailQuery,
    useLazyTriggerBOQuery
} = admin;

export default admin;