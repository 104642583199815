import { useEffect, useRef, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
// _mock
import { _socials } from 'src/_mock';
// components
import Iconify from 'src/components/iconify';
//
import ProfilePostItem from './profile-post-item';
import { Chip, Skeleton, Typography } from '@mui/material';
import ChartPresenceRate from 'src/sections/charts/chart-presence-rate';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

type Props = {
  info: any;
  posts: any;
  employees: number;
  foundedYear: number;
  industries: string[];
  locations: string[];
  presenceRate: any;
  kind: string;
  isFetching: any,
  name: any
};

export default function ProfileHome({ name, info, posts, employees, foundedYear, industries, locations, kind, presenceRate, isFetching }: Props) {
  const { t } = useLocales();
  const [bestIndustry, setBestIndustry] = useState({});
  const [industryName, setIndustryName] = useState('');
  const [emoji, setEmoji] = useState('');

  useEffect(() => {

    if (!industries || industries.length === 0) return;
    const bestIndustry = industries.reduce((prev: any, current: any) => {
      if (!current?.score) return prev;
      return (current?.score > prev?.score) ? current : prev;
    }, industries[0]);
    setIndustryName(bestIndustry.name);
    setEmoji(bestIndustry.emoji);
    setBestIndustry(bestIndustry);


  }, [employees, foundedYear, industries, locations, kind]);

  const renderIndustries = (
    <Stack direction="row" spacing={2} sx={{ pl: 3, pr: 3, pt: 3 }}>

      {Object.keys(bestIndustry).length > 0 &&
        <Chip
          label={emoji + ' ' + industryName}
          sx={{ borderRadius: 2 }}
        />
      }

    </Stack>
  )

  const getEmployees = (numEmployees: any): string | null => {
    const ranges = [
      { min: 1, max: 1 },
      { min: 2, max: 10 },
      { min: 11, max: 50 },
      { min: 51, max: 200 },
      { min: 201, max: 500 },
      { min: 501, max: 1000 },
      { min: 1001, max: 5000 },
      { min: 5001, max: 10000 },
      { min: 10001, max: Infinity }
    ];

    // Vérifier si la valeur est déjà un intervalle ou une valeur de type 10000+
    if (typeof numEmployees === 'string') {
      if (/^\d+-\d+$/.test(numEmployees)) {
        return `${numEmployees} employees`;
      }
      if (/^\d+\+$/.test(numEmployees)) {
        return `${numEmployees} employees`;
      }
    }

    // Si numEmployees est un nombre
    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i];

      // Si numEmployees est dans l'intervalle actuel
      if (numEmployees >= range.min && numEmployees <= range.max) {
        const midPoint = (range.min + range.max) / 2;
        if (numEmployees < midPoint && i > 0) {
          return `${ranges[i - 1].min} - ${numEmployees} employees`;
        } else {
          return `${range.min} - ${numEmployees} employees`;
        }
      }
    }

    // Si numEmployees est supérieur à tous les intervalles spécifiés
    for (let i = ranges.length - 1; i >= 0; i--) {
      const range = ranges[i];
      if (numEmployees > range.max) {
        return `${range.min} - ${numEmployees} employees`;
      }
    }

    // Si numEmployees est inférieur à toutes les plages spécifiées
    return `${ranges[0].min} - ${numEmployees} employees`;
  };

  const renderAbout = (
    <Card>
      <CardHeader title={t('dashboard.profilePage.about')} />

      {renderIndustries}

      <Stack spacing={2} sx={{ p: 3 }}>
        {/* Badge industries */}

        <Box sx={{ typography: 'body2', textDecoration: 'italic' }}>{info?.description}</Box>

        {employees &&
          <Stack direction="row" spacing={2}>

            <Iconify icon="fa6-solid:people-group" width={24} />
            <Box sx={{ typography: 'body2' }}>
              {getEmployees(employees)}

            </Box>
          </Stack>
        }

        {kind &&
          <Stack direction="row" spacing={2}>

            <Iconify icon="clarity:building-solid" width={24} />
            <Box sx={{ typography: 'body2' }}>
              {kind.toLowerCase().split('_').map(word => (
                word.charAt(0).toUpperCase() + word.slice(1)
              )).join(' ')}
            </Box>

          </Stack>
        }



        {foundedYear && <Stack direction="row" spacing={2}>
          <Iconify icon="fa6-solid:calendar-day" width={24} />
          <Box sx={{ typography: 'body2' }}>
            {t('dashboard.profilePage.founded_in')} {foundedYear}
          </Box>

        </Stack>
        }

        {locations &&
          <Stack direction="row" spacing={2}>
            <Box>
              <Iconify icon="ant-design:like-o" width={24} />
              <Box sx={{ typography: 'body2' }}>
                {t('dashboard.profilePage.location')} {locations}
              </Box>
            </Box>
          </Stack>
        }

        {info?.company?.location?.country &&
          <Stack direction="row" spacing={2}>
            <Iconify icon="mingcute:location-fill" width={24} />

            <Box sx={{ typography: 'body2' }}>
              <Link variant="subtitle2" color="inherit">
                {info?.company?.location?.country}
              </Link>
            </Box>
          </Stack>
        }

      </Stack>
    </Card>
  );

  const socialLinks = info?.links || [];

  const renderSocials = socialLinks.length > 0 && (
    <Card>
      <CardHeader title={t('dashboard.profilePage.social')} />

      <Stack spacing={2} sx={{ p: 3 }}>
        {socialLinks.map((link: any) => (
          <Stack
            key={link.name}
            spacing={2}
            direction="row"
            sx={{ wordBreak: 'break-all', typography: 'body2' }}
          >
            {link.name === 'twitter' ? (
              <Iconify
                icon={`ri:twitter-x-fill`}
                width={24}
                sx={{
                  flexShrink: 0,
                  color: link.color,
                }}
              />
            ) : link.name === 'crunchbase' ? (
              <Iconify
                icon={`simple-icons:crunchbase`}
                width={24}
                sx={{
                  flexShrink: 0,
                  color: link.color,
                }}
              />) : (
              <Iconify
                icon={`mdi:${link.name}`}
                width={24}
                sx={{
                  flexShrink: 0,
                  color: link.color,
                }}
              />
            )}
            <Link href={link.url} color="inherit">
              {link.name === 'twitter'
                ? 'X'
                : link.name.charAt(0).toUpperCase() + link.name.slice(1)
              }
            </Link>
          </Stack>
        ))}
      </Stack>
    </Card>
  );


  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={4}>
        <Stack spacing={3}>

          <Card sx={{ height: 220 }}>
            {!posts || isFetching
              ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                <>
                  <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                  <Typography color={"success.main"}>Active</Typography>
                </>
              </Skeleton>
              : <>
                <CardHeader title={t('dashboard.global.presenceRate')} />

                <Stack spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <ChartPresenceRate
                    presenceRate={parseFloat((presenceRate * 100).toFixed(1))}
                  />

                </Stack>
              </>
            }

          </Card>
          {info &&
            <>{renderAbout}</>
          }

          {renderSocials}
        </Stack>
      </Grid>

      <Grid xs={12} md={8}>
        <Stack spacing={3}>
          <ProfilePostItem name={name} info={info} key={posts} post={posts} isFetching={isFetching} />
        </Stack>
      </Grid>
    </Grid>
  );
}