
export const generateAnnotations = (workspace: any, filterStore: any, labels: string[]) => {
    if (!workspace?.name.includes("Demo")) return [];

    return [
        {
           
            x: filterStore?.timestep === 'date' ?'10/15/2024' : filterStore?.timestep === 'year_week' ? '10/07/2024' : labels[1] ,
            fillColor: '#ffe5e9',
            borderColor: 'transparent',

        },
        {
            x: filterStore?.timestep === 'date' ? '10/15/2024' : filterStore?.timestep === 'year_week' ? '10/07/2024' : labels[1],
            strokeDashArray: 0,
            borderColor: "#775DD0",
            border:0,
            offsetX: 13.5,
            label: {
                offsetX:13.5,
                borderColor: "#775DD0",
                
                style: {
                    color: "#fff",
                    background: "#775DD0",
                    fontSize: '14px',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                text: "Cross Brand Deployment"
            }
        },
        {
            x: filterStore?.timestep === 'date' ? '10/15/2024' : filterStore?.timestep === 'year_week' ?  '10/07/2024' : labels[1],
            borderColor: 'transparent',
        },        
    ];
};
