import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { Stack, useTheme } from '@mui/system';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useCurrentUserQuery, useProviderRegisterMutation, useProviderUserDeleteMutation, useUpdatePasswordFirstLogMutation, useVerifyTokenMutation } from 'src/context/api/auth';
import { useLocales } from 'src/locales';
import * as Yup from 'yup'
import { jwtDecode } from 'jwt-decode'
import { useAcceptInvitationMutation, useInvitationsQuery } from 'src/context/api/workspaces';
import { useRouter } from 'src/routes/hooks';
import { paths, ROOTS } from 'src/routes/paths';
import { useAuth0 } from '@auth0/auth0-react';
import Iconify from 'src/components/iconify';
import { enqueueSnackbar } from 'notistack';

export default function UpdatePassword() {
    const { t } = useLocales();
    const [updatePassword,] = useUpdatePasswordFirstLogMutation();
    const [acceptInvitation,] = useAcceptInvitationMutation();
    const [providerRegister,] = useProviderRegisterMutation();
    const theme = useTheme();
    const [decodedToken, setDecodedToken]: any = useState();
    const router = useRouter();
    const [isExpired, setIsExpired] = useState(false);
    const [tokens, setTokens] = useState<any>([]);
    const invitations: any = useInvitationsQuery(null);
    const user: any = useCurrentUserQuery();
    const [verifyToken, responseVerify] = useVerifyTokenMutation();
    const { loginWithPopup, getAccessTokenSilently, isAuthenticated, user: auth0User, error } = useAuth0();
    const [provider, setProvider] = useState<any>('');
    const [providerUserDelete] = useProviderUserDeleteMutation();

    const [isLoadingSSO, setIsLoadingSSO] = useState(false);

    const handleProviderAuth = async () => {
        setIsLoadingSSO(true);
        try {
            const token: any = await getAccessTokenSilently();
            if (auth0User?.email === decodedToken?.email) {
                const response: any = await providerRegister({ token, provider })
                if (response && response.data && response.data.status === 200) {

                    const workspaceIds = decodedToken.workspaces.map((ws: any) => ws.id);
                    const tokens = decodedToken.token;
                    const acceptAllInvitations = workspaceIds.map((workspaceId: number, index: number) => {
                        const token = tokens[index];
                        return acceptInvitation({ id: workspaceId, token });
                    });
                    const invitations = await Promise.all(acceptAllInvitations);
                    if (invitations.length > 1) {

                        router.push(ROOTS.WORKSPACES);
                    } else if (invitations.length === 1) {

                        router.push(paths.dash.businessOverview);
                    }
                    else {

                        router.push(ROOTS.AUTH);
                    }
                } else {
                    enqueueSnackbar('Failed to register user. Status: ' + response?.status, {
                        variant: 'error',
                    });
                }

            } else {
                enqueueSnackbar('Failed to sign up with this email ', {
                    variant: 'error',
                });
               
                if (isAuthenticated) {
                    const idUser:any = auth0User?.sub 
                    const newToken: any =  getAccessTokenSilently()
                      await providerUserDelete({ token:newToken, userId: idUser }).unwrap();
                }
               
            }
        } catch (error) {
            enqueueSnackbar('Error during sign up', {
                variant: 'error',
            });
        } finally {
            setIsLoadingSSO(false);
        }

    }

    useEffect(() => {
        if (isAuthenticated && !error && !isExpired) {
            handleProviderAuth()
        }
    }, [isAuthenticated, error, isExpired,auth0User]);

    const signUpWithProvider = async (provider: any) => {
        setProvider(provider)
        try {
            loginWithPopup({
                authorizationParams: {
                    connection: provider,
                }
            })
        } catch (error) {
            enqueueSnackbar('Error : Error during linking account', {
                variant: 'error',
            });
        }
    }

    useEffect(() => {
        if (decodedToken) {
            setTokens(decodedToken.token);
        }

    }, [decodedToken])

    const schema = Yup.object({
        password: Yup.string().required(),
        lastname: Yup.string().required(),
        firstname: Yup.string().required(),
        confirmPassword: Yup.string().required(),
        token: Yup.string().required(),
    });


    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            password: '',
            confirmPassword: '',
            firstname: '',
            lastname: '',
            token: undefined
        },
    });

    const { formState: { isSubmitting } } = methods;
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    useEffect(() => {
        if (token) {
            methods.setValue("token", token);

            try {
                setDecodedToken(jwtDecode(token));
            } catch (error) {
                console.error('Invalid token:', error);
            }
        }
    }, [token])

    const handleUpdatePassword = async (data: { password: string; confirmPassword: string; token: string; lastname: string; firstname: string }) => {
        try {
            await updatePassword(data)
            const workspaceIds = decodedToken.workspaces.map((ws: any) => ws.id);
            const tokens = decodedToken.token;

            const acceptAllInvitations = workspaceIds.map((workspaceId: number, index: number) => {
                const token = tokens[index];
                return acceptInvitation({ id: workspaceId, token });
            });

            const invitations = await Promise.all(acceptAllInvitations);
            if (invitations.length > 1) {
                router.push(ROOTS.WORKSPACES);
            } else if (invitations.length === 1) {
                router.push(paths.dash.businessOverview);
            }
            else {
                router.push(ROOTS.AUTH);
            }
        } catch (error) {
            console.error("Failed to update password or accept invitations:", error);
            enqueueSnackbar('Error : Error during sign up', {
                variant: 'error',
            });
        }
    };

    useEffect(() => {
        if (tokens.length >= 1) {


            tokens.map((token: any, index: number) => {
                verifyToken({ token: token })
                    .then((response) => {
                        const data: any = Object.values(response)[0];
                        const status = data.status;
                        if (status != 200) {
                            setIsExpired(true);
                        }
                    })
                    .catch(error => {
                        console.error('Error :', error);
                    });
            });
        }
    }, [tokens, verifyToken])

    return (
        <div style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: 'url(/assets/background/background.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            <div style={{
                width: '100%',
                maxWidth: '400px',
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '40px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                position: 'relative',
            }}>

                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
                    <div style={{
                        width: '100px',
                        height: '100px',

                        position: 'relative',
                        borderRadius: '50%',
                        overflow: 'hidden',
                    }}>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            backgroundImage: 'linear-gradient(180deg, rgba(0, 167, 111, 1) -100%, rgba(0, 167, 111, 0.22) 100%)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 0,
                            opacity: 0.3,
                            borderRadius: '50%',
                        }}>

                        </div>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            zIndex: 1,
                        }}>
                            <img
                                src='/assets/logo.png'
                                alt="Logo"
                                style={{
                                    width: '60px',
                                    height: 'auto',
                                }}
                            />
                        </div>
                    </div>
                </div>
                <>
                    {isExpired && (
                        <Typography variant="h4" style={{ color: '#1C252E', textAlign: 'center' }}>
                            Your token has expired
                        </Typography>
                    )}
                    {isLoadingSSO && (
                        <Box sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: '10px',
                            padding: 2,
                            zIndex: 100,
                        }}>
                            <Box sx={{ width: '100%', height: '100%', display: 'flex', gap: 5, flexDirection: 'column', }}>
                                <CircularProgress size={50} sx={{ marginRight: 1, alignSelf: 'center' }} variant='indeterminate' value={50} />
                                <Typography variant="h6" color={'black'} fontWeight={'bold'} sx={{ textAlign: 'center' }}>
                                    SSO authentication is in progress...
                                </Typography>
                            </Box>

                        </Box>
                    )}


                    {!isExpired && (
                        <Box sx={{ display: isLoadingSSO ? 'none' : 'block' }}>

                            <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleUpdatePassword)}>
                                <Stack spacing={2} sx={{ mb: 2 }}>
                                    <Stack spacing={2} sx={{ position: 'relative' }}>
                                        <Typography variant="h4" style={{ color: '#1C252E', textAlign: 'center' }}>
                                            Create your account
                                        </Typography>
                                    </Stack>


                                    <Alert sx={{ mb: 4 }} severity="info">
                                        You have been invited by <span style={{ fontWeight: 'bold' }}>{decodedToken?.user?.profile?.firstname} {' '} {decodedToken?.user?.profile?.lastname} </span>
                                        to the
                                        <span style={{ fontWeight: 'bold' }}>
                                            {decodedToken?.workspaces.length > 1 ? ' workspaces: ' : ' workspace: '}
                                            {decodedToken?.workspaces.map((ws: any, index: number) => (
                                                <span key={index}>
                                                    {ws?.name}
                                                    {index < decodedToken?.workspaces.length - 1 && ', '}
                                                </span>
                                            ))}
                                        </span>.
                                    </Alert>


                                    <div style={{ display: 'flex', flexDirection: "row", gap: 10 }}>

                                        <RHFTextField
                                            name="firstname"
                                            label={"Firstname"}
                                            InputLabelProps={{
                                                sx: {
                                                    color: '#1C252E',
                                                    '&.Mui-focused': {
                                                        color: '#1C252E !important',
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                sx: {
                                                    color: '#1C252E',
                                                },
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#637381',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#637381',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#4c5b6e',
                                                    },
                                                    '& input:-webkit-autofill': {
                                                        '-webkit-box-shadow': '0 0 0 1000px white inset',
                                                        '-webkit-text-fill-color': '#1C252E',
                                                    },
                                                },
                                            }}
                                        />

                                        <RHFTextField
                                            name="lastname"
                                            label={"Lastname"}
                                            InputLabelProps={{
                                                sx: {
                                                    color: '#1C252E',
                                                    '&.Mui-focused': {
                                                        color: '#1C252E !important',
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                sx: {
                                                    color: '#1C252E',
                                                },
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#637381',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#637381',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#4c5b6e',
                                                    },
                                                    '& input:-webkit-autofill': {
                                                        '-webkit-box-shadow': '0 0 0 1000px white inset',
                                                        '-webkit-text-fill-color': '#1C252E',
                                                    },
                                                },
                                            }}
                                        />

                                    </div>


                                    {/* Champ d'email */}
                                    <RHFTextField
                                        name="password"
                                        label={"Password"}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#1C252E',
                                                '&.Mui-focused': {
                                                    color: '#1C252E !important',
                                                },
                                            },
                                        }}
                                        type='password'
                                        InputProps={{
                                            sx: {
                                                color: '#1C252E',
                                            },
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#637381',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#637381',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#4c5b6e',
                                                },
                                                '& input:-webkit-autofill': {
                                                    '-webkit-box-shadow': '0 0 0 1000px white inset',
                                                    '-webkit-text-fill-color': '#1C252E',
                                                },
                                            },
                                        }}
                                    />

                                    <RHFTextField
                                        name="confirmPassword"
                                        label={"Repeat password"}
                                        InputLabelProps={{
                                            sx: {
                                                color: '#1C252E',
                                                '&.Mui-focused': {
                                                    color: '#1C252E !important',
                                                },
                                            },
                                        }}
                                        type='password'
                                        InputProps={{
                                            sx: {
                                                color: '#1C252E',
                                            },
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#637381',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#637381',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#4c5b6e',
                                                },
                                                '& input:-webkit-autofill': {
                                                    '-webkit-box-shadow': '0 0 0 1000px white inset',
                                                    '-webkit-text-fill-color': '#1C252E',
                                                },
                                            },
                                        }}
                                    />

                                    {/* Bouton pour soumettre */}
                                    <LoadingButton
                                        color="inherit"
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        loading={isSubmitting}
                                        sx={{ backgroundColor: '#1C252E', color: 'white' }}
                                    >
                                        Accept invitation
                                    </LoadingButton>
                                </Stack>


                                <Divider><Typography color='black' variant='subtitle1'> or</Typography></Divider>

                                <Stack direction="column" spacing={1} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>

                                    <Button
                                        variant='outlined'
                                        sx={{
                                            height: '50px',
                                            borderRadius: '8px',
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            gap: 5,
                                            transition: 'background-color 0.3s, border-color 0.3s',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                            },
                                        }}
                                        onClick={() => signUpWithProvider('google-oauth2')}
                                    >

                                        <Iconify icon="logos:google-icon" width={24} />
                                        <Typography variant="subtitle1" color='black'>Sign up with Google</Typography>
                                    </Button>

                                    {/* <Button
                                        variant='outlined'
                                        sx={{
                                            height: '50px',
                                            borderRadius: '8px',
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            gap: 5,
                                            transition: 'background-color 0.3s, border-color 0.3s',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                            },
                                        }}
                                        onClick={() => signUpWithProvider('windowslive')}
                                    >
                                        <Iconify icon="logos:microsoft-icon" width={24} />
                                        <Typography variant="subtitle1" color='black'>Sign up with Microsoft</Typography>
                                    </Button>  */}
                                </Stack>
                            </FormProvider>
                        </Box>

                    )}
                </>
            </div>
        </div>
    );
}