// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// types
import { IUserItem } from './view/users';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import UserQuickEditForm from './user-quick-edit-form';
import { mediaUrl } from 'src/utils/media-url';
import { useRoleQuery, useWorkspaceUserDeleteMutation } from 'src/context/api/workspaces';
import { useParams } from 'src/routes/hooks';
import { useMemo } from 'react';

// ----------------------------------------------------------------------

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: IUserItem;
  onSelectRow: VoidFunction;
  onDeleteRow: (callback: () => void) => void;
};

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { fullname, profile: { picture } = {}, role, status, email } = row;
  const { id } = useParams() as { id: string };

  const currentUserRole = useRoleQuery({ id: parseInt(id) });

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();
  
  const canEditUser = useMemo(() => {
    if (currentUserRole.data) {
      const admin = currentUserRole.data.role === 'admin' && ['customer'].includes(row.role);

      return admin;
    }
    return false;
  }, [currentUserRole.data, row.role]);

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={fullname} src={picture ? mediaUrl(picture) : undefined} sx={{ mr: 2 }} />

          <ListItemText
            primary={fullname}
            secondary={email}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{role}</TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={
              (status === 'active' && 'success') ||
              (status === 'pending' && 'warning') ||
              (status === 'banned' && 'error') ||
              'default'
            }
          >
            {status}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton disabled={!canEditUser} color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>

          <IconButton disabled={!canEditUser} color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      {
        canEditUser && (
          <>
            <UserQuickEditForm
              open={quickEdit.value}
              onClose={quickEdit.onFalse}
              currentUser={row}
            />

            <CustomPopover
              open={popover.open}
              onClose={popover.onClose}
              arrow="right-top"
              sx={{ width: 140 }}
            >
              <MenuItem
                onClick={() => {
                  confirm.onTrue();
                  popover.onClose();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon="solar:trash-bin-trash-bold" />
                Delete
              </MenuItem>
            </CustomPopover>

            <ConfirmDialog
              open={confirm.value}
              onClose={confirm.onFalse}
              title="Delete"
              content="Are you sure want to delete?"
              action={
                <Button variant="contained" color="error" onClick={() => onDeleteRow(confirm.onFalse)}>
                  Delete
                </Button>
              }
            />
          </>
        )
      }
    </>
  );
}
