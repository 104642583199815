import { ApexOptions } from 'apexcharts';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Card, { CardProps } from '@mui/material/Card';
import Chart, { useChart } from 'src/components/chart';
import { useLocales } from 'src/locales';
import { useSelector } from 'react-redux';
import { formatNumber, fPercent } from 'src/utils/format-number';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { generateAnnotations } from 'src/utils/graph-demo-annotations';
import { formatCurrency } from 'src/utils/format-currency';
import { formatDate } from 'src/utils/dates-labels';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  chart: {
    series: {
      categories: string[];
      data: {
        name: string;
        data: number[];
        visible: boolean;
        dashStyle: any;
        color: any;
        yAxis: number;
      }[];
    }[];
    options?: ApexOptions;
  };
}

export default function AppAreaInstalled({ title, subheader, chart, ...other }: Props) {
  const lang = localStorage.getItem('i18nextLng');
  const { series, options } = chart;
  const { t, currentLang } = useLocales();

  const filterStore = useSelector((state: any) => state.filters);
  const workspace = useCurrentWorkspace();

  // Extract visible series
  const visibleSeries = series[0].data.filter((seriesItem) => seriesItem.visible);
  const colors = visibleSeries.map((seriesItem) => seriesItem.color);
  const annotations = generateAnnotations(workspace, filterStore, series[0].categories || []);

  // Find the maximum length of data arrays across all series
  const maxDataLength = Math.max(...visibleSeries.map(seriesItem => seriesItem.data.length));

  // Pad each series data array with `0`s up to `maxDataLength`
  const paddedSeries = visibleSeries.map(seriesItem => ({
    ...seriesItem,
    data: [
      ...seriesItem.data,
      ...Array(maxDataLength - seriesItem.data.length).fill(0) // Add zeros if data is shorter
    ]
  }));

  // Cap the "Blended CTR" and "Blended CTR Compare" values at 100%
  const cappedSeries = paddedSeries.map((seriesItem) => ({
    ...seriesItem,
    data: seriesItem.name === t('dashboard.global.blended_ctr') || seriesItem.name === "Blended CTR Compare"
      ? seriesItem.data.map((value) => Math.min(value, 100))
      : seriesItem.data,
  }));

  // Configure y-axis with cap for "Blended CTR" series
  const yaxis = cappedSeries.map((seriesItem, index) => ({
    seriesName: seriesItem.name,
    axisTicks: { show: false },
    axisBorder: { show: false },
    labels: {
      show: false,
      formatter: (value: any) => {
        if (seriesItem.name === t('dashboard.global.blended_ctr') || seriesItem.name === "Blended CTR Compare") {
          return `${Math.min(value, 100)}%`; // Cap display at 100%
        }
        return value.toFixed(0);
      },
    },
    title: { text: undefined },
    opposite: index % 2 === 0,
    min: 0,
    max: seriesItem.name === t('dashboard.global.blended_ctr') || seriesItem.name === "Blended CTR Compare" ? 100 : undefined,
  }));

  const categories = series[0]?.categories;

  const chartOptions = useChart({
    xaxis: {
      labels: {
        show: true,
        style: {
          colors: ['#FFFFFF'],
          fontSize: '12px',
        },
        offsetX: 0,
      },
      categories: series[0]?.categories || [],
    },
    yaxis: yaxis,
    fill: {
      colors: colors,
    },
    colors: colors,
    legend: {
      show: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const date = categories[dataPointIndex];  // Récupération de la date
        const showDate = !(filterStore?.compare && filterStore?.timestep === "date");

        let tooltipHtml = `
          <div style="background-color: #191f28; border-radius: 8px; color: white; text-align: left;">`;

        // Affiche la section de la date uniquement si `showDate` est vrai
        if (showDate) {
          tooltipHtml += `
            <div style="padding: 8px; font-size: 13px; font-weight: bold; text-align: center; margin-bottom: 8px; background-color: #222832">${date}</div>`;
        }

        series.forEach((serie: any, i: any) => {
          let seriesName = w.config.series[i].name.replace("Compare", "").trim();  // Supprime "Compare" du nom de la série
          let value = series[i][dataPointIndex];

          // Formatage selon le type de série
          if (w.config.series[i].name.toLowerCase().includes("ad cost")) {
            value = formatCurrency(value, workspace!.currency, lang!, true, 1);
          } else if (w.config.series[i].name.toLowerCase().includes("blended ctr")) {
            value = fPercent(value); // Format en pourcentage pour "blended ctr"
          } else {
            value = value.toLocaleString(); // Format standard
          }

          const color = w.config.colors[i];
          const isCompare = w.config.series[i].name.includes("Compare");
          const fontWeight = isCompare ? "bold" : "bold";
          const fontSize = "12px";
          const colorStyle = isCompare ? "" : "";

          const iconSvg = isCompare
            ? `<svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 12v.01M8 12v.01m4-.01v.01m4-.01v.01m4-.01v.01"/></svg>`
            : `<svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 20 20"><path fill="currentColor" fill-rule="evenodd" d="M1 10a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1" clip-rule="evenodd"/></svg>`;

          const periodDates = (filterStore?.compare && filterStore?.timestep === "date")
            && (() => {
              if (isCompare) {
                const compareStartDate = new Date(filterStore.compareStart);
                const compareEndDate = new Date(filterStore.compareEnd);
                const compareDates = [];

                for (let d = new Date(compareStartDate); d <= compareEndDate; d.setDate(d.getDate() + 1)) {
                  compareDates.push(new Date(d).toLocaleDateString(currentLang?.value));
                }

                return `(${compareDates[dataPointIndex]})`;
              } else {
                return `(${date})`
              }

            })();

          tooltipHtml += `
            <div style="display: flex; align-items: center; margin-bottom: 3px; padding: 8px">
              <span style="color: ${color}; font-weight: bold; font-size: 14px; margin-right: 8px; margin-top: 5px;">
                ${iconSvg}
              </span>
              <span style="font-size: ${fontSize}; ${colorStyle}">${seriesName} ${periodDates ? periodDates : ''}: </span>
              <span style="font-size: ${fontSize}; font-weight: ${fontWeight}; margin-left: 4px;">${value}</span>
            </div>`;
        });

        tooltipHtml += `</div>`;
        return tooltipHtml;
      },
      y: {
        formatter: (value, { seriesIndex, w }) => {
          const seriesName = w.config.series[seriesIndex].name;
          if (seriesName === t('dashboard.global.blended_ctr') || seriesName === "Blended CTR Compare") {
            return fPercent(Math.min(value, 100));
          } else if (seriesName === t('dashboard.global.ad_cost') || seriesName === "Ad Cost Compare") {
            return formatCurrency(value, workspace!.currency, lang!, true, 1);
          }
          return value.toFixed(0).toString();
        },
      },
    },
    annotations: {
      xaxis: annotations,
    },
    stroke: {
      width: 2,
      dashArray: cappedSeries.map((seriesItem) => (seriesItem.dashStyle === "ShortDash" ? 4 : 0)),
    },
    ...options,
  });

  return (
    <Card {...other} sx={{ borderRadius: 0, borderBottomRightRadius: 20, borderBottomLeftRadius: 20 }}>
      <CardHeader title={title} subheader={subheader} />
      <Box sx={{ ml: 4 }}>
        <Chart
          dir="ltr"
          type="line"
          series={cappedSeries.map((seriesItem) => ({
            name: seriesItem.name,
            data: seriesItem.data,
            yAxisIndex: seriesItem.yAxis,
          }))}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
