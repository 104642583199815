import { useState, useCallback } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { fPercent } from 'src/utils/format-number';
import { useLocales } from 'src/locales';

const createColumns = (t: any): GridColDef[] => [
  {
    field: 'search_term',
    headerName: t('dashboard.global.searchTerm'),
    flex: 1,
  },
  {
    field: 'ghostRate',
    headerName: t('dashboard.competitorsMonitoring.ghostRate'),
    flex: 1,
    width: 160,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.ghostRate || 0}
          variant="determinate"
          color='info'
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.ghostRate ? fPercent(params.row.ghostRate) : '0%'}
        </Typography>
      </Stack>
    ),
  },
];

type Props = {
  data: {
    id: string;
    role: string;
    email: string;
    profile: {
      firstname: string;
      lastname: string;
    }
  }[];
};

export default function DataGridCustomPresence({ data }: Props) {
  const { t } = useLocales();
  
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: true,
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const handlePaginationModelChange = (newModel: any) => {
    setPaginationModel(newModel);
  };

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-root': {
          '--unstable_DataGrid-radius': '0px', 
        },
      }}
    >
      <DataGridPro
        checkboxSelection={false}
        disableRowSelectionOnClick
        rows={data}
        pagination
        paginationModel={paginationModel}
        columns={createColumns(t)}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </Box>
  );
}
