import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { fPercent } from 'src/utils/format-number';
import { generateAnnotations } from 'src/utils/graph-demo-annotations';
import { formatCurrency } from 'src/utils/format-currency';

type Props = {
  series: {
    name: string;
    type: string;
    data: number[];
  }[];
  labels: string[]; // Ajout de la prop labels
};

export default function ChartMixed({ series, labels }: Props) {
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const workspace = useCurrentWorkspace()
  const annotations = generateAnnotations(workspace, filterStore, labels)
  const lang = localStorage.getItem('i18nextLng') || 'en';

  enum ColorSchema {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
  }

  const chartOptions = useChart({

    plotOptions: {
      bar: { columnWidth: '50%', borderRadius: 0 },
    },
    chart: {
      stacked: true,
    },
    fill: {
      colors: series?.length === 2
        ? ['#ff9800', '#5119b7']  // Couleurs fixes pour test
        : ['#5119b7', '#ffb300', '#ffd54f', '#ffe180']  // Ajoute une couleur pour pMax
    },
    colors: series?.length === 2
      ? ['#ff9800', '#5119b7']  // Idem que pour fill.colors
      : ['#5119b7', '#ffb300', '#ffd54f', '#ffe180'],  // Couleurs ajustées pour pMax   
    labels: labels || [],
    markers: {
      size: 6
    },
    xaxis: {
      // labels: {
      //   formatter: filterStore?.timestep === "date"
      //     ? (val) => {
      //       const date = new Date(val);
      //       const year = date.getUTCFullYear();
      //       const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      //       const day = String(date.getUTCDate()).padStart(2, '0');
      //       return `${year}-${month}-${day}`;
      //     }
      //     : undefined,
      // },
    }, 
    yaxis: series?.length === 2 ? [
      {
        title: { text: t('dashboard.global.ad_cost') },
        labels: { formatter(value) { return (value / 1000).toFixed(0) + "k" } }
      },
      {
        title: { text: t('dashboard.global.blended_ctr') },
        opposite: true,
        min: 0,
        max: 100,  // Cap the maximum y-axis value to 100%
        labels: { formatter(value) { return `${Math.min(value, 100).toFixed(0)}%`; } }  // Prevents display above 100%
      }
    ]
      : [
        {
          title: { text: t('dashboard.global.blended_ctr') },
          opposite: true,
          labels: { formatter(value) { return `${Math.min(value, 100).toFixed(0)}%`; } },
          min: 0,
          max: 100,  // Cap the maximum y-axis value to 100%

        },
        ...(series.some(serie => serie.name === t('dashboard.brandTraffic.searchAdCost') || serie.name === t('dashboard.brandTraffic.shoppingAdCost')) ? [{
          title: { text: t('dashboard.brandTraffic.searchAdCost') + '/' + t('dashboard.brandTraffic.shoppingAdCost') },
          labels: { formatter(value: any) { return formatCurrency(value, workspace?.currency, lang, true, 1) } }
        }] : [])
      ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value: number, { seriesIndex, w }) => {
          if (typeof value !== 'undefined') {
            const seriesType = w.config.series[seriesIndex].type;
            if (seriesType === "line") {
              return `${fPercent(Math.min(value, 100))}`; // Prevents display above 100%
            } else {
              return formatCurrency(value, workspace?.currency, lang, true, 1);
            }
          }
          return value;
        },
      },
    },
    annotations: {
      xaxis:annotations
    },

  });

  return <Chart dir="ltr" type="line" series={series} options={chartOptions} sx={{ width: { xs: '100%', lg: '95%' } }} height={350} />;
}
