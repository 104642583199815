import { StackProps } from '@mui/material/Stack';
import { ContainerProps } from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

type ComponentBlockProps = StackProps & {
    title?: string;
};

export function ComponentBlockCustom({ title, sx, children, ...other }: ComponentBlockProps) {
    return (
        <Stack
            sx={{
                px: 3,
                pt: 5,
                pb: 4,
                width: 1,
                flexWrap: 'wrap',
                borderRadius: 1.5,
                position: 'relative',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                bgcolor: (theme) => theme.palette.mode === "dark" ? '#212a33' : 'white',
                boxShadow: (theme) => `0 0 0 1px ${theme.palette.divider}`,
                ...sx,
            }}
            {...other}
        >
            {title && (
                <Box
                    component="span"
                    sx={{
                        px: .5,
                        top: 0,
                        ml: 1,
                        left: 0,
                        py: 0.25,
                        borderRadius: 2,
                        position: 'absolute',
                        bgcolor: (theme) => theme.palette.mode === "dark" ? '#212a33' : 'white',
                        color: (theme) => theme.palette.mode === "dark" ? '#919EAB' : '#637381',
                        transform: 'translateY(-50%)',
                        fontSize: (theme) => theme.typography.caption.fontSize,
                        fontWeight: 600,
                    }}
                >
                    {title}
                </Box>
            )}
            {children}
        </Stack>
    );
}

// ----------------------------------------------------------------------

export function ComponentContainerCustom({ children, sx, ...other }: ContainerProps) {
    return (
        <Container
            sx={{
                mt: 10,
                mb: 15,
                gap: 5,
                display: 'flex',
                flexDirection: 'column',
                ...sx,
            }}
            {...other}
        >
            {children}
        </Container>
    );
}
