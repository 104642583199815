import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ListItemText from '@mui/material/ListItemText';
import { useTheme, alpha } from '@mui/material/styles';
import { CurrentUserResponse } from 'src/context/api/auth';
import { AnimateAvatar } from './animate-avatar';

// ----------------------------------------------------------------------

const bgGradient = ({ imgUrl, theme }: any) => ({
  backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.primary.darker, 0.4)}, ${alpha(theme.palette.primary.darker, 0.4)
    }), url(${imgUrl})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
});

export default function ProfileCover({ email, coverUrl, profile = {} as CurrentUserResponse["profile"] }: CurrentUserResponse) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...bgGradient({
          imgUrl: coverUrl || "/assets/covers/cover-4.webp",
          color: alpha(theme.palette.primary.darker, 0.4),
          theme,
        }),
        height: '100vh',
        color: 'common.white',
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          left: { md: 24 },
          bottom: { md: 24 },
          zIndex: { md: 10 },
          pt: { xs: 6, md: 0 },
          position: { md: 'absolute' },
        }}
      >

        <AnimateAvatar
          width={120}
          slotProps={{
            overlay: {
              border: 2,
              spacing: 2,
            },
          }}
        />

        <ListItemText
          sx={{
            mt: 4.5,
            ml: { md: 3 },
            textAlign: { xs: 'center', md: 'unset' },
          }}
          primary={profile?.firstname ? `${profile.firstname} ${profile.lastname}` : email}
          secondary={email}
          primaryTypographyProps={{
            typography: 'h4',
          }}
          secondaryTypographyProps={{
            color: 'inherit',
            component: 'span',
            typography: 'body2',
            sx: { opacity: 0.48 },
          }}
        />
      </Stack>
    </Box>
  );
}
