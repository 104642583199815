import { Route, Routes } from 'react-router';
import Signin from './signin';
import Redirect from './redirect';
import ForgotPassword from './forgot-password';
import UpdatePassword from './update-password';
import ResetPassword from './reset-password';
import SSOProviderLoader from './sso-provider-loader';

export default function Auth() {
  return (
    <Routes>
      <Route path="/auth/signin" element={<Signin />} />
      <Route path="/auth/forgot" element={<ForgotPassword />} />
      <Route path="/auth/password" element={<UpdatePassword />} />
      <Route path="/auth/reset_password" element={<ResetPassword />} />
      <Route path="/auth/sso_loader" element={<SSOProviderLoader />} />
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
}
