import React, { useEffect, useRef, useState } from 'react';
import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useLocales } from 'src/locales';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { generateAnnotations } from 'src/utils/graph-demo-annotations';
import { formatCurrency } from 'src/utils/format-currency';
import { formatNumber } from 'src/utils/format-number';

type Props = {
  series: {
    name: string;
    type: string;
    data: number[];
  }[];
  labels: string[];
};

export default function ChartMixed({ series, labels }: Props) {
  const { t } = useLocales();
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const chartRef = useRef<any>(null);
  const [hiddenSeries, setHiddenSeries] = useState<string[]>([t('dashboard.global.blended_cpc'), t('dashboard.global.blended_cpl')]);
  const [visibleSeriesData, setVisibleSeriesData] = useState(series);
  const workspace = useCurrentWorkspace()
  const annotations = generateAnnotations(workspace, filterStore, labels || [])
  const lang = localStorage.getItem('i18nextLng') || 'en';

  useEffect(() => {
    const updatedSeries = series.map(serie => ({
      ...serie,
      show: !hiddenSeries.includes(serie.name)
    }));
    setVisibleSeriesData(updatedSeries);

    if (chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      hiddenSeries.forEach(seriesName => {
        chart.hideSeries(seriesName);
      });
    }
  }, [series, hiddenSeries]);

  useEffect(() => {
    setHiddenSeries([t('dashboard.global.blended_cpc'), t('dashboard.global.blended_cpl')]);
  }, [t]);

  const toggleSeries = (seriesName: string) => {
    setHiddenSeries(prevHiddenSeries => {
      const isHidden = prevHiddenSeries.includes(seriesName);
      if (chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        if (isHidden) {
          chart.showSeries(seriesName);
        } else {
          chart.hideSeries(seriesName);
        }
      }
      return isHidden ? prevHiddenSeries.filter(name => name !== seriesName) : [...prevHiddenSeries, seriesName];
    });
  };

  enum ColorSchema {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
  }

  const chartOptions = useChart({
    chart: {
      stacked: series.some((serie) => serie.type === 'stacked'),
      events: {
        legendClick: function (chartContext, seriesIndex: any, config) {
          const seriesName = config.globals.seriesNames[seriesIndex];
          toggleSeries(seriesName);
          return false;
        }
      }
    },
    series: visibleSeriesData,
    plotOptions: {
      bar: {
        columnWidth: '50%',
        borderRadius: 0,
      },
    },
    labels: labels,
    fill: {
      colors: [theme.palette[ColorSchema.WARNING].light, "#006c9c", "#5119b7"]
    },
    colors: [theme.palette[ColorSchema.WARNING].light, "#006c9c", "#5119b7"],
    markers: {
      size: 6
    },
    yaxis: [
      {
        title: {
          text: t('dashboard.global.ad_cost'),
        },
        labels: {
          formatter: function (value) {
            return formatCurrency(value, workspace?.currency, lang, true, 1);
          },
        },
      },
      {
        opposite: true,
        title: {
          text: t('dashboard.global.blended_leads'),
        },
        min: 0,
        labels: {
          formatter: function (value) {
            return value !== undefined ? formatNumber(value) : "";
          },
        },
      },
      {
        opposite: true,
        title: {
          text: t('dashboard.global.blended_cpl'),
        },
        min: 0,
        labels: {
          formatter: function (value) {
            return formatCurrency(value, workspace?.currency, lang, true, 1);
          },
        },
      },
      {
        title: {
          text: t('dashboard.global.blended_cpc'),
        },
        min: 0,
        labels: {
          formatter: function (value) {
            return formatCurrency(value, workspace?.currency, lang, true, 2);
          },
        },
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value: number, { seriesIndex, w }) => {
          if (value === undefined) return "";
          const seriesName = w.config.series[seriesIndex].name;

          if (seriesName === t('dashboard.global.ad_cost') || seriesName === t('dashboard.global.blended_cpl')) {
            return formatCurrency(value, workspace?.currency, lang, true, 1);
          } else if (seriesName === t('dashboard.global.blended_cpc')) {
            return formatCurrency(value, workspace?.currency, lang, true, 2);
          } else if (t('dashboard.global.blended_leads')) {
            return formatNumber(value?.toFixed(0))
          }

          return `${value.toFixed(2)}`;
        },
      },
    },
    grid: {
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      }
    },
    annotations: {
      xaxis: annotations
    },
  });

  return <Chart dir="ltr" ref={chartRef} series={visibleSeriesData} options={chartOptions} sx={{ width: { xs: '100%', lg: '95%' } }} height={350} />;
}

