import { Box, useTheme } from '@mui/system';
import { ApexOptions } from 'apexcharts';
import GaugeComponent from 'react-gauge-component'
interface ChartPresenceRateProps {
    presenceRate: number;
    options?: ApexOptions;
}

export default function ChartPresenceRate({ presenceRate }: ChartPresenceRateProps) {
    const theme = useTheme();

    return (
        <Box sx={{ pb: 3 }}>
            <GaugeComponent
                type="semicircle"
                arc={{
                    colorArray: ["#118D57", "#FFAB00", "#FF5630", "#B71D18"],
                    padding: 0.02,
                    subArcs:
                        [
                            { limit: 0, color: "#118D57" },
                            { limit: 25, color: "#FFAB00" },
                            { limit: 50, color: "#FF5630" },
                            { limit: 75, color: "#B71D18" },
                            {},
                        ]
                }}
                pointer={{
                    type: "blob",
                    animationDelay: 0,
                    baseColor: theme.palette.mode === "dark" ? "#212B36" : "#FFFFFF",
                }}
                value={presenceRate}

                labels={{
                    valueLabel: { style: { fontSize: 22, fill: theme.palette.mode === "dark" ? "#fff" : "#212B36" } },
                    tickLabels: {
                        hideMinMax: true,
                    }
                }}
            />
        </Box>
    );
}