import type { Theme, SxProps } from '@mui/material/styles';
import { forwardRef } from 'react';
import Iconify from './iconify';

// ----------------------------------------------------------------------

export type FlagIconProps = {
    code?: string;
    sx?: SxProps<Theme>;
};

export const FlagIcon = forwardRef<HTMLSpanElement, FlagIconProps>(
    ({ code, sx }, ref) => {
        const baseStyles = {
            width: 26,
            height: 20,
            flexShrink: 0,
            overflow: 'hidden',
            borderRadius: '5px',
            display: 'inline-flex',
            bgcolor: 'background.neutral',
        };

        if (!code) {
            return null;
        }

        return (
            <Iconify
                key={code}
                icon={`circle-flags:${code}`}
                width={16}
                style={{ position: "relative", left: 5 }}
            />
        );
    }
);
