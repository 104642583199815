import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const countries = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
  reducerPath: 'api.countries',
  tagTypes: ['countries'],
  endpoints: (builder) => ({
    getCountries: builder.query<any, void>({
      query: () => ({
        url: `/countries/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useGetCountriesQuery,
} = countries;

export default countries;
