import { useTheme, alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CardProps } from '@mui/material/Card';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// theme
import { bgGradient } from 'src/theme/css';
import { useResponsive } from 'src/hooks/use-responsive';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  description?: string;
  img?: React.ReactNode;
  action?: React.ReactNode;
  onClose?: any;  // ajouter un prop pour gérer la fermeture
}

export default function Welcome({ title, description, action, img, onClose, ...other }: Props) {
  const theme = useTheme();
  const lgUp = useResponsive('up', 'lg');

  return (
    <Box
      flexDirection={{ xs: 'column', md: 'row' }}
      sx={{
        ...bgGradient({
          direction: '135deg',
          color: `to right, ${theme.palette.grey[900]} 25%, ${alpha(theme.palette.primary.darker, 0.88)}`,

          imgUrl: '/assets/background/background-6.webp'
        }),
        pt: 5,
        pb: 5,
        pr: 3,
        gap: 5,
        borderRadius: 2,
        display: 'flex',
        height: { md: 1 },
        position: 'relative',
        pl: { xs: 3, md: 5 },
        alignItems: 'center',
        color: 'common.white',
        textAlign: { xs: 'center', md: 'left' },
        flexDirection: { xs: 'column', md: 'row' },
        border: `solid 1px ${theme.palette.grey[800]}`,
      }}
      {...other}
    >
      
      <Stack
        flexGrow={1}
        justifyContent="center"
        alignItems={{ xs: 'center', md: 'flex-start' }}
        sx={{
          p: {
            xs: theme.spacing(5),
            md: theme.spacing(5),
          },
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Typography variant="h4" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
          {title}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            opacity: 0.8,
            maxWidth: 360,
            mb: { xs: 3, xl: 5 },
          }}
        >
          {description}
        </Typography>

        {action && action}
      </Stack>

      {lgUp &&
        <>
          {img && (
            <Stack
              component="span"
              justifyContent="center"
              sx={{
                p: { xs: 5, md: 3 },
                maxWidth: 360,
                mx: 'auto',
              }}
            >
              {img}
            </Stack>
          )}
        </>
      }
    </Box>
  );
}
