import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { useCurrentUserQuery } from "src/context/api/auth";
import { useRouter } from "src/routes/hooks";

export default function SSOProviderLoader() {
    const router = useRouter();
    const user = useCurrentUserQuery();

    useEffect(() => {
        if (user.data) {
            const role = user.data.role;
            const workspaces = user.data.workspaces || [];

            // Redirect based on user role and workspaces
            if (role === "admin" && workspaces.length > 0) {
                router.push("/workspaces");
            } else if (role !== "admin" && workspaces.length === 1) {
                router.push(`/dash/brand-overview?workspace=${workspaces[0].id}`);
            } else if (role !== "admin" && workspaces.length > 1) {
                router.push("/workspaces");
            }
        }
    }, [user.data, router]);

    return (
        <div style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: 'url(/assets/background/background.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            <div style={{
                width: '100%',
                maxWidth: '400px',
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '40px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}>
                <Box sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '10px',
                    padding: 2,
                    zIndex: 100,
                }}>
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', gap: 5, flexDirection: 'column', }}>
                        <CircularProgress size={50} sx={{ marginRight: 1, alignSelf: 'center' }} variant='indeterminate' value={50} />
                        <Typography variant="h6" color={'black'} fontWeight={'bold'} sx={{ textAlign: 'center' }}>
                            SSO authentication is in progress...
                        </Typography>
                    </Box>
                </Box>
            </div>
        </div>
    )
}
