import { ApexOptions } from 'apexcharts';
// @mui
/*  */
import { useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { CardProps } from '@mui/material/Card';
import Typography from '@mui/material/Typography';
// utils
import { fNumber, fPercent } from 'src/utils/format-number';
// theme
import { ColorSchema } from 'src/theme/palette';
import { bgGradient } from 'src/theme/css';
// components
import Iconify from 'src/components/iconify';
import Chart, { useChart } from 'src/components/chart';
import { useResponsive } from 'src/hooks/use-responsive';
import { useSelector } from 'react-redux';
import { useLocales } from 'src/locales';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { Checkbox, FormControlLabel } from '@mui/material';
import { formatDate } from 'src/utils/dates-labels';
import { t } from 'i18next';
import { formatCurrency } from 'src/utils/format-currency';

// ----------------------------------------------------------------------

interface Props extends CardProps {
    title: string;
    val: string;
    total: number;
    percent: number;
    color?: any;
    textColor?: string;
    compareData?: number;
    valueCompare?: number;
    icon: string;
    handleKPI: (title: string) => void;
    kpiToogle: any
    spanColor: any;
    textColorSpan?: any;
    trendColor?: any;
    chart: {
        series: {
            x: any;
            y: any;
        }[];
        options?: ApexOptions;
    };
}

export default function BankingWidgetSummary({
    title,
    val,
    total,
    compareData,
    icon,
    handleKPI,
    valueCompare,
    textColor,
    kpiToogle,
    spanColor,
    percent,
    trendColor,
    textColorSpan,
    color = 'primary',
    chart,
    sx,
    ...other
}: Props) {
    const theme: any = useTheme();
    const lgUp = useResponsive('up', 'lg');
    const filterStore = useSelector((state: any) => state.filters);
    const workspace = useCurrentWorkspace()
    const { currentLang, t } = useLocales();
    const lang = localStorage.getItem('i18nextLng') || 'en';

    const { series, options } = chart;

    const chartOptions = useChart({
        chart: {
            sparkline: {
                enabled: true,
            },
        },
        xaxis: {
            labels: {
                show: true,
            },
        },
        yaxis: {
            labels: {
                show: true,
            },
        },
        legend: {
            show: true,
        },
        grid: {
            show: true,
        },
        tooltip: {
            marker: {
                show: false,
            },
            y: {
                formatter: (value: number) =>
                    title === "Blended ctr" ? `${Math.min(value, 100).toFixed(1)}%` : fNumber(value),
                title: {
                    formatter: () => '',
                },
            },
        },
        fill: {
            colors: [theme.palette[color].main],
        },
        colors: [theme.palette[color].main],
        ...options,
    });

    let trendIconActive = '';
    let trendColorActive = '';
    if (compareData !== undefined) {
        if (compareData < 0) {
            trendIconActive = 'eva:trending-down-fill';
            trendColorActive = '#a81b21';
        } else if (compareData > 0) {
            trendIconActive = 'eva:trending-up-fill';
            trendColorActive = '#065e49';
        }
    }


    const formatNumber = (number: any, options: any) => {

        let formattedNumber;
        if (number >= 1000000) {
            formattedNumber = (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 10000) {
            formattedNumber = (number / 1000).toFixed(1) + 'K';
        } else {
            formattedNumber = number.toLocaleString(lang, options);
        }

        if (lang === 'fr') {
            formattedNumber = formattedNumber.replace('.', ',');
        }

        return formattedNumber;
    };
    const formatTitle = (title: any, total: any) => {
        switch (title) {
            case t('dashboard.global.ad_cost'):
                return formatCurrency(total, workspace?.currency || 'USD', lang || 'en', true, 1);
            case t('dashboard.brandTraffic.blendedCtr'):
                const cappedTotal = Math.min(total, 100);
                return cappedTotal === 0 ? '0.0%' : fPercent(cappedTotal);
            case t('dashboard.brandTraffic.brandSearches'):
                return formatNumber(total, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
            default:
                return total >= 1
                    ? formatNumber(total, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
                    : formatNumber(total * 1000, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
        }
    };
    return (
        <Stack
            onClick={() => handleKPI(val)}
            sx={{
                ...bgGradient({
                    direction: '135deg',
                    startColor: alpha(theme.palette[color].light, 0.2),
                    endColor: alpha(theme.palette[color].main, 0.2),
                }),
                width: 1,
                height: '100%',
                borderTopLeftRadius: val === "brandSearches" ? 20 : 0,
                borderTopRightRadius: val === "adCost" ? 20 : 0,
                cursor: 'pointer',
                overflow: 'hidden',
                position: 'relative',
                color: theme.palette.mode !== "dark" ? `${textColor}` : 'white',
                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                backgroundColor: theme.palette.mode === "dark" ? `${color}.darker` : `${color}.lighter`,
                ...sx,
            }}
            {...other}
        >
            {lgUp &&
                <Iconify
                    icon={icon}
                    sx={{
                        transform: icon === 'icon-park-twotone:search' ? 'rotate(80deg)' : undefined || icon === 'icon-park-twotone:click' ? 'rotate(80deg)' : undefined,
                        width: 80,
                        right: -20,
                        top: 20,
                        height: 80,
                        opacity: 0.5,
                        position: 'absolute',
                    }}
                />
            }

            <Stack sx={{ p: 2 }}>

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        color: 'white',
                                    },
                                    '&.Mui-checked': {
                                        '& .MuiSvgIcon-root': {
                                            color: 'white',
                                        },
                                    },
                                    '& .MuiCheckbox-root': {
                                        color: 'white',
                                        borderColor: 'white',
                                    },
                                }}
                                checked={kpiToogle?.includes(val)}
                            />
                        }
                        label=""
                    />
                    <Typography sx={{ fontSize: 12, position: 'relative', left: -17 }} variant="subtitle2">
                        {title.toUpperCase()}
                    </Typography>
                </Box>

                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant={filterStore?.compare ? "h4" : "h3"}>
                            {formatTitle(title, total)}
                        </Typography>

                        {filterStore?.compare !== false &&

                            <Box sx={{ mt: .6, ml: 1 }}>
                                {total !== 0 && compareData !== undefined && !isNaN(compareData) && isFinite(compareData)
                                    ? <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                        <div style={{ backgroundColor: spanColor, padding: '1px 4px', display: 'flex', flexDirection: 'row', borderRadius: 10 }}>
                                            <Iconify fontSize={{ xs: 10, lg: 15 }} icon={trendIconActive} sx={{ color: trendColor === undefined ? trendIconActive : trendColor }} />

                                            <Box fontSize={{ xs: 12, lg: 15 }} sx={{ opacity: 0.8, color: trendColor === undefined ? trendColorActive : trendColor, whiteSpace: 'nowrap' }}>
                                                {fPercent(compareData || 0)}
                                            </Box>

                                        </div>
                                    </Box>
                                    : <div style={{ height: 22 }}></div>
                                }
                            </Box>
                        }

                        <Box>

                        </Box>
                    </Box>


                    {filterStore?.compare &&
                        <Typography sx={{ fontSize: 12 }} whiteSpace={'nowrap'}>
                            {formatDate(filterStore?.start, currentLang.value)} - {formatDate(filterStore?.end, currentLang.value)}
                        </Typography>
                    }
                </Box>

                {filterStore?.compare &&
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography variant="h4">
                                {formatTitle(title, valueCompare)}
                            </Typography>

                            <Box>

                            </Box>
                        </Box>

                        <Typography sx={{ fontSize: 12 }} whiteSpace={'nowrap'}>
                            {formatDate(filterStore?.compareStart, currentLang.value)} - {formatDate(filterStore?.compareEnd, currentLang.value)}
                        </Typography>
                    </Box>
                }


            </Stack>
        </Stack>
    );
}
