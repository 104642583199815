import { useState, useCallback, useEffect } from 'react';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
// types
import { IFileShared } from 'src/types/file';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
// import {  useDeleteUserMutation, useUpdateUserMutation } from "src/context/api/admin/api";
import { useSnackbar } from 'src/components/snackbar';
import { useDeleteInvitationMutation } from 'src/context/api/workspaces';
import { useCurrentUserQuery } from 'src/context/api/auth';

// ----------------------------------------------------------------------

type Props = {
  person: IFileShared;
  workspaceId: number;
  editable?: boolean;
  showButton?: boolean;
  onInvitation?: (item: any) => void;
};

export default function FileManagerInvitedPendingItem({ person, workspaceId, editable, onInvitation, showButton }: Props) {
  const [permission, setPermission] = useState(person.permission);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const popover = usePopover();
  const confirmDelete = useBoolean();
  const { t } = useLocales();
  const [deletePendingInvitation,] = useDeleteInvitationMutation();
  const user: any = useCurrentUserQuery();

  const handleDeleteRow = async (person: any) => {
    try {
      const invitation_id = person.id;
      await deletePendingInvitation({ workspaceId: workspaceId, invitationId: Number(invitation_id) }).unwrap().then(() => {
        enqueueSnackbar(t('dashboard.snackbar.delete_success'));
      })
      onInvitation?.({ "workspaceId": workspaceId, "email": person?.email, "id": Number(invitation_id), "toDelete": true })
      confirmDelete.onFalse();
    } catch (error) {
      enqueueSnackbar(t('dashboard.snackbar.failed_to_delete_invitation'), { variant: 'error' });
    }
  };

  return (
    <>
      <ListItem
        sx={{
          px: 0,
          py: 1,
          alignItems: 'center'
        }}
      >
        {
          person?.firstname ? (
            <Avatar alt={person.firstname + " " + person.lastname}
              sx={{
                mr: 2,
                color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',

                background: (theme) =>
                  `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
              }} >
              <Typography sx={{ fontSize: '1rem', color: theme.palette.mode === 'dark' ? '#fff' : '#ffffff' }}>
                {person?.firstname?.charAt(0).toUpperCase()}
                {person?.lastname?.charAt(0).toUpperCase()}
              </Typography>
            </Avatar>
          ) : (
            <Avatar
              alt={person.name}
              src={person.avatarUrl}
              sx={{
                mr: 2,
                color: theme.palette.mode === 'dark' ? '#fff' : '#ffffff',
                background: (theme) =>
                  `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
              }}
            />

          )
        }

        <ListItemText
          primary={
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="subtitle2" sx={{ mr: 1 }}>
                {person.firstname ? `${person.firstname} ${person.lastname}` : t('dashboard.global.new_user')}
              </Typography>
              <Typography variant="caption"
                sx={{
                  // flexGrow: 1,
                  // textAlign: 'center',

                  // mr: 2,
                  fontWeight: 'bold',
                  borderRadius: 1,
                  p: 0.5,
                  backgroundColor: theme.palette.mode === 'dark' ? person?.status === 'PENDING' ? '#453E2B' : '#21433A' : person?.status === 'PENDING' ? '#FCEED3' : '#D8F3E2',
                  color: theme.palette.mode === 'dark' ? person?.status === 'PENDING' ? '#FFD666' : '#77ED8B' : person?.status === 'PENDING' ? '#B1741A' : '#118D57',
                }} >
                {person?.status ? person.status.charAt(0) + person.status.slice(1).toLowerCase() : t('dashboard.global.active')}
              </Typography>
            </Stack>}
          secondary={
            <Tooltip title={null} >
              <span>{person.email}</span>
            </Tooltip>
          }
          primaryTypographyProps={{ noWrap: true, typography: 'subtitle2' }}
          secondaryTypographyProps={{ noWrap: true, component: 'span' }}
          sx={{ flexGrow: 2, pr: 1 }}
        />
        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
          {person.role}
        </Typography>

        {
          showButton && (
            <Box sx={{ mr: 1 }}>
              <IconButton color='error' onClick={confirmDelete.onTrue}>
                <Iconify icon="solar:trash-bin-trash-bold" />
              </IconButton>
            </Box>

          )
        }

        {
          editable && <Button
            size="small"
            color="inherit"
            endIcon={
              <Iconify
                width={20}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ ml: -1 }}
              />
            }
            onClick={popover.onOpen}
            sx={{
              flexShrink: 0,
              ...(popover.open && {
                bgcolor: 'action.selected',
              }),
            }}
          >
            Can {permission}
          </Button>
        }

      </ListItem>
      <ConfirmDialog
        open={confirmDelete.value}
        onClose={confirmDelete.onFalse}
        title="Delete"
        content="Are you sure want to delete this invitation?"
        action={
          <Button variant="contained" color="error" onClick={() => handleDeleteRow(person)}>
            {t('dashboard.global.delete')}
          </Button>
        }
      />



    </>
  );
}