// components
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Chart, { useChart } from 'src/components/chart';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useLocales } from 'src/locales';
import { fPercent } from 'src/utils/format-number';
import { generateAnnotations } from 'src/utils/graph-demo-annotations';

// ----------------------------------------------------------------------

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  labels: string[]; // Ajout de la prop labels
};

export default function ChartColumnStackedPercent({ series, labels }: Props) {
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const workspace = useCurrentWorkspace()
  const annotations = generateAnnotations(workspace, filterStore, labels)
  const { t } = useLocales();

  enum ColorSchema {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
  }

  const chartOptions = useChart({
    chart: {
      stacked: true,
      type: 'bar',
      stackType: '100%',
    },
    series: series,
    fill: {
      colors: series?.length === 3
        ? [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, '#b7b7b7']
        : series?.length === 5 ? [theme.palette[ColorSchema.SUCCESS].light, "#1e88e5", "#64b5f6", '#b7dcfb', '#b7b7b7'] : ["#118d57", "#1e88e5", "#64b5f6", "#E1E1E1"],
    },
    colors: series?.length === 3
      ? [theme.palette[ColorSchema.SUCCESS].light, theme.palette[ColorSchema.TERTIARY].light, '#b7b7b7']
      : series?.length === 5 ? [theme.palette[ColorSchema.SUCCESS].light, "#1e88e5", "#64b5f6", '#b7dcfb', '#b7b7b7'] : ["#118d57", "#1e88e5", "#64b5f6", "#E1E1E1"],
    labels: labels || [],
    legend: {
      itemMargin: {
        vertical: 8,
      },
      markers: {
        customHTML: function () {
          return '<div style="width: 16px; height: 16px; border-radius: 50%; background-color: currentcolor;"></div>';
        },
      },
      position: 'top',
      // offsetY: 20,
    },
    plotOptions: {
      bar: {
        barHeight: '80%',
        columnWidth: '70%', // Réduire légèrement la largeur des colonnes
        borderRadius: 0,
        distributed: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    stroke: {
      width: 0, // Désactiver les bordures des barres
      show: false,
    },
    yaxis: {
      labels: {
        formatter: (val: number) => {
          return `${val} %`;
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // Vérifiez la validité des données
        if (!series || !w.globals.seriesNames || !w.globals.colors) {
          return '';
        }

        // Commencer la construction du tooltip HTML
        let tooltipHTML = '<div class="apexcharts-theme-false" style="left: 164.126px; top: 179.348px;">';
        tooltipHTML += `<div class="apexcharts-tooltip-title" style="font-family: Public Sans, sans-serif; font-size: 12px;">${w.globals.labels[dataPointIndex] || ''}</div>`;

        series.forEach((s: any, i: any) => {
          const seriesName = w.globals.seriesNames[i];
          const value = s[dataPointIndex];
          const total = w.globals.stackedSeriesTotals[dataPointIndex];
          const percentage = total ? ((value / total) * 100).toFixed(2) : 0;
          tooltipHTML += `
            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: ${i + 1}; display: flex;">
              <span class="apexcharts-tooltip-marker" style="background-color: ${w.globals.colors[i]};"></span>
              <div class="apexcharts-tooltip-text" style="font-family: Public Sans, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-y-group">
                  <span class="apexcharts-tooltip-text-y-label">${seriesName}: </span>
                  <span class="apexcharts-tooltip-text-y-value">${value.toFixed(0)} (${fPercent(percentage)})</span>
                </div>
                <div class="apexcharts-tooltip-goals-group">
                  <span class="apexcharts-tooltip-text-goals-label"></span>
                  <span class="apexcharts-tooltip-text-goals-value"></span>
                </div>
                <div class="apexcharts-tooltip-z-group">
                  <span class="apexcharts-tooltip-text-z-label"></span>
                  <span class="apexcharts-tooltip-text-z-value"></span>
                </div>
              </div>
            </div>
          `;
        });

        const brandSearches = series.reduce((acc: number, curr: number[]) => acc + curr[dataPointIndex], 0)?.toFixed(0);

        const totalBrandTraffic = series.reduce((acc: number, curr: number[], i: number) => {
          const seriesName = w.globals.seriesNames[i];
          if (seriesName !== t('dashboard.brandTraffic.uncapturedBrandTraffic')) { // Replace with the exact series name if different
            return acc + curr[dataPointIndex];
          }
          return acc;
        }, 0).toFixed(0);

        // Définir les noms des séries à additionner
        const paidSeriesNames = [
          t('dashboard.brandTraffic.pmaxPaid'),
          t('dashboard.brandTraffic.searchPaid'),
          t('dashboard.brandTraffic.shoppingPaid')
        ];

        // Calculer totalPaidTraffic
        const totalPaidTraffic = series.reduce((acc: number, curr: number[], i: number) => {
          const seriesName = w.globals.seriesNames[i];
          if (paidSeriesNames.includes(seriesName)) {
            return acc + curr[dataPointIndex];
          }
          return acc;
        }, 0).toFixed(0);

        // Ajouter le total fixe ou dynamique
        tooltipHTML += `
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: ${series.length + 1}; display: flex;">
            <span class="apexcharts-tooltip-marker" style="background-color: #880e4f;"></span>
            <div class="apexcharts-tooltip-text" style="font-family: Public Sans, sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group">
                <span class="apexcharts-tooltip-text-y-label">Total Brand Traffic:</span>
                <span class="apexcharts-tooltip-text-y-value">${totalBrandTraffic}</span>
              </div>
              <div class="apexcharts-tooltip-goals-group">
                <span class="apexcharts-tooltip-text-goals-label"></span>
                <span class="apexcharts-tooltip-text-goals-value"></span>
              </div>
              <div class="apexcharts-tooltip-z-group">
                <span class="apexcharts-tooltip-text-z-label"></span>
                <span class="apexcharts-tooltip-text-z-value"></span>
              </div>
            </div>
          </div>
          <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: ${series.length + 1}; display: flex;">
            <span class="apexcharts-tooltip-marker" style="background-color: #b71d18;"></span>
            <div class="apexcharts-tooltip-text" style="font-family: Public Sans, sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group">
                <span class="apexcharts-tooltip-text-y-label">Brand Searches: </span>
                <span class="apexcharts-tooltip-text-y-value">${brandSearches}</span>
              </div>
              <div class="apexcharts-tooltip-goals-group">
                <span class="apexcharts-tooltip-text-goals-label"></span>
                <span class="apexcharts-tooltip-text-goals-value"></span>
              </div>
              <div class="apexcharts-tooltip-z-group">
                <span class="apexcharts-tooltip-text-z-label"></span>
                <span class="apexcharts-tooltip-text-z-value"></span>
              </div>
            </div>
          </div>
          ${workspace?.template === "E-commerce" ?
            `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: ${series.length + 1}; display: flex;">
                <span class="apexcharts-tooltip-marker" style="background-color: #006c9c;"></span>
                <div class="apexcharts-tooltip-text" style="font-family: Public Sans, sans-serif; font-size: 12px;">
                  <div class="apexcharts-tooltip-y-group">
                    <span class="apexcharts-tooltip-text-y-label">Total Paid Traffic: </span>
                    <span class="apexcharts-tooltip-text-y-value">${totalPaidTraffic}</span>
                  </div>
                  <div class="apexcharts-tooltip-goals-group">
                    <span class="apexcharts-tooltip-text-goals-label"></span>
                    <span class="apexcharts-tooltip-text-goals-value"></span>
                  </div>
                  <div class="apexcharts-tooltip-z-group">
                    <span class="apexcharts-tooltip-text-z-label"></span>
                    <span class="apexcharts-tooltip-text-z-value"></span>
                  </div>
                </div>
              </div>` : ``
          }
        `;

        tooltipHTML += '</div>';

        return tooltipHTML;
      },
      y: {
        formatter: (val: number, { dataPointIndex, w }) => {
          const total = w.globals.stackedSeriesTotals[dataPointIndex];
          const percentage = total ? ((val / total) * 100).toFixed(2) : 0;
          return `${val.toFixed(0)} (${percentage}%)`;
        },
      },
    },
    annotations: {
      xaxis: annotations
    },
  });

  return <Chart dir="ltr" type="bar" series={series} options={chartOptions} height={350} />;
}
