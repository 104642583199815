import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { round } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useForgotPasswordMutation } from 'src/context/api/auth';
import { useLocales } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import { useRouter } from 'src/routes/hooks';
import * as Yup from 'yup';

export default function ForgotPassword() {
  const { t } = useLocales();
  const router = useRouter()

  const [forgotPassword] = useForgotPasswordMutation();
  
  const schema = Yup.object({
    email: Yup.string().required(t('auth.form.email_must_be_filled')),
  });
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const resetPassword = async (data: { email: string }) => {

      await forgotPassword({ email: data.email }).unwrap()
      .then((response) => {
        const status = Object.values(response)[1]
        if (status === 200) {
          enqueueSnackbar(t('auth.form.reset_success'), { variant: 'success' });
          router.push('/auth/signin'); // Redirection après succès
        }
      })
      .catch((error) => {
        enqueueSnackbar(t('auth.form.reset_failed'), { variant: 'error' });
      });
  };

  
  return (
<div style={{
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'url(/assets/background/background.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}}>
  <div style={{
    width: '100%',
    maxWidth: '400px',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '40px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    position: 'relative', // Ajouté pour positionner le contenu absolu
  }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
          <div style={{
            width: '100px',
            height: '100px',

            position: 'relative',
            borderRadius: '50%',
            overflow: 'hidden',
          }}>
            <div style={{
              width: '100%',
              height: '100%',
              backgroundImage: 'linear-gradient(180deg, rgba(0, 167, 111, 1) -100%, rgba(0, 167, 111, 0.22) 100%)',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 0,
              opacity: 0.3,
              borderRadius: '50%',
            }}>

            </div>
            <div style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              zIndex: 1,
            }}>
              <img
                src='/assets/logo.png'
                alt="Logo"
                style={{
                  width: '60px',
                  height: 'auto',
                }}
              />
            </div>
          </div>
        </div>

    {/* Formulaire Forgot Password */}
    <FormProvider methods={methods} onSubmit={handleSubmit(resetPassword)}>
      <Stack spacing={2} sx={{ mb: 5 }}>
        <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
          <Typography variant="h4" style={{ color: '#1C252E', textAlign: 'center' }}>
            {t('auth.form.forgot')}
          </Typography>

          <Stack direction="row" spacing={0.5} justifyContent="center">
            <Link href={'/auth/signin'} component={RouterLink} variant="subtitle2" sx={{ color: "#00A76F", textDecoration: 'none' }}>
              {t('auth.form.back_to_signin')}
            </Link>
          </Stack>
        </Stack>
        
        {/* Champ d'email */}
        <RHFTextField
          name="email"
          label={t('auth.form.signin.Email')}
          InputLabelProps={{
            sx: {
              color: '#1C252E',
              '&.Mui-focused': {
                color: '#1C252E !important',
              },
            },
          }}
          InputProps={{
            sx: {
              color: '#1C252E',
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#637381',
              },
              '&:hover fieldset': {
                borderColor: '#637381',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#4c5b6e',
              },
            },
          }}
        />

        {/* Bouton pour soumettre */}
        <LoadingButton
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          fullWidth
          loading={isSubmitting}
          sx={{ backgroundColor: '#1C252E', color: 'white' }}
        >
          {t('auth.form.resquest_reset')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  </div>
</div>

  );
}
