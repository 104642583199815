import { Box, Container, Grid, Paper, Skeleton, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { setFilterKeyword, setFilterLoadedKeyword, setFilterLoadedPlatform, setFilterMinDatePicker } from "src/context/reducers/filter-settings/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fDate } from "src/utils/format-time";
import DataGridCustomKeywords from "src/components/data-grid/data-grid-custom-keywords";
import { _mock } from "src/_mock";
import { useGetCountriesQuery, useGetDevicesQuery, useGetKeywordsQuery, useGetPlatformsStatsQuery, useGetStatsQuery } from "src/context/api/trafficDetails/api";
import Iconify from "src/components/iconify";
import { useGetPlatformsQuery, useGetStatsDashCustomQuery } from "src/context/api/trafficAnalysis/api";
import { differenceInDays } from "date-fns";
import BankingWidgetSummary from "./banking-widget-summary-toogle";
import { SplashScreen } from "src/components/loading-screen";
import DataGridCustomDay from "src/components/data-grid/data-grid-custom-day";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import ChartLine from "src/sections/charts/chart-line";
import DataGridCustomDevices from "src/components/data-grid/data-grid-custom-devices";
import DataGridCustomCountries from "src/components/data-grid/data-grid-custom-countries";
import { enqueueSnackbar } from "notistack";
import { useLocales } from "src/locales";
import { usePreviousLocation } from "src/components/previousLocationContext";
import DataGridCustomPlatforms from "src/components/data-grid/data-grid-custom-platforms";

export default function trafficDetails() {
    const { t, currentLang } = useLocales();
    const dispatch = useDispatch();
    const filterStore = useSelector((state: any) => state.filters);
    const theme = useTheme();
    const workspace = useCurrentWorkspace();
    const [kpiToogle, setKPIToogle]: any = useState(["brandSearches", "totalBrandTraffic"]);
    const [value, setValue]: any = useState(0);
    const prevLocation = usePreviousLocation();

    useEffect(() => {
        if (prevLocation === "/dash/competitor-focus" || prevLocation === "/dash/competitors-monitoring") {
            dispatch(setFilterLoadedKeyword([]));
            dispatch(setFilterKeyword([]))
        }
    }, [prevLocation])

    enum ColorSchema {
        PRIMARY = 'primary',
        SECONDARY = 'secondary',
        TERTIARY = 'tertiary',
        DARKER = 'darker',
        ERROR = 'error',
        WARNING = 'warning',
        INFO = 'info',
        SUCCESS = 'success',
        DEFAULT = 'default'
    }

    const skipQuery = !filterStore.start || !filterStore.end || !workspace?.id;

    const { data: platformsData, error: platformsDataError, isFetching: isFetchingPlatformsData } = useGetPlatformsStatsQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        countries: filterStore.countries,
        device: filterStore?.devices,
        keywords: filterStore.keywords,
        platform: filterStore?.platforms,
        workspaceId: workspace?.id,
    }, {
        skip: skipQuery,
        refetchOnMountOrArgChange: false,
    });

    const { data: platforms, error: platformsError, isFetching: isFetchingPlatforms } = useGetPlatformsQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        devices: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
    },
        { skip: skipQuery }
    );

    useEffect(() => {
        if (platforms)
            dispatch(setFilterLoadedPlatform(platforms))
    }, [platforms])


    useEffect(() => {
        if (location?.pathname?.split("/")[3] === "countries") {
            setValue(1)
        }
    }, [location])


    const getDaysDifference = (startDate: any, endDate: any) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return differenceInDays(end, start);
    }

    const { data: data } = useGetStatsDashCustomQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore?.timestep,
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        platform: filterStore?.platforms
    }, {
        skip: skipQuery,
        refetchOnMountOrArgChange: false,

    });

    const [customLabels, setCustomLabels] = useState<any>(data?.stats?.map((item: any) => item[filterStore?.timestep]));

    const { data: keywordsData, error: keywordsError, isFetching: isFetchingKeywords } = useGetKeywordsQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        platform: filterStore?.platforms
    }, {
        skip: skipQuery,
        refetchOnMountOrArgChange: false,

    });

    const dataKeywords = keywordsData?.keywords?.map((item: any, index: any) => ({
        id: index,
        keyword: item?.search_term || '',
        brandSearches: item?.total_organic_searches ? item.total_organic_searches.toFixed(0) : 0,
        brandSearchesCompare: item?.total_organic_searches_compare ? item.total_organic_searches_compare.toFixed(0) : 0,
        brandSearchesDiff: item?.total_organic_searches && item?.total_organic_searches_compare
            ? (item.total_organic_searches - item.total_organic_searches_compare).toFixed(0) : 0,
        brandTraffic: ((item?.total_ad_clicks || 0) + (item?.total_organic_clicks || 0)).toFixed(0),
        brandTrafficCompare: ((item?.total_ad_clicks_compare || 0) + (item?.total_organic_clicks_compare || 0)).toFixed(0),
        brandTrafficDiff: (
            ((item?.total_ad_clicks || 0) + (item?.total_organic_clicks || 0)) -
            ((item?.total_ad_clicks_compare || 0) + (item?.total_organic_clicks_compare || 0))
        ).toFixed(0),
        organicBrandTraffic: item?.total_organic_clicks ? item.total_organic_clicks.toFixed(0) : 0,
        organicBrandTrafficCompare: item?.total_organic_clicks_compare ? item.total_organic_clicks_compare.toFixed(0) : 0,
        organicBrandTrafficDiff: item?.total_organic_clicks && item?.total_organic_clicks_compare
            ? (item.total_organic_clicks - item.total_organic_clicks_compare).toFixed(0) : 0,
        paidBrandTraffic: item?.total_ad_clicks ? item.total_ad_clicks.toFixed(0) : 0,
        paidBrandTrafficCompare: item?.total_ad_clicks_compare ? item.total_ad_clicks_compare.toFixed(0) : 0,
        paidBrandTrafficDiff: item?.total_ad_clicks && item?.total_ad_clicks_compare
            ? (item.total_ad_clicks - item.total_ad_clicks_compare).toFixed(0) : 0,
        uncapturedBrandTraffic: item?.uncaptured_brand_traffic ? item.uncaptured_brand_traffic.toFixed(0) : 0,
        uncapturedBrandTrafficCompare: item?.uncaptured_brand_traffic_compare ? item.uncaptured_brand_traffic_compare.toFixed(0) : 0,
        uncapturedBrandTrafficDiff: item?.uncaptured_brand_traffic && item?.uncaptured_brand_traffic_compare
            ? (item.uncaptured_brand_traffic - item.uncaptured_brand_traffic_compare).toFixed(0) : 0,
        blendedCtr: item?.blended_ctr || 0,
        blendedCtrCompare: item?.blended_ctr_compare || 0,
        blendedCtrDiff: (Math.min(item?.blended_ctr, 100) - Math.min(item?.blended_ctr_compare, 100)).toFixed(2) || 0,
        paidCtr: ((item.total_ad_clicks / item.total_organic_searches) * 100).toFixed(2),
        paidCtrCompare: item?.total_ad_clicks_compare && item?.total_organic_searches_compare
            ? ((item.total_ad_clicks_compare / item.total_organic_searches_compare) * 100).toFixed(2) : 0,
        paidCtrDiff: (Math.min((item.total_ad_clicks / item.total_organic_searches) * 100, 100) - Math.min((item.total_ad_clicks_compare / item.total_organic_searches_compare) * 100, 100))?.toFixed(2),
        organicCtr: item?.total_organic_clicks && item?.total_organic_searches
            ? ((item.total_organic_clicks / item.total_organic_searches) * 100).toFixed(2) : 0,
        organicCtrCompare: item?.total_organic_clicks_compare && item?.total_organic_searches_compare
            ? ((item.total_organic_clicks_compare / item.total_organic_searches_compare) * 100).toFixed(2) : 0,
        organicCtrDiff: item?.total_organic_clicks && item?.total_organic_clicks_compare && item?.total_organic_searches && item?.total_organic_searches_compare
            ? ((item.total_organic_clicks / item.total_organic_searches - item.total_organic_clicks_compare / item.total_organic_searches_compare) * 100).toFixed(2) : 0,
        uncapturedPercent: item?.uncaptured_brand_traffic && item?.total_organic_searches
            ? ((item.uncaptured_brand_traffic / item.total_organic_searches) * 100).toFixed(2) : 0,
        uncapturedPercentCompare: item?.uncaptured_brand_traffic_compare && item?.total_organic_searches_compare
            ? ((item.uncaptured_brand_traffic_compare / item.total_organic_searches_compare) * 100).toFixed(2) : 0,
        uncapturedPercentDiff: item?.uncaptured_brand_traffic && item?.uncaptured_brand_traffic_compare && item?.total_organic_searches && item?.total_organic_searches_compare
            ? ((item.uncaptured_brand_traffic / item.total_organic_searches - item.uncaptured_brand_traffic_compare / item.total_organic_searches_compare) * 100).toFixed(2) : 0,
        adCost: item?.total_ad_cost ? item.total_ad_cost.toFixed(0) : 0,
        adCostCompare: item?.total_ad_cost_compare ? item.total_ad_cost_compare.toFixed(0) : 0,
        adCostDiff: item?.total_ad_cost && item?.total_ad_cost_compare
            ? (item.total_ad_cost - item.total_ad_cost_compare).toFixed(0) : 0
    })) || [];


    const { data: devicesData, error: deviceError, isFetching: isFetchingDevice } = useGetDevicesQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        countries: filterStore.countries,
        device: filterStore?.devices,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        platform: filterStore?.platforms,
    }, {
        skip: skipQuery,
        refetchOnMountOrArgChange: false,
    });

    const dataPlatforms = platformsData?.platforms?.map((item: any, index: any) => ({
        id: index,
        platform: item?.platform?.charAt(0).toUpperCase() + item?.platform?.slice(1).toLowerCase(),
        brandSearches: item?.total_organic_searches.toFixed(0) || 0,
        brandSearchesCompare: item?.total_organic_searches_compare?.toFixed(0) || 0,
        brandSearchesDiff: (item?.total_organic_searches - item?.total_organic_searches_compare).toFixed(0) || 0,
        brandTraffic: (item?.total_ad_clicks + item?.total_organic_clicks).toFixed(0) || 0,
        brandTrafficCompare: (item?.total_ad_clicks_compare + item?.total_organic_clicks_compare).toFixed(0) || 0,
        brandTrafficDiff: (
            ((item?.total_ad_clicks || 0) + (item?.total_organic_clicks || 0)) -
            ((item?.total_ad_clicks_compare || 0) + (item?.total_organic_clicks_compare || 0))
        ).toFixed(0),
        organicBrandTraffic: item?.total_organic_clicks?.toFixed(0) || 0,
        organicBrandTrafficCompare: item?.total_organic_clicks_compare?.toFixed(0) || 0,
        organicBrandTrafficDiff: (item?.total_organic_clicks - item?.total_organic_clicks_compare)?.toFixed(0) || 0,
        paidBrandTraffic: item?.total_ad_clicks?.toFixed(0) || 0,
        paidBrandTrafficCompare: item?.total_ad_clicks_compare?.toFixed(0) || 0,
        paidBrandTrafficDiff: (item?.total_ad_clicks - item?.total_ad_clicks_compare)?.toFixed(0) || 0,
        uncapturedBrandTraffic: item?.uncaptured_brand_traffic?.toFixed(0) || 0,
        uncapturedBrandTrafficCompare: item?.uncaptured_brand_traffic_compare?.toFixed(0) || 0,
        uncapturedBrandTrafficDiff: (item?.uncaptured_brand_traffic - item?.uncaptured_brand_traffic_compare)?.toFixed(0) || 0,
        blendedCtr: item?.blended_ctr || 0,
        blendedCtrCompare: item?.blended_ctr_compare || 0,
        blendedCtrDiff: (Math.min(item?.blended_ctr, 100) - Math.min(item?.blended_ctr_compare, 100)).toFixed(2) || 0,
        paidCtr: item?.total_ad_clicks && item?.total_organic_searches
            ? ((item.total_ad_clicks / item.total_organic_searches) * 100).toFixed(2) : 0,
        paidCtrCompare: item?.total_ad_clicks_compare && item?.total_organic_searches_compare
            ? ((item.total_ad_clicks_compare / item.total_organic_searches_compare) * 100).toFixed(2) : 0,
        paidCtrDiff: (Math.min((item.total_ad_clicks / item.total_organic_searches) * 100, 100) - Math.min((item.total_ad_clicks_compare / item.total_organic_searches_compare) * 100, 100))?.toFixed(2),
        organicCtr: ((item?.total_organic_clicks / item?.total_organic_searches) * 100) || 0,
        organicCtrCompare: ((item?.total_organic_clicks_compare / item?.total_organic_searches_compare) * 100) || 0,
        organicCtrDiff: ((item?.total_organic_clicks / item?.total_organic_searches - item?.total_organic_clicks_compare / item?.total_organic_searches_compare) * 100).toFixed(2) || 0,
        uncapturedPercent: ((item?.uncaptured_brand_traffic / item?.total_organic_searches) * 100) || 0,
        uncapturedPercentCompare: ((item?.uncaptured_brand_traffic_compare / item?.total_organic_searches_compare) * 100) || 0,
        uncapturedPercentDiff: ((item?.uncaptured_brand_traffic / item?.total_organic_searches - item?.uncaptured_brand_traffic_compare / item?.total_organic_searches_compare) * 100).toFixed(2) || 0,
        adCost: item?.total_ad_cost?.toFixed(0) || 0,
        adCostCompare: item?.total_ad_cost_compare?.toFixed(0) || 0,
        adCostDiff: (item?.total_ad_cost - item?.total_ad_cost_compare).toFixed(0) || 0
    })) || [];

    const dataDevices = devicesData?.devices?.map((item: any, index: any) => ({
        id: index,
        device: item?.device?.charAt(0).toUpperCase() + item?.device?.slice(1).toLowerCase(),
        brandSearches: item?.total_organic_searches.toFixed(0) || 0,
        brandSearchesCompare: item?.total_organic_searches_compare?.toFixed(0) || 0,
        brandSearchesDiff: (item?.total_organic_searches - item?.total_organic_searches_compare).toFixed(0) || 0,
        brandTraffic: (item?.total_ad_clicks + item?.total_organic_clicks).toFixed(0) || 0,
        brandTrafficCompare: (item?.total_ad_clicks_compare + item?.total_organic_clicks_compare).toFixed(0) || 0,
        brandTrafficDiff: (
            ((item?.total_ad_clicks || 0) + (item?.total_organic_clicks || 0)) -
            ((item?.total_ad_clicks_compare || 0) + (item?.total_organic_clicks_compare || 0))
        ).toFixed(0),
        organicBrandTraffic: item?.total_organic_clicks?.toFixed(0) || 0,
        organicBrandTrafficCompare: item?.total_organic_clicks_compare?.toFixed(0) || 0,
        organicBrandTrafficDiff: (item?.total_organic_clicks - item?.total_organic_clicks_compare)?.toFixed(0) || 0,
        paidBrandTraffic: item?.total_ad_clicks?.toFixed(0) || 0,
        paidBrandTrafficCompare: item?.total_ad_clicks_compare?.toFixed(0) || 0,
        paidBrandTrafficDiff: (item?.total_ad_clicks - item?.total_ad_clicks_compare)?.toFixed(0) || 0,
        uncapturedBrandTraffic: item?.uncaptured_brand_traffic?.toFixed(0) || 0,
        uncapturedBrandTrafficCompare: item?.uncaptured_brand_traffic_compare?.toFixed(0) || 0,
        uncapturedBrandTrafficDiff: (item?.uncaptured_brand_traffic - item?.uncaptured_brand_traffic_compare)?.toFixed(0) || 0,
        blendedCtr: item?.blended_ctr || 0,
        blendedCtrCompare: item?.blended_ctr_compare || 0,
        blendedCtrDiff: (Math.min(item?.blended_ctr, 100) - Math.min(item?.blended_ctr_compare, 100)).toFixed(2) || 0,
        paidCtr: item?.total_ad_clicks && item?.total_organic_searches
            ? ((item.total_ad_clicks / item.total_organic_searches) * 100).toFixed(2) : 0,
        paidCtrCompare: item?.total_ad_clicks_compare && item?.total_organic_searches_compare
            ? ((item.total_ad_clicks_compare / item.total_organic_searches_compare) * 100).toFixed(2) : 0,
        paidCtrDiff: (Math.min((item.total_ad_clicks / item.total_organic_searches) * 100, 100) - Math.min((item.total_ad_clicks_compare / item.total_organic_searches_compare) * 100, 100))?.toFixed(2),
        organicCtr: ((item?.total_organic_clicks / item?.total_organic_searches) * 100) || 0,
        organicCtrCompare: ((item?.total_organic_clicks_compare / item?.total_organic_searches_compare) * 100) || 0,
        organicCtrDiff: ((item?.total_organic_clicks / item?.total_organic_searches - item?.total_organic_clicks_compare / item?.total_organic_searches_compare) * 100).toFixed(2) || 0,
        uncapturedPercent: ((item?.uncaptured_brand_traffic / item?.total_organic_searches) * 100) || 0,
        uncapturedPercentCompare: ((item?.uncaptured_brand_traffic_compare / item?.total_organic_searches_compare) * 100) || 0,
        uncapturedPercentDiff: ((item?.uncaptured_brand_traffic / item?.total_organic_searches - item?.uncaptured_brand_traffic_compare / item?.total_organic_searches_compare) * 100).toFixed(2) || 0,
        adCost: item?.total_ad_cost?.toFixed(0) || 0,
        adCostCompare: item?.total_ad_cost_compare?.toFixed(0) || 0,
        adCostDiff: (item?.total_ad_cost - item?.total_ad_cost_compare).toFixed(0) || 0
    })) || [];

    const { data: countriesData, error: countriesError, isFetching: isFetchingCountries } = useGetCountriesQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        devices: filterStore?.devices,
        keywords: filterStore?.keywords,
        countries: filterStore?.countries,
        workspaceId: workspace?.id,
        platform: filterStore?.platforms,
    }, {
        skip: skipQuery,
        refetchOnMountOrArgChange: false,
    });

    const dataCountries = countriesData?.countries?.filter((item: any) => item?.country !== null)?.map((item: any, index: any) => ({
        id: index,
        country: item?.country,
        brandSearches: item?.total_organic_searches.toFixed(0),
        brandSearchesCompare: item?.total_organic_searches_compare?.toFixed(0) || 0,
        brandSearchesDiff: (item?.total_organic_searches - item?.total_organic_searches_compare).toFixed(0) || 0,
        brandTraffic: (item?.total_ad_clicks + item?.total_organic_clicks).toFixed(0) || 0,
        brandTrafficCompare: (item?.total_ad_clicks_compare + item?.total_organic_clicks_compare).toFixed(0) || 0,
        brandTrafficDiff: (
            ((item?.total_ad_clicks || 0) + (item?.total_organic_clicks || 0)) -
            ((item?.total_ad_clicks_compare || 0) + (item?.total_organic_clicks_compare || 0))
        ).toFixed(0),
        organicBrandTraffic: item?.total_organic_clicks?.toFixed(0) || 0,
        organicBrandTrafficCompare: item?.total_organic_clicks_compare?.toFixed(0) || 0,
        organicBrandTrafficDiff: (item?.total_organic_clicks - item?.total_organic_clicks_compare)?.toFixed(0) || 0,
        paidBrandTraffic: item?.total_ad_clicks?.toFixed(0) || 0,
        paidBrandTrafficCompare: item?.total_ad_clicks_compare?.toFixed(0) || 0,
        paidBrandTrafficDiff: (item?.total_ad_clicks - item?.total_ad_clicks_compare)?.toFixed(0) || 0,
        uncapturedBrandTraffic: item?.uncaptured_brand_traffic?.toFixed(0) || 0,
        uncapturedBrandTrafficCompare: item?.uncaptured_brand_traffic_compare?.toFixed(0) || 0,
        uncapturedBrandTrafficDiff: (item?.uncaptured_brand_traffic - item?.uncaptured_brand_traffic_compare)?.toFixed(0) || 0,
        blendedCtr: item?.blended_ctr || 0,
        blendedCtrCompare: item?.blended_ctr_compare || 0,
        blendedCtrDiff: (Math.min(item?.blended_ctr, 100) - Math.min(item?.blended_ctr_compare, 100)).toFixed(2) || 0,
        paidCtr: item?.total_ad_clicks && item?.total_organic_searches
            ? ((item.total_ad_clicks / item.total_organic_searches) * 100).toFixed(2) : 0,
        paidCtrCompare: item?.total_ad_clicks_compare && item?.total_organic_searches_compare
            ? ((item.total_ad_clicks_compare / item.total_organic_searches_compare) * 100).toFixed(2) : 0,
        paidCtrDiff: (Math.min((item.total_ad_clicks / item.total_organic_searches) * 100, 100) - Math.min((item.total_ad_clicks_compare / item.total_organic_searches_compare) * 100, 100))?.toFixed(2),
        organicCtr: ((item?.total_organic_clicks / item?.total_organic_searches) * 100) || 0,
        organicCtrCompare: ((item?.total_organic_clicks_compare / item?.total_organic_searches_compare) * 100) || 0,
        organicCtrDiff: ((item?.total_organic_clicks / item?.total_organic_searches - item?.total_organic_clicks_compare / item?.total_organic_searches_compare) * 100).toFixed(2) || 0,
        uncapturedPercent: ((item?.uncaptured_brand_traffic / item?.total_organic_searches) * 100) || 0,
        uncapturedPercentCompare: ((item?.uncaptured_brand_traffic_compare / item?.total_organic_searches_compare) * 100) || 0,
        uncapturedPercentDiff: ((item?.uncaptured_brand_traffic / item?.total_organic_searches - item?.uncaptured_brand_traffic_compare / item?.total_organic_searches_compare) * 100).toFixed(2) || 0,
        adCost: item?.total_ad_cost?.toFixed(0) || 0,
        adCostCompare: item?.total_ad_cost_compare?.toFixed(0) || 0,
        adCostDiff: (item?.total_ad_cost - item?.total_ad_cost_compare).toFixed(0) || 0
    })) || [];


    const { data: statsData, error: statsError, isFetching: isFetchingStats } = useGetStatsDashCustomQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        platform: filterStore?.platforms
    }, {
        skip: skipQuery,
        refetchOnMountOrArgChange: false,

    });

    const { data: statsDataDay, error: statsDayError, isFetching: isFetchingStatsDay } = useGetStatsQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        device: filterStore.devices,
        countries: filterStore.countries,
        workspaceId: workspace?.id,
        keywords: filterStore.keywords,
        timestep: filterStore?.timestep,
        platform: filterStore?.platforms,
    }, {
        skip: skipQuery,
        refetchOnMountOrArgChange: false,

    });

    useEffect(() => {
        dispatch(setFilterMinDatePicker(statsData?.minDatePicker[0]?.first_date))
    }, [statsData])

    const dataDay = statsDataDay?.stats?.map((item: any, index: any) => ({
        id: index,
        date: item[filterStore?.timestep],
        brandSearches: item?.total_organic_searches.toFixed(0),
        organicBrandTraffic: item?.total_organic_clicks?.toFixed(0),
        paidBrandTraffic: item?.total_ad_clicks?.toFixed(0),
        uncapturedBrandTraffic: item?.uncaptured_brand_traffic?.toFixed(0),
        blendedCtr: (item?.blended_ctr * 100),
        paidCtr: (item?.total_ad_clicks / item?.total_organic_searches * 100),
        organicCtr: (item?.total_organic_clicks / item?.total_organic_searches * 100),
        uncapturedPercent: (item?.uncaptured_brand_traffic / item?.total_organic_searches * 100),
        adCost: item?.total_ad_cost?.toFixed(0),
        totalBrandTraffic: (item?.total_ad_clicks + item?.total_organic_clicks).toFixed(0)
    })) || [];

    const SparkLinesDataBrandSearches = data?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_organic_clicks || 0) + (item?.total_ad_clicks || 0) + (item?.uncaptured_brand_traffic || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_organic_clicks || 0) + (item?.total_ad_clicks || 0) + (item?.uncaptured_brand_traffic || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_organic_clicks || 0) + (item?.total_ad_clicks || 0) + (item?.uncaptured_brand_traffic || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const SparkLinesDataTotalBrandTraffic = data?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_organic_clicks || 0) + (item?.total_ad_clicks || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_organic_clicks || 0) + (item?.total_ad_clicks || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_organic_clicks || 0) + (item?.total_ad_clicks || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const SparkLinesDataOrganicBrandTraffic = data?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_organic_clicks || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_organic_clicks || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_organic_clicks || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const SparkLinesDataAdBrandTraffic = data?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_ad_clicks || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_ad_clicks || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_ad_clicks || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const SparkLinesDataBlendedCtr = data?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const totalClicks = (item?.total_ad_clicks || 0) + (item?.total_organic_clicks || 0);
            const totalSearches = item?.total_organic_searches || 1;
            const blendedCtr = (totalClicks / totalSearches) * 100;

            return { y: blendedCtr, x: item?.year_month };
        } else if (daysDifference > 90) {
            const totalClicks = (item?.total_ad_clicks || 0) + (item?.total_organic_clicks || 0);
            const totalSearches = item?.total_organic_searches || 1;
            const blendedCtr = (totalClicks / totalSearches) * 100;
            return { y: blendedCtr, x: item?.year_week };
        } else {
            const totalClicks = (item?.total_ad_clicks || 0) + (item?.total_organic_clicks || 0);
            const totalSearches = item?.total_organic_searches || 1;
            const blendedCtr = (totalClicks / totalSearches) * 100;

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: blendedCtr, x: `${year}-${month}-${day}` };
        }
    });

    const SparkLinesDataTotalAdCost = data?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_ad_cost || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_ad_cost || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_ad_cost || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const stats = [
        {
            title: t('dashboard.brandTraffic.brandSearches'),
            key: "brandSearches",
            value: statsData?.totals.organic_searches,
            valueCompare: statsData?.compare_totals.organic_searches,
            compareData: ((statsData?.totals.organic_searches - statsData?.compare_totals.organic_searches) / statsData?.compare_totals.organic_searches) * 100,
            color: kpiToogle?.includes("brandSearches") ? ColorSchema.ERROR : theme.palette.mode === "dark" ? ColorSchema.DARKER : ColorSchema.DEFAULT,
            textColor: theme.palette.mode === "dark" ? '#b71d18' : kpiToogle?.includes("brandSearches") ? '#b71d18' : '#e2e5e9',
            spanColor: theme.palette.mode === "dark" ? kpiToogle?.includes("brandSearches") ? '#db8377' : undefined : 'transparent',
            trendColor: kpiToogle?.includes("brandSearches") ? kpiToogle?.includes("brandSearches") ? "#b71d18" : "undefined" : '#e2e5e9',
            icon: "icon-park-twotone:search",
            series: SparkLinesDataBrandSearches
        },
        {
            title: t('dashboard.brandTraffic.totalBrandTraffic'),
            key: "totalBrandTraffic",
            value: statsData?.totals.brand_traffic,
            valueCompare: statsData?.compare_totals.brand_traffic,
            compareData: ((statsData?.totals.brand_traffic - statsData?.compare_totals.brand_traffic) / statsData?.compare_totals.brand_traffic) * 100,
            color: kpiToogle?.includes("totalBrandTraffic") ? ColorSchema.SECONDARY : theme.palette.mode === "dark" ? ColorSchema.DARKER : ColorSchema.DEFAULT,
            textColor: theme.palette.mode === "dark" ? '#880e4f' : kpiToogle?.includes("totalBrandTraffic") ? '#880e4f' : '#e2e5e9',
            spanColor: theme.palette.mode === "dark" ? kpiToogle?.includes("totalBrandTraffic") ? '#a64b7b' : undefined : 'transparent',
            trendColor: kpiToogle?.includes("totalBrandTraffic") ? kpiToogle?.includes("totalBrandTraffic") ? "#880e4f" : "undefined" : '#e2e5e9',
            icon: "solar:user-circle-bold-duotone",
            series: SparkLinesDataTotalBrandTraffic
        },
        {
            title: t('dashboard.brandTraffic.organic_brand_traffic'),
            key: "organicBrandTraffic",
            value: statsData?.totals.organic_clicks,
            valueCompare: statsData?.compare_totals.organic_clicks,
            compareData: ((statsData?.totals.organic_clicks - statsData?.compare_totals.organic_clicks) / statsData?.compare_totals.organic_clicks) * 100,
            color: kpiToogle?.includes("organicBrandTraffic") ? ColorSchema.SUCCESS : theme.palette.mode === "dark" ? ColorSchema.DARKER : ColorSchema.DEFAULT,
            textColor: theme.palette.mode === "dark" ? '#118d57' : kpiToogle?.includes("organicBrandTraffic") ? "#118d57" : "#e2e5e9",
            spanColor: theme.palette.mode === "dark" ? kpiToogle?.includes("organicBrandTraffic") ? '#6dad8d' : undefined : 'transparent',
            trendColor: kpiToogle?.includes("organicBrandTraffic") ? kpiToogle?.includes("organicBrandTraffic") ? "#118d57" : "undefined" : '#e2e5e9',
            icon: "uim:google",
            series: SparkLinesDataOrganicBrandTraffic
        },
        {
            title: t('dashboard.brandTraffic.ad_brand_traffic'),
            key: "adBrandTraffic",
            value: statsData?.totals.ad_clicks,
            valueCompare: statsData?.compare_totals.ad_clicks,
            compareData: ((statsData?.totals.ad_clicks - statsData?.compare_totals.ad_clicks) / statsData?.compare_totals.ad_clicks) * 100,
            color: kpiToogle?.includes("adBrandTraffic") ? ColorSchema.TERTIARY : theme.palette.mode === "dark" ? ColorSchema.DARKER : ColorSchema.DEFAULT,
            textColor: theme.palette.mode === "dark" ? '#006c9c' : kpiToogle?.includes("adBrandTraffic") ? "#006c9c" : "#e2e5e9",
            spanColor: theme.palette.mode === "dark" ? kpiToogle?.includes("adBrandTraffic") ? '#65b5c9' : undefined : 'transparent',
            trendColor: kpiToogle?.includes("adBrandTraffic") ? kpiToogle?.includes("adBrandTraffic") ? "#006c9c" : "undefined" : '#e2e5e9',
            icon: "cib:google-ads",
            series: SparkLinesDataAdBrandTraffic
        },
        {
            title: t('dashboard.brandTraffic.blendedCtr'),
            key: "blendedCtr",
            valueCompare: statsData?.compare_totals.blended_ctr,
            value: statsData?.totals.blended_ctr,
            compareData: ((statsData?.totals.blended_ctr - statsData?.compare_totals.blended_ctr) / statsData?.compare_totals.blended_ctr) * 100,
            color: kpiToogle?.includes("blendedCtr") ? ColorSchema.INFO : theme.palette.mode === "dark" ? ColorSchema.DARKER : ColorSchema.DEFAULT,
            textColor: theme.palette.mode === "dark" ? '#5119b7' : kpiToogle?.includes("blendedCtr") ? "#5119b7" : "#e2e5e9",
            spanColor: theme.palette.mode === "dark" ? kpiToogle?.includes("blendedCtr") ? '#9e77da' : undefined : 'transparent',
            trendColor: kpiToogle?.includes("blendedCtr") ? kpiToogle?.includes("blendedCtr") ? "#5119b7" : "undefined" : '#e2e5e9',
            icon: "icon-park-twotone:click",
            series: SparkLinesDataBlendedCtr
        },
        {
            title: t('dashboard.global.ad_cost'),
            key: "adCost",
            value: statsData?.totals.ad_cost,
            valueCompare: statsData?.compare_totals.ad_cost,
            compareData: ((statsData?.totals.ad_cost - statsData?.compare_totals.ad_cost) / statsData?.compare_totals.ad_cost) * 100,
            color: kpiToogle?.includes("adCost") ? ColorSchema.WARNING : theme.palette.mode === "dark" ? ColorSchema.DARKER : ColorSchema.DEFAULT,
            textColor: theme.palette.mode === "dark" ? '#b76e00' : kpiToogle?.includes("adCost") ? "#b76e00" : "#e2e5e9",
            spanColor: theme.palette.mode === "dark" ? kpiToogle?.includes("adCost") ? '#ffd066' : undefined : 'transparent',
            trendColor: kpiToogle?.includes("adCost") ? kpiToogle?.includes("adCost") ? "#b76e00" : "undefined" : '#e2e5e9',
            icon: "solar:money-bag-bold-duotone",
            series: SparkLinesDataTotalAdCost
        }
    ];

    const handleToggleKPI = (key: string) => {
        setKPIToogle((prevState: string[] = []) => {
            if (prevState.includes(key)) {
                if (prevState.length > 1) {
                    return prevState.filter((k: string) => k !== key);
                } else {
                    enqueueSnackbar(t('dashboard.snackbar.you_cant_deselect_the_last_item'), { variant: 'error' });
                    return prevState;
                }
            } else {
                return [...prevState, key];
            }
        });
    };

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (data) {
            if (filterStore?.timestep === 'year_week') {
                const yearWeekRegex = /^\d{4}-\d{2}$/;
                const formattedLabels = data.stats.map((item: any) => {
                    return item?.year_week
                });
                setCustomLabels(formattedLabels);
            } else if (filterStore.timestep === 'year_month') {
                const yearWeekRegex = /^\d{4}-\d{2}$/;
                const formatedMontlyDates = data?.stats?.map((item: any) => {
                    if (yearWeekRegex.test(item[filterStore.timestep])) {
                        const [year, week] = item[filterStore.timestep].split('-');
                        return `${week}/${year}`;
                    }
                })
                setCustomLabels(formatedMontlyDates);

            } else {
                const formatedDailyDates = data?.stats?.map((item: any) => {
                    const newDate = new Date(item[filterStore.timestep]);
                    const month = String(newDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based, so we add 1
                    const day = String(newDate.getUTCDate()).padStart(2, '0');
                    const year = newDate.getUTCFullYear();
                    if (currentLang.value === 'fr') {
                        return `${day}/${month}/${year}`;
                    } else {
                        return `${month}/${day}/${year}`;
                    }
                })
                setCustomLabels(formatedDailyDates);
            }
        }
    }, [currentLang.value, data, filterStore.timestep]);

    return (
        <>
            {(!filterStore?.countries || !filterStore?.keywords || !filterStore?.devices)
                ? <SplashScreen />
                : <Box sx={{ marginTop: 1, px: 3 }}>

                    <div>
                        <Grid container sx={{
                            display: 'flex',
                            overflowX: { xs: 'scroll', lg: 'unset' },
                            flexWrap: 'nowrap',
                        }}>
                            {stats.map((stat, index) => (
                                <Grid item key={index} xs={6} md={4} lg={2} sx={{
                                    flex: { xs: '0 0 50%', sm: '0 0 25%', md: 1 },
                                }}>
                                    {isFetchingStats || (!statsData && !statsError) ? (
                                        <Skeleton
                                            width={'100%'}
                                            height={'110px'}
                                            style={{ borderRadius: 0, borderTopLeftRadius: index === 0 ? 10 : 0, borderTopRightRadius: index === 5 ? 10 : 0 }}
                                            animation="wave"
                                        >
                                            <>
                                                <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                                                <Typography color="success.main">Active</Typography>
                                            </>
                                        </Skeleton>
                                    ) : (
                                        <BankingWidgetSummary
                                            handleKPI={(key: any) => handleToggleKPI(key)}
                                            spanColor={stat.spanColor}
                                            kpiToogle={kpiToogle}
                                            title={stat?.title}
                                            val={stat?.key}
                                            compareData={stat?.compareData}
                                            valueCompare={stat?.valueCompare}
                                            textColor={stat?.textColor}
                                            icon={stat?.icon}
                                            color={stat.color}
                                            percent={0}
                                            trendColor={stat?.trendColor}
                                            total={stat?.value}
                                            chart={{
                                                series: stat.series,
                                            }}
                                        />
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </div>

                    {isFetchingStats || (!statsData && !statsError) ? (
                        <Skeleton
                            width={'100%'}
                            height={'364px'}
                            style={{ borderRadius: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
                            animation="wave"
                        >
                            <>
                                <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                                <Typography color="success.main">Active</Typography>
                            </>
                        </Skeleton>
                    ) : (
                        <ChartLine
                            chart={{
                                series: [
                                    {
                                        categories: customLabels,
                                        data: [
                                            {
                                                name: t('dashboard.brandTraffic.brandSearches'),
                                                data: data?.stats?.map((item: any) => item?.total_organic_searches),
                                                visible: kpiToogle?.includes("brandSearches"),
                                                color: theme.palette[ColorSchema.ERROR].light,
                                                yAxis: 1,
                                                dashStyle: "solid"
                                            },
                                            ...(filterStore?.compare === true && filterStore?.timestep === "date" ? [{
                                                name: "Brand Searches Compare",
                                                data: data?.statsCompare?.map((item: any) => item?.total_organic_searches),
                                                visible: kpiToogle?.includes("brandSearches"),
                                                color: theme.palette[ColorSchema.ERROR].light,
                                                yAxis: 1,
                                                dashStyle: "ShortDash"
                                            }] : []),
                                            {
                                                name: t('dashboard.brandTraffic.totalBrandTraffic'),
                                                data: data?.stats?.map((item: any) => item?.total_organic_clicks + item?.total_ad_clicks),
                                                visible: kpiToogle?.includes("totalBrandTraffic"),
                                                color: theme.palette[ColorSchema.SECONDARY].darker,
                                                yAxis: 1,
                                                dashStyle: "solid"
                                            },
                                            ...(filterStore?.compare === true && filterStore?.timestep === "date" ? [{
                                                name: "Total Brand Traffic Compare",
                                                data: data?.statsCompare?.map((item: any) => item?.total_organic_clicks + item?.total_ad_clicks),
                                                visible: kpiToogle?.includes("totalBrandTraffic"),
                                                color: theme.palette[ColorSchema.SECONDARY].darker,
                                                yAxis: 1,
                                                dashStyle: "ShortDash"
                                            }] : []),
                                            {
                                                name: t('dashboard.brandTraffic.organicBrandTraffic'),
                                                data: data?.stats?.map((item: any) => item?.total_organic_clicks),
                                                visible: kpiToogle?.includes("organicBrandTraffic"),
                                                color: theme.palette[ColorSchema.SUCCESS].light,
                                                yAxis: 1,
                                                dashStyle: "solid"
                                            },
                                            ...(filterStore?.compare === true && filterStore?.timestep === "date" ? [{
                                                name: "Organic Compare",
                                                data: data?.statsCompare?.map((item: any) => item?.total_organic_clicks),
                                                visible: kpiToogle?.includes("organicBrandTraffic"),
                                                color: theme.palette[ColorSchema.SUCCESS].light,
                                                yAxis: 1,
                                                dashStyle: "ShortDash"
                                            }] : []),
                                            {
                                                name: t('dashboard.brandTraffic.adBrandTraffic'),
                                                data: data?.stats?.map((item: any) => item?.total_ad_clicks),
                                                visible: kpiToogle?.includes("adBrandTraffic"),
                                                color: theme.palette[ColorSchema.TERTIARY].light,
                                                yAxis: 1,
                                                dashStyle: "solid"
                                            },
                                            ...(filterStore?.compare === true && filterStore?.timestep === "date" ? [{
                                                name: "Ad Brand Traffic Compare",
                                                data: data?.statsCompare?.map((item: any) => item?.total_ad_clicks),
                                                visible: kpiToogle?.includes("adBrandTraffic"),
                                                color: theme.palette[ColorSchema.TERTIARY].light,
                                                yAxis: 1,
                                                dashStyle: "ShortDash"
                                            }] : []),
                                            {
                                                name: t('dashboard.global.blended_ctr'),
                                                data: data?.stats?.map((item: any) => item?.blended_ctr),
                                                visible: kpiToogle?.includes("blendedCtr"),
                                                color: theme.palette[ColorSchema.INFO].light,
                                                yAxis: 1,
                                                dashStyle: "solid"
                                            },
                                            ...(filterStore?.compare === true && filterStore?.timestep === "date" ? [{
                                                name: "Blended CTR Compare",
                                                data: data?.statsCompare?.map((item: any) => item?.blended_ctr),
                                                visible: kpiToogle?.includes("blendedCtr"),
                                                color: theme.palette[ColorSchema.INFO].light,
                                                yAxis: 1,
                                                dashStyle: "ShortDash"
                                            }] : []),
                                            {
                                                name: t('dashboard.global.ad_cost'),
                                                data: data?.stats?.map((item: any) => item?.total_ad_cost),
                                                visible: kpiToogle?.includes("adCost"),
                                                color: theme.palette[ColorSchema.WARNING].light,
                                                yAxis: 1,
                                                dashStyle: "solid"
                                            },
                                            ...(filterStore?.compare === true && filterStore?.timestep === "date" ? [{
                                                name: "Ad Cost Compare",
                                                data: data?.statsCompare?.map((item: any) => item?.total_ad_cost),
                                                visible: kpiToogle?.includes("adCost"),
                                                color: theme.palette[ColorSchema.WARNING].light,
                                                yAxis: 1,
                                                dashStyle: "ShortDash"
                                            }] : []),
                                        ]
                                    }
                                ],
                            }}
                        />
                    )}
                    {isFetchingStats || (!statsData && !statsError) ? (
                        <Skeleton
                            width={'100%'}
                            height={'700px'}
                            style={{ borderRadius: 10, marginTop: 20 }}
                            animation="wave"
                        >
                            <>
                                <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                                <Typography color="success.main">Active</Typography>
                            </>
                        </Skeleton>
                    ) : (
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                minHeight: 700,
                                borderRadius: 2,
                                marginTop: 3,
                                marginBottom: 1
                            }}
                        >
                            <Tabs sx={{ pl: 4, pt: 2, pb: 2 }} value={value} onChange={handleChange} aria-label={t('dashboard.brandTraffic.basic_tabs_example')}>
                                <Tab label={t('dashboard.global.keywords')} />
                                <Tab label={t('dashboard.global.countries')} />
                                <Tab label={t('dashboard.global.devices')} />
                                <Tab label={t('dashboard.global.date')} />
                                {dataPlatforms?.length >= 2 &&
                                    <Tab label={"Platforms"} />

                                }
                            </Tabs>

                            <Box >

                                {value === 0 && (
                                    <>
                                        {isFetchingKeywords || (!keywordsData && !keywordsError)
                                            ? <Skeleton width={'100%'} height={680} style={{ borderRadius: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }} variant="rectangular" animation={"wave"}>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </Skeleton>
                                            :
                                            <Box height={"auto"}>
                                                <DataGridCustomKeywords data={dataKeywords} />
                                            </Box>

                                        }
                                    </>
                                )}

                                {value === 1 && (
                                    <>
                                        {isFetchingCountries || (!countriesData && !countriesError)
                                            ? <Skeleton width={'100%'} height={680} style={{ borderRadius: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }} variant="rectangular" animation={"wave"}>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </Skeleton>
                                            : <Box>
                                                <Box height={"auto"}>
                                                    <DataGridCustomCountries data={dataCountries} />
                                                </Box>
                                            </Box>
                                        }
                                    </>
                                )}

                                {value === 2 && (
                                    <>
                                        {isFetchingDevice || (!devicesData && !deviceError)
                                            ? <Skeleton width={'100%'} height={680} style={{ borderRadius: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }} variant="rectangular" animation={"wave"}>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </Skeleton>
                                            : <>
                                                <Box height={"auto"}>
                                                    <DataGridCustomDevices data={dataDevices.filter((item: any) => item.device !== "Na")} />
                                                </Box>
                                            </>
                                        }
                                    </>
                                )}

                                {value === 3 && (
                                    <>
                                        {isFetchingStatsDay || (!dataDay && !statsDayError)
                                            ? <Skeleton width={'100%'} height={680} style={{ borderRadius: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }} variant="rectangular" animation={"wave"}>
                                                <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                <Typography color={"success.main"}>Active</Typography>
                                            </Skeleton>
                                            : <>
                                                <Box height={"auto"}>
                                                    <DataGridCustomDay data={dataDay} />
                                                </Box>
                                            </>
                                        }
                                    </>
                                )}

                                {dataPlatforms?.length >= 2 &&
                                    <>
                                        {value === 4 && (
                                            <>
                                                {isFetchingPlatformsData || (!platformsData && !platformsDataError)
                                                    ? <Skeleton width={'100%'} height={680} style={{ borderRadius: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }} variant="rectangular" animation={"wave"}>
                                                        <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                                        <Typography color={"success.main"}>Active</Typography>
                                                    </Skeleton>
                                                    : <>
                                                        <Box height={"auto"}>
                                                            <DataGridCustomPlatforms data={dataPlatforms} />
                                                        </Box>
                                                    </>
                                                }
                                            </>
                                        )}
                                    </>
                                }

                            </Box>
                        </Paper>
                    )}
                </Box>
            }
        </>
    )
}
