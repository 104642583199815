import { createApi } from '@reduxjs/toolkit/query/react';
import { Invitation, WorkspaceList } from './models';
import fetchBaseQueryCSRF from '../helper';

interface QueryArg {
  id?: number;
  name: string;
  url: string;
  editors?: string[];
  logo?: File;
  datasource?: number[];
}



const workspaces = createApi({
  baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL + "/workspaces" }),
  reducerPath: 'api.workspaces',
  tagTypes: ['workspaces'],
  endpoints: (builder) => ({
    createWorkspace: builder.mutation<{ id: number }, QueryArg>({
      query: (body) => ({
        url: `/`,
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    updateWorkspace: builder.mutation<{ id: number }, QueryArg>({
      query: (body) => ({
        url: `/${body.id}`,
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    deleteWorkspace: builder.mutation<{ id: number }, QueryArg>({
      query: (body) => ({
        url: `/${body.id}`,
        method: 'DELETE',
        body,
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
        invalidatesTags: ['workspaces'],
      }),
    }),
    getWorkspaces: builder.query<WorkspaceList[], null>({
      query: () => ({
        url: `/`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    getWorkspace: builder.query<WorkspaceList, { id: number }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    workspaceUserUpdate: builder.mutation<WorkspaceList, { id: number, userId: number, role: string }>({
      query: ({ id, userId, role }) => ({
        url: `/${id}/users/${userId}`,
        method: 'POST',
        body: { role },
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    workspaceUserDelete: builder.mutation<WorkspaceList, { id: number, userId: number }>({
      query: ({ id, userId }) => ({
        url: `/${id}/users/${userId}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    // Get current user role in workspace
    role: builder.query<{ role: string }, { id: number }>({
      query: ({ id }) => ({
        url: `/${id}/users/me`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    invitations: builder.query<Invitation[], null>({
      query: () => ({
        url: `/invitations`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    invitation: builder.query<Invitation, { id: number, token: string }>({
      query: ({ id, token }) => ({
        url: `/${id}/invitations/${token}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    invite: builder.mutation<Invitation, { workspaceIds: number[], userId: number, userExist?: boolean }>({
      query: ({ workspaceIds, userId, userExist }) => {
        const body: any = { userId, workspaceIds };

        if (userExist !== undefined) {
          body.userExist = userExist;
        }

        return {
          url: `/invitations`,
          method: 'POST',
          body: body,
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        };
      },
    }),
    acceptInvitation: builder.mutation<Invitation, { id: number, token: string }>({
      query: ({ id, token }) => ({
        url: `/${id}/invitations/${token}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    deleteInvitation: builder.mutation<Invitation, { workspaceId: number, invitationId: number }>({
      query: ({ workspaceId, invitationId }) => ({
        url: `/${workspaceId}/invitations/${invitationId}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    declineInvitation: builder.mutation<Invitation, { id: number, token: string }>({
      query: ({ id, token }) => ({
        url: `/${id}/invitations/${token}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    getClientsName: builder.query<String[], null>({
      query: () => ({
        url: `/clients`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
    // Ajout de la mutation pour ajouter un client
    addClient: builder.mutation<{ message: string, client_id: number }, { name: string, domain_client: string }>({
      query: (body) => ({
        url: `/clients`,
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
      invalidatesTags: ['workspaces'],  // Invalide le cache après l'ajout
    }),
    getUserWorkspace: builder.query<WorkspaceList[], { workspace_id: number }>({
      query: ({ workspace_id }) => ({
        url: `/${workspace_id}/users`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'max-age=0',
        },
      }),
    }),
  }),
});

export const {
  useCreateWorkspaceMutation,
  useGetWorkspacesQuery,
  useGetClientsNameQuery,
  useUpdateWorkspaceMutation,
  useInvitationQuery,
  useAcceptInvitationMutation,
  useDeclineInvitationMutation,
  useGetWorkspaceQuery,
  useWorkspaceUserDeleteMutation,
  useDeleteInvitationMutation,
  useWorkspaceUserUpdateMutation,
  useInviteMutation,
  useInvitationsQuery,
  useRoleQuery,
  useLazyRoleQuery,
  useDeleteWorkspaceMutation,
  useAddClientMutation,
  useGetUserWorkspaceQuery
} = workspaces;
export default workspaces;
