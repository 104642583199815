
import { addDays, startOfWeek, format } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';

export const getFirstDayOfWeek = (year: number, week: number, locale: any) => {
    const firstDayOfYear = new Date(year, 0, 1);
    const firstDayOfWeekYear = startOfWeek(firstDayOfYear, { weekStartsOn: 1 });
    const startOfTargetWeek = addDays(firstDayOfWeekYear, (week - 1) * 7);

    return startOfTargetWeek;
}

export const formatWeekLabel = (year: number, week: number, currentLang: string) => {
    const locale = currentLang === 'fr' ? fr : enUS;
    const firstDay = getFirstDayOfWeek(year, week, locale);
    const formatString = currentLang === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';

    return format(firstDay, formatString, { locale });
}

export const formatDateLabel = (month: number, day: number, year: number, currentLang: string) => {
    return format(new Date(year, month - 1, day), currentLang === 'fr' ? 'dd MMMM yyyy' : 'MM/dd/yyyy');
}

// Nouvelle fonction pour formater la date updated_at
export const formatUpdatedAt = (dateString: string, currentLang: string) => {
    const locale = currentLang === 'fr' ? fr : enUS;
    const date = new Date(dateString);
    const formatString = currentLang === 'fr' ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm';
    return format(date, formatString, { locale });
};

// Nouvelle fonction pour formater la date updated_at
export const formatDate = (dateString: string, currentLang: string) => {
    const locale = currentLang === 'fr' ? fr : enUS;
    const date = new Date(dateString);
    const formatString = currentLang === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    return format(date, formatString, { locale });
};