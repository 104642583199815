import MenuCarousel from "src/components/mega-menu/_common/menu-carousel";
import Carousel, { CarouselDots, useCarousel } from "src/components/carousel";
import { RouterLink } from "src/routes/components";
import path from "path";
import TextMaxLine from "src/components/text-max-line";
import Image from "src/components/image";
import { Button, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Iconify from "../iconify";
import { Stack } from "rsuite";
import { useRouter } from "src/routes/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocales } from "src/locales";


export default function CarouselBrandOverview({ data }: any) {
    const router = useRouter();
    const filterStore = useSelector((state: any) => state.filters);
    const { t, currentLang } = useLocales();

    const screenWidth = window.innerWidth;
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(screenWidth <= 768);
    }, [screenWidth]);

    const colors = ["#00B8D9", "#22C55E", "#FFAB00", '#7635DC'];
    const hoverColorsBtn = ['#006C9C', '#118D57', '#B76E00', '#5119B7'];
    const cardGradients = [
        'linear-gradient(to bottom, #003768aa, #29264aaa, #28182dcc, #1c0e17ee, #000000)',
        'linear-gradient(to bottom, #065e4999, #334322aa, #342a16cc, #221612ee, #000000)',
        'linear-gradient(to bottom, #b76e0099, #96481faa, #692c24cc, #37191cee, #000000)',
        'linear-gradient(to bottom, #200a6999, #330044aa, #2e0027cc, #200013ee, #000000)'
    ];

    const handleRedirect = (index: any) => {
        const currentUrl = window.location.href;
        const urlParams = currentUrl.split('=');
        const workspaceId = urlParams[1];

        if (index == 0 || index == 1) {
            router.push(`/dash/business-overview?workspace=${workspaceId}`);
        } else {
            router.push(`/dash/traffic-analysis?workspace=${workspaceId}`);
        }
    };

    const carousel = useCarousel({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        ...CarouselDots({
            sx: (theme) => ({
                position: "absolute",
                bottom: 20,
                width: "auto",
                right: 10,
                paddingBottom: 1,
                "& .slick-active": {
                    color: colors[carousel.currentIndex],
                },
                "> li": {
                    color: colors[carousel.currentIndex],
                },
                "& .slick-dots li": {
                    margin: "0 4px",
                },
                "& .slick-dots li button": {
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    transition: "all 0.3s ease",
                },
            }),
        }),
    });

    return (
        <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings} adaptiveHeight={true} draggable={false}>
            {data && data.map((product: any, index: any) => {
                const comparisonValue = parseFloat(product.comparison.replace('%', '').replace(',', '.'));

                let trendIcon = '';
                let trendColor = '';
                let trendBg = '';

                if (comparisonValue > 0) {
                    trendIcon = 'eva:trending-up-fill';
                    trendColor = '#5AAE6A';
                    trendBg = '#1E3026';
                } else {
                    trendIcon = 'eva:trending-down-fill';
                    trendColor = '#ffac82';
                    trendBg = '#412d2e';
                }

                return (
                    <Box key={index} sx={{ textAlign: 'center', flex: 1, position: 'relative', }}>
                        <Box
                            sx={{
                                height: '365px',
                                borderRadius: 2,
                                display: 'block',
                                position: 'relative',
                                overflow: 'hidden',
                                background: `${cardGradients[carousel.currentIndex]}, url(${product.coverUrl})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '40%', // Height of the gradient overlay
                                    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                    padding: '10px',
                                    paddingBottom: 3,
                                    zIndex: 1, // Ensure this is below the text
                                }}
                            >
                                <Box sx={{ display: 'block', justifyContent: 'flex-start' }}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bolder',
                                            color: 'gray',
                                            paddingLeft: '4px',
                                            textAlign: 'start',
                                            zIndex: 2, // Ensure text is above the gradient
                                        }}
                                    >
                                        {product.type.toUpperCase()}
                                    </Typography>

                                    <TextMaxLine
                                        variant="h5"
                                        sx={{
                                            fontWeight: 'fontWeightSemiBold',
                                            textAlign: 'start',
                                            color: 'white', // Adjust color to ensure readability
                                            padding: '4px', // Add padding for better spacing
                                            marginTop: '4px', // Optional: space between text elements
                                            zIndex: 2, // Ensure text is above the gradient
                                        }}
                                    >
                                        {product.name}
                                    </TextMaxLine>

                                    {filterStore?.compare === true &&
                                        <Stack direction="row" spacing={2} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 5, marginBottom: 2, marginLeft: 5 }}>
                                            <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                <Iconify fontSize={{ xs: 10, lg: 15, flex: 1, backgroundColor: trendBg, padding: 2 }} icon={trendIcon} sx={{ color: trendColor, borderRadius: 50, }} />
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 'fontWeightSemiBold', color: 'white' }}>
                                                {product.comparison}
                                            </Typography>
                                            <Typography variant="body2" sx={{ fontWeight: 'fontWeightSemiBold', color: 'gray' }}>
                                                {t('dashboard.brandOverview.vs_comparison_period')}
                                            </Typography>
                                        </Stack>
                                    }

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingX: 1, marginTop: 3 }}>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleRedirect(index)}
                                            sx={{
                                                borderRadius: 1,
                                                backgroundColor: colors[carousel.currentIndex],
                                                color: index === 2 ? '#000' : '#fff',
                                                '&:hover': {
                                                    backgroundColor: hoverColorsBtn[carousel.currentIndex],
                                                    boxShadow: 'none',
                                                },
                                            }}
                                        >
                                            {index === 0 || index === 1 ? isMobile ? t('nav.businessOverview') : t('dashboard.brandOverview.btn_business_overview') : isMobile ? t('nav.trafficAnalysis') : t('dashboard.brandOverview.btn_traffic_analysis')}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                );
            })}
        </Carousel>
    )
}