import { useState, useCallback, useEffect } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { fPercent } from 'src/utils/format-number';
import { useRouter } from 'src/routes/hooks';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useLocales } from 'src/locales';

const createColumns = (router: any, t: any, workspace:any): GridColDef[] => [
  {
    field: 'Image',
    headerName: "",
    width: 70,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <Box component="img" src={workspace.includes('Demo') ? '/assets/logo.png' :`https://www.google.com/s2/favicons?domain=${params?.row?.domain}&sz=64`} sx={{ width: 25, height: 25 }} />
      </Stack>
    ),
  },
  {
    field: 'domain',
    headerName: t('dashboard.global.domain'),
    flex: 1,
  },
  {
    field: 'presenceRate',
    headerName: t('dashboard.competitorsMonitoring.presenceRate'),
    flex: 1,
    width: 160,
    renderCell: (params) => (
      <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
        <LinearProgress
          value={params.row.presenceRate || 0}
          variant="determinate"
          color={
            (Number.isNaN(params.row.presenceRate) && 'success') ||
            (params.row.presenceRate < 30 && 'success') ||
            (params.row.presenceRate >= 30 && params.row.presenceRate < 70 && 'warning') ||
            'error'
          }
          sx={{ width: 1, height: 6 }}
        />
        <Typography variant="caption" sx={{ width: 80 }}>
          {params.row.presenceRate ? fPercent(params.row.presenceRate) : '0%'}
        </Typography>
      </Stack>
    ),
  },
];

type Props = {
  data: {
    id: string;
    role: string;
    email: string;
    profile: {
      firstname: string;
      lastname: string;
    }
  }[];
  pageSize: number;
};

export default function DataGridCustomPresence({ data, pageSize }: Props) {
  const router = useRouter();
  const workspace = useCurrentWorkspace();
  const workspaceId = workspace?.id || 1;
  const { t } = useLocales();
  
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: true,
  });

  const [paginationModel, setPaginationModel] = useState({

    pageSize: pageSize,

    page: 0,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const handlePaginationModelChange = (newModel: any) => {
    setPaginationModel(newModel);
  };

  const handleRowClick = (params: any) => {
    router.push(`/dash/competitor/${params?.row?.domain}/?workspace=${workspaceId}`);
  };

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-cell': {
          cursor: 'pointer',
        },
      }}
    >
      <DataGridPro
        checkboxSelection={false}
        disableRowSelectionOnClick
        rows={data}
        pagination
        paginationModel={paginationModel}
        columns={createColumns(router, t,workspace?.name)}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={handleRowClick}
        disableDensitySelector
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </Box>
  );
}
