import { alpha, createTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'tertiary';

declare module '@mui/material/styles/createPalette' {

  interface Palette {
    tertiary: PaletteColor,
    darker: PaletteColor,
    default: PaletteColor,
  }
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#006c9c',
  light: '#5BE49B',
  main: '#00A76F',
  dark: '#006c9c',
  darker: '#004B50',
  contrastText: '#FFFFFF',
};

const DEFAULT = {
  lighter: '#FFFFFF',
  light: '#FFFFFF',
  main: '#FFFFFF',
  dark: '#FFFFFF',
  darker: '#FFFFFF',
  contrastText: '#FFFFFF',
};


const DARKER = {
  lighter: '#212b36',
  light: '#212b36',
  main: '#212b36',
  dark: '#212b36',
  darker: '#212b36',
  contrastText: '#212b36',
};

const TERTIARY = {
  lighter: '#cafdf5',
  light: '#006c9c',
  main: '#68CDF9',
  dark: '#006c9c',
  darker: '#006c9c',
  contrastText: '#006c9c',
};


const SECONDARY = {
  lighter: '#fad1e7',
  light: '#fad1e7',
  main: '#f73378',
  dark: '#880e4f',
  darker: '#880e4f',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#efd6ff',
  light: '#5119b7',
  main: '#8E33FF',
  dark: '#5119b7',
  darker: '#5119b7',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#d3fcd2',
  light: '#118d57',
  main: '#22C55E',
  dark: '#118d57',
  darker: '#065E49',
  contrastText: '#ffffff',
};

const WARNING = {
  lighter: '#fff5cc',
  light: '#ffab00',
  main: '#FFD666',
  dark: '#ffab00',
  darker: '#ffab00',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#ffe9d5',
  light: '#b71d18',
  main: '#FF5630',
  dark: '#b71d18',
  darker: '#b71d18',
  contrastText: '#FFFFFF',
};

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  default: DEFAULT,
  tertiary: TERTIARY,
  darker: DARKER,
  grey: GREY,
  divider: alpha(GREY[500], 0.2),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const facebook = createTheme().palette.augmentColor({
  color: { main: '#1877F2', lighter: '#1889f2', darker: '#183cf2' },
});

const lightbutton = createTheme().palette.augmentColor({
  color: { main: '#dedede', lighter: '#d9d9d9', darker: '#a6a6a6' },
});

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: GREY[200],
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
    facebook,
    lightbutton,
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    facebook,
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.12),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return mode === 'light' ? light : dark;
}
