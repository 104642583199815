import { useCurrentWorkspace } from "src/context/reducers/app-settings"
import CustomEcommerce from "./businessOverview/customEcommerce";
import StandartEcommerce from "./businessOverview/standartEcommerce";
import StandartLead from "./businessOverview/standartLead";
import CustomLead from "./businessOverview/customLead";

export default function businessOverview() {
    const workspace = useCurrentWorkspace();

    return (
        <>

            {workspace?.custom === true && workspace?.template === "E-commerce" &&
                <CustomEcommerce />
            }

            {workspace?.custom === false && workspace?.template === "E-commerce" &&
                <StandartEcommerce />
            }

            {workspace?.custom === true && workspace?.template === "Lead" &&
                <CustomLead />
            }

            {workspace?.custom === false && workspace?.template === "Lead" &&
                <StandartLead />
            }


        </>

    )
}