import { Box, Container, Grid, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import Welcome from "../welcome";
import { MotivationIllustration } from "src/assets/illustrations";
import { useResponsive } from "src/hooks/use-responsive";
import { setFilterLoadedPlatform, setFilterMinDatePicker } from "src/context/reducers/filter-settings/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useGetPlatformsStandartQuery, useGetStatsStandartEcommerceQuery } from "src/context/api/businessOverview/api";
import { fDate } from "src/utils/format-time";
import { useCurrentWorkspace } from "src/context/reducers/app-settings";
import { SplashScreen } from "src/components/loading-screen";
import Iconify from "src/components/iconify";
import BankingWidgetSummary from "../banking-widget-summary";
import ChartMixedCustom from "src/sections/charts/chart-mixed-standart-ecommerce";
import ChartColumnStacked from "src/sections/charts/chart-column-standart-lead";
import { differenceInDays } from "date-fns";
import { useLocales } from "src/locales";
import { useBoolean } from "src/hooks/use-boolean";

export default function StandartEcommerce() {
    const { t, currentLang } = useLocales();
    const lgUp = useResponsive('up', 'lg');
    const dispatch = useDispatch();
    const workspace = useCurrentWorkspace();
    const filterStore = useSelector((state: any) => state.filters);
    const theme = useTheme()
    const disableWelcome = useBoolean();

    const skipQuery = !filterStore.start || !filterStore.end || !workspace?.id;

    const { data: platforms, error: platformsError, isFetching: isFetchingPlatforms } = useGetPlatformsStandartQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        devices: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
    },
        { skip: skipQuery }
    );

    useEffect(() => {
        if (platforms)
            dispatch(setFilterLoadedPlatform(platforms))
    }, [platforms])


    const { data: statsData, error: statsError, isFetching } = useGetStatsStandartEcommerceQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        platforms: filterStore.platforms
    },
        {
            skip: skipQuery,
        });

    const [customLabels, setCustomLabels]: any = useState<any[]>(statsData?.stats?.map((item: any) => item?.timestep));
    const [customSavingsLabels, setCustomSavingsLabels]: any = useState<any[]>(statsData?.stats_savings?.map((item: any) => item[filterStore?.timestep]));

    const getDaysDifference = (startDate: any, endDate: any) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return differenceInDays(end, start);
    }

    const getTimestep = (startDate: any, endDate: any) => {
        const daysDifference = getDaysDifference(startDate, endDate);

        if (daysDifference > 365) {
            return 'year_month';
        } else if (daysDifference > 90) {
            return 'year_week';
        } else {
            return 'date';
        }
    }

    const { data: sparkLinesData, error: sparkLinesDataError, isFetching: isFetchingSparkLinesData } = useGetStatsStandartEcommerceQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: getTimestep(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null),
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        platforms: filterStore.platforms,
    },
        {
            skip: skipQuery,
        });

    enum ColorSchema {
        PRIMARY = 'primary',
        SECONDARY = 'secondary',
        TERTIARY = 'tertiary',
        ERROR = 'error',
        WARNING = 'warning',
        INFO = 'info',
        SUCCESS = 'success'
    }

    useEffect(() => {
        dispatch(setFilterMinDatePicker(statsData?.minDatePicker[0]?.first_date))
    }, [statsData])

    const sparkLinesDataTotalAdCost = sparkLinesData?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_ad_cost || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_ad_cost || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_ad_cost || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const sparkLinesDataTotalActualSavings = sparkLinesData?.stats_savings?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_actual_savings || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_actual_savings || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_actual_savings || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const sparkLinesDataTotalBlendedRevenue = sparkLinesData?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);
        const averageCart = workspace?.averageCart ?? 0;

        if (daysDifference > 365) {
            const sum = (item?.total_blended_conversions || 0) * averageCart;
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_blended_conversions || 0) * averageCart;
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_blended_conversions || 0) * averageCart;

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const sparkLinesDataTotalBlendedCPO = sparkLinesData?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null, filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null);

        if (daysDifference > 365) {
            const sum = (item?.total_blended_conversions || 0);
            return { y: sum, x: item?.year_month };
        } else if (daysDifference > 90) {
            const sum = (item?.total_blended_conversions || 0);
            return { y: sum, x: item?.year_week };
        } else {
            const sum = (item?.total_blended_conversions || 0);

            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: sum, x: `${year}-${month}-${day}` };
        }
    });

    const sparkLinesDataTotalBlendedCPC = sparkLinesData?.stats?.map((item: any) => {
        const daysDifference = getDaysDifference(
            filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
            filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null
        );

        if (daysDifference > 365) {
            // Aggregate data yearly
            const blendedCpc = item?.total_ad_cost / item?.total_blended_conversions || 0;
            return { y: blendedCpc, x: item?.year_month };
        } else if (daysDifference > 90) {
            // Aggregate data weekly
            const blendedCpc = item?.total_ad_cost / item?.total_blended_conversions || 0;
            return { y: blendedCpc, x: item?.year_week };
        } else {
            // Aggregate data daily
            const blendedCpc = item?.total_ad_cost / item?.total_blended_conversions || 0;
            const date = new Date(item?.date);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return { y: blendedCpc, x: `${year}-${month}-${day}` };
        }
    });


    const stats = [
        {
            title: t('dashboard.global.savings'),
            value: statsData?.total_actual_savings,
            compareData: parseFloat((((statsData?.total_actual_savings - statsData?.total_actual_savings_compare) / statsData?.total_actual_savings_compare) * 100).toFixed(2)),
            color: ColorSchema.SUCCESS,
            textColor: '#118d57',
            spanColor: theme.palette.mode === "dark" ? '#6dad8d' : 'transparent',
            icon: "solar:hand-money-bold-duotone",
            series: sparkLinesDataTotalActualSavings
        },
        {
            title: t('dashboard.global.ad_cost'),
            value: statsData?.total_ad_cost,
            compareData: parseFloat((((statsData?.total_ad_cost - statsData?.total_ad_cost_compare) / statsData?.total_ad_cost_compare) * 100).toFixed(2)),
            color: ColorSchema.WARNING,
            textColor: '#b76e00',
            icon: "solar:money-bag-bold-duotone",
            spanColor: theme.palette.mode === "dark" ? '#ffd066' : 'transparent',
            series: sparkLinesDataTotalAdCost
        },
        {
            title: t('dashboard.global.blended_revenue'),
            value: parseFloat((statsData?.total_blended_conversions * (workspace?.averageCart ?? 0)).toFixed(3)),
            compareData: statsData?.total_blended_conversions_compare > 0
                ? parseFloat((((statsData?.total_blended_conversions * (workspace?.averageCart ?? 0) - statsData?.total_blended_conversions_compare * (workspace?.averageCart ?? 0)) / (statsData?.total_blended_conversions_compare * (workspace?.averageCart ?? 0))) * 100).toFixed(2))
                : 0,
            color: ColorSchema.TERTIARY,
            textColor: '#006c9c',
            spanColor: theme.palette.mode === "dark" ? '#65b5c9' : 'transparent',
            icon: "solar:dollar-bold-duotone",
            series: sparkLinesDataTotalBlendedRevenue
        },
        {
            title: t('dashboard.global.blended_cpo'),
            value: statsData?.total_blended_conversions ? parseFloat((statsData?.total_ad_cost / (statsData?.total_paid_conversions + statsData?.total_organic_conversions)).toFixed(3)) : 0,
            compareData: parseFloat((((statsData?.total_ad_cost / (statsData?.total_paid_conversions_compare + statsData?.total_organic_conversions_compare)) - (statsData?.total_ad_cost_compare / (statsData?.total_paid_conversions_compare + statsData?.total_organic_conversions_compare))) / (statsData?.total_ad_cost_compare / (statsData?.total_paid_conversions_compare + statsData?.total_organic_conversions_compare)) * 100).toFixed(2)),
            color: ColorSchema.INFO,
            textColor: '#5119b7',
            icon: "solar:tag-price-bold-duotone",
            spanColor: theme.palette.mode === "dark" ? '#9e77da' : 'transparent',
            series: sparkLinesDataTotalBlendedCPO
        },
    ]

    const blendedCpcStat = {
        title: t('dashboard.global.blended_cpc'),
        value: statsData?.total_ad_cost / statsData?.total_blended_clicks || 0,
        compareData: statsData?.total_blended_clicks_compare > 0
            ? parseFloat((((statsData?.total_ad_cost / statsData?.total_blended_clicks) - (statsData?.total_blended_clicks_compare / statsData?.total_blended_clicks_compare)) / (statsData?.total_ad_cost_compare / statsData?.total_blended_clicks_compare) * 100).toFixed(2))
            : 0,
        color: ColorSchema.ERROR,
        textColor: '#004085',
        icon: "solar:graph-bold-duotone",
        spanColor: theme.palette.mode === "dark" ? '#8fc2e6' : 'transparent',
        series: sparkLinesDataTotalBlendedCPC
    };

    const filteredStats = workspace?.showSavings
        ? stats
        : [
            ...stats.filter(item => item.title !== t('dashboard.global.savings')),
            blendedCpcStat,
        ];

    const blendedRevenueAdCost = [
        { name: t('dashboard.global.ad_cost'), data: statsData?.stats?.map((item: any) => item?.total_ad_cost ?? 0), type: "bar" },
        { name: t('dashboard.global.blended_revenue'), data: statsData?.stats?.map((item: any) => item?.total_blended_conversions * (workspace?.averageCart ?? 0)), type: "line" },
        { name: t('dashboard.global.blended_cpo'), data: statsData?.stats?.map((item: any) => (item?.total_ad_cost / (item?.total_paid_conversions + item?.total_organic_conversions))), type: "line" },
        { name: t('dashboard.global.blended_cpc'), data: statsData?.stats?.map((item: any) => item?.total_blended_cpc ?? 0), type: "line" },
        { name: t('dashboard.global.blended_roi'), data: statsData?.stats?.map((item: any) => item?.total_blended_conversions * (workspace?.averageCart ?? 0) / item?.total_ad_cost), type: "line" },
    ]

    const savingsOverTime = [
        { name: t('dashboard.business_overview.actual_savings'), data: statsData?.stats_savings?.map((item: any) => item?.total_actual_savings || 0) },
        { name: t('dashboard.business_overview.remaining_pot_savings'), data: statsData?.stats_savings?.map((item: any) => item?.total_savings || 0) },
    ]

    useEffect(() => {
        if (statsData && !isFetching) {
            if (filterStore.timestep === 'year_week') {
                const formattedLabels = statsData.stats.map((item: any) => {
                    return item?.year_week
                });
                setCustomLabels(formattedLabels);

                const formattedSavingsLabels = statsData.stats_savings.map((item: any) => {
                    return item?.year_week
                });
                setCustomSavingsLabels(formattedSavingsLabels);
            } else if (filterStore.timestep === 'year_month') {
                const formatedMontlyDates = statsData.stats.map((item: any) => {
                    const [year, week] = item[filterStore.timestep].split('-');
                    return `${week}/${year}`;
                })
                setCustomLabels(formatedMontlyDates);

                const formatedMontlySavingsDates = statsData.stats_savings.map((item: any) => {
                    const [year, week] = item[filterStore.timestep].split('-');
                    return `${week}/${year}`;
                })
                setCustomSavingsLabels(formatedMontlySavingsDates);

            } else {

                const formatedDailyDates = statsData.stats.map((item: any) => {
                    const newDate = new Date(item[filterStore.timestep]);
                    const month = String(newDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based, so we add 1
                    const day = String(newDate.getUTCDate()).padStart(2, '0');
                    const year = newDate.getUTCFullYear();
                    if (currentLang.value === 'fr') {
                        return `${day}/${month}/${year}`;
                    } else {
                        return `${month}/${day}/${year}`;
                    }
                })
                setCustomLabels(formatedDailyDates);

                const formatedDailySavingsDates = statsData.stats_savings.map((item: any) => {
                    const newDate = new Date(item[filterStore.timestep]);
                    const month = String(newDate.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based, so we add 1
                    const day = String(newDate.getUTCDate()).padStart(2, '0');
                    const year = newDate.getUTCFullYear();
                    if (currentLang.value === 'fr') {
                        return `${day}/${month}/${year}`;
                    } else {
                        return `${month}/${day}/${year}`;
                    }
                })
                setCustomSavingsLabels(formatedDailySavingsDates);

            }

        }

    }, [currentLang.value, filterStore.timestep, statsData?.stats]);

    return (
        <>
            {(!filterStore?.countries || !filterStore?.keywords || !filterStore?.devices)
                ? <SplashScreen />
                : <Box sx={{ marginTop: 1, px: 3 }}>

                    {!disableWelcome?.value &&
                        <Grid container spacing={2} sx={{ marginBottom: 3, borderRadius: 2 }}>
                            <Grid item xs={12}>
                                <Welcome
                                    onClose={() => disableWelcome.onTrue()}
                                    title={t('dashboard.global.welcome')}
                                    description={t('dashboard.business_overview.descriptionWelcome')}
                                    img={lgUp ? <MotivationIllustration /> : <></>}
                                />
                            </Grid>
                        </Grid>
                    }

                    <Grid container spacing={2}>
                        {filteredStats.map((stat, index) => (
                            <Grid item key={index} xs={6} md={4} lg={3}>
                                {isFetching || (!statsData && !statsError) ? (
                                    <Skeleton
                                        width={'100%'}
                                        height={'110px'}
                                        style={{ borderRadius: 10 }}
                                        variant="rounded"
                                        animation="wave"
                                    >
                                        <>
                                            <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
                                            <Typography color="success.main">Active</Typography>
                                        </>
                                    </Skeleton>
                                ) : (
                                    <BankingWidgetSummary
                                        spanColor={stat.spanColor}
                                        title={stat?.title}
                                        compareData={stat?.compareData}
                                        textColor={stat?.textColor}
                                        icon={stat?.icon}
                                        color={stat.color}
                                        percent={0}
                                        total={stat?.value}
                                        chart={{
                                            series: stat.series,
                                        }}
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>


                    <Grid item xs={12} md={6} lg={6}>
                        <Paper
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                height: '500px',
                                borderRadius: 2,
                                marginTop: 3,
                                marginBottom: 1
                            }}
                        >

                            {isFetching || (!statsData && !statsError)
                                ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                    <>
                                        <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                        <Typography color={"success.main"}>Active</Typography>
                                    </>
                                </Skeleton>
                                : <div>
                                    <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                        <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.business_overview.business')}</Typography>

                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            {t('dashboard.business_overview.blended_revenue_ad_cost')}
                                        </Typography>

                                        <Typography fontSize={16} sx={{ mb: 5, color: "#999999", opacity: .7 }}>
                                            {t('dashboard.business_overview.evolution_of_your_ad_costs_and_blended')}
                                        </Typography>

                                    </div>

                                    <Box sx={{ paddingLeft: 2 }}>
                                        <ChartMixedCustom
                                            series={blendedRevenueAdCost}
                                            labels={customLabels}
                                        />
                                    </Box>

                                </div>
                            }
                        </Paper>
                    </Grid>

                    {workspace?.showSavings &&
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper
                                sx={{
                                    backgroundColor: theme.palette.mode === "dark" ? '#212b36' : 'white',
                                    boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                    height: '600px',
                                    borderRadius: 2,
                                    marginTop: 2,
                                    marginBottom: 1
                                }}
                            >

                                {isFetching || (!statsData && !statsError)
                                    ? <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: 10 }} variant="rectangular" animation={"wave"}>
                                        <>
                                            <Iconify py={"auto"} icon={"eva:checkmark-circle-2-fill"} color={"success.main"} />
                                            <Typography color={"success.main"}>Active</Typography>
                                        </>
                                    </Skeleton>
                                    : <div>
                                        <div style={{ paddingTop: 18, paddingLeft: 25, paddingRight: 25 }}>
                                            <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>{t('dashboard.business_overview.business')}</Typography>

                                            <Typography variant="h6" sx={{ mb: 1 }}>
                                                {t('dashboard.business_overview.savings_over_time')}
                                            </Typography>

                                            <Typography fontSize={16} sx={{ mb: 5, color: "#999999", opacity: .7 }}>
                                                {t('dashboard.business_overview.evolution_brend_spends_savings_over_time')}
                                            </Typography>

                                        </div>

                                        <Box sx={{ paddingLeft: 2, paddingRight: 4 }}>
                                            <ChartColumnStacked
                                                series={savingsOverTime}
                                                labels={customSavingsLabels}
                                            />
                                        </Box>

                                    </div>
                                }
                            </Paper>
                        </Grid>
                    }
                </Box>
            }
        </>

    )
}
