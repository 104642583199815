// types.ts
export type NumericValue = string | number | null | undefined;

export interface FormatOptions {
  style?: 'decimal' | 'currency' | 'percent' | 'compact';
  currency?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  notation?: 'standard' | 'scientific' | 'engineering' | 'compact';
}

// format-number.ts
const DEFAULT_LOCALE = 'en';
const FALLBACK_VALUE = '0';

export class NumberFormatter {
  static getCurrentLocale = (): string => {
    return localStorage.getItem('i18nextLng') || DEFAULT_LOCALE;
  };

  static parseNumber = (value: NumericValue): number => {
    if (value === null || value === undefined || value === '') return 0;
    const parsed = Number(value);
    // Return 0 instead of throwing for NaN
    if (isNaN(parsed)) return 0;
    return parsed;
  };

  static formatWithLocale = (value: number, options: FormatOptions = {}): string => {
    const locale = this.getCurrentLocale();
    // Handle NaN case early
    if (isNaN(value)) return FALLBACK_VALUE;
    
    const { style, ...restOptions } = options;
    const formatOptions: Intl.NumberFormatOptions = {
      ...restOptions,
      style: style === 'compact' ? undefined : style,
      notation: style === 'compact' ? 'compact' : options.notation,
    };
    
    try {
      let formatted = value.toLocaleString(locale, formatOptions);
      return locale === 'fr' ? formatted.replace('.', ',') : formatted;
    } catch (error) {
      console.error('Error formatting number:', error);
      return FALLBACK_VALUE;
    }
  };

  static format = (value: NumericValue): string => {
    return this.formatWithLocale(this.parseNumber(value));
  };

  static formatPercent = (value: NumericValue): string => {
    return this.formatWithLocale(this.parseNumber(value) / 100, {
      style: 'percent',
      minimumFractionDigits: 1
    });
  };

  static formatCompact = (value: NumericValue): string => {
    return this.formatWithLocale(this.parseNumber(value), {
      notation: 'compact',
      maximumFractionDigits: 1
    });
  };
}

// Legacy API compatibility
export const fNumber = NumberFormatter.format;
export const fPercent = NumberFormatter.formatPercent;
export const fShortenNumber = NumberFormatter.formatCompact;
export const fData = NumberFormatter.formatCompact;
export const formatNumber = NumberFormatter.formatCompact;