import { useState, useEffect, useCallback } from 'react';
// @mui
import Collapse from '@mui/material/Collapse';
// routes
import { usePathname } from 'src/routes/hooks';
import { useActiveLink } from 'src/routes/hooks/use-active-link';
//
import { NavListProps, NavConfigProps } from '../types';
import NavItem from './nav-item';

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
  config: NavConfigProps;
  defaultOpen?: boolean;
  lastChildren: any;
};

export default function NavList({ data, depth, hasChild, config, defaultOpen, lastChildren }: NavListRootProps) {
  const pathname = usePathname();
  const active = useActiveLink(data.path, hasChild);
  const externalLink = data.path.includes('http');

  const [open, setOpen] = useState(active);
  const [urlToogle, setUrlToogle] = useState<string | undefined>(undefined);

  // Mettre à jour urlToogle à chaque fois que pathname change
  useEffect(() => {
    if (active) {
      setUrlToogle(data.path); // Mettre à jour urlToogle uniquement si l'élément est actif
    }
  }, [pathname, active, data.path]);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev); // On inverse l'état de l'ouverture
    if (!open) {
      setUrlToogle(data.path); // Mettre à jour urlToogle quand on ouvre
    } else {
      setUrlToogle(undefined); // Réinitialiser quand on ferme
    }
  }, [open, data.path]);

  const handleClose = useCallback(() => {
    if (defaultOpen !== true) {
      setOpen(false);
      setUrlToogle(undefined); // Réinitialiser lorsque fermé
    }
  }, [defaultOpen]);

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        style={{ color: 'white' }}
        open={defaultOpen || open}
        active={active}
        externalLink={externalLink}
        onClick={handleToggle}
        isChild={depth > 1}
        isLastChild={lastChildren}
        config={config}
        urlToogle={urlToogle} // Passer l'URL ici
      />

      {hasChild && (
        <Collapse in={defaultOpen || open} unmountOnExit>
          <NavSubList data={data.children} depth={depth} config={config} />
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
  config: NavConfigProps;
};

function NavSubList({ data, depth, config }: NavListSubProps) {
  return (
    <>
      {data.map((list: any) => (
        <NavList
          key={list.title + list.path}
          lastChildren={list.lastChildren}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
    </>
  );
}
