import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const businessOverview = createApi({
    baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
    reducerPath: 'api.businessOverview',
    tagTypes: ['businessOverview'],
    endpoints: (builder) => ({
        getStatsStandartLead: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], timestep: string, workspaceId: any | null, platforms: string[] | null }>({
            query: ({ startDate, endDate, device, countries, keywords, timestep, compareStartDate, compareEndDate, workspaceId, platforms }) => {
                const url = `/businessOverview/getStatsStandartLead`;
                const body = {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    device,
                    countries,
                    keywords,
                    timestep,
                    workspaceId,
                    platforms
                };

                return {
                    url,
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        Accept: 'application/json',
                    },
                };
            },
        }),
        getStatsStandartEcommerce: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], timestep: string, workspaceId: any | null, platforms: string[] | null }>({
            query: ({ startDate, endDate, device, countries, keywords, timestep, compareStartDate, compareEndDate, workspaceId, platforms }) => {
                const url = `/businessOverview/getStatsStandartEcommerce`;
                const body = {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    device,
                    countries,
                    keywords,
                    timestep,
                    workspaceId,
                    platforms
                };

                return {
                    url,
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        Accept: 'application/json',
                    },
                };
            },
        }),
        getStatsCustomEcommerce: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], timestep: string, workspaceId: any | null, platforms: string[] | null }>({
            query: ({ startDate, endDate, device, countries, keywords, timestep, compareStartDate, compareEndDate, workspaceId, platforms }) => {
                const url = `/businessOverview/getStatsCustomEcommerce`;
                const body = {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    device,
                    countries,
                    keywords,
                    timestep,
                    workspaceId,
                    platforms
                };

                return {
                    url,
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        Accept: 'application/json',
                    },
                };
            },
        }),
        getStatsCustomLead: builder.query<any, { compareStartDate: string | null, compareEndDate: string | null, startDate: string | null, endDate: string | null, device: string[] | null, countries: string[], keywords: string[], timestep: string, workspaceId: any | null, platforms: any }>({
            query: ({ startDate, endDate, device, countries, keywords, timestep, compareStartDate, compareEndDate, workspaceId, platforms }) => {
                const url = `/businessOverview/getStatsCustomLead`;
                const body = {
                    startDate,
                    endDate,
                    compareStartDate,
                    compareEndDate,
                    device,
                    countries,
                    keywords,
                    timestep,
                    workspaceId,
                    platforms,
                };

                return {
                    url,
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        Accept: 'application/json',
                    },
                };
            },
        }),
        getPlatformsStandart: builder.query<any, { startDate: string | null, endDate: string | null, countries: string[], keywords: string[], devices: string[], workspaceId: any }>({
            query: ({ startDate, endDate, countries, keywords, devices, workspaceId }) => {
                const url = `/businessOverview/getPlatformsStandart`;
                const body = {
                    startDate,
                    endDate,
                    keywords,
                    countries,
                    devices,
                    workspaceId
                };

                return {
                    url,
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        Accept: 'application/json',
                    },
                };
            },
        }),
        getPlatformsCustom: builder.query<any, { startDate: string | null, endDate: string | null, countries: string[], keywords: string[], devices: string[], workspaceId: any }>({
            query: ({ startDate, endDate, countries, keywords, devices, workspaceId }) => {
                const url = `/businessOverview/getPlatformsCustom`;
                const body = {
                    startDate,
                    endDate,
                    keywords,
                    countries,
                    devices,
                    workspaceId
                };

                return {
                    url,
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        Accept: 'application/json',
                    },
                };
            },
        })
    }),
});

export const {
    useGetStatsStandartLeadQuery,
    useGetStatsStandartEcommerceQuery,
    useGetStatsCustomEcommerceQuery,
    useGetStatsCustomLeadQuery,
    useGetPlatformsStandartQuery,
    useGetPlatformsCustomQuery
} = businessOverview;

export default businessOverview;
