import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { IconButton, InputAdornment, Link, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { use } from 'i18next';
import { jwtDecode } from 'jwt-decode';
import { set } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import Iconify from 'src/components/iconify';
import { useForgotPasswordMutation,useResetPasswordMutation,useVerifyTokenMutation } from 'src/context/api/auth';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import { useRouter } from 'src/routes/hooks';
import * as Yup from 'yup';

export default function ResetPassword() {
  const { t } = useLocales();
  const [forgotPassword] = useForgotPasswordMutation();
  const [resetPassword, response] = useResetPasswordMutation();
  const [verifyToken, responseVerify] = useVerifyTokenMutation();
  const [tokenValid, setTokenValid] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const  [validToken, setValidToken] = useState<string | null>(null);
  const router = useRouter();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const schema = Yup.object({
    email: Yup.string().required(t('fieldRequired', { field: t("auth.form.signin.Email") })),
    password: Yup.string().required(t('fieldRequired', { field: t("auth.form.signin.password") })),
    password_confirmation: Yup.string()
    .oneOf([Yup.ref('password')], t('fieldRequired', { field: t("auth.form.signin.password_confirmation_must_match") }))
    .required(t('fieldRequired', { field: t("auth.form.signin.password_confirmation") })),
  });

  const password = useBoolean();
  const passwordConfirmation = useBoolean();
  const [email, setEmail] = useState<string >('');

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
        email: '',
      password: '',
      password_confirmation: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const [searchParams] = useSearchParams();
  const tokenContent = searchParams.get('token');

  useEffect(() => {
    if (tokenContent) {
      // Vérifiez le token auprès de l'API
      verifyToken({ token: tokenContent })
        .then((response) => {
          
         
          const data: any = Object.values(response)[0];
          const message = data.message;
          const token = data.token;
          const user_email = data.user_email;
          const status = data.status;
        
          if (status === 400 ) {
            setTokenValid(false);
            setIsExpired(true);
            setErrorMsg(t('auth.token_expired'));
          } 
           else if (status === 200 && user_email && token) {
            setEmail(user_email);
            methods.setValue('email', user_email);
            setValidToken(token);
          } 
        })
        .catch(error => {
          console.error('Error :', error);
          setTokenValid(false);
          // setErrorMsg(t('auth.token_invalid'));
        });
    } else {
      setTokenValid(false);
      // setErrorMsg(t('auth.token_missing'));
    }
  }, [tokenContent, verifyToken, methods, t]);


  useEffect(() => {
    const error = response.error as any;
    if (response.isError) {
      const reason = error?.data.message || 'UnknownError';
      setErrorMsg(t('auth.' + reason));
    } 
    
  }, [response]);


  const handleReset= async (data: { password: string, password_confirmation: string}) => {
    try {
      if (validToken) { 
        await resetPassword({
          password: data.password,
          confirm_password: data.password_confirmation,
          token: validToken,
        })
          enqueueSnackbar(t('auth.form.reset_success'), { variant: 'success' });
          router.push('/auth/signin'); // Redirection après succès
      }
      
    } catch (error) {
      setErrorMsg(t('auth.form.reset_failed')); // Message d'erreur personnalisé
      enqueueSnackbar(t('auth.form.reset_failed'), { variant: 'error' });
    }
  };
  

  return (
<div style={{
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'url(/assets/background/background.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}}>
  <div style={{
    width: '100%',
    maxWidth: '400px',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '40px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    position: 'relative', // Ajouté pour positionner le contenu absolu
  }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
          <div style={{
            width: '100px',
            height: '100px',

            position: 'relative',
            borderRadius: '50%',
            overflow: 'hidden',
          }}>
            <div style={{
              width: '100%',
              height: '100%',
              backgroundImage: 'linear-gradient(180deg, rgba(0, 167, 111, 1) -100%, rgba(0, 167, 111, 0.22) 100%)',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 0,
              opacity: 0.3,
              borderRadius: '50%',
            }}>

            </div>
            <div style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              zIndex: 1,
            }}>
              <img
                src='/assets/logo.png'
                alt="Logo"
                style={{
                  width: '60px',
                  height: 'auto',
                }}
              />
            </div>
          </div>
        </div>

    {/* Formulaire Forgot Password */}
    {
      isExpired ? (
         
         <Box sx={{ display: 'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',height:150,width:'100%'}}>
          <Typography variant="h4" style={{ color: '#1C252E', textAlign: 'center' }}>
            Your token has expired
          </Typography>
          <Typography variant="subtitle2" style={{ color: '#1C252E', textAlign: 'center' }}>
            Please request a new one
          </Typography>
          <Stack direction="row" spacing={0.5} justifyContent="center">
            <Link href={'/auth/signin'} component={RouterLink} variant="subtitle2" sx={{ color: "#00A76F", textDecoration: 'none' }}>
              {t('auth.form.back_to_signin')}
            </Link>
          </Stack>
         </Box>
        
     ) : (
         <FormProvider methods={methods} onSubmit={handleSubmit(handleReset)}>
         <Stack spacing={2} sx={{ mb: 5 }}>
           <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
             <Typography variant="h4" style={{ color: '#1C252E', textAlign: 'center' }}>
               {t('auth.form.forgot')}
             </Typography>
   
             <Stack direction="row" spacing={0.5} justifyContent="center">
               <Link href={'/auth/signin'} component={RouterLink} variant="subtitle2" sx={{ color: "#00A76F", textDecoration: 'none' }}>
                 {t('auth.form.back_to_signin')}
               </Link>
             </Stack>
           </Stack>
           
           {/* Champ d'email */}
           <RHFTextField
                
                 disabled
                name="email"
                value={email}
                label={t('auth.form.signin.Email')}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    color: '#1C252E',
                    '&.Mui-focused': {
                      color: '#1C252E !important',
                    },
                  },
                }}
                InputProps={{
                  sx: {
                    color: '#1C252E',
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#637381',
                    },
                    '&:hover fieldset': {
                      borderColor: '#637381',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#4c5b6e',
                    },
                    '& input:-webkit-autofill': {
                      '-webkit-box-shadow': '0 0 0 1000px white inset',
                      '-webkit-text-fill-color': '#1C252E',
                    },
                  },
                }}
              />
   
   
           <RHFTextField
                 
                 name="password"
                 label={t('auth.form.signin.password')}
                 type={password.value ? 'text' : 'password'}
                 
                 InputProps={{
                   endAdornment: (
                     <InputAdornment position="end">
                       <IconButton onClick={password.onToggle} edge="end">
                         <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                       </IconButton>
                     </InputAdornment>
                   ),
                   sx: {
                     color: '#1C252E'
                   },
                 }}
                 InputLabelProps={{
                   shrink: true,
                   sx: {
                     color: '#1C252E',
                     '&.Mui-focused': {
                       color: '#1C252E !important',
                     },
                   },
                 }}
                 sx={{
                   '& .MuiOutlinedInput-root': {
                     '& fieldset': {
                       borderColor: '#637381',
                     },
                     '&:hover fieldset': {
                       borderColor: '#637381',
                     },
                     '&.Mui-focused fieldset': {
                       borderColor: '#4c5b6e',
                     },
                   },
                 }}
               />
   
   <RHFTextField
                 
                 name="password_confirmation"
                 label={t('auth.form.password_confirmation')}
                 type={passwordConfirmation.value ? 'text' : 'password'}
                 InputProps={{
                   endAdornment: (
                     <InputAdornment position="end">
                       <IconButton onClick={passwordConfirmation.onToggle} edge="end">
                         <Iconify icon={passwordConfirmation.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                       </IconButton>
                     </InputAdornment>
                   ),
                   sx: {
                     color: '#1C252E'
                   },
                 }}
                 InputLabelProps={{
                   shrink: true,
                   sx: {
                     color: '#1C252E',
                     '&.Mui-focused': {
                       color: '#1C252E !important',
                     },
                   },
                 }}
                 sx={{
                   '& .MuiOutlinedInput-root': {
                     '& fieldset': {
                       borderColor: '#637381',
                     },
                     '&:hover fieldset': {
                       borderColor: '#637381',
                     },
                     '&.Mui-focused fieldset': {
                       borderColor: '#4c5b6e',
                     },
                   },
                 }}
               />
   
           {/* Bouton pour soumettre */}
           <LoadingButton
             color="inherit"
             size="large"
             type="submit"
             variant="contained"
             fullWidth
             loading={isSubmitting}
             sx={{ backgroundColor: '#1C252E', color: 'white' }}
           >
             {t('auth.form.resquest_reset')}
           </LoadingButton>
         </Stack>
       </FormProvider>
      )
    }
   
  </div>
</div>

  );
}
