import { useState, useCallback, useEffect, useRef } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
// _mock
import { _userAbout, _userFeeds, _userFriends, _userGallery, _userFollowers } from 'src/_mock';
// components
import Iconify from 'src/components/iconify';
//
import ProfileHome from './profile/profile-home';
import ProfileCover from './profile/profile-cover';
import { useParams } from 'react-router';
import { useGetAdsPlaQuery, useGetAdsTxtQuery, useGetBrandQuery, useGetCompetitorFilterQuery, useGetPresenceSpecificDomainQuery } from 'src/context/api/competitorsMonitoring/api';
import { useDispatch, useSelector } from 'react-redux';
import { fDate } from 'src/utils/format-time';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { useLocales } from 'src/locales';
import TextAds from './text-ads';
import PlaAds from './pla-ads';
import { setFilterLoadedCountry, setFilterLoadedDevice, setFilterLoadedKeyword, setFilterMinDatePicker } from 'src/context/reducers/filter-settings/filterSlice';

// ----------------------------------------------------------------------

export default function Competitor() {
    const { domain } = useParams() as { domain: string };
    const [currentTab, setCurrentTab] = useState('profile');
    const filterStore = useSelector((state: any) => state.filters);
    const workspace: any = useCurrentWorkspace();
    const { t } = useLocales();
    const dispatch = useDispatch();
    const isFirstLoad = useRef(true);

    const skipQuery = !filterStore.start || !filterStore.end || !workspace?.bqTable;

    const { data: ads, error: statsError, isFetching } = useGetAdsPlaQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        device: filterStore.devices.map((device: string) => device.toLowerCase()),
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        domain: domain,
        workspaceTable: workspace?.bqTable,
        platform: filterStore?.platforms
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,
        }
    );

    const { data: txtAds, error: statsErrorTxtAds, isFetching: isFetchingTxtAds } = useGetAdsTxtQuery({
        startDate: filterStore.start ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        domain: domain,
        device: filterStore.devices.map((device: string) => device.toLowerCase()),
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceTable: workspace?.bqTable,
        platform: filterStore?.platforms
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,
        }
    );

    const TABS = [
        {
            value: 'profile',
            label: t('dashboard.global.profile'),
            icon: <Iconify icon="solar:user-id-bold" width={24} />,
        },
        ...(txtAds?.length > 0
            ? [{
                value: 'Text Ads',
                label: 'Text Ads',
                icon: <Iconify icon="fa-solid:ad" width={24} />,
            }]
            : []),
        ...(ads?.ads?.length > 0
            ? [{
                value: 'Product Listing Ads',
                label: 'Product Listing Ads',
                icon: <Iconify icon="ion:bag-handle" width={24} />,
            }]
            : [])
    ];

    const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    }, []);

    const { data: presenceData, error: dataPresenceError, isFetching: isFetchingDataPresence } = useGetPresenceSpecificDomainQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        compareStartDate: filterStore.compareStart !== undefined ? fDate(filterStore.compareStart, "yyyy-MM-dd") : null,
        compareEndDate: filterStore.compareEnd !== undefined ? fDate(filterStore.compareEnd, "yyyy-MM-dd") : null,
        timestep: filterStore.timestep,
        device: filterStore.devices,
        countries: filterStore.countries,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        additionalDomain: domain,
        platform: filterStore?.platforms
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,
        }
    );

    const { data: competitorData, error: competitorError } = useGetCompetitorFilterQuery({
        startDate: filterStore.start !== undefined ? fDate(filterStore.start, "yyyy-MM-dd") : null,
        endDate: filterStore.end !== undefined ? fDate(filterStore.end, "yyyy-MM-dd") : null,
        keywords: filterStore.keywords,
        workspaceId: workspace?.id,
        domain: domain,
        platform: filterStore?.platforms
    },
        {
            skip: skipQuery,
            refetchOnMountOrArgChange: false,
        }
    );


    useEffect(() => {
        if (presenceData?.minDatePicker) {
            dispatch(setFilterMinDatePicker(presenceData.minDatePicker[0]?.first_date));
        }
    }, [presenceData, dispatch]);

    const { data: brandData, error, isLoading } = useGetBrandQuery({ domain });
    const [hasIcon, setHasIcon] = useState(false);
    const [Icon, setIcon] = useState([]);
    const [Logo, setLogo] = useState([]);

    const DemoInfo = {
        "claimed": false,
        "colors": [
            {
                "brightness": 56,
                "hex": "#ff0018",
                "type": "accent"
            }
        ],
        "company": {
            "employees": '100',
            "foundedYear": 2019,
            "industries": [
                {
                    "emoji": "💰",
                    "id": "50",
                    "name": "SEO",
                    "parent": null,
                    "score": 0.5927721261978149,
                    "slug": "SEO"
                },

            ],
            "kind": null,
            "location": {
                "city": 'Paris',
                "country": 'France',
                "countryCode": 'FR',
                "region": null,
                "state": null,
                "subregion": null
            }
        },
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu pharetra leo. Integer ultricies ac.",
        "domain": workspace?.url,
        "fonts": [
            {
                "name": "Foco",
                "origin": "custom",
                "originId": null,
                "type": "title",
                "weights": []
            },
            {
                "name": "Source Sans Pro",
                "origin": "google",
                "originId": "Source+Sans+Pro",
                "type": "body",
                "weights": []
            }
        ],

        "images": [
            {
                "formats": [
                    {
                        "background": null,
                        "format": "jpeg",
                        "height": 500,
                        "size": 131645,
                        "src": "",
                        "width": 1500
                    }
                ],
                "tags": [],
                "type": "banner"
            }
        ],
        "isNsfw": false,
        "links": [
            {
                "name": "youtube",
                "url": '#'
                // "url": "https://youtube.com/user/canadalifeca"
            },
            {
                "name": "twitter",
                "url": '#'
                // "url": "https://twitter.com/canadalifeco"
            },
            {
                "name": "instagram",
                "url": '#'
                // "url": "https://instagram.com/canadalifeco"
            },
            {
                "name": "linkedin",
                "url": '#'
                // "url": "https://linkedin.com/company/canada-life"
            },
            {
                "name": "facebook",
                "url": '#'
                // "url": "https://facebook.com/CanadaLifeCo"
            }
        ],
        "logos": [
            {
                "formats": [
                    {
                        "background": null,
                        "format": "jpeg",
                        "height": 400,
                        "size": 17439,
                        "src": "/assets/logo.png",
                        "width": 400
                    }
                ],
                "tags": [],
                "theme": "dark",
                "type": "icon"
            },
            {
                "formats": [
                    {
                        "background": "transparent",
                        "format": "svg",
                        "height": null,
                        "size": 12087,
                        "src": "/assets/logo.png",
                        "width": null
                    },
                    {
                        "background": "transparent",
                        "format": "png",
                        "height": 276,
                        "size": 25721,
                        "src": "/assets/logo.png",
                        "width": 800
                    }
                ],
                "tags": [],
                "theme": "dark",
                "type": "logo"
            },
            {
                "formats": [
                    {
                        "background": "transparent",
                        "format": "svg",
                        "size": 3745,
                        "src": "/assets/logo.png"
                    },
                    {
                        "background": "transparent",
                        "format": "png",
                        "height": 800,
                        "size": 53396,
                        "src": "/assets/logo.png",
                        "width": 800
                    }
                ],
                "tags": [],
                "theme": "dark",
                "type": "symbol"
            }
        ],
        "name": workspace?.name,
    }

    useEffect(() => {

        if (!brandData?.logos) return;
        const Icon = brandData?.logos.filter((item: any) => item?.type?.includes('icon'));
        const Logo = brandData?.logos.filter((item: any) => item?.type?.includes('logo'));

        if (Icon.length > 0) {
            setHasIcon(true);
            setIcon(Icon[0]?.formats[0]?.src);
        } else {
            setHasIcon(false);
            setLogo(Logo[0]?.formats[0]?.src);
        }
    }, [brandData]);

    useEffect(() => {
        if (presenceData) {
            const competitorKeywords = presenceData?.presence.map((item: any) => ({
                search_term: item.search_term,
                total_organic_searches: item.total_organic_searches,
            }));
            if (isFirstLoad.current && competitorKeywords) {
                dispatch(setFilterLoadedKeyword(competitorKeywords));
                isFirstLoad.current = false; // Mettre à jour le ref pour éviter un futur dispatch
            }
        }

    }, [presenceData, dispatch, location, isFirstLoad]);

    useEffect(() => {
        if (competitorData) {
            const devices = competitorData.filters?.map((item: any) => item.device);
            const countries = competitorData.filters?.map((item: any) => item.country);
            const uniqueCountries = countries.filter((item: any, index: any) => countries.indexOf(item) === index);
            const uniqueDevices = devices.filter((item: any, index: any) => devices.indexOf(item) === index);

            dispatch(setFilterLoadedCountry(uniqueCountries));
            dispatch(setFilterLoadedDevice(uniqueDevices));
        }
    }, [competitorData]);

    return (
        <Container maxWidth="xl" sx={{ marginTop: 5 }}>

            <>
                <Card
                    sx={{
                        mb: 3,
                        height: 290,
                    }}
                >
                    <ProfileCover
                        role={
                            workspace?.name.includes('Demo')
                                ? `${domain.replace(/\s+/g, '').replace(/([a-zA-Z])(\d)/g, '$1-$2')}.com`
                                : brandData?.domain
                        }

                        name={workspace?.name.includes('Demo') ? domain : brandData?.name}
                        employees={brandData?.company?.employees}
                        foundedYear={brandData?.company?.foundedYear}
                        industries={brandData?.company?.industries}
                        locations={brandData?.company?.locations}
                        kind={brandData?.company?.kind}
                        hasIcon={hasIcon}
                        avatarUrl={workspace?.name.includes('Demo') ? '/assets/logo.png' : hasIcon && Icon ? Icon : !hasIcon && Logo ? Logo : brandData?.logos[0]?.formats[0]?.src}
                        coverUrl={brandData?.images[0]?.formats[0]?.src}
                        primaryColor={brandData?.colors[0]?.hex}
                    />

                    <Tabs
                        value={currentTab}
                        onChange={handleChangeTab}
                        sx={{
                            width: 1,
                            bottom: 0,
                            zIndex: 9,
                            position: 'absolute',
                            bgcolor: 'background.paper',
                            [`& .${tabsClasses.flexContainer}`]: {
                                pr: { md: 3 },
                                justifyContent: {
                                    sm: 'center',
                                    md: 'flex-end',
                                },
                            },
                        }}
                    >
                        {TABS.map((tab) => (
                            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
                        ))}
                    </Tabs>
                </Card>


                {(currentTab === 'profile') &&
                    <ProfileHome
                        name={workspace?.name.includes('Demo') ? domain : brandData?.name}
                        isFetching={isFetchingDataPresence}
                        info={workspace?.name.includes('Demo') ? DemoInfo : brandData}
                        posts={presenceData}
                        presenceRate={presenceData?.presenceRate[0]?.total_presence_rate}
                        employees={workspace?.name.includes('Demo') ? DemoInfo?.company?.employees : brandData?.company?.employees}
                        foundedYear={workspace?.name.includes('Demo') ? DemoInfo?.company?.foundedYear : brandData?.company?.foundedYear}
                        industries={workspace?.name.includes('Demo') ? DemoInfo?.company?.industries : brandData?.company?.industries}
                        locations={brandData?.company?.locations}
                        kind={brandData?.company?.kind}
                    />
                }

                {(currentTab === 'Text Ads') &&
                    <TextAds domain={domain} workspaceTable={workspace?.bqTable} />
                }


                {(currentTab === 'Product Listing Ads') &&
                    <PlaAds domain={domain} workspaceTable={workspace?.bqTable} />
                }

            </>

        </Container>
    );
}
