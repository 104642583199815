import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';
import CompetitorsFocus from 'src/sections/overview/dashboards/views/competitorsFocus';

// ----------------------------------------------------------------------

export default function Home() {
  const { t } = useLocales()
  return (
    <>
      <Helmet>
        <title>{t('competitorFocus')}</title>
      </Helmet>

      <CompetitorsFocus />
    </>
  );
}
