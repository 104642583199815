import { alpha, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import SvgColor from '../../svg-color';
import { varHover } from 'src/components/animate';
import { m } from 'framer-motion';

// ----------------------------------------------------------------------

type Props = {
  icons: string[];
  value: string;
  options: string[];
  onChange: (newValue: string) => void;
};

export default function BaseOptions({ icons, value, onChange }: Props) {
  const theme = useTheme();

  const handleClick = () => {
    onChange(theme.palette.mode === 'dark' ? 'light' : 'dark');
  };

  return (
    <IconButton
      component={m.button}
      whileTap="tap"
      whileHover="hover"
      variants={varHover(1.05)}
      onClick={handleClick}
    >
      <SvgColor
        sx={{ cursor: 'pointer' }}
        src={`/assets/icons/setting/ic_${theme.palette.mode === 'dark' ? icons[0] : icons[1]}.svg`}
      />
    </IconButton>
  );
}
