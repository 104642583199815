import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';
import TrafficAnalysis from 'src/sections/overview/dashboards/views/trafficAnalysis';

// ----------------------------------------------------------------------

export default function Home() {
  const {t} = useLocales()
  return (
    <>
      <Helmet>
        <title>{t('trafficAnalysis')}</title>
      </Helmet>

      <TrafficAnalysis />
    </>
  );
}
