import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: any, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';
  if (!date) return '';

  // Convertit la date en objet Date
  const d = new Date(date);

  // Spécifie le fuseau horaire en UTC, peu importe l'emplacement de l'utilisateur
  const options: any = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone === "Europe/Paris" ?  "Europe/Paris" : 'UTC',
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  };

  // Formatage en anglais, par exemple, pour le 15 août 2024 => "Aug 15, 2024"
  return format(new Date(new Intl.DateTimeFormat('en-US', options).format(d)), fm);
}

// export function fDate(date: InputValue, newFormat?: string) {
//   const fm = newFormat || 'dd MMM yyyy';

//   return date ? format(new Date(date), fm) : '';
// }

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fCustomDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || "EEE, dd MMM yyyy HH:mm:ss 'GMT'";

  return date ? format(new Date(date), fm) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true,
    })
    : '';
}
