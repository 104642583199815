import { useState, useCallback, useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
// utils
import { fDateTime } from 'src/utils/format-time';
// types
import { IFile } from 'src/types/file';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
//
import FileManagerShareDialog from './file-manager-share-dialog';
import FileManagerInvitedItem from './file-manager-invited-item';
import { useLocales } from 'src/locales';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Skeleton, Tab, Tabs } from '@mui/material';
import FileManagerInvitedQuickEditForm from './file-manager-invited-quick-edit-item';
import { InlineEdit, Input, InputPicker, TagPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

import { useGetUserWorkspaceQuery, useUpdateWorkspaceMutation } from 'src/context/api/workspaces';
import { ThemeProvider, useTheme } from '@mui/system';
import { enqueueSnackbar } from 'notistack';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useCurrentUserQuery } from 'src/context/api/auth';

import FileManagerInvitedPendingItem from './file-manager-invited-pending-item';
import { usePermissions } from 'src/utils/permissionsContext';

// ----------------------------------------------------------------------

type Props = DrawerProps & {
  item: IFile;
  favorited?: boolean;
  bqTables?: any;

  //
  onFavorite?: VoidFunction;
  onCopyLink: VoidFunction;
  //
  onClose: VoidFunction;
  onDelete: VoidFunction;
  onUpdate?: (item: any) => void;
  onInvitation?: (item: any) => void;
};

export default function FileManagerFileDetails({
  item,
  open,
  favorited,
  bqTables,
  //
  onFavorite,
  onCopyLink,
  onClose,
  onDelete,
  onUpdate,
  onInvitation,
  ...other
}: Props) {
  const { name, size, url, friendlyDomains, isDataReady, showSavings, showPlaAlert, showTxtAlert, type, shared, modifiedAt, users, bqTable, created_at, id, template, custom, invitations_len, invitations, currency, averageCart } = item;
  const { t } = useLocales();
  const currentUser: any = useCurrentUserQuery();
  const { data: usersWorkspace, error, isLoading } = useGetUserWorkspaceQuery({ workspace_id: parseInt(id) }, {
    skip: !open
  });
  // const workspaceShared = item?.users
  const [shareActive, setShareActive] = useState<any>(usersWorkspace || []);
  const [tabValue, setTabValue] = useState(0);
  const theme: any = useTheme();
  const confirmDeleteWorkspace = useBoolean();
  const [confirmExit, setConfirmExit] = useState(false);
  const [update, updateWorkspace] = useUpdateWorkspaceMutation()
  const [isHovered, setIsHovered] = useState<string | null>(null);
  const urlRegex = /^(?!www\.)[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
  const [validUrl, setValidUrl] = useState(true);
  const [validAverageCart, setValidAverageCart] = useState(true);
  const handleMouseEnter = (field: string) => () => setIsHovered(field);
  const handleMouseLeave = () => setIsHovered(null);
  const editStyle = (field: string) => ({
    fontSize: 12,
    display: 'flex',
    gap: 2,
    justifyContent: 'space-between',
    borderRadius: 5,
    width: field === 'currency' ? '100%' : '70%',
    cursor: 'pointer',
    backgroundColor: isHovered === field && !editMode[field] ? 'rgba(28, 37, 46, 0.6)' : editMode[field] ? 'rgb(102, 101, 70)' : 'transparent',
    fontWeight: isHovered === field ? 'bold' : 'normal',
    border: 'none',
  });

  const share = useBoolean();
  const properties = useBoolean(true);
  const settings = useBoolean(false);
  const advancedSettings = useBoolean(false);

  const [inviteEmail, setInviteEmail] = useState('');
  const [currentInvitations, setCurrentInvitations] = useState<any>(invitations || []);
  const [combinedArray, setCombinedArray]: any = useState<String[]>([]);

  const [editMode, setEditMode] = useState<any>({
    name: false,
    url: false,
    isDataReady: false,
    friendlyDomains: false,
    showSavings: false,
    showPlaAlert: false,
    showTxtAlert: false,
    template: false,
    bqTable: false,
    custom: false,
    currency: false,
    averageCart: false
  });

  const [settingsValue, setSettingsValue] = useState<any>({
    id,
    name,
    url,
    friendlyDomains,
    isDataReady,
    showSavings,
    showPlaAlert,
    showTxtAlert,
    template,
    bqTable,
    custom,
    currency,
    averageCart,
    invitations
  });


  const [originalSettings, setOriginalSettings] = useState<any>({
    id,
    name,
    url,
    friendlyDomains,
    isDataReady,
    showSavings,
    showPlaAlert,
    showTxtAlert,
    template,
    bqTable,
    custom,
    currency,
    averageCart,
    invitations
  });

  const [hasChanges, setHasChanges] = useState(false);


  useEffect(() => {
    const relevantSettingsValue = {
      name: settingsValue.name,
      url: settingsValue.url,
      isDataReady: settingsValue.isDataReady,
      showSavings: settingsValue.showSavings,
      showPlaAlert: settingsValue.showPlaAlert,
      showTxtAlert: settingsValue.showTxtAlert,
      friendlyDomains: settingsValue.friendlyDomains,
      template: settingsValue.template,
      bqTable: settingsValue.bqTable,
      custom: settingsValue.custom,
      currency: settingsValue.currency,
      averageCart: settingsValue.averageCart
    };

    const relevantOriginalSettings = {
      name: originalSettings.name,
      url: originalSettings.url,
      friendlyDomains: originalSettings.friendlyDomains,
      isDataReady: originalSettings.isDataReady,
      showSavings: originalSettings.showSavings,

      showPlaAlert: originalSettings.showPlaAlert,
      showTxtAlert: originalSettings.showTxtAlert,
      template: originalSettings.template,
      bqTable: originalSettings.bqTable,
      custom: originalSettings.custom,
      currency: originalSettings.currency,
      averageCart: originalSettings.averageCart
    };

    const normalizeObject = (obj: any) => {
      return JSON.stringify(
        Object.keys(obj).sort().reduce((acc: any, key) => {
          acc[key] = obj[key];
          return acc;
        }, {})
      );
    }

    setHasChanges(normalizeObject(relevantSettingsValue) !== normalizeObject(relevantOriginalSettings));
  }, [settingsValue, originalSettings]);


  useEffect(() => {
    if (usersWorkspace) {
      setShareActive(usersWorkspace)
    }
  }, [usersWorkspace])

  const MetricsData = [
    { label: 'Default', value: 'Default' },
    { label: 'Custom', value: 'Custom' },
  ];
  const templateOptions = [
    { label: 'Lead', value: 'Lead' },
    { label: 'E-Commerce', value: 'E-commerce' },
  ];
  const currencyOptions = [
    { label: 'Usd', value: 'usd' },
    { label: 'Eur', value: 'eur' },
    { label: 'Gbp', value: 'gbp' },
    { label: 'Aud', value: 'aud' },
    { label: 'Cad', value: 'cad' },
    { label: 'Jpy', value: 'jpy' },
    { label: 'Ins', value: 'ins' },
  ];

  const validateDomain = (domain: any) => urlRegex.test(domain);

  const handlePickerChange = (field: any, value: any) => {
    setSettingsValue((prev: any) => ({ ...prev, [field]: value }));
  };

  const handleEditMode = () => {
    const newEditMode: any = { ...editMode };

    for (const field in settingsValue) {
      if (settingsValue[field] !== originalSettings[field]) {
        newEditMode[field] = true;
      } else {
        newEditMode[field] = false;
      }
    }

    setEditMode(newEditMode);
  };

  const handleChange = (field: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (field === 'custom') {
      const newCustomValue = event as any === 'Custom';
      setSettingsValue((prev: any) => ({ ...prev, [field]: newCustomValue }));
    }
    else if (field === 'url') {
      setSettingsValue((prev: any) => ({ ...prev, [field]: event }));
      const checkUrl = validateDomain(event);
      if (!checkUrl) {
        setValidUrl(false);
      } else {
        setValidUrl(true);
      }
    } else if (field === 'averageCart') {
      setSettingsValue((prev: any) => ({ ...prev, [field]: event }));
      const isOnlyDots = event.toString().split('.').every(part => part === '');
      const isValid = /^(\d*\.?\d*)?$/.test(event.toString());
      if ((isOnlyDots || !isValid && settingsValue?.averageCart !== originalSettings.averageCart) && event.toString() !== '') {
        setValidAverageCart(false);
      } else {
        setValidAverageCart(true);
      }

    } else if (field === 'template' || field === 'currency') {
      setSettingsValue((prev: any) => ({ ...prev, [field]: event }));
    }
    else {
      setSettingsValue((prev: any) => ({ ...prev, [field]: event }));

    }

  };

  const { canPerformAction } = usePermissions();
  const currentUserRole = currentUser?.data?.role || "";

  // set edit mode only if settingsValue !== originalSettings
  useEffect(() => {
    handleEditMode();
  }, [settingsValue]);

  const handleCancel = (field: any) => () => {
    if (field === 'all') {
      setSettingsValue({ ...originalSettings });
      setEditMode({
        name: false,
        url: false,
        template: false,
        bqTable: false,
        custom: false,
        friendlyDomains: false,
        showSavings: false,
        showPlaAlert: false,
        showTxtAlert: false,
        currency: false,
        averageCart: false
      });
      handleMouseLeave()
    } else if (field === 'url') {
      setSettingsValue({ ...originalSettings });
      setEditMode((prev: any) => ({ ...prev, [field]: false }));
      handleMouseLeave()
      setValidUrl(true);
    } else if (field === 'averageCart') {
      setSettingsValue({ ...originalSettings });
      setEditMode((prev: any) => ({ ...prev, [field]: false }));
      handleMouseLeave()
      setValidAverageCart(true);
    } else {
      setSettingsValue({ ...originalSettings });
      setEditMode((prev: any) => ({ ...prev, [field]: false }));
      handleMouseLeave()
    }

  };

  const handleSaveChanges = async () => {
    // Si friendlyDomains n'est pas un tableau, on tente de le convertir
    // par exemple, si l'utilisateur saisit plusieurs domaines séparés par des virgules
    if (!Array.isArray(settingsValue.friendlyDomains)) {
      const domainsString = settingsValue.friendlyDomains;

      if (typeof domainsString === 'string' && domainsString.trim() !== '') {
        // On divise la chaîne par les virgules, on nettoie les espaces et on enlève les entrées vides
        settingsValue.friendlyDomains = domainsString.split(',')
          .map((d) => d.trim())
          .filter((d) => d !== '');
      } else {
        // Si c'est une chaîne vide ou de type inattendu, on met un tableau vide
        settingsValue.friendlyDomains = [];
      }
    }

    if (validUrl && validAverageCart && hasChanges) {
      try {
        await update(settingsValue as any).unwrap().then(() => {
          enqueueSnackbar('Workspace update successfully')
          setOriginalSettings({ ...settingsValue });
          handleMouseLeave()
          setEditMode({
            name: false,
            url: false,
            friendlyDomains: false,
            showSavings: false,
            showPlaAlert: false,
            showTxtAlert: false,
            template: false,
            bqTable: false,
            custom: false,
            currency: false,
            averageCart: false
          })

        });
        onUpdate?.(settingsValue)
      } catch (error) {
        enqueueSnackbar('Error while updating workspace', { variant: 'error' });
      }
    } else if (!validUrl) {
      enqueueSnackbar('Invalid URL. Please enter a valid URL like "example.com".', { variant: 'error' });
    } else if (!validAverageCart) {
      enqueueSnackbar('Invalid Average Order Value. Please enter a valid Average Order Value like "1 or 1.00".', { variant: 'error' });
    }
  };


  const handleChangeInvite = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInviteEmail(event.target.value);
  }, []);

  useEffect(() => {
    if (invitations) {
      setCurrentInvitations(invitations)
    }
  }, [invitations])

  useEffect(() => {
    setCombinedArray((shareActive || []).concat(currentInvitations || []));
  }, [item, currentInvitations, usersWorkspace, shareActive])

  const metricValue = settingsValue?.custom === true ? 'Custom' : 'Default';

  const handleKeyDown = (event: any) => {
    const charCode = event.which || event.keyCode;
    // Autoriser uniquement les touches numériques, la touche "backspace" et les touches de contrôle comme "Tab"
    // Autoriser uniquement les touches numériques du clavier principal et du pavé numérique, ainsi que les touches de contrôle
    const isNumeric = (charCode >= 96 && charCode <= 105)
    // Les touches de contrôle comme Backspace, Tab, Delete, etc.
    const isControl = charCode === 8 || // Backspace
      charCode === 46 || // Delete
      charCode === 37 || // Left Arrow
      charCode === 39 || // Right Arrow
      charCode === 13 || // Enter
      charCode === 110  // Point du pavé numérique

    if (isNumeric || isControl) {
      return; // Autoriser les caractères valides
    }
    event.preventDefault(); // Bloquer les autres caractères
  };

  //  to remove invitation in invitations array
  const handleInvitationPending = async (invitation: any) => {

    if (invitation.toDelete) {

      try {
        setCurrentInvitations((prev: any) => prev.filter((item: any) => item.id !== invitation.id))
        setSettingsValue((prev: any) => ({ ...prev, invitations: prev.invitations.filter((item: any) => item.id !== invitation.id) }))

        await update(settingsValue as any).unwrap().then(() => {
          enqueueSnackbar('Workspace update successfully')
          setOriginalSettings({ ...settingsValue });
        });

      } catch (error) {
        enqueueSnackbar('Error updating workspace', { variant: 'error' });
      }
      onInvitation?.(invitation)
    }
  }

  const handleInvitations = async (invitation: any) => {
    try {
      const newInvit = {
        email: invitation.email,
        id: invitation.id,
        firstname: invitation.firstname,
        lastname: invitation.lastname,
        status: 'PENDING'
      }
      setCurrentInvitations((prev: any) => [...prev, newInvit])
      setSettingsValue((prev: any) => ({ ...prev, invitations: [...prev.invitations, newInvit] }))
      await update(settingsValue as any).unwrap().then(() => {
        enqueueSnackbar('Workspace update successfully')
        setOriginalSettings({ ...settingsValue });
      });
      onInvitation?.(newInvit)
      enqueueSnackbar(t('dashboard.workspacesTerms.success_invite_user'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error while updating workspace', { variant: 'error' });
    }

  }

  useEffect(() => {
    if (theme.palette.mode === "dark") {
      document.body.classList.add('dark-theme');
      document.body.classList.remove('light-theme');
    } else {
      document.body.classList.add('light-theme');
      document.body.classList.remove('dark-theme');
    }
  }, [theme.palette.mode]);



  useEffect(() => {
    const handleDocumentClick = (event: any) => {
      const pickerElement = document.querySelector('.rs-picker');
      if (pickerElement && !pickerElement.contains(event.target)) {
        pickerElement.classList.remove('rs-picker-focused');
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const renderSettings = (
    <Stack spacing={1.5}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="subtitle2"> {t('workspaces.settings')} </Typography>
        <IconButton size="small" onClick={settings.onToggle}>
          <Iconify
            icon={settings.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          />
        </IconButton>
      </Stack>
      {settings.value && (
        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
          {/* NAME */}
          <Grid item xs={3}>
            <Box sx={{ color: 'text.secondary', }}>
              <Typography variant="body2" sx={{}}> {t('workspaces.name')} </Typography>
            </Box>
          </Grid>
          <Grid item xs={9} >
            <Stack direction="row" gap={1}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                justifyContent: 'space-between',
                ".rs-inline-edit-controls": {
                  position: 'relative !important',
                  display: 'flex',
                  alignItems: 'center',
                },
                ".rs-inline-edit": {

                  "> div:hover": {
                    backgroundColor: 'transparent',
                  },
                  ".rs-input": {
                    backgroundColor: 'transparent',
                    color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
                    textAlign: 'flex-start',
                    ":hover": {
                      borderColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
                    },
                    ":focus": {
                      border: "none !important",
                      outline: "none !important",
                    },
                  },
                },
              }}>
              {
                currentUser?.data?.role === "admin" ? (
                  <>
                    <InlineEdit
                      showControls={false}
                      value={settingsValue?.name}
                      onChange={handleChange('name')}
                      onCancel={handleCancel('name')}

                      onMouseEnter={handleMouseEnter('name')}
                      onMouseLeave={handleMouseLeave}
                      style={editStyle('name')}
                    />
                    {editMode.name &&
                      <Box sx={{
                        position: 'relative',
                        height: '100%',
                        right: 0,
                        top: 0,
                        display: 'flex',
                        gap: 1,

                      }}>
                        <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#7635DC', color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', ":hover": { backgroundColor: '#7635DC' } }} onClick={handleSaveChanges}>
                          <Iconify icon="material-symbols:check" />
                        </Button>
                        <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#fff', color: theme.palette.mode === 'dark' ? '#000' : '#fff', ":hover": { backgroundColor: '#fff' } }} onClick={handleCancel('name')}>
                          <Iconify icon="basil:cross-outline" />
                        </Button>

                      </Box>

                    }
                  </>
                ) : (
                  <Typography variant="body2" >
                    {name}
                  </Typography>
                )
              }
            </Stack>
          </Grid>
          {/* URL */}
          <Grid item xs={3}>
            <Box sx={{ color: 'text.secondary', }}>
              <Typography variant="body2">{t('workspaces.Url')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Stack direction="row" gap={1}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                justifyContent: 'space-between',
                ".rs-inline-edit-controls": {
                  position: 'relative !important',
                  display: 'flex',
                  alignItems: 'center',
                },
                ".rs-inline-edit": {
                  "> div:hover": {
                    backgroundColor: 'transparent',
                  },

                  ".rs-input": {
                    backgroundColor: 'transparent',
                    color: !validUrl ? 'red' : theme.palette.mode === 'dark' ? '#ffffff' : '#000000',

                    ":hover": {
                      borderColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
                    },
                    ":focus": {
                      border: !validUrl ? '2px solid red' : 'none !important',

                      outline: 'none !important',
                    },
                  },
                },
              }}>
              {
                currentUser?.data?.role === "admin" ? (
                  <>
                    <InlineEdit
                      showControls={false}
                      value={
                        settingsValue?.url
                        && settingsValue.url.charAt(0).toLowerCase() + settingsValue.url.slice(1)
                      }
                      onChange={handleChange('url')}
                      onCancel={handleCancel('url')}
                      style={editStyle('url')}
                      onMouseEnter={handleMouseEnter('url')}
                      onMouseLeave={handleMouseLeave}
                    />
                    {editMode.url &&
                      <Box sx={{ position: 'relative', right: 0, top: 0, display: 'flex', gap: 1, }}>
                        <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#7635DC', color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', ":hover": { backgroundColor: '#7635DC' } }} onClick={handleSaveChanges}>
                          <Iconify icon="material-symbols:check" />
                        </Button>
                        <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#fff', color: theme.palette.mode === 'dark' ? '#000' : '#fff', ":hover": { backgroundColor: '#fff' } }} onClick={handleCancel('name')}>
                          <Iconify icon="basil:cross-outline" />
                        </Button>

                      </Box>

                    }
                  </>

                ) : (
                  <Typography variant="body2" >
                    {url}
                  </Typography>
                )
              }
            </Stack>
          </Grid>

          {/* TEMPLATE */}
          <Grid item xs={3}>
            <Box sx={{ color: 'text.secondary', }}>
              <Typography variant="body2">{t('workspaces.template')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Stack direction="row" gap={1}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                justifyContent: 'space-between',
                ".rs-inline-edit-controls": {
                  position: 'relative !important',
                  display: 'flex',
                  alignItems: 'center',
                },
                ".rs-inline-edit": {
                  "> div:hover": {
                    backgroundColor: 'transparent',
                  },
                  ".rs-picker-input": {
                    backgroundColor: 'transparent !important',
                    border: 'none',
                    outline: "none !important",

                    ":focus": {
                      border: "#fff !important",
                    }
                  },
                  // select input style
                  ".rs-picker": {
                    outline: "none !important",
                    ".rs-btn": {
                      backgroundColor: 'transparent !important',
                      ".rs-picker-toggle-value": {
                        color: theme.palette.mode === 'dark' ? '#ffffff !important' : '#000000 !important',
                      },
                      ".rs-picker-toggle": {
                        borderColor: "none !important"
                      },
                      ".rs-picker-toggle-active": {
                        borderColor: "none !important"
                      },
                      ":hover": {
                        borderColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
                      },
                      ":focus": {
                        border: "none !important",
                        outline: "none !important",
                      },
                    },
                  },
                  ".rs-picker-toggle-active, .rs-picker.rs-picker-focused": {
                    outline: "none !important",
                    border: "none !important",
                    "webkitBoxShadow": "none !important",
                    "boxShadow": "none !important",
                  },
                  "& .rs-picker-focused": {
                    border: "none !important",
                    outline: "none !important",
                  },
                },

                ".rs-picker-clean ": {
                  display: 'none',
                },

              }}>
              {
                currentUser?.data?.role === "admin" ? (
                  <>
                    <ThemeProvider theme={theme}>
                      <InlineEdit
                        showControls={false}
                        value={settingsValue?.template}
                        onCancel={handleCancel('template')}
                        style={editStyle('template')}
                        onMouseEnter={handleMouseEnter('template')}
                        onMouseLeave={handleMouseLeave}
                      >
                        <InputPicker data={templateOptions} style={{ position: 'relative', flex: 1 }}
                          menuStyle={{ zIndex: 1300, alignContent: 'center' }}
                          onSelect={((value) => handlePickerChange('template', value))}
                        />
                      </InlineEdit>

                    </ThemeProvider>
                    {editMode.template &&
                      <Box sx={{ position: 'relative', right: 0, top: 0, display: 'flex', gap: 1, }}>
                        <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#7635DC', color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', ":hover": { backgroundColor: '#7635DC' } }} onClick={handleSaveChanges}>
                          <Iconify icon="material-symbols:check" />
                        </Button>
                        <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#fff', color: theme.palette.mode === 'dark' ? '#000' : '#fff', ":hover": { backgroundColor: '#fff' } }} onClick={handleCancel('name')}>
                          <Iconify icon="basil:cross-outline" />
                        </Button>

                      </Box>

                    }
                  </>

                ) : (
                  <Typography variant="body2" >
                    {settingsValue?.template}
                  </Typography>
                )
              }
            </Stack>
          </Grid>

          {/* AVERAGE ORDER VALUE */}
          {
            settingsValue?.template === 'E-commerce' && (
              <>
                <Grid item xs={3}>
                  <Box sx={{ color: 'text.secondary' }}>
                    <Typography variant="body2">{t('dashboard.workspacesTerms.averageOrderValue')}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Stack direction="row" gap={1}
                    sx={{
                      width: '95%',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      justifyContent: 'space-between',
                      ".rs-inline-edit-controls": {
                        position: 'relative !important',
                        display: 'flex',
                        alignItems: 'center',
                      },
                      ".rs-inline-edit": {
                        "> div:hover": {
                          backgroundColor: 'transparent',
                        },
                        ".rs-input": {
                          backgroundColor: 'transparent !important',
                          color: !validAverageCart ? 'red' : theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
                          border: !validAverageCart ? '1px solid red' : 'none',
                          ":hover": {
                            borderColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
                          },
                          ":focus": {
                            border: "none !important",
                            outline: "none !important",
                          },
                        },
                      },
                    }}>
                    {
                      currentUser?.data?.role === "admin" ? (
                        <>
                          <InlineEdit
                            showControls={false}
                            onKeyDown={handleKeyDown}
                            value={settingsValue?.averageCart}
                            onChange={handleChange('averageCart')}

                            onCancel={handleCancel('averageCart')}
                            datatype='number'
                            style={editStyle('averageCart')}
                            onMouseEnter={handleMouseEnter('averageCart')}
                            onMouseLeave={handleMouseLeave}
                          />
                          {editMode.averageCart &&
                            <Box sx={{ position: 'relative', right: 0, top: 0, display: 'flex', gap: 1, }}>
                              <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#7635DC', color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', ":hover": { backgroundColor: '#7635DC' } }} onClick={handleSaveChanges}>
                                <Iconify icon="material-symbols:check" />
                              </Button>
                              <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#fff', color: theme.palette.mode === 'dark' ? '#000' : '#fff', ":hover": { backgroundColor: '#fff' } }} onClick={handleCancel('name')}>
                                <Iconify icon="basil:cross-outline" />
                              </Button>

                            </Box>

                          }
                        </>

                      ) : (
                        <Typography variant="body2" >
                          {settingsValue?.averageCart}
                        </Typography>
                      )
                    }
                  </Stack>
                </Grid>
              </>

            )
          }
          {/* CURRENCY */}
          <Grid item xs={3}>
            <Box sx={{ color: 'text.secondary', }}>
              <Typography variant="body2">{t('workspaces.currency')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Stack direction="row" gap={1}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                justifyContent: 'space-between',
                ".rs-inline-edit-controls": {
                  position: 'relative !important',
                  display: 'flex',
                  alignItems: 'center',
                },
                ".rs-inline-edit": {
                  "> div:hover": {
                    backgroundColor: 'transparent',
                  },
                  ".rs-picker-input": {
                    backgroundColor: 'transparent !important',
                    border: 'none',
                    outline: "none !important",

                    ":focus": {
                      border: "#fff !important",
                    }
                  },
                  // select input style
                  ".rs-picker": {
                    ".rs-btn": {
                      backgroundColor: 'transparent !important',
                      ".rs-picker-toggle-value": {
                        color: theme.palette.mode === 'dark' ? '#ffffff !important' : '#000000 !important',
                      },
                      ".rs-picker-toggle": {
                        borderColor: "#5119B7 !important"
                      },
                      ".rs-picker-toggle-active": {
                        borderColor: "#5119B7 !important"
                      },
                      ":hover": {
                        borderColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
                      },
                      ":focus": {
                        border: "none !important",
                        outline: "none !important",
                      },
                    },
                  },
                  ".rs-picker-toggle-active, .rs-picker.rs-picker-focused": {
                    outline: "none !important",
                    border: "none !important",
                    "webkitBoxShadow": "none !important",
                    "boxShadow": "none !important",
                  },
                  ".rs-picker-focused": {
                    border: "none !important",
                    outline: "none !important",
                  },
                },

                ".rs-picker-clean ": {
                  display: 'none',
                },

              }}>
              {
                currentUser?.data?.role === "admin" ? (
                  <>
                    <ThemeProvider theme={theme} >
                      <InlineEdit
                        showControls={false}
                        value={settingsValue?.currency}
                        onCancel={handleCancel('currency')}
                        style={{ ...editStyle('currency'), width: '60%' }}
                        onMouseEnter={handleMouseEnter('currency')}
                        onMouseLeave={handleMouseLeave}                    >
                        <InputPicker

                          data={currencyOptions}
                          style={{ position: 'relative', }}
                          menuStyle={{ zIndex: 1300, alignContent: 'center', }}
                          onSelect={((value) => handlePickerChange('currency', value))}


                        />
                      </InlineEdit>
                    </ThemeProvider>

                    {editMode.currency &&
                      <Box sx={{ position: 'relative', right: 0, top: 0, display: 'flex', gap: 1, }}>
                        <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#7635DC', color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', ":hover": { backgroundColor: '#7635DC' } }} onClick={handleSaveChanges}>
                          <Iconify icon="material-symbols:check" />
                        </Button>
                        <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#fff', color: theme.palette.mode === 'dark' ? '#000' : '#fff', ":hover": { backgroundColor: '#fff' } }} onClick={handleCancel('name')}>
                          <Iconify icon="basil:cross-outline" />
                        </Button>
                      </Box>
                    }
                  </>
                ) : (
                  <Typography variant="body2" >
                    {settingsValue?.currency}
                  </Typography>
                )
              }
            </Stack>
          </Grid>

          {/* DATA SOURCE */}
          <Grid item xs={3}>
            <Box sx={{ color: 'text.secondary' }}>
              <Typography variant="body2">{t('workspaces.data_source')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Stack direction="row" gap={1}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                justifyContent: 'space-between',
                ".rs-inline-edit-controls": {
                  position: 'relative !important',
                  display: 'flex',
                  alignItems: 'center',
                },
                ".rs-inline-edit": {
                  "> div:hover": {
                    backgroundColor: 'transparent',
                  },
                  ".rs-picker-input": {
                    backgroundColor: 'transparent !important',
                    border: "none !important",
                    outline: "none !important",
                    ":focus": {
                      border: "#fff !important",
                    }
                  },
                  // select input style
                  ".rs-picker": {
                    ":active": {
                      border: "none !important",
                      outline: "solid none !important",
                    },
                    ".rs-btn": {
                      backgroundColor: 'transparent !important',
                      ".rs-picker-toggle-value": {
                        color: theme.palette.mode === 'dark' ? '#ffffff !important' : '#000000 !important',
                      },
                      ".rs-picker-toggle": {
                        borderColor: "none !important"
                      },
                      ".rs-picker-toggle-active": {
                        borderColor: "none !important"
                      },
                      ":hover": {
                        borderColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
                      },
                      ":focus": {
                        border: "none !important",
                        outline: "none !important",
                      },
                    },
                  },
                  ".rs-picker-toggle-active": {
                    outline: "none !important",
                    border: "none !important",
                    "webkitBoxShadow": "none !important",
                    "boxShadow": "none !important",
                  },
                  ".rs-picker.rs-picker-focused": {
                    outline: "none !important",
                    border: "none !important",
                    "webkitBoxShadow": "none !important",
                    "boxShadow": "none !important",
                  },

                  ".rs-picker-focused": {
                    border: "none !important",
                    outline: "none !important",
                  },

                },

                ".rs-picker-clean ": {
                  display: 'none',
                },

              }}>
              {
                currentUser?.data?.role === "admin" ? (
                  <>
                    <ThemeProvider theme={theme}>
                      <InlineEdit
                        disabled
                        showControls={false}
                        value={settingsValue?.bqTable}
                        onCancel={handleCancel('bqTable')}
                        style={{ ...editStyle('bqTable'), width: '80%' }}
                        onMouseEnter={handleMouseEnter('bqTable')}
                        onMouseLeave={handleMouseLeave}
                      >
                        <InputPicker
                          data={bqTables}
                          style={{ position: 'relative', flex: 1, }}
                          menuStyle={{ zIndex: 1300, alignContent: 'center' }}
                          onSelect={((value) => handlePickerChange('bqTable', value))}


                        />
                      </InlineEdit>
                    </ThemeProvider>
                    {
                      editMode.bqTable &&
                      <Box sx={{ position: 'relative', right: 0, top: 0, display: 'flex', gap: 1, }}>
                        <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#7635DC', color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', ":hover": { backgroundColor: '#7635DC' } }} onClick={handleSaveChanges}>
                          <Iconify icon="material-symbols:check" />
                        </Button>
                        <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#fff', color: theme.palette.mode === 'dark' ? '#000' : '#fff', ":hover": { backgroundColor: '#fff' } }} onClick={handleCancel('name')}>
                          <Iconify icon="basil:cross-outline" />
                        </Button>

                      </Box>
                    }
                  </>

                ) : (
                  <Typography variant="body2" >
                    {settingsValue?.bqTable}
                  </Typography>
                )
              }
            </Stack>
          </Grid>

          {/* METRICS */}
          <Grid item xs={3}>
            <Box sx={{ color: 'text.secondary', }}>
              <Typography variant="body2">{t('workspaces.metrics')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Stack direction="row" gap={1}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                justifyContent: 'space-between',
                ".rs-inline-edit-controls": {
                  position: 'relative !important',
                  display: 'flex',
                  alignItems: 'center',
                },
                ".rs-inline-edit": {
                  "> div:hover": {
                    backgroundColor: 'transparent',
                  },
                  ".rs-picker-input": {
                    backgroundColor: 'transparent !important',
                    border: "none !important",
                    outline: "none !important",

                    ":focus": {
                      border: "none !important",
                    }
                  },
                  // select input style
                  ".rs-picker": {
                    ".rs-btn": {
                      backgroundColor: 'transparent !important',
                      ".rs-picker-toggle-value": {
                        color: theme.palette.mode === 'dark' ? '#ffffff !important' : '#000000 !important',
                      },
                      ".rs-picker-toggle": {
                        borderColor: "none !important"
                      },
                      ".rs-picker-toggle-active": {
                        borderColor: "none !important"
                      },
                      ":hover": {
                        borderColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
                      },
                      ":focus": {
                        border: "none !important",
                        outline: "none !important",

                      },
                    },
                  },
                  ".rs-picker-toggle-active, .rs-picker.rs-picker-focused": {
                    outline: "none !important",
                    border: "none !important",
                    "webkitBoxShadow": "none !important",
                    "boxShadow": "none !important",
                  },
                  ".rs-picker-focused": {
                    border: "none !important",
                    // outline: "none !important",
                    outline: "none !important",
                  },
                },

                ".rs-picker-clean ": {
                  display: 'none',
                },

              }}>
              {
                currentUser?.data?.role === "admin" ? (
                  <>
                    <ThemeProvider theme={theme}>
                      <InlineEdit
                        showControls={false}
                        value={metricValue}
                        onChange={handleChange('custom')}
                        onCancel={handleCancel('custom')}
                        style={editStyle('custom')}
                        onMouseEnter={handleMouseEnter('custom')}
                        onMouseLeave={handleMouseLeave}              >
                        <InputPicker
                          data={MetricsData}
                          style={{ position: 'relative', flex: 1 }}
                          onSelect={((value) => handlePickerChange('custom', value))}
                          menuStyle={{ zIndex: 1300, alignContent: 'center' }}
                        />
                      </InlineEdit>
                    </ThemeProvider>

                    {
                      editMode.custom &&
                      <Box sx={{ position: 'relative', right: 0, top: 0, display: 'flex', gap: 1, }}>
                        <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#7635DC', color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', ":hover": { backgroundColor: '#7635DC' } }} onClick={handleSaveChanges}>
                          <Iconify icon="material-symbols:check" />
                        </Button>
                        <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#fff', color: theme.palette.mode === 'dark' ? '#000' : '#fff', ":hover": { backgroundColor: '#fff' } }} onClick={handleCancel('name')}>
                          <Iconify icon="basil:cross-outline" />
                        </Button>

                      </Box>
                    }
                  </>

                ) : (
                  <Typography variant="body2" >
                    {metricValue}
                  </Typography>
                )
              }
            </Stack>
          </Grid>

        </Grid>
      )}

    </Stack>
  )

  const renderProperties = (
    <Stack spacing={1.5}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ typography: 'subtitle2' }}
      >
        {t('workspaces.properties')}
        <IconButton size="small" onClick={properties.onToggle}>
          <Iconify
            icon={properties.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          />
        </IconButton>
      </Stack>
      {properties.value && (

        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
          <Grid item xs={3.5} >
            <Box sx={{ color: 'text.secondary', }}>
              <Typography variant="body2">{t('workspaces.created_at')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={8.5}>
            <Typography variant="body2" >{fDateTime(created_at)}</Typography>
          </Grid>
          <Grid item xs={3.5}>
            <Box sx={{ color: 'text.secondary' }}>
              <Typography variant="body2">{t('workspaces.users')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={8.5}>
            <Typography variant="body2" >{users?.length} {users && users?.length > 1 ? t('workspaces.users') : t('workspaces.user')}</Typography>
          </Grid>
          <Grid item xs={3.5}>
            <Box sx={{ color: 'text.secondary', }}>
              <Typography variant="body2">{t('workspaces.invitations')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={8.5}>
            <Typography variant="body2" >{currentInvitations.length} {currentInvitations && currentInvitations.length > 1 ? t('workspaces.pending_invitations') : t('workspaces.pending_invitation')}</Typography>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: {
      borderBottom: tabValue === index ? '2px solid #5119B7' : 'none',
    },
  })

  const handleDeleteUser = (person: any) => {
    if (person.toDelete) {
      setCombinedArray((prev: any) => prev.filter((item: any) => item.email !== person.email))
      setShareActive((prev: any) => prev.filter((item: any) => item.email !== person.email))
      setCurrentInvitations((prev: any) => prev.filter((item: any) => item.email !== person.email))
      setSettingsValue((prev: any) => ({ ...prev, invitations: prev.invitations.filter((item: any) => item.email !== person.email) }))
      onInvitation?.({ email: person.email, id: person.id, workspaceId: item.id, toDelete: true })
    }
  }
  const handleActiveUser = (person: any) => {
    if (person.toDelete) {
      setShareActive((prev: any) => prev.filter((item: any) => item.email !== person.email))
      setCombinedArray((prev: any) => prev.filter((item: any) => item.email !== person.email))

    }
  }

  const renderShared = (
    <Box sx={{ height: "auto", mb: 10 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2.5, }}>
        <Typography variant="subtitle2"> {t('workspaces.share_workspace')} </Typography>
        {
          (currentUser?.data?.role === "admin" || currentUser?.data?.role === "brand specialist" || currentUser?.data?.role === "consultant") && (
            <IconButton
              size="small"
              color="primary"
              onClick={share.onTrue}
              sx={{
                width: 24,
                height: 24,
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            >
              <Iconify icon="mingcute:add-line" />
            </IconButton>

          )
        }
      </Stack>
      <Tabs
        value={tabValue}
        onChange={handleChangeTab}
        aria-label="basic tabs example"
        sx={{
          display: 'flex',
          justifyContent: 'center', // Centre le contenu des tabs 
          mb: 1.5,
          '& .MuiTabs-flexContainer': { justifyContent: 'center' },
          borderBottom: '1px solid rgba(145, 158, 171, 0.2)'
        }}>
        <Tab
          wrapped
          iconPosition='end'
          label={<Typography variant="subtitle2" > {t('dashboard.global.all')} </Typography>}
          icon={
            <Typography variant="subtitle2" sx={{ backgroundColor: '#333D48', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 25, height: 25, p: 0.4, borderRadius: 1, color: '#fff' }}> {combinedArray?.length}  </Typography>
          }
          {...a11yProps(0)} />
        <Tab
          wrapped
          iconPosition='end'

          label={<Typography variant="subtitle2"> {t('dashboard.global.active')} </Typography>}

          icon={
            <Typography variant="subtitle2" sx={{ backgroundColor: theme.palette.mode === 'dark' ? '#20443B' : '#d8f3e2', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 25, height: 25, p: 0.4, borderRadius: 1, color: theme.palette.mode === 'dark' ? '#77ED8B' : '#118D57' }}> {shareActive?.length}  </Typography>
          }
          {...a11yProps(1)} />
        <Tab
          wrapped
          iconPosition='end'
          label={<Typography variant="subtitle2"> {t('dashboard.pending')} </Typography>}
          icon={
            <Typography variant="subtitle2" sx={{ backgroundColor: theme.palette.mode === 'dark' ? '#453E2B' : '#FCEED3', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 25, height: 25, p: 0.4, borderRadius: 1, color: theme.palette.mode === 'dark' ? '#FFD666' : '#B76E00' }}> {currentInvitations?.length}  </Typography>
          }
          {...a11yProps(2)} />
      </Tabs>


      {(!combinedArray || combinedArray.length === 0) && shareActive.length === 0 ? (
        <Skeleton width="100%" height="100%" variant="rectangular" animation="wave" sx={{ maxHeight: '100%', minHeight: '435px' }}>
          <Iconify py="auto" icon="eva:checkmark-circle-2-fill" color="success.main" />
          <Typography color="success.main">Active</Typography>
        </Skeleton>
      ) : (
        <>
          {tabValue === 0 && (
            <Box>
              {combinedArray && (
                <Box sx={{ pl: 2.5, pr: 1 }}>
                  {combinedArray?.map((user: any, index: any) => {
                    return (
                      <FileManagerInvitedItem
                        key={index}
                        editable={false}
                        person={user}
                        workspaceId={parseInt(id)}
                        onInvitation={handleDeleteUser}
                        showButton={canPerformAction(currentUserRole, "delete_user", user?.role)}
                      />
                    )
                  })}
                </Box>
              )}
            </Box>
          )}
          {tabValue === 1 && (
            <Box>
              {shareActive && (
                <Box sx={{ pl: 2.5, pr: 1 }}>
                  {(() => {
                    return shareActive.map((user: any, index: any) => (
                      <FileManagerInvitedQuickEditForm
                        key={index}
                        editable={false}
                        person={user}
                        workspaceId={parseInt(id)}
                        invitations={invitations}
                        onInvitation={handleActiveUser}
                        showButton={canPerformAction(currentUserRole, "delete_user", user?.role)}
                      />
                    ));
                  })()}
                </Box>
              )}
            </Box>
          )}
          {tabValue === 2 && (
            <Box>
              {currentInvitations && (
                <Box sx={{ pl: 2.5, pr: 1 }}>
                  {currentInvitations.map((user: any, index: any) => (
                    <FileManagerInvitedPendingItem
                      key={index}
                      editable={false}
                      person={user}
                      workspaceId={parseInt(id)}
                      onInvitation={handleInvitationPending}
                      showButton={canPerformAction(currentUserRole, "delete_user", user?.role)}
                    />
                  ))}
                </Box>
              )}
            </Box>
          )}
        </>
      )}



    </Box>
  );

  const handleResetValues = () => {

    setSettingsValue({ ...originalSettings });
    setEditMode({
      name: false,
      url: false,
      template: false,
      bqTable: false,
      custom: false,
      currency: false,
      averageCart: false
    });
    handleMouseLeave()
    setConfirmExit(false);
    onClose();
  }

  const handleClose = () => {
    if (hasChanges) {
      setConfirmExit(true);
    } else {
      onClose();
    }
  }

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor="right"
      slotProps={{
        backdrop: { invisible: true },
      }}
      PaperProps={{
        sx: { width: 350 },
      }}
      {...other}
    >
      <Scrollbar sx={{ height: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2.5 }}>
          <Typography variant="h6"> Workspace Information </Typography>
        </Stack>
        <Stack
          spacing={2.5}
          justifyContent="center"
          sx={{
            p: 2.5,
            bgcolor: 'background.neutral',
          }}
        >
          <Box sx={{
            maxWidth: 52,
            padding: 1.4,
            backgroundColor: theme.palette.mode === 'dark' ? '#28323D' : ' #F4F6F8',
            color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
            borderRadius: '10px',
          }}>
            <Box component="img" src={name.includes("Demo") ? '/assets/logo.png' : `https://www.google.com/s2/favicons?domain=${url}&sz=64`} sx={{ width: 30, height: 30, borderRadius: 1 }} />
          </Box>
          <Typography variant="subtitle1" >
            {name}
          </Typography>

          {(currentUser?.data?.role === "admin" || currentUser?.data?.role === "brand specialist") && (
            <>
              <Divider sx={{ borderStyle: 'dashed' }} />
              {renderSettings}
            </>
          )}

          {(currentUser?.data?.role === "admin" || currentUser?.data?.role === "brand specialist") && (
            <>
              <Divider sx={{ borderStyle: 'dashed' }} />
              <Stack spacing={1.5}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2">Advanced Settings</Typography>
                  <IconButton size="small" onClick={advancedSettings.onToggle}>
                    <Iconify
                      icon={advancedSettings.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                    />
                  </IconButton>
                </Stack>
                {advancedSettings.value && (
                  <>
                    {/* OTHER DOMAINS */}
                    <Grid item xs={9}>
                      {advancedSettings.value && (
                        <>
                          {/* OTHER DOMAINS */}
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Box sx={{ color: 'text.secondary' }}>
                              <Typography variant="body2">Other Domains</Typography>
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                              {currentUser?.data?.role === "admin" ? (
                                editMode.friendlyDomains ? (
                                  // Mode édition : utilisation d'un TagPicker pour gérer plusieurs domaines
                                  <Box sx={{ width: '100%' }}>
                                    <TagPicker
                                      data={(settingsValue.friendlyDomains || []).map((d: string) => ({ label: d, value: d }))}
                                      value={(settingsValue.friendlyDomains || [])}
                                      onChange={(values) =>
                                        setSettingsValue((prev: any) => ({ ...prev, friendlyDomains: values }))
                                      }
                                      creatable
                                      style={{ width: '100%', backgroundColor: '#242c37' }}
                                      placeholder="Add domains"
                                    />
                                    {editMode.friendlyDomains && (
                                      <Box
                                        sx={{
                                          position: 'relative',
                                          right: 0,
                                          top: 0,
                                          display: 'flex',
                                          gap: 1,
                                          mt: 1,
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          sx={{
                                            minWidth: 'auto',
                                            minHeight: 37,
                                            backgroundColor: '#7635DC',
                                            color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
                                            ":hover": { backgroundColor: '#7635DC' },
                                          }}
                                          onClick={handleSaveChanges}
                                        >
                                          <Iconify icon="material-symbols:check" />
                                        </Button>
                                        <Button
                                          variant="contained"
                                          sx={{
                                            minWidth: 'auto',
                                            minHeight: 37,
                                            backgroundColor: '#fff',
                                            color: theme.palette.mode === 'dark' ? '#000' : '#fff',
                                            ":hover": { backgroundColor: '#fff' },
                                          }}
                                          onClick={handleCancel('friendlyDomains')}
                                        >
                                          <Iconify icon="basil:cross-outline" />
                                        </Button>
                                      </Box>
                                    )}
                                  </Box>
                                ) : (
                                  // Mode affichage : affiche la liste des domaines ou un texte par défaut
                                  <Typography
                                    variant="body2"
                                    style={{ whiteSpace: 'pre-wrap', cursor: 'pointer' }}
                                    onMouseEnter={handleMouseEnter('friendlyDomains')}
                                    onMouseLeave={handleMouseLeave}
                                    sx={{ ml: 1 }}
                                    onClick={() => {
                                      setEditMode((prev: any) => ({ ...prev, friendlyDomains: true }));
                                    }}
                                  >
                                    {(settingsValue.friendlyDomains || []).length > 0
                                      ? (settingsValue.friendlyDomains || []).join(', ')
                                      : "No domains added"}
                                  </Typography>
                                )
                              ) : (
                                // Si l'utilisateur n'est pas admin, on affiche la liste ou un texte par défaut
                                <Typography variant="body2" style={{ whiteSpace: 'no-wrap' }}>
                                  {(settingsValue.friendlyDomains || []).length > 0
                                    ? (settingsValue.friendlyDomains || []).join(', ')
                                    : "No domains added"}
                                </Typography>
                              )}
                            </Box>
                          </Box>



                          {/* DATA READY */}
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                            <Box sx={{ color: 'text.secondary' }}>
                              <Typography variant="body2" sx={{ width: "80%" }}>Data Ready</Typography>
                            </Box>
                            <Checkbox
                              checked={settingsValue.isDataReady}
                              onChange={(e) => setSettingsValue((prev: any) => ({ ...prev, isDataReady: e.target.checked }))}
                            />
                            {editMode.isDataReady &&
                              <Box sx={{ position: 'relative', right: 0, top: 0, display: 'flex', gap: 1, }}>
                                <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#7635DC', color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', ":hover": { backgroundColor: '#7635DC' } }} onClick={handleSaveChanges}>
                                  <Iconify icon="material-symbols:check" />
                                </Button>
                                <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#fff', color: theme.palette.mode === 'dark' ? '#000' : '#fff', ":hover": { backgroundColor: '#fff' } }} onClick={handleCancel('isDataReady')}>
                                  <Iconify icon="basil:cross-outline" />
                                </Button>

                              </Box>
                            }
                          </Box>
                          {/* SHOW SAVINGS*/}
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                            <Box sx={{ color: 'text.secondary' }}>
                              <Typography variant="body2" sx={{ width: "80%" }}>Show Savings</Typography>
                            </Box>
                            <Checkbox
                              checked={settingsValue.showSavings}
                              onChange={(e) => setSettingsValue((prev: any) => ({ ...prev, showSavings: e.target.checked }))}
                            />
                            {editMode.showSavings &&
                              <Box sx={{ position: 'relative', right: 0, top: 0, display: 'flex', gap: 1, }}>
                                <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#7635DC', color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', ":hover": { backgroundColor: '#7635DC' } }} onClick={handleSaveChanges}>
                                  <Iconify icon="material-symbols:check" />
                                </Button>
                                <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#fff', color: theme.palette.mode === 'dark' ? '#000' : '#fff', ":hover": { backgroundColor: '#fff' } }} onClick={handleCancel('showSavings')}>
                                  <Iconify icon="basil:cross-outline" />
                                </Button>

                              </Box>
                            }
                          </Box>

                          {/* SHOW PLA ALERT */}
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
                            <Box sx={{ color: 'text.secondary' }}>
                              <Typography variant="body2" sx={{ width: "80%" }}>Show PLA Brand Infringement Alerts</Typography>
                            </Box>
                            <Checkbox
                              checked={settingsValue.showPlaAlert}
                              onChange={(e) => setSettingsValue((prev: any) => ({ ...prev, showPlaAlert: e.target.checked }))}
                            />
                            {editMode.showPlaAlert &&
                              <Box sx={{ position: 'relative', right: 0, top: 0, display: 'flex', gap: 1, }}>
                                <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#7635DC', color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', ":hover": { backgroundColor: '#7635DC' } }} onClick={handleSaveChanges}>
                                  <Iconify icon="material-symbols:check" />
                                </Button>
                                <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#fff', color: theme.palette.mode === 'dark' ? '#000' : '#fff', ":hover": { backgroundColor: '#fff' } }} onClick={handleCancel('name')}>
                                  <Iconify icon="basil:cross-outline" />
                                </Button>

                              </Box>
                            }
                          </Box>

                          {/* SHOW TXT ALERT */}
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
                            <Box sx={{ color: 'text.secondary' }}>
                              <Typography variant="body2" sx={{ width: "80%" }}>Show TXT Brand Infringement Alerts</Typography>
                            </Box>
                            <Checkbox
                              checked={settingsValue.showTxtAlert}
                              onChange={(e) => setSettingsValue((prev: any) => ({ ...prev, showTxtAlert: e.target.checked }))}
                            />
                            {editMode.showTxtAlert &&
                              <Box sx={{ position: 'relative', right: 0, top: 0, display: 'flex', gap: 1, }}>
                                <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#7635DC', color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', ":hover": { backgroundColor: '#7635DC' } }} onClick={handleSaveChanges}>
                                  <Iconify icon="material-symbols:check" />
                                </Button>
                                <Button variant="contained" sx={{ minWidth: 'auto', minHeight: 37, backgroundColor: '#fff', color: theme.palette.mode === 'dark' ? '#000' : '#fff', ":hover": { backgroundColor: '#fff' } }} onClick={handleCancel('name')}>
                                  <Iconify icon="basil:cross-outline" />
                                </Button>

                              </Box>
                            }
                          </Box>
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </Stack>
            </>
          )}

          <Divider sx={{ borderStyle: 'dashed' }} />
          {renderProperties}

        </Stack>
        {renderShared}
        {
          currentUser?.data?.role === "admin" && (
            <Box sx={{ flex: 1, p: 2, display: 'flex', backgroundColor: theme.palette.mode === 'dark' ? '#202A35' : '#fff', justifyContent: 'center', alignItems: 'center', position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 10 }}>
              <Button variant="contained" sx={{ flex: 1, gap: 1, height: 48, backgroundColor: theme.palette.mode === 'dark' ? '#462d2d' : '#ffdfd8', color: theme.palette.mode === 'dark' ? '#ffac82' : '#b71d18', ":hover": { backgroundColor: theme.palette.mode === 'dark' ? '#462d2d' : '#ffdfd8' } }} onClick={confirmDeleteWorkspace.onTrue}>
                <Iconify icon="solar:trash-bin-trash-bold" />
                Delete workspace
              </Button>
            </Box>
          )
        }
      </Scrollbar>
      {/* EXIT CONFIRM  */}
      <Dialog fullWidth maxWidth="xs" open={confirmExit} onClose={onClose} {...other}>
        <DialogTitle sx={{ pb: 2 }}>{t('dashboard.global.save_changes')}</DialogTitle>
        <DialogContent sx={{ typography: 'body2' }}> {t('dashboard.global.are_you_sure_you_want_to_close_without_saving')} </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={() => {
            setConfirmExit(false);
            handleSaveChanges()
            setEditMode({
              name: false,
              url: false,
              template: false,
              bqTable: false,
              custom: false,
              currency: false,
              averageCart: false
            })
            // setConfirmExit(false);
          }}>
            {t('dashboard.global.save_changes')}
          </Button>

          <Button variant="outlined" color="inherit" onClick={handleResetValues}>
            {t('dashboard.global.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* DELETE CONFIRM */}
      <ConfirmDialog
        sx={{ mt: 2 }}
        open={confirmDeleteWorkspace.value}
        onClose={confirmDeleteWorkspace.onFalse}
        title={t('dashboard.global.delete')}
        content={t('dashboard.global.are_you_sure_you_want_to_delete')}
        action={
          <Button variant="contained" color="error" onClick={onDelete}>
            {t('dashboard.global.delete')}
          </Button>
        }
      />
      <FileManagerShareDialog
        id={id}
        item={item}
        open={share.value}
        shared={shared}
        inviteEmail={inviteEmail}
        onChangeInvite={handleChangeInvite}
        onClose={() => {
          share.onFalse();
          setInviteEmail('');
        }}
        onUpdate={handleInvitations}
      />
    </Drawer>
  );
}