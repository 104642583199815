// ----------------------------------------------------------------------

export const _id = [...Array(40)].map(
  (_, index) => `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b${index + 1}`
);

export const _fullAddress = [
  '19034 Verna Unions Apt. 164 - Honolulu, RI / 87535',
  '1147 Rohan Drive Suite 819 - Burlington, VT / 82021',
  '18605 Thompson Circle Apt. 086 - Idaho Falls, WV / 50337',
  '110 Lamar Station Apt. 730 - Hagerstown, OK / 49808',
  '36901 Elmer Spurs Apt. 762 - Miramar, DE / 92836',
  '2089 Runolfsson Harbors Suite 886 - Chapel Hill, TX / 32827',
  '279 Karolann Ports Apt. 774 - Prescott Valley, WV / 53905',
  '96607 Claire Square Suite 591 - St. Louis Park, HI / 40802',
  '9388 Auer Station Suite 573 - Honolulu, AK / 98024',
  '47665 Adaline Squares Suite 510 - Blacksburg, NE / 53515',
  '989 Vernice Flats Apt. 183 - Billings, NV / 04147',
  '91020 Wehner Locks Apt. 673 - Albany, WY / 68763',
  '585 Candelario Pass Suite 090 - Columbus, LA / 25376',
  '80988 Renner Crest Apt. 000 - Fargo, VA / 24266',
  '28307 Shayne Pike Suite 523 - North Las Vegas, AZ / 28550',
  '205 Farrell Highway Suite 333 - Rock Hill, OK / 63421',
  '253 Kara Motorway Suite 821 - Manchester, SD / 09331',
  '13663 Kiara Oval Suite 606 - Missoula, AR / 44478',
  '8110 Claire Port Apt. 703 - Anchorage, TN / 01753',
  '4642 Demetris Lane Suite 407 - Edmond, AZ / 60888',
  '74794 Asha Flat Suite 890 - Lancaster, OR / 13466',
  '8135 Keeling Pines Apt. 326 - Alexandria, MA / 89442',
  '441 Gibson Shores Suite 247 - Pasco, NM / 60678',
  '4373 Emelia Valley Suite 596 - Columbia, NM / 42586',
];

// ----------------------------------------------------------------------

export const _booleans = [
  true,
  true,
  true,
  false,
  false,
  true,
  false,
  false,
  false,
  false,
  true,
  true,
  true,
  false,
  false,
  false,
  true,
  false,
  false,
  false,
  true,
  false,
  false,
  true,
];

// ----------------------------------------------------------------------

export const _emails = [
  'nannie_abernathy70@yahoo.com',
  'ashlynn_ohara62@gmail.com',
  'milo.farrell@hotmail.com',
  'violet.ratke86@yahoo.com',
  'letha_lubowitz24@yahoo.com',
  'aditya_greenfelder31@gmail.com',
  'lenna_bergnaum27@hotmail.com',
  'luella.ryan33@gmail.com',
  'joana.simonis84@gmail.com',
  'marjolaine_white94@gmail.com',
  'vergie_block82@hotmail.com',
  'vito.hudson@hotmail.com',
  'tyrel_greenholt@gmail.com',
  'dwight.block85@yahoo.com',
  'mireya13@hotmail.com',
  'dasia_jenkins@hotmail.com',
  'benny89@yahoo.com',
  'dawn.goyette@gmail.com',
  'zella_hickle4@yahoo.com',
  'avery43@hotmail.com',
  'olen_legros@gmail.com',
  'jimmie.gerhold73@hotmail.com',
  'genevieve.powlowski@hotmail.com',
  'louie.kuphal39@gmail.com',
];

// ----------------------------------------------------------------------

export const _fullNames = [
  'Jayvion Simon',
  'Lucian Obrien',
  'Deja Brady',
  'Harrison Stein',
  'Reece Chung',
  'Lainey Davidson',
  'Cristopher Cardenas',
  'Melanie Noble',
  'Chase Day',
  'Shawn Manning',
  'Soren Durham',
  'Cortez Herring',
  'Brycen Jimenez',
  'Giana Brandt',
  'Aspen Schmitt',
  'Colten Aguilar',
  'Angelique Morse',
  'Selina Boyer',
  'Lawson Bass',
  'Ariana Lang',
  'Amiah Pruitt',
  'Harold Mcgrath',
  'Esperanza Mcintyre',
  'Mireya Conner',
];

export const _firstNames = [
  'Mossie',
  'David',
  'Ebba',
  'Chester',
  'Eula',
  'Jaren',
  'Boyd',
  'Brady',
  'Aida',
  'Anastasia',
  'Gregoria',
  'Julianne',
  'Ila',
  'Elyssa',
  'Lucio',
  'Lewis',
  'Jacinthe',
  'Molly',
  'Brown',
  'Fritz',
  'Keon',
  'Ella',
  'Ken',
  'Whitney',
];

export const _lastNames = [
  'Carroll',
  'Simonis',
  'Yost',
  'Hand',
  'Emmerich',
  'Wilderman',
  'Howell',
  'Sporer',
  'Boehm',
  'Morar',
  'Koch',
  'Reynolds',
  'Padberg',
  'Watsica',
  'Upton',
  'Yundt',
  'Pfeffer',
  'Parker',
  'Zulauf',
  'Treutel',
  'McDermott',
  'McDermott',
  'Cruickshank',
  'Parisian',
];

// ----------------------------------------------------------------------

export const _prices = [
  83.74, 97.14, 68.71, 85.21, 52.17, 25.18, 43.84, 60.98, 98.42, 53.37, 72.75, 56.61, 64.55, 77.32,
  60.62, 79.81, 93.68, 47.44, 76.24, 92.87, 72.91, 20.54, 94.25, 37.51,
];

export const _ratings = [
  4.2, 3.7, 4.5, 3.5, 0.5, 3.0, 2.5, 2.8, 4.9, 3.6, 2.5, 1.7, 3.9, 2.8, 4.1, 4.5, 2.2, 3.2, 0.6,
  1.3, 3.8, 3.8, 3.8, 2.0,
];

export const _ages = [
  30, 26, 59, 47, 29, 46, 18, 56, 39, 19, 45, 18, 46, 56, 38, 41, 44, 48, 32, 45, 42, 60, 33, 57,
];

export const _percents = [
  10.1, 13.6, 28.2, 42.1, 37.2, 18.5, 40.1, 94.8, 91.4, 53.0, 25.4, 62.9, 86.6, 62.4, 35.4, 17.6,
  52.0, 6.8, 95.3, 26.6, 69.9, 92.1, 46.2, 85.6,
];

export const _nativeS = [
  11, 10, 7, 10, 12, 5, 10, 1, 8, 8, 10, 11, 12, 8, 4, 11, 8, 9, 4, 9, 2, 6, 3, 7,
];

export const _nativeM = [
  497, 763, 684, 451, 433, 463, 951, 194, 425, 435, 807, 521, 538, 839, 394, 269, 453, 821, 364,
  849, 804, 776, 263, 239,
];

export const _nativeL = [
  9911, 1947, 9124, 6984, 8488, 2034, 3364, 8401, 8996, 5271, 8478, 1139, 8061, 3035, 6733, 3952,
  2405, 3127, 6843, 4672, 6995, 6053, 5192, 9686,
];

// ----------------------------------------------------------------------

export const _phoneNumbers = [
  '365-374-4961',
  '904-966-2836',
  '399-757-9909',
  '692-767-2903',
  '990-588-5716',
  '955-439-2578',
  '226-924-4058',
  '552-917-1454',
  '285-840-9338',
  '306-269-2446',
  '883-373-6253',
  '476-509-8866',
  '201-465-1954',
  '538-295-9408',
  '531-492-6028',
  '981-699-7588',
  '500-268-4826',
  '205-952-3828',
  '222-255-5190',
  '408-439-8033',
  '272-940-8266',
  '812-685-8057',
  '353-801-5212',
  '606-285-8928',
];

// ----------------------------------------------------------------------

export const _roles = [
  'HR Manager',
  'Data Analyst',
  'Legal Counsel',
  'UX/UI Designer',
  'Project Manager',
  'Account Manager',
  'Registered Nurse',
  'Business Analyst',
  'Creative Director',
  'Financial Planner',
  'Event Coordinator',
  'Marketing Director',
  'Software Developer',
  'Research Scientist',
  'Content Strategist',
  'Operations Manager',
  'Sales Representative',
  'Supply Chain Analyst',
  'Operations Coordinator',
  'Customer Service Associate',
  'Quality Assurance Specialist',
  'CEO',
  'CFO',
  'CTO',
];

// ----------------------------------------------------------------------

export const _postTitles = [
  '10 Essential Tips for Healthy Living',
  'The Ultimate Guide to Productivity Hacks',
  'Exploring the Hidden Gems of [Destination]',
  'How to Master the Art of Public Speaking',
  'The Future of Artificial Intelligence: Trends and Insights',
  'Delicious Recipes for a Vegan Diet',
  "A Beginner's Guide to Investing in Stocks",
  'The Impact of Social Media on Society',
  '10 Must-Visit Destinations for Adventure Travelers',
  'The Benefits of Mindfulness Meditation',
  'The Importance of Mental Health Awareness',
  'Building a Strong Personal Brand: Tips and Strategies',
  '10 Effective Strategies for Digital Marketing Success',
  'Unveiling the Secrets of Successful Entrepreneurs',
  'The Rise of Remote Work and its Impact on the Workforce',
  'The Art of Landscape Photography: Techniques and Inspiration',
  'Understanding Blockchain Technology and its Potential Applications',
  'How to Create Engaging Content for Social Media',
  'The Role of Artificial Intelligence in Healthcare',
  '10 Home Organization Hacks for a Clutter-Free Space',
  'Exploring the History and Culture of [City/Region]',
  'The Power of Positive Thinking: Transform Your Mindset',
  'The Influence of Music on Mood and Emotions',
  'Travel Photography Tips: Capturing Memories Around the World',
];

// ----------------------------------------------------------------------

export const _productNames = [
  'Nike Air Force 1 NDESTRUKT',
  'Foundations Matte Flip Flop',
  'Nike Air Zoom Pegasus 37 A.I.R. Chaz Bear',
  'Arizona Soft Footbed Sandal',
  'Boston Soft Footbed Sandal',
  'Zoom Freak 2',
  'Gazelle Vintage low-top sneakers',
  'Jordan Delta',
  'Air Jordan XXXV PF',
  'Rod Laver low-top sneakers',
  'Kyrie 7 EP Sisterhood',
  'Pharrell Williams Human Race NMD sneakers',
  'Nike Blazer Low 77 Vintage',
  'ASMC Winter Boot Cold.Rdy',
  'ZX 8000 Lego sneakers',
  'Ultraboost 21 sneakers',
  '2750 Cotu Classic Sneaker',
  'ZX 9000 A-ZX Series sneakers',
  'Madrid Big Buckle Sandal',
  'Chuck 70 Hi Sneaker',
  'Relaxed Adjustable Strap Slingback Sandal',
  'Superturf Adventure X Atmos',
  'Chuck Taylor All Star Lift Sneaker',
  'Run Star Hike Platform Sneaker',
];

// ----------------------------------------------------------------------

export const _tourNames = [
  'Adventure Seekers Expedition',
  'Historic Heritage Tour',
  'Culinary Delights Exploration',
  "Nature's Wonders Escapade",
  'Cultural Immersion Journey',
  'Wildlife Safari Expedition',
  "Urban Explorer's City Tour",
  'Coastal Paradise Getaway',
  'Wine Tasting Experience',
  'Spiritual Retreat Tour',
  'Outdoor Adventure Trek',
  'Photography Expedition',
  'Music and Arts Discovery Tour',
  'Wellness and Yoga Retreat',
  'Hidden Gems Discovery Tour',
  'Volcano and Geothermal Exploration',
  "Foodie's Gastronomic Tour",
  'Hiking and Camping Adventure',
  'Architecture and Design Tour',
  'Coastal Cruise and Island Hopping',
  'Scenic Train Ride Experience',
  'Historical Landmarks Expedition',
  'Surfing and Beach Adventure',
  'Nightlife and Entertainment Tour',
];

// ----------------------------------------------------------------------

export const _jobTitles = [
  'Software Engineer',
  'Marketing Manager',
  'Financial Analyst',
  'Graphic Designer',
  'Sales Representative',
  'Project Manager',
  'Data Scientist',
  'Human Resources Coordinator',
  'Accountant',
  'Customer Service Representative',
  'Nurse',
  'Product Manager',
  'Operations Manager',
  'Social Media Specialist',
  'Business Development Executive',
  'Content Writer',
  'Web Developer',
  'Electrical Engineer',
  'Research Scientist',
  'Legal Assistant',
  'Chef',
  'Financial Planner',
  'Architect',
  'Event Planner',
];

// ----------------------------------------------------------------------

export const _companyNames = [
  'Lueilwitz and Sons',
  'Gleichner, Mueller and Tromp',
  'Nikolaus - Leuschke',
  'Hegmann, Kreiger and Bayer',
  'Grimes Inc',
  'Durgan - Murazik',
  'Altenwerth, Medhurst and Roberts',
  'Raynor Group',
  'Mraz, Donnelly and Collins',
  'Padberg - Bailey',
  'Heidenreich, Stokes and Parker',
  'Pagac and Sons',
  'Rempel, Hand and Herzog',
  'Dare - Treutel',
  'Kihn, Marquardt and Crist',
  'Nolan - Kunde',
  'Wuckert Inc',
  'Dibbert Inc',
  'Goyette and Sons',
  'Feest Group',
  'Bosco and Sons',
  'Bartell - Kovacek',
  'Schimmel - Raynor',
  'Tremblay LLC',
];

// ----------------------------------------------------------------------

export const _tags = [
  'Technology',
  'Marketing',
  'Design',
  'Photography',
  'Art',
  'Fashion',
  'Food',
  'Travel',
  'Fitness',
  'Nature',
  'Business',
  'Music',
  'Health',
  'Books',
  'Sports',
  'Film',
  'Education',
  'Motivation',
  'Gaming',
  'Pets',
  'Cooking',
  'Finance',
  'Selfcare',
  'Writing',
];

// ----------------------------------------------------------------------

export const _taskNames = [
  'Complete Project Proposal',
  'Conduct Market Research',
  'Design User Interface Mockups',
  'Develop Backend API',
  'Implement Authentication System',
  'Write Test Cases',
  'Perform Database Optimization',
  'Create Content Marketing Plan',
  'Update Website Copy',
  'Conduct A/B Testing',
  'Create Social Media Graphics',
  'Optimize Website Performance',
  'Review Competitor Websites',
  'Implement Payment Gateway Integration',
  'Conduct User Acceptance Testing',
  'Prepare Monthly Sales Report',
  'Enhance SEO Strategy',
  'Conduct Customer Satisfaction Survey',
  'Design Email Newsletter Template',
  'Monitor Server Logs for Errors',
  'Create Training Materials',
  'Plan and Execute Marketing Campaign',
  'Develop Mobile Application',
  'Coordinate Project Meetings',
];

// ----------------------------------------------------------------------

export const _sentences = [
  'The sun slowly set over the horizon, painting the sky in vibrant hues of orange and pink.',
];

// ----------------------------------------------------------------------

export const _descriptions = [
  `Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut. Est quidem iusto praesentium excepturi harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium doloribus eaque debitis.`,
];
