import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';
import BrandOverview from 'src/sections/overview/dashboards/views/brandOverview';

// ----------------------------------------------------------------------

export default function Home() {
    const { t } = useLocales()
    return (
        <>
            <Helmet>
                <title>{t('brandOverview')}</title>
            </Helmet>

            <BrandOverview />
        </>
    );
}
