import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import App from './app';
import store from './context/store';
import Maintenance from './pages/maintenance/maintenance';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const isMaintenanceMode = false;

if (isMaintenanceMode) {
  window.history.replaceState({}, '', '/maintenance');
}

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense>
          {isMaintenanceMode ? <Maintenance /> : <App />}
        </Suspense>
      </BrowserRouter>
    </Provider>
  </HelmetProvider>
);
