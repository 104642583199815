import { Card, Grid, Typography, useTheme } from "@mui/material";
import AccountGeneralSettings from "./profile-settings";

export default function AccountGeneral() {
    const theme = useTheme();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Card sx={{ padding: 3, }}>
                    <Typography variant="subtitle1" fontWeight={'bold'} sx={{ color: theme.palette.mode == "dark" ? "white" : 'black', textAlign: 'start', pb: 3 }}>
                        User Information
                    </Typography>
                    <AccountGeneralSettings />
                </Card>
            </Grid>
        </Grid>
    )
}    