import React, { useEffect, useRef, useState } from 'react';
import Chart, { useChart } from 'src/components/chart';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useLocales } from 'src/locales';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { generateAnnotations } from 'src/utils/graph-demo-annotations';
import { formatCurrency } from 'src/utils/format-currency';

type Props = {
  series: {
    name: string;
    type: string;
    data: number[];
  }[];
  labels: string[];
};

export default function ChartMixed({ series, labels }: Props) {
  const { t } = useLocales();
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const chartRef = useRef<any>(null);
  const [hiddenSeries, setHiddenSeries] = useState<string[]>([t('dashboard.global.blended_cpc'), t('dashboard.global.blended_roi'), t('dashboard.global.blended_cpo')]);
  const [visibleSeriesData, setVisibleSeriesData] = useState(series);
  const workspace = useCurrentWorkspace();

  const annotations = generateAnnotations(workspace,filterStore, labels || [])
  const lang = localStorage.getItem('i18nextLng') || 'en';

  useEffect(() => {
    const updatedSeries = series.map(serie => ({
      ...serie,
      show: !hiddenSeries.includes(serie.name)
    }));
    setVisibleSeriesData(updatedSeries);

    if (chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      hiddenSeries.forEach(seriesName => {
        chart.hideSeries(seriesName);
      });
    }
  }, [series, hiddenSeries]);

  useEffect(() => {
    setHiddenSeries([t('dashboard.global.blended_cpc'), t('dashboard.global.blended_roi'), t('dashboard.global.blended_cpo')]);
  }, [t]);

  const toggleSeries = (seriesName: string) => {
    setHiddenSeries(prevHiddenSeries => {
      const isHidden = prevHiddenSeries.includes(seriesName);
      if (chartRef.current && chartRef.current.chart) {
        const chart = chartRef.current.chart;
        if (isHidden) {
          chart.showSeries(seriesName);
        } else {
          chart.hideSeries(seriesName);
        }
      }
      return isHidden ? prevHiddenSeries.filter(name => name !== seriesName) : [...prevHiddenSeries, seriesName];
    });
  };


  const chartOptions = useChart({
    chart: {
      stacked: series.some(serie => serie.type === 'stacked'),
      events: {
        legendClick: function (chartContext, seriesIndex: any, config) {
          const seriesName = config.globals.seriesNames[seriesIndex];
          toggleSeries(seriesName);
          return false;
        }
      },
      animations: {
        enabled: true, // Ensure animations are enabled
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    series:visibleSeriesData,
    plotOptions: {
      bar: {
        columnWidth: '50%',
        borderRadius: 0,
      },
    },
    labels: labels,
    fill: {
      colors: [theme.palette.warning.light, "#006c9c", "#5119b7"]
    },
    colors: [theme.palette.warning.light, "#006c9c", "#5119b7"],
    markers: {
      size: 6
    },
    xaxis: {
      // labels: {
      //   formatter: filterStore?.timestep === "date"
      //     ? val => {
      //       const date = new Date(val);
      //       const year = date.getUTCFullYear();
      //       const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      //       const day = String(date.getUTCDate()).padStart(2, '0');
      //       return `${year}-${month}-${day}`;
      //     }
      //     : undefined,
      // },
    },
    yaxis: [
      {
        title: {
          text: t('dashboard.global.ad_cost'),
        },
        labels: {
          formatter: function (value) {
            return value !== undefined ? "$" + value.toFixed(1) : "";
          },
        },
      },
      {
        opposite: true,
        title: {
          text: t('dashboard.global.blended_revenue'),
        },
        min: 0,
        labels: {
          formatter: function (value) {
            return value !== undefined ? value.toFixed(1) : "";
          },
        },
      },
      {
        opposite: true,
        title: {
          text: t('dashboard.global.blended_cpo'),
        },
        min: 0,
        labels: {
          formatter: function (value) {
            return value !== undefined ? value.toFixed(1) : "";
          },
        },
      },
      {
        title: {
          text: t('dashboard.global.blended_cpc'),
        },
        min: 0,
        labels: {
          formatter: function (value) {
            return value !== undefined ? "$" + value : "";
          },
        },
      },
      {
        opposite: true,
        title: {
          text: t('dashboard.global.blended_roi'),
        },
        min: 0,
        labels: {
          formatter: function (value) {
            return value !== undefined ? value.toFixed(1) : "";
          },
        },
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value, { seriesIndex, w }) => {
          if (value === undefined) return "";
          const seriesName = w.config.series[seriesIndex].name;

          if (seriesName === t('dashboard.global.ad_cost') || seriesName === t('dashboard.global.blended_revenue') || seriesName === t('dashboard.global.blended_cpo')) {
            return formatCurrency(value, workspace?.currency || "USD", lang, true, 1);
          } else if (seriesName === t('dashboard.global.blended_cpc')) {
            return formatCurrency(value, workspace?.currency || "USD", lang, true, 2);
          }

          return `${value.toFixed(2)}`;
        },
      },
    },
    annotations:{
      xaxis:annotations,
    },
    grid: {
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      }
    }
  });

  return <Chart dir="ltr" ref={chartRef} series={visibleSeriesData} options={chartOptions} sx={{ width: { xs: '100%', lg: '95%' } }} height={350} />;
}
